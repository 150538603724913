import React, {MutableRefObject, useContext, useRef} from 'react';
import * as Phase2State from './state';
import {DEFAULT_STATE, State} from './state';
import {Button, Col, Divider, Form, Input, Radio, Row, Select, Tabs} from "antd";
import PersonSearch from "../../../../components/search/PersonSearch";
import PersonSearchByCred from "../../../../components/search/PersonSearchByCred";
import {Person} from "common/types/carriable";
import './phase-2-scheduler.less';
import * as Util from "./util";
import CargoForm from "../../../../components/form/CargoForm";
import { formItemLayout} from "../../../../common/form";
import DepDestSelect from "components/form/select/dep-dest-select";

const BASE_CLS = Util.BASE_CLS;

export interface CarriableSelectorProps {
    cargoFormRef: MutableRefObject<any>
}

const CarriableSelector: React.FC<CarriableSelectorProps> = (props) => {

    const { api } = useContext(Phase2State.Context);
    const cargoFormRef = useRef(null);

    let personSearchContent = null;
    let tabBarExtra = null;

    const searchFormValues = api.forms.searchForm.get();

    const { upperTab, lowerTab } = api.carriableSelector.tabs.get();

    if (upperTab === 'personSearch'){
        tabBarExtra = (
            <Radio.Group
                value={lowerTab}
                onChange={(e) => {
                    let lowerT = e.target.value as typeof DEFAULT_STATE.carriableSelector.lowerTab;
                    api.carriableSelector.tabs.set(upperTab, lowerT);
                }}
            >
                <Radio value="byName">By Name</Radio>
                <Radio value="byId">By ID</Radio>
            </Radio.Group>
        )
    }

    if (lowerTab === 'byName'){
        personSearchContent = (
            <PersonSearch
                style={{
                    height: '100%'
                }}
                filterOptions={{
                    lastName: searchFormValues.lastName,
                    firstName: searchFormValues.firstName
                }}
                selectedItems={api.selection.personnelIds.get()}
                onSelection={api.selection.personnelIds.set}
                filterResults={(persons: Person[]) =>
                    persons.filter((person) => !api.getScheduledPaxNodes().find((p) => p?.personID?._id === person._id))
                }
            />
        )
    }
    else if (lowerTab === 'byId'){
        personSearchContent = (
            <PersonSearchByCred
                style={{
                    height: '100%'
                }}
                filterOptions={{
                    credKey: searchFormValues.credId
                }}
                selectedItems={api.selection.personnelIds.get()}
                onSelection={api.selection.personnelIds.set}
            />
        )
    }

    let selectedPersonnelSelector = <span>Please select a person</span>;
    if (api.selection.personnelIds.get().length) {
        selectedPersonnelSelector = (
            <Select
                loading={api.selection.personnelIds.getAsObjects().loading}
                className={BASE_CLS + '-personnel-select'}
                mode="multiple"
                showArrow={false}
                disabled={!api.selection.personnelIds.get().length}
                showSearch={false}
                open={false}
                labelInValue
                onChange={(val) => api.selection.personnelIds.set(val.map(val => val?.key))}
                value={
                    api.selection.personnelIds.getAsObjects()?.objects?.map((per) => {
                        return {
                            key: per._id,
                            label: `${per.lastName}, ${per.firstName}`
                        }
                    })
                }
            >
                {api.selection.personnelIds.getAsObjects()?.objects?.map((per) => {
                    return <Select.Option
                        key={per._id}
                        value={per._id}
                    >{`${per.lastName}, ${per.firstName}`}</Select.Option>
                })}
            </Select>
        )
    }

    const departureValue = api.carriableSelector.legSelector.get().departure;
    const destinationValue = api.carriableSelector.legSelector.get().destination;

    const addUI = (
        <Row type="flex" gutter={6} style={{padding: 6}}>
            <Col style={{flex: 1}}>
                <DepDestSelect
                    value={api.carriableSelector.legSelector.get()}
                    onChange={(value) => (
                        api.carriableSelector.legSelector.set(value?.departure, value?.destination)
                    )}
                />
            </Col>
            <Col>
                {upperTab === 'personSearch' ? (
                    <Button
                        type="primary"
                        disabled={Boolean((!api.selection.personnelIds.get().length || !departureValue?.key || !destinationValue?.key))}
                        onClick={() => api.selection.personnelIds.addToSchedule(departureValue?.key, destinationValue?.key)}
                    >
                        Add
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        disabled={
                            !Util.allRequiredFieldsHaveValue(
                                {...api.forms.cargoForm.get()},
                                [
                                    'name',
                                    'weight',
                                    'attentionTo',
                                    'initials'
                                ]
                            ) ||
                            !departureValue?.key ||
                            !destinationValue?.key
                        }
                        onClick={() => {
                            api.forms.cargoForm.submit(
                                departureValue?.key,
                                destinationValue?.key
                            )
                        }}
                    >
                        Add
                    </Button>
                )}

            </Col>
        </Row>
    );

    return (
        <div className={BASE_CLS + '-left-panel'}>
            <Tabs
                type="card"
                className={"ant-tabs-flex " + BASE_CLS + '-left-panel-content'}
                defaultActiveKey="personSearch"
                tabBarGutter={7}
                tabBarStyle={{padding: '7px 8px 0 8px', margin: 0}}
                activeKey={upperTab}
                onChange={(key) => {
                    api.carriableSelector.tabs.set(key as State['carriableSelector']['upperTab'], lowerTab);
                }}
                tabBarExtraContent={tabBarExtra}
                style={{
                    height: '100%'
                }}
            >
                <Tabs.TabPane key="personSearch" tab="Personnel Search">
                    {personSearchContent}
                    <div className={BASE_CLS + '-selected-personnel'}>
                        <div className={BASE_CLS + '-selected-personnel-header'}>
                            <span>Adding {api.selection.personnelIds.get().length} personnel</span>
                            {api.selection.personnelIds.get().length ? (
                                <span style={{float: 'right'}}>
                        <Button
                            type="link"
                            size="small"
                            onClick={api.selection.personnelIds.clear}
                        >Clear All</Button>
                    </span>
                            ) : null}
                        </div>
                        <Row gutter={6} style={{padding: '6px 6px 0px 6px'}}>
                            <Col span={24} className={BASE_CLS + '-personnel-select-wrapper'}>
                                {selectedPersonnelSelector}
                            </Col>
                        </Row>
                        {addUI}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    key="cargoForm"
                    tab="Add Cargo"
                    style={{
                        padding: 12
                    }}
                >
                    <CargoForm
                        ref={(ref) => {
                            cargoFormRef.current = ref;
                            if (props.cargoFormRef) {
                                props.cargoFormRef.current = ref;
                            }
                        }}
                        useFields={[
                            'name',
                            'weight',
                            'hazmat',
                            'hazmatUnCode',
                            'attentionTo',
                            'notes'
                        ]}
                        renderExtra={(form) => {
                            return <Form.Item label="Initials" {...formItemLayout}>
                                {form.getFieldDecorator('initials', {
                                    rules: [{ required: true, message: 'Initials is required' }]
                                })(<Input maxLength={2} />)}
                            </Form.Item>
                        }}
                        onFieldsChange={api.forms.cargoForm.setFields}
                        fields={api.forms.cargoForm.get()}
                    />
                    <Divider type="horizontal" style={{ margin: 0 }} />
                    <div style={{ padding: 6 }}>
                        {addUI}
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default CarriableSelector;