import gql from "graphql-tag";

// eslint-disable-next-line import/no-anonymous-default-export
export default gql`
enum OwnerFilter {
    own
    all
}
type ScheduleFilter {
    owner: OwnerFilter,
    startDate: String
    endDate: String
}
type UserFilter {
    paxSets: ScheduleFilter
    scheduledPax: ScheduleFilter
    scheduledFlights: ScheduleFilter
}
type PaxGlobalFilter {
    lastName: String
    firstName: String
}

input PaxGlobalFilterInput {
    lastName: String
    firstName: String
}

type CgoGlobalFilter {
    name: String
}

input CgoGlobalFilterInput {
    name: String
}

type GlobalFilter {
    visible: Boolean
    customer: [SelectItem]
    transporter: [SelectItem]
    departure: [SelectItem]
    destination: [SelectItem]
    employer: [SelectItem]
    pax: [PaxGlobalFilter]
    cgo: [CgoGlobalFilter]

}

type TableSettings {
    visibleColumns: [String!]
}

type TableSettingsItem {
    name: String!
    settings: TableSettings!
}

type SelectItem {
    key: ID
    label: String
}
input SelectItemInput {
    key: ID
    label: String
}
extend type Query {
    appLoaded: Boolean
    cognitoUser: String
    userFilter: UserFilter
    getGlobalFilter: GlobalFilter
    globalFilter: GlobalFilter
    s3Report(id: String!, encoding: String): Report
    tableSettings: [TableSettingsItem!]!
}
extend type Mutation {
    setPaxSetsOwnerFilter(payload: String!): OwnerFilter
    setScheduledPaxOwnerFilter(payload: String!): OwnerFilter
    setScheduledFlightsOwnerFilter(payload: String!): OwnerFilter
    setGlobalFilter(
        visible: Boolean
        customer: [SelectItemInput]
        transporter: SelectItemInput
        departure: [SelectItemInput]
        destination: [SelectItemInput]
        employer: [SelectItemInput]
        pax: [PaxGlobalFilterInput]
        cgo: [CgoGlobalFilterInput]
    ): GlobalFilter
    resetGlobalFilter: GlobalFilter
    setPaginationLimit(limit: Int!): Int,
    tableSettings(name: String!, action: String!, payload: String): [TableSettings!]!
}
`