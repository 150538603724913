import moment, { Moment } from 'moment-timezone';

// Based on https://github.com/aws/aws-sdk-js-v3/issues/1877#issuecomment-967223047
// To help with AWS's stupid change that makes reading S3 objects overly complicated.
export async function readableStreamToBuffer(stream: ReadableStream): Promise<Buffer> {
    const chunks: Buffer[] = [];
  
    const reader = stream.getReader();
  
    let moreData = true;
    do {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await reader.read();
      if (done) {
        moreData = false;
      } else {
        chunks.push(Buffer.from(value as Uint8Array));
      }
    } while (moreData);
  
    return Buffer.concat(chunks);
  }

export interface TzZone {
    name: string,
    shorthand: string,
    offset: number
}

let timezones = {
  "America/Chicago": ["CST", -6, "CDT", -5],
  "America/Denver": ["MST", -7, "MDT", -6],
  "America/Los_Angeles": ["PST", -8, "PDT", -7],
  "America/Phoenix": ["AZST", -7, "AZDT", -7],
  "America/New_York": ["EST", -5, "EDT", -4],
  "Pacific/Honolulu": ["HST", -10, "HDT", -10]
}

let sAbbrToName = {}

Object.entries(timezones)
  .map(([ k, v ]) => sAbbrToName[v[0]] = k)

export function getZoneList(date: string, noDST=false): Array<TzZone> {

    let zoneNames = Object.entries<Array<string | number>>(timezones);
    let zoneList = zoneNames.map(([ name, [ abbrS, s, abbrD, d ] ]) => {
        let zone = moment.tz(date, name);
        if (zone.isDST() && !noDST){
            return {
                name,
                shorthand: String(abbrD),
                offset: Number(d)
            }
        }
        return {
            name: name,
            shorthand: String(abbrS),
            offset: Number(s)
        }
    })
    .sort((a, b) => {
            if (a.offset > b.offset){
                return -1
            }
            else
            {
                return 1
            }
        })
    return zoneList
}

export function getTzDataFromName(tzList: Array<TzZone>, tzName: string): TzZone {
  let tzEntry = tzList.find((entry) => entry.name === tzName);
  return tzEntry;
}

export function getTzNameFromDoc(doc: any){
  let defaultTz = "America/Chicago";
  const createdBy = doc.createdBy;
  if (!createdBy) return defaultTz;

  const cbArray = String(createdBy).split(".");
  const abbr = cbArray.find(el => String(el).toUpperCase() in sAbbrToName)?.toUpperCase();

  if (!abbr || !sAbbrToName[abbr]){
    return defaultTz;
  }

  return sAbbrToName[abbr];
}

/**
 * Appends or inserts an element into a mapping where the value is an array
 * @param map Map object to append to
 * @param key Key of the key/value pair to append to
 * @param valueToInsert Value to append to the value array
 * @returns 0 if value is appened to an existing element in the map; 1 if a new key is created in the map
 */
export function appendOrInsertMapValue<K, V>(map: Map<K, Array<V>>, key: K, valueToInsert: V): number {
  if (map.has(key)){
    map.get(key).push(valueToInsert);
    return 0
  }
  else
  {
    map.set(key, [valueToInsert]);
    return 1
  }
}

export function getLocalizedMoment(dateTime: string, doc: any): Moment{
  if (!dateTime) return null;

  let tzName = doc ? getTzNameFromDoc(doc) : 'America/Chicago';
  let tzAwareDT = moment(dateTime).parseZone().tz(tzName, false);
  return tzAwareDT;
}

export function getSortFunction<T = any>(itemGetter: ((item: T) => any) | string, order: 'asc' | 'desc'='asc'): (a: T, b: T) => number{
  const getValue = (obj: T) => {
    if (typeof itemGetter === 'function'){
      return itemGetter(obj);
    }
    return obj[itemGetter];
  }
  return (a, b) => {
    const aVal = getValue(a);
    const bVal = getValue(b);

    if (aVal > bVal){
      return order === 'asc' ? 1 : -1;
    }
    else if (bVal > aVal){
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  }
}

export function momentSorter(fieldName, a, b) {
  const aa = moment(a[fieldName]);
  const bb = moment(b[fieldName]);
  if (aa.isBefore(bb)) return -1
  if (aa.isAfter(bb)) return 1
  return 0
}

export function getAreaBlock(locDoc: any){
  if (!locDoc) return null;
  if (locDoc.type === "OFFSHORE" && locDoc.block && locDoc.field){
      return `${locDoc.block}${locDoc.field}`
  }
  return null;
}

// Adds props to a component that keeps click events from
// propagating.
export function stopPropagationProps(){
    return {
        onClick: (e) => {
            e.stopPropagation();
        }
    }
}

/**
 * Converts an array to a Map object.
 * @param array Array to convert.
 * @param keyGetter Field name/func to use as key.
 * @param transformElement Transform each array element before storing it in the map.
 *
 * @example
 * const data = [
 *  { id: "1", name: "John" },
 *  { id: "2", name: "Jane" }
 * ];
 *
 * const result = arrayToMap(array, "id", (element) => element.name);
 * const sameResult = arrayToMap(array, (value) => value.id, (element) => element.name); // <-- Equivalent to above
 *
 * // result:
 * // {"1": "John", "2": "Jane"}
 */
export function arrayToMap<K, V, T=V>(
    array: V[],
    keyGetter: string | ((value: V) => K),
    transformElement?: (value: V) => T
){
    const map = new Map<K, T>();
    if (!array) return map;

    let getter: (value: V) => K;

    if (typeof keyGetter === 'string'){
        getter = (value: V) => value[keyGetter];
    }
    else
    {
        getter = keyGetter;
    }

    array.forEach((item: V) => {
        const key = getter(item);
        let finalValue: T = item as unknown as T;
        if (transformElement){
            finalValue = transformElement(item);
        }
        map.set(key, finalValue);
    })

    return map;
}

/**
 * Converts an array to a Map object where each value is an array.
 * @param array Array to convert.
 * @param keyGetter Field name/func to use as key.
 * @param transformElement Transform each array element before storing it in the map.
 *
 * @example
 * const data = [
 *  { type: "truck", name: "Ford F150" },
 *  { type: "truck", name: "Ford Raptor" },
 *  { type: "car", name: "Ford Escort" }
 * ];
 *
 * const result = arrayToMap(array, "id", (element) => element.name);
 * const sameResult = arrayToMap(array, (value) => value.id, (element) => element.name); // <-- Equivalent to above
 *
 * // result:
 * // {"truck": ["Ford F150", "Ford Raptor"], "car": ["Ford Escort"]}
 */
export function arrayToMapAccumulate<K, V, T=V>(
    array: V[],
    keyGetter: string | ((value: V) => K),
    transformElement?: (value: V) => T
){
    const map = new Map<K, (V|T)[]>();
    if (!array) return map;

    let getter: (value: V) => K;

    if (typeof keyGetter === 'string'){
        getter = (value: V) => value[keyGetter];
    }
    else
    {
        getter = keyGetter;
    }

    array.forEach((item: V) => {
        const key = getter(item);
        let finalValue: V|T = item;
        if (transformElement){
            finalValue = transformElement(item);
        }

        if (map.has(key)){
            map.get(key).push(finalValue);
        }
        else
        {
            map.set(key, [finalValue]);
        }
    })

    return map;
}

/**
 * If v is truey (not null, not empty string, not undefined, etc), returns true. Otherwise false.
 * @param v
 *
 * @example
 * const text = ["h", "e", "l", undefined, "l", null, "o", ""];
 * const filteredText = text.filter(isTruey);
 * // filteredText: ["h", "e", "l", "l", "o"]
 */
export function isTruey(v: any){
    return !!v;
}