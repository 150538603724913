import { TableComponents, TableProps } from 'antd/lib/table';
import { Table } from 'antd';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import Column from 'antd/lib/table/Column';
import cn from 'classnames';
import './enhanced-antd-table.less';
import ReactDOM from 'react-dom';
import AutoScroll from 'common/auto-scroll';
import { ThemeContext } from 'context/theme';
import ETableGroup from "./table-group";
export { default as ETableGroup } from './table-group';

export interface ETableTotals {
    colNum: number,
    label: any,
    masterLabel?: any,
    td?: React.ReactElement<HTMLAttributes<HTMLTableCellElement>>[],

    /** Gets all rows of a certain group so you can calculate a total from that. Also puts the totals row in each group. */
    renderTdFromGroup?: ((groupData: any[]) => React.ReactElement<HTMLAttributes<HTMLTableCellElement>>[]),

    /** Adds a master total that shows at the bottom of all of the groups */
    renderMasterTotalTd?: ((data: any[]) => React.ReactElement<HTMLAttributes<HTMLTableCellElement>>[]),

    sticky?: boolean,
    masterSticky?: boolean,

    /** Include the totals on the top of the table as well as the footer */
    includeTotalOnTop?: boolean
}

// @ts-ignore
export interface ETableProps<T=any> extends TableProps<T> {
    totals?: ETableTotals,
    overrideFooter?: React.ReactElement<HTMLAttributes<HTMLTableSectionElement>>,

    squareCorners?: boolean,

    /** Groups data according to the value of the column. Each group will have it's own table header. */
    groupByColKey?: string,

    /**
     * Change the value of the group before it is factored in the grouping mechanism.
     * Ex for grouping by first letter of lastName: (groupValue) => groupValue[0]
     * */
    transformGroupValue?: (groupValue: any) => any,
    
    groupHeaderType?: 'header' | ((groupValue: any, body: any, idx: number) => React.ReactElement),

    /** Empty space between groups */
    groupSpacing?: number | boolean,
    stickyHeader?: boolean,
    stickyHeaderOffset?: 12,

    /** Allows height of table to shrink to the available space of a flexbox and enables scrolling internally */
    flexMode?: boolean,

    autoScroll?: {
        enabled?: boolean,
        speed?: number,
        stopOnMouseHover?: boolean,
        hideIdleMouse?: boolean,
        onScrollReachEnd?: () => void,
        onScrollNeeded?: (needed: boolean) => void
    },

    components?: {
        table?: EnhancedTableCompWrapper<unknown>;
        header?: {
            wrapper?: EnhancedHeaderComp;
            row?: React.ReactType;
            cell?: React.ReactType;
        };
        body?: {
            wrapper?: EnchancedBodyCompWrapper;
            row?: React.ReactType;
            cell?: React.ReactType;
        }
    }
}

export interface EnhancedTableCompProps<T> extends ETableProps {
    tableProps: TableProps<T>,
    isFixed: boolean
}

class EnhancedTableComp<T> extends React.Component<EnhancedTableCompProps<T>> {

    getColumns(): Array<Column<T>>{
        let headTable = this.props.tableProps.children[0];
        return headTable.props.columns;
    }

    getNumColumns(){
        return this.getColumns().length;
    }

    renderTotalRow(){
        return <tr className="ant-table-row">
            {this.props.totals.colNum ? (
                <td colSpan={this.props.totals.colNum} />
            ) : null}
            <td style={{ textAlign: 'right' }}>{this.props.totals.label}</td>
            {this.props.totals.td}
            {this.props.totals.colNum === 0 && this.getNumColumns() <= 2 ? null : (
                <td colSpan={this.getNumColumns() - (this.props.totals.colNum + 1 + (this.props.totals.td?.length || 0))} />
            )}
        </tr>
    }

    renderFooter(){
        if (this.props.isFixed){
            return null
        }
        if (this.props.overrideFooter){ // Rendering footer takes priority over totals
            let footer = React.cloneElement(this.props.overrideFooter, { className: 'ant-table-tbody mc-enhanced-table-footer' })
            return footer;
        }
        if (this.props.totals && !this.props.totals.renderTdFromGroup){
            return <tfoot className={cn({"ant-table-tbody mc-enhanced-table-footer": true, "mc-enhanced-table-footer-sticky": this.props.totals.sticky})}>
                {this.renderTotalRow()}
            </tfoot>
        }
        return null;
    }

    renderTotalTop(){
        if (this.props.totals?.includeTotalOnTop){
            return <thead className={cn({"ant-table-tbody mc-enhanced-table-totals mc-enhanced-table-header": true, "mc-enhanced-table-header-sticky": this.props.totals.sticky})}>
                {this.renderTotalRow()}
            </thead>
        }
        return null
    }

    render(): React.ReactNode {
        let children = React.Children.toArray(this.props.tableProps.children);
        children.splice(2, 0, this.renderTotalTop());
        return <table className={this.props.tableProps.className}>
            {children}
            {this.renderFooter()}
        </table>
    }
}

class EnhancedTableCompWrapper<T> extends React.Component<PropsWithChildren<{ className?: string }>> {

    render(): React.ReactNode {
        return <ETableWrapperContext.Consumer>
        {(value) => (
            <ETableContext.Provider value={{children: this.props.children}}>
                <EnhancedTableComp<T> tableProps={this.props} {...value} isFixed={this.props.className.includes('ant-table-fixed')} />
            </ETableContext.Provider>
        )}
        </ETableWrapperContext.Consumer>
    }
}

export interface EnchancedTableBodyCompProps extends HTMLAttributes<HTMLTableSectionElement> {

}

class EnhancedBodyComp<T> extends React.Component<any> {

    getGroupSpacing(){
        let groupSpacing = this.props.groupSpacing;
        if (groupSpacing === true){
            return 24
        }
        return groupSpacing;
    }

    getColumns(): Array<Column<T>>{
        return this.props.columns;
    }

    renderHeader(key: any){
        return <thead key={"header-" + key} className={"ant-table-thead mc-enhanced-table-group-header"}>
            {this.getGroupSpacing() ? (
                <tr style={{ height: `${this.getGroupSpacing()}px` }}></tr>
            ) : null}
            <tr>
                {this.props.columns.map((col, idx) => (
                    <th className={idx === this.props.columns.length-1 ? "ant-table-row-cell-last" : null}>{col.title}</th>
                ))}
            </tr>
        </thead>
    }

    getNumColumns(){
        return this.getColumns().length;
    }

    renderFooter(records: any[], key: any){
        if (this.props.overrideFooter){ // Rendering footer takes priority over totals
            let footer = React.cloneElement(this.props.overrideFooter, { className: 'ant-table-tbody mc-enhanced-table-footer mc-enhanced-table-grouped-footer' })
            return footer;
        }
        if (this.props.totals && this.props.totals.renderTdFromGroup){
            let td = this.props.totals.renderTdFromGroup?.(records) || this.props.totals.td;
            return <tbody className={cn({"ant-table-tbody mc-enhanced-table-footer mc-enhanced-table-grouped-footer": true, "mc-enhanced-table-footer-sticky": this.props.totals.sticky})}>
                <tr key={"footer-" + key} className="ant-table-row">
                    {this.props.totals.colNum ? (
                        <td colSpan={this.props.totals.colNum} />
                    ) : null}
                    <td style={{ textAlign: 'right' }}>{this.props.totals.label}</td>
                    {td}
                    {this.props.totals.colNum === 0 && this.getNumColumns() <= 2 ? null : (
                        <td colSpan={this.getNumColumns() - (this.props.totals.colNum + 1 + (td?.length || 0))} />
                    )}
                </tr>
            </tbody>
        }

        return null;
    }

    renderMasterFooter(records: any[]){
        if (this.props.totals && this.props.totals.renderMasterTotalTd){
            let td = this.props.totals.renderMasterTotalTd?.(records) || this.props.totals.td;
            return <tbody className={cn({"ant-table-tbody mc-enhanced-table-footer mc-enhanced-table-grouped-footer": true, "mc-enhanced-table-footer-sticky": this.props.totals.masterSticky})}>
                <tr className="ant-table-row">
                    {this.props.totals.colNum ? (
                        <td colSpan={this.props.totals.colNum} />
                    ) : null}
                    <td style={{ textAlign: 'right' }}>{this.props.totals.masterLabel || this.props.totals.label}</td>
                    {td}
                    <td colSpan={this.getNumColumns() - (this.props.totals.colNum + 1 + (td?.length || 0))} />
                </tr>
            </tbody>
        }
        return null;
    }

    renderHeaderElement = (groupValue: any, body: any, idx: number) => {
        if (!this.props.groupHeaderType || this.props.groupHeaderType === 'header'){
            return idx > 0 ? this.renderHeader(groupValue) : null
        }
        else if (typeof this.props.groupHeaderType === 'function'){
            return this.props.groupHeaderType(groupValue, body, idx);
        }
        return null
    }

    renderGroupedBody(){
        let rowItems = React.Children.toArray(this.props.children);
        rowItems = rowItems.sort((a: any, b: any) => {
            function getVal(item): string{
                if (typeof item === 'object' && item !== null && item !== undefined){
                    return String(item._id);
                }
                return String(item);
            }
            let aVal = getVal(a.props.record[this.props.groupByColKey]);
            let bVal = getVal(b.props.record[this.props.groupByColKey]);
            let compareRes = aVal.localeCompare(bVal);

            if (compareRes > 0) return 1;
            if (compareRes < 0) return -1;
            return 0;
        })

        let records = rowItems
            .filter((child: any) => child.props.record)
            .map((child: any) => child.props.record)
        
        let tableBodies = new Map();
        
        rowItems.forEach((child: any, idx) => {
            let groupKey = this.props.groupByColKey;
            let groupValue = child.props.record[groupKey];
            if (this.props.transformGroupValue){
                groupValue = this.props.transformGroupValue(groupValue);
            }
            if (!tableBodies.has(groupValue)){
                // New group is started. Add a new header.
                
                tableBodies.set(groupValue, [child]);
            }
            else
            {
                tableBodies.get(groupValue).push(child)
            }
        })

        return <>
            {Array.from(tableBodies.entries()).map(([groupValue, body], idx) => [
                this.renderHeaderElement(groupValue, body, idx),
                <tbody key={"body-" + groupValue} className={'ant-table-tbody' + (this.getGroupSpacing() ? ' mc-enhanced-table-with-group-spacing' : '')}>{body}</tbody>,
                this.renderFooter(body.map(row => row.props.record), groupValue)
            ])}
            {this.renderMasterFooter(records)}
        </>
    }

    render(): React.ReactNode {
        if (this.props.groupByColKey){
            return this.renderGroupedBody();
        }
        return <tbody className="ant-table-tbody">
            {this.props.children}
        </tbody>
    }
}

class EnchancedBodyCompWrapper extends React.Component {
    render(): React.ReactNode {
        return <ETableWrapperContext.Consumer>
        {(tableProps) => (
            <ETableContext.Consumer>
            {({ children: tableChildren }) => (
                <EnhancedBodyComp {...this.props} {...tableProps} tableChildren={tableChildren} />
            )}
            </ETableContext.Consumer>
        )}
        </ETableWrapperContext.Consumer>
    }
}

class EnhancedHeaderComp extends React.Component {
    render(){
        return <thead className="ant-table-thead">
            {this.props.children}
        </thead>
    }
}

const ETableWrapperContext = React.createContext<ETableProps>({
    totals: null,
    groupByColKey: null
})

const ETableContext = React.createContext<{ children: React.ReactNode}>(null);

class ETable<T> extends React.Component<ETableProps & TableProps<T>> {

    static Group = ETableGroup;

    tableRef = null;
    tableWrapperBody: Element = null;

    autoScroll: AutoScroll

    private scrollTimeoutHandle: NodeJS.Timeout;
    private mouseHideTimeoutHandle: NodeJS.Timeout;
    private mouseInTable = false;
    private isScrolling = false;

    getComponents = (): TableComponents => {
        return {
            table: EnhancedTableCompWrapper,
            header: {
                ...this.props.components?.header,
                wrapper: this.props.components?.header?.wrapper || EnhancedHeaderComp
            },
            body: {
                ...this.props.components?.body,
                wrapper: this.props.components?.body?.wrapper || EnchancedBodyCompWrapper
            }
        }
    }

    /** 
     * Grabs the ant-table-body class element and stores it into the tableWrapperBody member variable.
     * Will be used to scroll automatically.
    */
    initAutoScroll = () => {
        // Ant Design does not forward the ref to a DOM Node. So we have to find the DOM node this way.
        // Use of findDOMNode is discouraged, but I cannot find any other way around this without having to modify
        // the Ant Design source code.
        let textOrElement: any = ReactDOM.findDOMNode(this.tableRef);

        if ( textOrElement instanceof Text){
            console.error('Table element is a text object for some reason?');
            return;
        }

        let tableElement: Element = textOrElement;
        
        if (!tableElement){
            console.error('Could not find ref to table DOM element!');
            return;
        }

        let elems = tableElement.getElementsByClassName('ant-table-body');
        
        if (!elems){
            console.error('Could not find ref to table wrapper body element!');
        }
        this.tableWrapperBody = elems[0];

        if (!this.autoScroll){
            this.autoScroll = new AutoScroll(this.tableWrapperBody, this.props.autoScroll?.speed || 5);
            
            // Listen for when the scroll is finished
            if (this.props.autoScroll?.onScrollReachEnd){
                this.autoScroll.onFinishCallback(this.props.autoScroll.onScrollReachEnd);
            }

            // Listen for when scroll is needed or not
            if (this.props.autoScroll?.onScrollNeeded){
                this.autoScroll.scrollNeededCallback((needed) => {
                    this.props.autoScroll?.onScrollNeeded(needed);
                    if (this.props.autoScroll?.enabled){
                        this.startAutoScroll();
                    }
                });
            }
        }

        if (this.props.autoScroll?.enabled){
            this.startAutoScroll();
        }
    }

    onBodyMouseOver = (ev) => {
        this.mouseInTable = true;
        if (this.props.autoScroll?.stopOnMouseHover){
            this.props.autoScroll?.enabled && this.stopAutoScroll()
        }
    };
    onBodyMouseLeave = (ev) => {
        this.mouseInTable = false;
        this.props.autoScroll?.enabled && this.startAutoScroll(false, false);
    }

    onBodyScroll = (ev) => {
        if (!this.autoScroll){ return; }
        if (!this.props.autoScroll?.enabled){ return; }

        this.isScrolling = true;
        window.clearTimeout(this.scrollTimeoutHandle);
        this.autoScroll.stop();
        this.scrollTimeoutHandle = setTimeout(() => {
            this.autoScroll.start();
            this.isScrolling = false;
        }, 1000);

    }

    onBodyMouseDown = (ev) => {
        if (!this.autoScroll){ return; }
        if (!this.props.autoScroll?.enabled){ return; }
        this.autoScroll.stop();
    }

    onBodyMouseUp = (ev) => {
        if (!this.autoScroll){ return; }
        if (!this.props.autoScroll?.enabled){ return; }

        setTimeout(() => {
            this.autoScroll.start();
        }, 1000);
    }

    onBodyMouseMove = (ev) => {
        if (this.tableWrapperBody.hasAttribute('style')){
            this.tableWrapperBody.removeAttribute('style');
        }
        if (this.mouseInTable && this.autoScroll?.enabled){
            window.clearTimeout(this.mouseHideTimeoutHandle);
            this.mouseHideTimeoutHandle = setTimeout(() => {
                this.tableWrapperBody.setAttribute('style', 'cursor: none;');
            }, 5000);
        }
        else
        {
            window.clearTimeout(this.mouseHideTimeoutHandle);
        }
    }

    addMouseEvents = () => {
        this.tableWrapperBody.addEventListener('mouseover', this.onBodyMouseOver)
        this.tableWrapperBody.addEventListener('mouseleave', this.onBodyMouseLeave)
        this.tableWrapperBody.addEventListener('mousemove', this.onBodyMouseMove);
        this.tableWrapperBody.addEventListener('mousedown', this.onBodyMouseDown);
        this.tableWrapperBody.addEventListener('mouseup', this.onBodyMouseUp);
        this.tableWrapperBody.addEventListener('wheel', this.onBodyScroll)
    }

    removeMouseEvents = () => {
        this.tableWrapperBody.removeEventListener('mouseover', this.onBodyMouseOver);
        this.tableWrapperBody.removeEventListener('mouseleave', this.onBodyMouseLeave);
        this.tableWrapperBody.removeEventListener('mousemove', this.onBodyMouseMove);
        this.tableWrapperBody.removeEventListener('mousedown', this.onBodyMouseDown);
        this.tableWrapperBody.removeEventListener('mouseup', this.onBodyMouseUp);
        this.tableWrapperBody.removeEventListener('wheel', this.onBodyScroll);
    }

    startAutoScroll(startFromTop=false, delay=true){
        if (!this.autoScroll.enabled){
            this.autoScroll.start(startFromTop, delay);
            console.debug('Started auto scroll');
        }
    }

    stopAutoScroll(){
        if (this.autoScroll.enabled){
            this.autoScroll.stop();
            console.debug('Stopped auto scroll');
        }
    }

    recomputeScrollDuration(){
        if (this.autoScroll){
            this.autoScroll.computeDuration();
        }
    }
    
    componentDidMount(): void {
        this.initAutoScroll();
        this.addMouseEvents();
        window.addEventListener('resize', this.recomputeScrollDuration);
    }

    componentDidUpdate(prevProps: Readonly<ETableProps & TableProps<T>>): void {
        if (this.props.autoScroll?.enabled && !prevProps.autoScroll?.enabled){
            this.startAutoScroll(false, false);
        }
        else if (!this.props.autoScroll?.enabled && prevProps.autoScroll?.enabled){
            this.stopAutoScroll();
        }

        if (this.autoScroll && typeof this.props.autoScroll?.speed == 'number' && this.props.autoScroll?.speed !== prevProps.autoScroll?.speed){
            this.autoScroll.setScrollSpeed(this.props.autoScroll.speed);
        }
    }

    componentWillUnmount(): void {
        this.removeMouseEvents();
        window.removeEventListener('resize', this.recomputeScrollDuration);
    }

    render(): React.ReactNode {
        return <ETableWrapperContext.Provider value={this.props}>
            <ThemeContext.Consumer>
                {({ themeName }) => (
                    <Table
                        {...this.props}
                        components={this.getComponents()}
                        className={cn({
                            [this.props.className]: true,
                            'mc-enhanced-table': true,
                            'mc-enhanced-table-auto-scroll-fix': this.props.scroll ? true : false,
                            'mc-enhanced-table-sticky-header': this.props.stickyHeader ? true : false,
                            'mc-enhanced-table-sticky-header-12': this.props.stickyHeader && this.props.stickyHeaderOffset === 12 ? true : false,
                            'mc-enchanced-table-flexmode': this.props.flexMode,
                            'mc-enhanced-table-light-theme-only': themeName === 'light',
                            'mc-enhanced-table-bordered': this.props.bordered ? true : false,
                            'mc-enhanced-table-square-corners': this.props.squareCorners ? true : false
                        })}
                        ref={(elem) => this.tableRef = elem}
                    />
                )}
            </ThemeContext.Consumer>
        </ETableWrapperContext.Provider> 
    }
}

export default ETable;