import {EntityId, NoteId, NoteMessage, State} from "./state";
import {WritableDraft} from "immer";
import {ScheduleNode} from "../../../../scheduling/types";
import {getSNodeID} from "../../../../scheduling/util";
import {CargoNode, PaxNode} from "../../../../../__generated__/graphql/graphql";

export const BASE_CLS = 'notes-for-pilot-editor';

export function buildEntityMap(entityList: ScheduleNode[]){
    const entityMap = new Map<EntityId, ScheduleNode>();

    for (let entity of entityList) {
        entityMap.set(getSNodeID(entity), entity);
    }

    return entityMap;
}

export function getValidNotes(noteMap: Map<NoteId, NoteMessage>){
    const newMap = new Map<NoteId, NoteMessage>();

    const entries = Array.from(noteMap.entries());
    for (let [ noteId, message ] of entries) {
        if (!message)
            continue;

        newMap.set(noteId, message);
    }

    return newMap;
}

export function buildNotes(entityList: ScheduleNode[]){

    let nextNoteId = 0;
    const noteIdToMsg = new Map<NoteId, NoteMessage>();
    const msgToNoteId = new Map<NoteMessage, NoteId>();

    for (const entity of entityList) {

        const message = entity.notesForPilot;

        if (!message || msgToNoteId.has(message)){
            continue;
        }

        noteIdToMsg.set(nextNoteId, message);
        msgToNoteId.set(message, nextNoteId);

        nextNoteId++;
    }

    return {
        noteIdToMsg: noteIdToMsg,
        msgToNoteId: msgToNoteId
    };
}

export function getNextNoteId(noteMap: Map<NoteId, NoteMessage>){
    const entryList = Array.from(noteMap.entries());
    return entryList.reduce((prev, entry) => Math.max(prev, entry[0]), -1) + 1;
}

export function addNote(noteMap: WritableDraft<Map<NoteId, NoteMessage>>, message: string){
    const nextNoteId = getNextNoteId(noteMap);
    noteMap.set(nextNoteId, message);
}

export function buildEntityToNotes(entityList: ScheduleNode[]){
    const { msgToNoteId } = buildNotes(entityList);
    const entityToNote = new Map<EntityId, NoteId>();

    for (let entity of entityList) {
        if (msgToNoteId.has(entity.notesForPilot)){
            entityToNote.set(getSNodeID(entity), msgToNoteId.get(entity.notesForPilot));
        }
    }

    return entityToNote;
}

export function getEntityNoteMsg(entity: ScheduleNode, state: State){
    if (!state.entityIdToNote.has(getSNodeID(entity))){
        return;
    }

    const noteId = state.entityIdToNote.get(getSNodeID(entity));

    return state.notes.get(noteId);
}

export function findUnassignedNoteIds(state: State){

    const noteIds = Array.from(state.notes.keys());

    function entityListIsMissingNote(noteId: number){
        const entityNoteList = Array.from(state.entityIdToNote.values());
        const foundNoteIdx = entityNoteList.findIndex(_noteId => noteId === _noteId);

        return foundNoteIdx === -1;
    }

    return noteIds.filter(entityListIsMissingNote)
}

export function getEntityName(entity: ScheduleNode){
    let name: string;
    if (entity.classType === 'flytsuite.paxnode'){
        const pax = entity as PaxNode;
        name = `${pax.lastName}, ${pax.firstName}`;
    }
    else {
        const cgo = entity as CargoNode;
        name = cgo.name;
    }

    return name;
}

export function getEntityIcon(entity: ScheduleNode){
    const iconType = entity.classType === 'flytsuite.paxnode' ?
        "user" : "cargo";
    return iconType;
}