import gql from "graphql-tag";

export default gql`
query LiveTwoGetFlights(
    $filter: FlightsFilterInput
    $bookmark: String
    $limit: Int
){
    flights(
        filter: $filter
        limit: $limit
        bookmark: $bookmark
    ) {
        bookmark
        docs {
            _id,
            ... on FlightNode {
                desig,
                departureID {
                    _id
                    name
                }
                aircraftID {
                    _id
                    maxSeats
                }
                legs
                firstLegUsableWt
                state
                legsArray {
                    seats
                    paxWeight
                    #paxCounts
                    departureID
                    destinationID
                }
                paxIDList {
                    _id
                }
                cgoIDList {
                    _id
                }
            }
        }
    }
}`