import {produce} from "immer";
import {Dispatch, ReducerAction} from "react";
import { difference } from "lodash";
import * as Util from './util';
import {ScheduleNode} from "../../../../scheduling/types";
import {validateNote} from "../validation";

export type EntityId = string;
export type NoteId = number;
export type NoteMessage = string;

export const DEFAULT_STATE = {
    origEntities: new Map<EntityId, ScheduleNode>(),
    notes: new Map<NoteId, NoteMessage>(),
    entityIdToNote: new Map<string, NoteId>(),
    visibleNotes: [] as number[],
    noteAssigner: {
        open: false,
        noteId: null as number | null
    }
}

export type State = typeof DEFAULT_STATE;

export type Action =
{
    type: 'INIT',
    entities: ScheduleNode[]
} |
{
    type: 'UPDATE_NOTE',
    noteId: number,
    newMessage: string
} |
{
    type: 'RESET'
} |
{
    type: 'ADD_EMPTY_NOTE'
} |
{
    type: 'REMOVE_NOTE',
    noteId: number
} |
{
    type: 'CLEAR'
} |
{
    type: 'SET_VISIBLE_NOTES',
    noteIds: number[]
} |
{
    type: 'OPEN_NOTE_ASSIGNER',
    noteId?: number,
    open: boolean
} |
{
    type: 'ASSIGN_ENTITY_TO_NOTE',
    noteId: number | null | undefined,
    entityId: string | string[]
}

export type Dispatcher = Dispatch<ReducerAction<typeof Reducer>>;

export const Reducer: React.Reducer<Readonly<State>, Action> = (prevState, action) => {

    function validateState(state: State){
        return produce(state, (currState) => {

            // Ensure keys in entityIdToNote don't refer to a non-existent entity in origEntities
            Array.from(currState.entityIdToNote.keys())
                .forEach(entityId => {
                    if (!currState.origEntities.has(entityId)){
                        currState.entityIdToNote.delete(entityId);
                    }
                })

            // Ensure entities are not assigned to a deleted note.
            currState.entityIdToNote.forEach((noteId, entityId) => {
                if (!currState.notes.has(noteId)){
                    currState.entityIdToNote.delete(entityId);
                }
            })

            // Ensure the noteId does not have a value if noteAssigner is closed.
            currState.noteAssigner = produce(currState.noteAssigner, (naState) => {
                if (naState.open && !currState.notes.size){
                    naState.open = false;
                    naState.noteId = null;
                }
            })
        })
    }

    const nextState = produce(prevState, (newState) => {

        function validateVisibleNotes(){
            newState.visibleNotes = newState.visibleNotes.filter((noteId) => {
                return newState.notes.has(noteId)
            });
        }

        switch (action.type){
            case "INIT":
                newState.origEntities = Util.buildEntityMap(action.entities);
                newState.notes = Util.buildNotes(action.entities).noteIdToMsg;
                newState.entityIdToNote = Util.buildEntityToNotes(action.entities);
                validateVisibleNotes();
                break;
            case "RESET":
                newState.notes = Util.buildNotes(Array.from(prevState.origEntities.values())).noteIdToMsg;
                validateVisibleNotes();
                break;
            case "UPDATE_NOTE":
                if (prevState.notes.has(action.noteId)){
                    const message = validateNote(action.newMessage);
                    newState.notes.set(action.noteId, message);
                }
                break;
            case 'ADD_EMPTY_NOTE':
                Util.addNote(newState.notes, '');
                break;
            case 'REMOVE_NOTE':
                newState.notes.delete(action.noteId);
                newState.visibleNotes = difference(newState.visibleNotes, [action.noteId]);
                break;
            case 'CLEAR':
                newState.notes = new Map<NoteId, NoteMessage>();
                newState.visibleNotes = [];
                break;
            case 'SET_VISIBLE_NOTES':
                newState.visibleNotes = action.noteIds;
                break;
            case 'OPEN_NOTE_ASSIGNER':
                newState.noteAssigner.open = action.open;

                if (action.open){
                    newState.noteAssigner.noteId = action.noteId || null;
                }
                else {
                    newState.noteAssigner.noteId = null;
                }

                break;
            case 'ASSIGN_ENTITY_TO_NOTE':

                let entityIds = Array.isArray(action.entityId) ? action.entityId : [action.entityId];

                for (let entityId of entityIds) {
                    if ([null, undefined].includes(action.noteId)){
                        // Remove entity from note if noteId is null or undefined
                        newState.entityIdToNote.delete(entityId);
                        continue;
                    }

                    // Assign note to entity
                    newState.entityIdToNote.set(entityId, action.noteId);
                }
                break;
        }

    })

    return validateState(nextState)
}