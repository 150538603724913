import regex from './regex';

export const validateISNNumber = (rule, value, callback) => {
    if ((!value || value === '') && (!rule || !rule.required)){
        callback();
    }
    const isnNumber = ('' + value).replace('ISN-', '');
    if (isnNumber.length !== 8){
        callback('ISN number must be 8 digits long');
    }
    else if (!/^\d+$/.test(isnNumber)){
        callback('ISN number must be digits 0-9');
    }
    else{
        callback();
    }
}

export const validateCredWithExpiration = (rule, value, callback) => {
    if ((!value || (value.key === '' && !value.expiration)) && (!rule || !rule.required)){
        callback();
    }else if(!value || (value.key === '' && value.expiration == null)){
        callback('Must have at least one credential')
    }else if(value.key !== '' && value.expiration == null){
        callback('Must have an expiration');
    }else if (value.key === '' && value.expiration !== null){
        callback('Must have a key');
    }else {
        callback();
    }
}

export const validatePhone = (rule, value, callback) => {
    if ((!value || value === '') && (!rule || !rule.required)){
        callback();
    }
    const r = regex.phone;
    if (r.test(value)){
        callback();
    }
    else
    {
        callback('Invalid phone number format');
    }
}

export const composeValidators = (...validators) => {
    return (rule, value, callback) => {
        let callbacks = [];
        for (let i = 0; i < validators.length; i++) {
            const validator = validators[i];
            validator(rule, value, (val) => callbacks.push(val));
        }

        if (callbacks.length > 0){
            let val = callbacks.filter(cb => cb).join(' ');
            callback(val ? val : undefined)
        }
        else
        {
            callback();
        }
    }
}

export const validateOtherFieldIsFilledIn = (form, fieldName, fieldLabel=fieldName) => {
    return (rule, value, callback) => {
        if ((!value || value === '') && (!rule || !rule.required)){
            callback();
            return;
        }
        let otherValue = form.getFieldValue(fieldName);
        if (otherValue){
            form.validateFields([fieldName]);
            callback();
        }
        else
        {
            callback(fieldLabel + ' must also be filled in.');
        }
    }
}

export const validateAlphaNumeric = (rule, value, callback) => {
    let r = /[a-zA-Z0-9]*/;

    let matched = String(value).match(r);
    if (matched[0].length < String(value).length){
        callback("Must have alphanumeric characters only. (a-z, A-Z, 0-9)")
    }else{
        callback()
    }
}

export const validateDecimal = (rule, value, callback) => {
    if ((!value || value === '') && (!rule || !rule.required)){
        callback();
        return;
    }

    let r = /[-.0-9]*/;

    if (String(value).startsWith('.')){
        callback('Please add a 0 in front of the period like this: 0' + value);
        return;
    }

    if (String(value).endsWith('.')){
        callback('Please remove the period at the end');
        return;
    }

    let matched = String(value).match(r);
    if (matched[0].length < String(value).length){
        callback("Must be a decimal number.")
    }else{
        callback()
    }
}

export const validateFirstLastName = (rule, value, callback) => {
    if ((!value || value === '') && (!rule || !rule.required)){
        callback();
    }

    let { lastName, firstName } = value;

    if (!firstName && lastName) callback("First name is required.")
    else if (firstName && !lastName)
        callback("Last name is required.")
    else if (!firstName && !lastName)
        callback("First name and last name is required.")
    else callback();
}

export const validatePassword = (rule, value, callback) => {
    if (!value){
        callback("Password is required.");
        return;
    };

    let violations = [];

    let rgx = [
        [/.*[0-9].*/, "At least one number is required."],
        [/.*[a-z].*/, "At least one lowercase letter is required."],
        [/.*[A-Z].*/, "At least one uppercase letter is required."]
    ]

    rgx.forEach(r => {
        if (!r[0].test(value)){
            violations.push(r[1]);
        }
    })

    if (violations.length > 0){
        callback(violations.join(" "));
    }
    else
    {
        callback();
    }
}

/**
 * 
 * @param {*} rule 
 * @param {import('components/date-time-picker').DateTimePickerValue} value 
 * @param {*} callback 
 */
export function validateDateTimePicker(rule, value, callback){
    if (rule.required && !value){
        callback(rule.message)
    }
    else if (!value.date){
        callback('Please enter a date')
    }
    else if (!value.time){
        callback('Please enter a time')
    }
    else
    {
        callback()
    }
}