import { ApolloError } from "apollo-client";
import { MutationResult, QueryHookOptions, QueryResult, useMutation, useQuery } from "react-apollo";
import SubmitReport from "../ReportForm/SubmitReport";
import QueryQuickReport from "../ReportForm/QueryQuickReport";

interface SubmitEvents {
    onCompleted?: (data: any) => void;
    onError?: (error: ApolloError) => void;
}

interface ReportSubmitterOptions {
    tpID?: string,
    customerID?: string,
    contractIDs?: string[]
}

/**
 * Create a new report which is then stored in S3 and CouchDB (stores only the metadata) using a GraphQL Mutation. Returns the report ID for later reference.
 * Can later be retrieved to check the status and view the data using useReportDetails hook (in report-details.tsx)
 * @param reportType Type of report [PAX|CGO|MANIFEST|ISN|COVID|PAX_COVID19_VAX|PERSON_COVID19_VAX_DETAILS|DISPATCH_HISTORY|DISPATCH_BILLING|DISPATCH_FUEL_TAKEN_S3|DISPATCH_CHANGES_BY_CONSOLE]
 * @param tpID Transporter ID of user
 * @param submitEvents Callbacks for when report submission is complete or failed
 * @param options Additional parameters for report
 * @returns sbmitFunc mutation function and result from mutation
 */
function useReportSubmitter(reportType: string, tpID: string, submitEvents?: SubmitEvents, options?: ReportSubmitterOptions): [ (reportData: any) => Promise<any>, MutationResult ] {
    const [ submit, result ] = useMutation(SubmitReport);

    const { onCompleted, onError } = submitEvents;
    
    const submitFunc = ({ name, criteria }) => new Promise((resolve, reject) => {
        submit({
            variables: {
                name,
                criteria: criteria.map(item => {
                    return {
                        ...item,
                        value: JSON.stringify(item.value)
                    };
                }),
                type: reportType,
                tpID: tpID || options?.tpID,
                customerID: options?.customerID,
                contractIDs: options?.contractIDs
            }
        })
        .then(({ data }) => {
            onCompleted({
                id: data.run_report,
                name
            });
            resolve?.(data);
        })
        .catch((error) => {
            onError?.(error);
            reject(error);
        })
    });
    return [ submitFunc, result ]
}

/**
 * Works similarly to useReportSubmitter, but it runs the report and immediately returns results back using a GraphQL Query.
 * This method of running a report should only be done with a narrow criteria on a report that is highly performant.
 * Reports that take a minute or more WILL FAIL.
 * @param reportType Type of report [PAX|CGO|MANIFEST|ISN|COVID|PAX_COVID19_VAX|PERSON_COVID19_VAX_DETAILS|DISPATCH_HISTORY|DISPATCH_BILLING|DISPATCH_FUEL_TAKEN_S3|DISPATCH_CHANGES_BY_CONSOLE]
 * @param tpID Transporter ID of user
 * @param reportName Human readable name for the report
 * @param submitEvents Callbacks for when report submission is complete or failed
 * @param options Additional parameters for report
 * @returns Query result
 */
export function useQuickReportQuery(reportType: string, tpID: string, reportName: string, reportCriteria: any, options?: QueryHookOptions): QueryResult {
    const result = useQuery(QueryQuickReport, {
        variables: {
            name: reportName,
            criteria: reportCriteria?.map(item => {
                return {
                    ...item,
                    value: JSON.stringify(item.value)
                };
            }),
            type: reportType,
            tpID: tpID
        },
        fetchPolicy: 'no-cache',
        ...options
    });
    return result
}

export default useReportSubmitter;