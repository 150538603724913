import {Location, Person, ScheduleHistoryDetail} from "__generated__/graphql/graphql";
import * as Phase2State from "./state";
import {ActionType, DEFAULT_STATE, State} from "./state";
import {CargoFormData, DepDestListItem, GroupData} from "./types";
import {useBulkISNChecker} from "components/BulkISNChecker";
import {useApolloClient, useQuery} from "react-apollo";
import React, {MutableRefObject, useContext, useEffect, useMemo, useReducer, useRef} from "react";
import {OrgDataContext} from "context/orgData";
import * as NotesForPilotRendererModule from "components/flights/scheduling/notes-for-pilot/quickedit";
import * as Util from "./util";
import {deRefLocations, findLegIdx} from "./util";
import {ResolvePersonIdsQuery} from "Queries/Person";
import {message, Modal} from "antd";
import {NetworkStatus} from "apollo-client";
import {personToPassenger} from "common/person";
import {cargoFormToCargoNode} from "./cargo-util";
import * as NotesForPilot from 'components/flights/scheduling/notes-for-pilot';
import {ScheduledCgoNode, ScheduledPaxNode, ScheduleNode} from "components/scheduling/types";
import {getSNodeID} from "components/scheduling/util";

/**
 * Getters and setters for modifying and reading the state of the Phase2 page.
 * Abstracts access to the actual state object so that child components consuming the state don't break from
 * structure changes.
 *
 * TODO:  Investigate ways to improve performance when using a "global" state like this.
 *        Something as simple as adding a single note to notesForPilot requires an entire re-render of the page.
 *        Look at potentially using the Redux library for this, or try to implement memoization.
 */
export interface ScheduleEditorApi {
    getScheduledPaxNodes: () => ScheduledPaxNode[],
    getScheduledCgoNodes: () => ScheduledCgoNode[],
    groupData: GroupData,
    data: {
        modifiedEntities: {
            get: () => State['data']['modifiedEntities'],
            getNotOnCarrier: () => State['data']['modifiedEntities'],
            set: (entities: ScheduleNode[]) => void,
            add: (entities: ScheduleNode[]) => void,
            remove: (entityIds: string[]) => void
        },
        originalEntities: {
            get: () => State['data']['originalEntities']
        }
        getModifiedEntityById: (entityId: string) => ScheduleNode | null,
        modifyEntity: (entityId: string, changedFields: Omit<Partial<ScheduleNode>, '_id'>) => void,
        removeEntities: (entityIds: string[]) => void,
        removeLeg: (departureID: string, destinationID: string) => void,
        swapEntities: (startIdx: number, endIdx: number) => void,
        swapLegs: (start: DepDestListItem, end: DepDestListItem | 'up' | 'down') => void,
        getLegs: () => DepDestListItem[],
        setLegs: (legs: DepDestListItem[]) => void,
        setEntityLegs: (entities: string[], departure: Location, destination: Location) => void,
        reset: () => void,
        getNotesForPilot: () => Record<string, ScheduleNode[]>
    },
    schedule: {
        finish: () => void
    },
    queryInfo: {
        loading: boolean,
        error: any,
        hasError: boolean
    },
    saveInfo: {
        saving: boolean,
        hasError: boolean
    },
    saveStatus: {
        get: () => State['saveStatus']
        dismiss: () => void
    }
    selection: {
        entityIds: {
            get: () => string[],
            set: (newEntities: string[]) => void,
            add: (newEntities: string[]) => void,
            toggle: (entityID: string) => void,
            remove: (removedEntities: string[]) => void,
            clear: () => void,
            all: () => void
        },
        personnelIds: {
            get: () => State['carriableSelector']['selectedPersonnelIds'],
            set: (ids: string[]) => void,
            clear: () => void,
            getAsObjects: () => {
                loading: boolean,
                objects: Person[]
            }
            addToSchedule: (departureID: string, destinationID: string) => Promise<void>
        }
    },
    bulkIsnChecker: ReturnType<typeof useBulkISNChecker>,
    editMode: {
        toggle: () => void,
        set: (editing: boolean) => void,
        get: () => boolean
    }
    carriableSelector: {
        tabs: {
            set: (
                upperTab: typeof DEFAULT_STATE.carriableSelector.upperTab,
                lowerTab: typeof DEFAULT_STATE.carriableSelector.lowerTab
            ) => void,
            get: () => {
                upperTab: State['carriableSelector']['upperTab'],
                lowerTab: State['carriableSelector']['lowerTab']
            }
        },
        legSelector: {
            set: (departure: { key: string, label: string }, destination: { key: string, label: string }) => void,
            get: () => State['carriableSelector']['legSelector']
        },
        hideCarriablePanel: {
            get: () => State['carriableSelector']['hidePanel']
            set: (hide: boolean) => void,
            toggle: () => void
        }
    },
    forms: {
        cargoForm: {
            get: () => State['forms']['cargoForm'],
            setFields: (fields: State['forms']['cargoForm']) => void,
            resetFields: () => void,
            submit: (departureID: string, destinationID: string) => Promise<void>,
            ref: MutableRefObject<any>
        },
        groupForm: {
            get: () => State['forms']['groupForm'],
            setFields: (fields: State['forms']['groupForm']) => void,
            resetFields: () => void,
            ref: MutableRefObject<any>
        },
        newLegForm: {
            get: () => State['forms']['newLegForm'],
            setFields: (fields: State['forms']['newLegForm']) => void,
            resetFields: () => void
        },
        searchForm: {
            set: (searchForm: typeof DEFAULT_STATE.carriableSelector.searchForm) => void,
            get: () => State['carriableSelector']['searchForm']
        }
    },
    modal: {
        paxModal: {
            open: (pax: ScheduledPaxNode) => void,
            close: () => void,
            get: () => State['modal']['paxModal']
        },
        newLegFormModal: {
            open: (
                departure?: Location | null,
                destination?: Location | null,
                callback?: State['modal']['newLegFormModal']['callback']
            ) => void,
            close: () => void,
            get: () => State['modal']['newLegFormModal'],
            callback?: State['modal']['newLegFormModal']['callback']
        }
    },
    drawer: {
        isnDrawer: {
            open: (pax: ScheduledPaxNode) => void,
            close: () => void,
            get: () => State['editor']['isnDrawer']
        }
    },
    chargeCodes: {
        getDefault: () => State['editor']['defaultChargeCode'],
        clear: () => void,
        setDefault: (chargeCode: string) => void
    },
    editor: {
        visibleLegs: {
            get: () => ({ departureID: string, destinationID: string }[]),
            isLegVisible: (departureID: string, destinationID: string) => boolean,
            showAll: () => void,
            hideAll: () => void,
            getAsStrings: () => string[],
            setAsStrings: (keys: string[]) => void,
            show: (departure: string, destination: string) => void
        }
    },
    notesForPilot: {
        getModifiedText: () => string,
        getQuickEditProps: (entityId: string) => Partial<NotesForPilot.QuickEdit.QuickEditProps>,
        editor: {
            isOpen: () => boolean,
            open: () => void,
            close: () => void,
            getNotes: () => NotesForPilot.Editor.State['notes'],
            hasNotes: () => boolean,
            submit: () => void,
            getEditorProps: () => Partial<NotesForPilot.Editor.EditorProps>
            getToolbarProps: () => NotesForPilot.Editor.ToolbarProps
        }
    }
}

export interface UseSchedulerApiProps {
    data: ScheduleHistoryDetail,
    groupData: GroupData,
    refetch?: () => void,
    editMode?: boolean,
    isLoading?: boolean,
    error?: any,
    onEditModeChange?: (editing: boolean) => void,
    onFinish?: () => void,
    saveInfo?: ScheduleEditorApi['saveInfo']
}

/**
 * Implements the Phase2Api as a React hook
 */
export function useScheduleEditorApi(props: UseSchedulerApiProps){
    const apolloClient = useApolloClient();

    // Hooks -----------------------------------------------------------------------------------------------------------
    const [ state, dispatch ] =
        useReducer<React.Reducer<Phase2State.State, Phase2State.Actions>>(Phase2State.Reducer, Phase2State.DEFAULT_STATE);

    const orgData = useContext(OrgDataContext);

    const groupFormRef = useRef(null);
    const cargoFormRef = useRef(null);
    // -----------------------------------------------------------------------------------------------------------------

    // ISN checker hook ------------------------------------------------------------------------------------------------
    function onIsnCheckerFinished(){
        props.refetch?.();
    }

    const bulkISNChecker = useBulkISNChecker(
        apolloClient, orgData.getActiveOrg()?._id,
        onIsnCheckerFinished
    );
    // -----------------------------------------------------------------------------------------------------------------

    // Updates the state as a side effect to prop changes --------------------------------------------------------------
    useEffect(() => {

        // Update editMode if prop is defined.
        if (props.editMode !== undefined && state.editMode !== props.editMode){
            dispatch({ type: ActionType.SET_EDIT_MODE, payload: props.editMode });
        }

        // Initialize data state
        if (props.data){
            let entities = [...props.data.paxList];
            dispatch({
                type: ActionType.INIT_DATA,
                entities: entities as ScheduleNode[],
                group: {
                    ...props.groupData,
                    lastKnownControllerObj: props.groupData.lastKnownControllerObj as Location
                }
            })
        }

        if (
            props.isLoading !== state.queryStatus.loading ||
            props.error !== state.queryStatus.error
        ){
            dispatch({ type: ActionType.SET_QUERY_STATUS, payload: { loading: props.isLoading, error: props.error } })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.editMode,
        state.editMode,
        props.data,
        props.isLoading,
        props.error
    ]);

    // Show and hide left panel if editMode changes.
    useEffect(() => {
        if (state.editMode){
            dispatch({ type: ActionType.SET_HIDE_CARRIABLE_PANEL, payload: false });
        }
        else
        {
            dispatch({ type: ActionType.SET_HIDE_CARRIABLE_PANEL, payload: true });
        }
    }, [state.editMode]);

    // -----------------------------------------------------------------------------------------------------------------

    // Tune visible legs -----------------------------------------------------------------------------------------------
    // Visible legs will be determined by how many legs there are. If there are more than 5 legs, all legs will be
    // collapsed by default.

    const hasOriginalEntities = state.data.originalEntities.length > 0

    useEffect(() => {
        if (state.data.originalEntities.length > 0){
            // Data has just been filled in after query finished.
            const legs = Util.DepDestList.build(state.data.originalEntities);

            if (legs.length > 5){
                dispatch({ type: ActionType.SET_VISIBLE_LEGS, mode: 'objects', action: 'set', payload: [] });
            }
            else
            {
                const depDestList = legs.map(item => ({
                    departureID: item.departure._id,
                    destinationID: item.destination._id
                }));
                dispatch({ type: ActionType.SET_VISIBLE_LEGS, mode: 'objects', action: 'set', payload: depDestList });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        hasOriginalEntities
    ]);
    // -----------------------------------------------------------------------------------------------------------------

    // Dereferences person id list to real personnel documents ---------------------------------------------------------
    const derefSelPersonnelResult = useQuery(ResolvePersonIdsQuery, {
        variables: {
            ids: state.carriableSelector.selectedPersonnelIds
        },
        fetchPolicy: 'cache-first',
        errorPolicy: 'all'
    })
    const selectedPersonnelObjs = (derefSelPersonnelResult.data?.resolve_entity_ids || []) as (Person | undefined)[];
    // -----------------------------------------------------------------------------------------------------------------

    function createDispatchFunc<T extends ActionType>(type: T){
        return (payload: any) => dispatch({ type: type as any, payload: payload });
    }

    function createDispatchFuncNoPayload<T extends ActionType>(type: T){
        return () => dispatch({ type: type as any });
    }

    function setEditMode(editing: boolean){
        if (props.editMode !== undefined && props.onEditModeChange){
            props.onEditModeChange(editing);
            return;
        }
        dispatch({ type: ActionType.SET_EDIT_MODE, payload: editing });
    }

    let groupData: GroupData = null;
    if (props.groupData.lastKnownControllerObj){
        groupData = {
            ...props.groupData,
            lastKnownControllerObj: props.groupData.lastKnownControllerObj
        }
    }

    // If I don't use useMemo, the filtered list will return a different reference
    // every render. This ensures the filtered version of modifiedEntities only changes references
    // when modifiedEntities changes.
    const modifiedEntitiesNotOnCarrier = useMemo(() => {
        return state.data.modifiedEntities.filter((entity) => {
            return !Boolean(entity.currentCarrierID)
        })
    }, [ state.data.modifiedEntities ])

    // Implements the API used by child components to communicate with other child components --------------------------
    const api: ScheduleEditorApi = {
        getScheduledPaxNodes: () => state.data.modifiedEntities.filter(e => e.classType === 'flytsuite.paxnode') as ScheduledPaxNode[],
        getScheduledCgoNodes: () => state.data.modifiedEntities.filter(e => e.classType === 'flytsuite.cgonode') as ScheduledCgoNode[],
        bulkIsnChecker: bulkISNChecker,
        groupData: groupData,
        schedule: {
            finish: () => props.onFinish?.()
        },
        data: {
            modifiedEntities: {
                get: () => state.data.modifiedEntities,
                getNotOnCarrier: () => modifiedEntitiesNotOnCarrier,
                set: (entities) => dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, set: entities }),
                add: (entities) => dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, add: entities }),
                remove: (entityIds) => dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, remove: entityIds }),
            },
            originalEntities: {
                get: () => state.data.originalEntities
            },
            getModifiedEntityById: (entityId) => state.data.modifiedEntities
                .find((entity) => getSNodeID(entity) === entityId),
            removeLeg(departureID, destinationID) {
                const entitiesToRemove = state.data.modifiedEntities
                    .filter(entity => (
                        entity.departureID?._id === departureID && entity.destinationID?._id === destinationID
                    ))
                    .map(e => getSNodeID(e));

                if (entitiesToRemove.length){
                    dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, remove: entitiesToRemove });
                }
            },
            modifyEntity(entityId, changedFields){
                const entityObj = state.data.modifiedEntities
                    .find(e => getSNodeID(e) === entityId);

                if (!entityObj) return;

                const newEntity = {
                    ...entityObj,
                    ...changedFields
                } as ScheduleNode;

                dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, add: [newEntity] });
            },

            removeEntities(entities) {
                dispatch({type: ActionType.SET_MODIFIED_ENTITIES, remove: entities})
            },

            swapEntities(startIdx, endIdx) {
                if (!state.data.modifiedEntities.length) return;
                if (startIdx < 0 || startIdx >= state.data.modifiedEntities.length) return;
                if (endIdx < 0 || endIdx >= state.data.modifiedEntities.length) return;

                const entityListCopy = [...state.data.modifiedEntities];

                const startObj = entityListCopy[startIdx];
                const endObj = entityListCopy[endIdx];

                if (
                    startObj.departureID?._id !== endObj.departureID?._id ||
                    startObj.destinationID?._id !== endObj.destinationID?._id
                ){
                    // Prevent reordering between legs
                    return;
                }

                entityListCopy[startIdx] = endObj;
                entityListCopy[endIdx] = startObj;

                dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, set: entityListCopy });
            },

            swapLegs(start, arg1) {

                // Compute legs from data
                const legs = this.getLegs();

                const startIdx = findLegIdx(legs, start);
                let endIdx = -1;

                if (typeof arg1 === 'string'){
                    // arg1 is either "up" or "down"
                    if (arg1 === 'up'){
                        endIdx = startIdx - 1;
                    }
                    else if (arg1 === 'down')
                    {
                        endIdx = startIdx + 1;
                    }
                }
                else
                {
                    // arg1 is another DepDestListItem
                    endIdx = findLegIdx(legs, arg1);
                }

                if (
                    startIdx < 0 || endIdx < 0 ||
                    startIdx >= legs.length || endIdx >= legs.length
                ){
                    console.error(new Error(`Index out of bounds: startIdx: ${startIdx}, endIdx: ${endIdx}`))
                    return;
                }

                // Swap
                {
                    const temp = legs[startIdx];
                    legs[startIdx] = legs[endIdx];
                    legs[endIdx] = temp;
                }

                this.setLegs(legs);
            },

            getLegs() {return Util.DepDestList.build(state.data.modifiedEntities) },

            setLegs(legs) {
                const newEntities = Util.DepDestList.toEntities(legs);
                dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, set: newEntities });
            },

            setEntityLegs(entityIds, departure, destination) {
                const newEntities = entityIds
                    .map(entityId => state.data.modifiedEntities.find(e => getSNodeID(e) === entityId))
                    .filter(e => e)
                    .map((entity) => {
                        return {
                            ...entity,
                            departureID: departure,
                            destinationID: destination
                        }
                    });

                dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, add: newEntities });
                dispatch({ type: ActionType.SET_SELECTED_ENTITY_IDS, set: [] });
            },

            reset(){ dispatch({ type: ActionType.RESET_DATA }); },

            getNotesForPilot() {
                /*  Gather all notesForPilot values from pax and cargo and create a dictionary of them with this structure:
                    {
                        <MESSAGE>: ScheduleNode[]
                    }
                 */

                return Util.getNoteMap(state.data.modifiedEntities)
            }
        },
        queryInfo: {
            loading: state.queryStatus.loading,
            error: state.queryStatus.error,
            hasError: !!state.queryStatus.error
        },
        saveInfo: {
            saving: !!props.saveInfo?.saving,
            hasError: !!props.saveInfo?.hasError
        },
        saveStatus: {
            get: () => state.saveStatus,
            dismiss: createDispatchFuncNoPayload(ActionType.DISMISS_SAVE_STATUS)
        },
        editMode: {
            get: () => state.editMode,
            toggle: () => setEditMode(!state.editMode),
            set: setEditMode
        },
        selection: {
            entityIds: {
                get: () => state.editor.selectedEntityIds,
                set: (newEntities) => dispatch({ type: ActionType.SET_SELECTED_ENTITY_IDS, set: newEntities }),
                add: (newEntities) => dispatch({ type: ActionType.SET_SELECTED_ENTITY_IDS, add: newEntities }),
                remove: (removedEntities) => dispatch({ type: ActionType.SET_SELECTED_ENTITY_IDS, remove: removedEntities }),
                toggle(entityID){
                    if (!state.editor.selectedEntityIds.includes(entityID))
                        this.add([entityID]);
                    else
                        this.remove([entityID]);
                },
                clear: () => dispatch({ type: ActionType.SET_SELECTED_ENTITY_IDS, set: [] }),
                all: () => {
                    let entities = state.data.modifiedEntities.map(e => getSNodeID(e));
                    dispatch({ type: ActionType.SET_SELECTED_ENTITY_IDS, set: entities });
                }
            },
            personnelIds: {
                set: createDispatchFunc(ActionType.SET_SELECTED_PERSONNEL_IDS),
                get: () => state.carriableSelector.selectedPersonnelIds,
                getAsObjects: () => ({
                    loading: [NetworkStatus.loading, NetworkStatus.setVariables].includes(derefSelPersonnelResult.networkStatus),
                    objects: selectedPersonnelObjs
                }),
                clear: () => dispatch({ type: ActionType.SET_SELECTED_PERSONNEL_IDS, payload: [] }),
                addToSchedule: async (departureID: string, destinationID: string) => {
                    // If derefSelPersonnelResult is still loading, we can't do anything.
                    if (derefSelPersonnelResult.loading){
                        message.error('Error: Personnel data is still loading. Please try again.')
                        return;
                    }

                    // Re-reference locations to real location documents
                    const locResult = await deRefLocations(apolloClient, [departureID, destinationID]);
                    if (locResult.errors.length){
                        message.error("Failed to get locations.");
                        return;
                    }
                    const [ departure, destination ] = locResult.data;

                    // Convert personnel to PaxNode stubs
                    function toPassenger(person: Person){

                        const pax = personToPassenger(
                            person,
                            {
                                departureID: departure,
                                destinationID: destination,
                                customerID: person.customerID,
                                tpID: orgData.transporterID,
                                chargeCode: null
                            }
                        );

                        // Using _id is a bad idea
                        pax.__temp_id = Util.genTempID();
                        delete pax._id;
                        return pax;
                    }

                    let autoFilledPersons = [];

                    selectedPersonnelObjs.forEach((person) => {
                        if (person.lastPaxWeight || person.lastBagWeight || person.lastBagCount){
                            autoFilledPersons.push(person);
                        }
                    });

                    if (autoFilledPersons.length){
                        message.info(`Previous weight info found for ${autoFilledPersons.length} personnel. 
                    Auto-filled fields.`, 5);
                    }

                    const paxNodes = selectedPersonnelObjs.map(toPassenger);

                    dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, add: paxNodes })
                }
            }
        },
        carriableSelector: {
            tabs: {
                set: (upperTab, lowerTab) =>
                    dispatch({ type: ActionType.SET_CARRIABLE_SELECTOR_TAB, payload: { upperTab, lowerTab } }),
                get: () => ({
                    upperTab: state.carriableSelector.upperTab,
                    lowerTab: state.carriableSelector.lowerTab
                })
            },
            legSelector: {
                set: (departure, destination) => (
                    dispatch({ type: ActionType.SET_LEG_SELECTOR, payload: { departure, destination } })
                ),
                get: () => state.carriableSelector.legSelector
            },
            hideCarriablePanel: {
                get: () => state.carriableSelector.hidePanel,
                set: createDispatchFunc(ActionType.SET_HIDE_CARRIABLE_PANEL),
                toggle: () => dispatch({ type: ActionType.SET_HIDE_CARRIABLE_PANEL, payload: undefined }),
            }
        },
        forms: {
            cargoForm: {
                get: () => state.forms.cargoForm,
                setFields: (fields) => dispatch({ type: ActionType.SET_FORM_DATA, cargo: fields }),
                resetFields: () => dispatch({
                    type: ActionType.RESET_FORM_DATA,
                    cargo: true
                }),
                submit: async (departureID, destinationID) => {

                    // Make sure form is valid
                    cargoFormRef.current.validateFieldsAndScroll(async (err: unknown, formValues: CargoFormData) => {
                        if (err) return;

                        // Re-reference locations to real location documents
                        const locResult = await deRefLocations(apolloClient, [departureID, destinationID]);
                        if (locResult.errors.length){
                            message.error("Failed to get locations.");
                            return;
                        }
                        const [ departure, destination ] = locResult.data;

                        const cgoNode = cargoFormToCargoNode(formValues, orgData, departure, destination);

                        dispatch({ type: ActionType.SET_MODIFIED_ENTITIES, add: [cgoNode] });
                    })
                },
                ref: cargoFormRef
            },
            groupForm: {
                get: () => state.forms.groupForm,
                setFields: (fields) => dispatch({ type: ActionType.SET_FORM_DATA, group: fields }),
                resetFields: () => dispatch({
                    type: ActionType.RESET_FORM_DATA,
                    group: true
                }),
                ref: groupFormRef
            },
            newLegForm: {
                get: () => state.forms.newLegForm,
                setFields: (fields) => dispatch({ type: ActionType.SET_FORM_DATA, newLeg: fields }),
                resetFields: () => dispatch({
                    type: ActionType.RESET_FORM_DATA,
                    newLeg: true
                })
            },
            searchForm: {
                get: () => state.carriableSelector.searchForm,
                set: createDispatchFunc(ActionType.SET_CARRIABLE_SEL_SEARCH)
            }
        },
        modal: {
            newLegFormModal: {
                get: () => state.modal.newLegFormModal,
                open: (departure=null, destination=null, callback=null) => {

                    if (departure && destination){
                        const newLegValue = {
                            departure: { key: departure._id, label: departure.name },
                            destination: { key: destination._id, label: destination.name },
                        }
                        dispatch({ type: ActionType.SET_NEW_LEG_MODAL, visible: true, newLegValue: newLegValue, callback });
                        return;
                    }
                    dispatch({ type: ActionType.SET_NEW_LEG_MODAL, visible: true, callback });
                },
                close: () => dispatch({ type: ActionType.SET_NEW_LEG_MODAL, visible: false }),
                callback: state.modal.newLegFormModal.callback
            },
            paxModal: {
                get: () => state.modal.paxModal,
                open: (pax) => dispatch({ type: ActionType.SET_PAXNODE_MODAL, pax, visible: true }),
                close: () => dispatch({ type: ActionType.SET_PAXNODE_MODAL, visible: false })
            },
        },
        drawer: {
            isnDrawer: {
                get: () => state.editor.isnDrawer,
                open: (pax) => dispatch({ type: ActionType.SET_ISN_DRAWER, payload: { pax, visible: true } }),
                close: () => dispatch({
                    type: ActionType.SET_ISN_DRAWER,
                    payload: { pax: state.editor.isnDrawer.pax, visible: false }
                })
            },
        },
        chargeCodes: {
            getDefault: () => state.editor.defaultChargeCode,
            setDefault: createDispatchFunc(ActionType.SET_DEFAULT_CHARGE_CODE),
            clear: () => dispatch({ type: ActionType.SET_DEFAULT_CHARGE_CODE, payload: '' })
        },
        editor: {
            visibleLegs: {
                get: () => state.editor.visibleLegs,
                show: (departureID, destinationID) => {
                    const payload = {
                        departureID: departureID,
                        destinationID: destinationID
                    }
                    dispatch({ type: ActionType.SET_VISIBLE_LEGS, mode: 'objects', action: 'add', payload: [payload] });
                },
                isLegVisible: (departureID, destinationID) => (
                    Boolean(
                        state.editor.visibleLegs.find((item) => (
                            item.departureID === departureID && item.destinationID === destinationID)
                        )
                    )
                ),
                showAll: () => {
                    const data = Util.DepDestList.build(state.data.modifiedEntities);

                    const depDestList = data
                        .map(item => ({
                            departureID: item.departure._id,
                            destinationID: item.destination._id
                        }));

                    dispatch({ type: ActionType.SET_VISIBLE_LEGS, mode: 'objects', action: 'set', payload: depDestList })
                },
                hideAll: () => {
                    dispatch({ type: ActionType.SET_VISIBLE_LEGS, mode: 'objects', action: 'set', payload: [] });
                },
                setAsStrings: (keys) => (
                    dispatch({ type: ActionType.SET_VISIBLE_LEGS, mode: 'strings', action: 'set', splitBy: '::', payload: keys })
                ),
                getAsStrings: () => {
                    return state.editor.visibleLegs
                        .map(item => `${item.departureID}::${item.destinationID}`)
                }
            }
        },
        notesForPilot: {
            getModifiedText: () => state.notesForPilotPopup.modifiedText,
            getQuickEditProps: (entityId: string) => {

                let componentState = NotesForPilotRendererModule.DEFAULT_STATE;

                if (entityId === state.notesForPilotPopup.entityId)
                    // If this is not the current entity having notes edited, then this NotesForPilotQuickedit should be closed.
                    componentState = {
                        open: state.notesForPilotPopup.open,
                        editMode: state.notesForPilotPopup.editMode,
                        modifiedText: state.notesForPilotPopup.modifiedText,
                        originalText: state.notesForPilotPopup.originalText
                    }

                const stateProps = {
                    state: componentState,
                    dispatch: (value) => {
                        dispatch({ type: ActionType.NOTES_FOR_PILOT_POPUP, entityId, action: value })
                    }
                }

                return {
                    overrideState: stateProps
                }
            },
            editor: {
                isOpen: () => state.notesForPilotEditor.open,
                open: () => dispatch({ type: ActionType.NOTES_FOR_PILOT_EDITOR, open: true }),
                close: () => dispatch({ type: ActionType.NOTES_FOR_PILOT_EDITOR, open: false }),
                getNotes: () => state.notesForPilotEditor.state.notes,
                hasNotes: () => Boolean(Object.keys(state.notesForPilotEditor.state.notes).length),
                submit: () => {

                    function handleSubmit(){
                        dispatch({ type: ActionType.SUBMIT_NOTES_FOR_PILOT_EDITOR });
                    }

                    // Confirm with user that there are notes that are not assigned to any PAX/CGO and
                    // it will remove them when they submit.
                    const unassignedNotes = NotesForPilot.Editor.Util
                        .findUnassignedNoteIds(state.notesForPilotEditor.state);

                    if (unassignedNotes.length){
                        Modal.confirm({
                            title: 'Unassigned notes found',
                            content: (
                                `${unassignedNotes.length} notes have no PAX/CGO assigned and they will be removed if you continue.`
                            ),
                            okText: 'Continue',
                            onOk: handleSubmit
                        })
                        return;
                    }

                    handleSubmit();
                },
                getEditorProps: () => {
                    return {
                        overrideState: {
                            state: state.notesForPilotEditor.state,
                            dispatch: (action) => dispatch({ type: ActionType.NOTES_FOR_PILOT_EDITOR, action: action })
                        }
                    }
                },
                getToolbarProps: () => {
                    return {
                        state: state.notesForPilotEditor.state,
                        dispatch: (action) => dispatch({ type: ActionType.NOTES_FOR_PILOT_EDITOR, action: action })
                    }
                }
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------------------

    return api;
}