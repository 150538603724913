import React from 'react';
import { FlightLeg } from '../../schema';
import { Card, Tabs } from 'antd';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import { CardProps } from 'antd/lib/card';
import ETable from 'components/enchanced-antd-table';

export interface LegManualAssignContentProps {
    legs: FlightLeg[],
    paxColumns?: ColumnProps<any>[],
    cgoColumns?: ColumnProps<any>[],
    showOrder?: boolean,
    showScheduledGroup?: boolean,
    rowSelection?: TableRowSelection<any>,
    onLegTabChange?(key: any): void,
    legTabKey?: any,
    cardProps?: CardProps
}

const LegManualAssignContent: React.FC<LegManualAssignContentProps> = (props) => {

    const renderLegData = (legData) => {
        return <Card
            title={`${legData.departure} to ${legData.destination}`}
            size="small"
            defaultActiveTabKey="pax"
            className="small-tabs ant-card-with-table ant-card-shadow"
            tabList={[
                {
                    key: 'pax',
                    tab: `Passengers (${legData ? legData.paxIDs.length : 0})`
                },
                {
                    key: 'cgo',
                    tab: `Cargo (${legData ? legData.cgoIDs.length : 0})`
                }
            ]}
            onTabChange={props.onLegTabChange}
            activeTabKey={props.legTabKey}
            {...(props.cardProps || {})}
        >
            {props.legTabKey === 'pax' ? (
                <ETable
                    size="small"
                    className='mc-table'
                    pagination={false}
                    style={{ border: 0 }}
                    dataSource={legData && legData.paxIDs}
                    columns={paxColumns}
                    rowKey={(row) => row._id}
                    // className="borderless marginless"
                    rowSelection={props.rowSelection}
                />
            ) : 
            (
                <ETable
                    size="small"
                    pagination={false}
                    style={{ border: 0 }}
                    dataSource={legData && legData.cgoIDs}
                    columns={cgoColumns}
                    rowKey={(row) => row._id}
                    className="borderless marginless mc-table"
                    rowSelection={props.rowSelection}
                />                                
            )}
        </Card>

    }

    const legsData = props.legs;
    let paxColumns = props.paxColumns;
    if (!paxColumns){

        paxColumns = [
            {
                title: 'Name',
                key: 'name',
                render: (_, record) => record && `${record.lastName}, ${record.firstName}`
            },
            {
                title: 'Employer',
                key: 'employer',
                dataIndex: 'employerID',
                render: (e) => e && e.name
            },
            {
                title: 'Weight',
                key: 'paxWeight',
                dataIndex: 'paxWeight'
            },
            {
                title: 'Bag Weight',
                key: 'bagWeight',
                dataIndex: 'bagWeight'
            },
            {
                title: 'Bag Count',
                key: 'bagCount',
                dataIndex: 'bagCount'
            },
            {
                title: 'Departure',
                key: 'departure',
                dataIndex: 'departureID',
                render: (l) => l && l.name
            },
            {
                title: 'Destination',
                key: 'destination',
                dataIndex: 'destinationID',
                render: (l) => l && l.name
            },
            {
                title: 'Transit Type',
                key: 'transitType',
                dataIndex: 'transitType'
            }
        ]

        if (props.showOrder){
            paxColumns.unshift({
                title: 'Order',
                key: 'scheduledOrder',
                dataIndex: 'scheduledOrder',
                render: (o: number, _, index) => o || (index + 1)
            })
        }
    
        if (props.showScheduledGroup){
            paxColumns.unshift({
                title: 'Scheduled Group',
                key: 'scheduledGroup',
                dataIndex: 'scheduledGroup'
            })
        }
    }

    let cgoColumns = props.cgoColumns;
    if (!cgoColumns){
        cgoColumns = [
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: 'Weight',
                key: 'weight',
                dataIndex: 'weight'
            },
            {
                title: 'Departure',
                key: 'departure',
                dataIndex: 'departureID',
                render: (l) => l && l.name
            },
            {
                title: 'Destination',
                key: 'destination',
                dataIndex: 'destinationID',
                render: (l) => l && l.name
            },
            {
                title: 'Delivered By',
                key: 'deliveredBy',
                dataIndex: 'deliveredBy'
            },
            {
                title: 'Attention To',
                key: 'attentionTo',
                dataIndex: 'attentionTo'
            },
            {
                title: 'Approved By',
                key: 'approvedBy',
                dataIndex: 'approvedBy'
            },
            {
                title: 'Transit Type',
                key: 'transitType',
                dataIndex: 'transitType'
            }
        ]
    }

    if (legsData.length <= 0) return null

    return (
        <Tabs>
            {legsData.map(leg => (
                <Tabs.TabPane key={String(leg.order)} tab={"Leg #" + (leg.order+1)}>
                    {renderLegData(leg)}
                </Tabs.TabPane>)
            )}
        </Tabs>
    )
}

export default LegManualAssignContent