import React, {useContext} from 'react';
import gql from 'graphql-tag';
import {useQuery} from 'react-apollo';
import { Select, message, Input } from 'antd';
import { SelectProps } from 'antd/lib/select';
import {OrgDataContext} from "../../context/orgData";

const { Option } = Select;

const GET_AIRCRAFTS = gql`
query AircraftSelectGetPilots($tpID: ID!){
    getAircrafts(tpID: $tpID) {
        _id
        tailNum
    }
}
`

const AircraftSelect = React.forwardRef((props: SelectProps, ref?: React.LegacyRef<Select>) => {

    const { transporterID } = useContext(OrgDataContext);

    const { data, loading } = useQuery(GET_AIRCRAFTS, {
        variables: {
            tpID: transporterID
        },
        fetchPolicy: 'cache-and-network',
        onError: () => message.error('Error: Could not load aircrafts')
    })

    const noAircraft = !loading && data && data.getAircrafts && data.getAircrafts.length <= 0;
    if (noAircraft){
        return (
            <Input disabled={true} value="No Aircraft Available" />
        )
    }
    return (
        <Select
            ref={ref}
            defaultValue=""
            {...props}
            showSearch
            optionFilterProp="children"
            showAction={["focus", "click"]}
            filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            value={(!loading && data && data.getAircrafts && data.getAircrafts.length && props.value) || ''}>
            <Option value="">Choose a aircraft</Option>
            {!loading && data && data.getAircrafts && data.getAircrafts.map((aircraft: any) => (
                aircraft && <Option key={aircraft._id} value={aircraft._id}>{aircraft.tailNum}</Option>
            ))}
        </Select>
    )
})

export default AircraftSelect