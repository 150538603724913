import gql from "graphql-tag";

export default gql`
enum Phase1Step {
    sets,
    history
}

type SchedulingState {
    phase1Step: Phase1Step
}

extend type Query {
    getPhase1Step: Phase1Step
    schedulingDateRange: [String!]!
    scheduling: SchedulingState!
}

extend type Mutation {
    setPhase1Step(step: Phase1Step): Phase1Step
}
`