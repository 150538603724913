import { FlightLeg } from "schema";
import FlightLegManager, { FlightCgo, FlightPax } from "./flight-leg-manager";
import {CgoNode, PaxNode} from "../types/carriable";
import { difference } from "lodash";
import {FlightPathOptionalArgs} from "./flight-path";

export function graphqlPaxToFlightManPax(gPax: any): FlightPax {
    return {
        _id: gPax._id,
        paxWeight: gPax.paxWeight,
        bagCount: gPax.bagCount,
        bagWeight: gPax.bagWeight,
        departureID: gPax.departureID?._id,
        destinationID: gPax.destinationID?._id
    }
}

export function graphqlCgoToFlightManCgo(gCgo: any): FlightCgo {
    return {
        _id: gCgo._id,
        departureID: gCgo.departureID?._id,
        destinationID: gCgo.destinationID?._id,
        weight: gCgo.weight
    }
}

export function buildLocNameMapFromPaxCgoLists(paxObjs?: any[], cgoObjs?: any[], flightLegs?: FlightLeg[]){
    let locIDToNameMap = new Map<string, string>();

    // Get location names from pax list
    paxObjs?.forEach((pax) => {
        if (pax.departureID?._id){
            locIDToNameMap.set(pax.departureID._id, pax.departureID.name)
        }
        if (pax.destinationID?._id){
            locIDToNameMap.set(pax.destinationID._id, pax.destinationID.name);
        }
    })

    // Get location names from cgo list
    cgoObjs?.forEach((cgo) => {
        if (cgo.departureID?._id && !locIDToNameMap.has(cgo.departureID._id)){
            locIDToNameMap.set(cgo.departureID._id, cgo.departureID.name)
        }
        if (cgo.destinationID?._id && !locIDToNameMap.has(cgo.destinationID._id)){
            locIDToNameMap.set(cgo.destinationID._id, cgo.destinationID.name);
        }
    })

    // Get location names from flight legs
    if (flightLegs && typeof flightLegs === 'string'){
        flightLegs = JSON.parse(flightLegs);
    }
    if (flightLegs){
        Object.values<any>(flightLegs).forEach((leg) => {
            if (leg.departureID && !locIDToNameMap.has(leg.departureID)){
                locIDToNameMap.set(leg.departureID, leg.departure);
            }
            if (leg.destinationID && !locIDToNameMap.has(leg.destinationID)){
                locIDToNameMap.set(leg.destinationID, leg.destination);
            }
        })
    }
    return locIDToNameMap;
}

// Example use: let flManager = getFlightLegManagerFromFlightQuery(this.props.data.getFlight, newLocNames);
export function getFlightLegManagerFromFlightQuery(
    flight: any,
    additionalLocIDToNameMap?: Map<string, string>,
    overrideOrigin?: { id: string, name: string },
    flightPathArgs?: FlightPathOptionalArgs
): FlightLegManager {
    let paxList = (flight.paxIDList || []).map(graphqlPaxToFlightManPax);
    let cgoList = (flight.cgoIDList || []).map(graphqlCgoToFlightManCgo);
    let parsedLegs: FlightLeg[] = [];
    try{
        if (typeof flight.legs === 'string'){
            parsedLegs = Object.values(JSON.parse(flight.legs));
        }
        else if (Array.isArray(flight.legs)){
            parsedLegs = flight.legs;
        }
        else {
            parsedLegs = Array.from(Object.values(flight.legs))
        }
    }
    catch(err){
        console.error('[FlightLegManager] Failed to parse flight legs');
    }
    let nameMap = buildLocNameMapFromPaxCgoLists(flight.paxIDList, flight.cgoIDList, parsedLegs);
    if (flight.departureID){
        nameMap.set(flight.departureID._id, flight.departureID.name);
    }
    if (flight.destinationID){
        nameMap.set(flight.destinationID._id, flight.destinationID.name);
    }
    additionalLocIDToNameMap?.forEach((val, key) => !nameMap.has(key) ? nameMap.set(key, val) : null);
    
    if (overrideOrigin?.id){
        nameMap.set(overrideOrigin.id, overrideOrigin.name);
    }

    return new FlightLegManager({
        initialLegs: parsedLegs,
        paxObjs: paxList,
        cgoObjs: cgoList,
        overrideOrigin: overrideOrigin?.id || flight.departureID?._id,
        locationIDToNameMap: nameMap,
        flightPathOptionalArgs: flightPathArgs
    })
}

export function computeLegWeightsAndCounts(paxNodes: PaxNode[], cgoNodes: CgoNode[]){
    return {
        paxCount: paxNodes.length,
        cgoCount: cgoNodes.length,
        paxWeight: paxNodes.reduce((acc, pax) => acc + (pax?.paxWeight || 0), 0),
        cgoWeight: cgoNodes.reduce((acc, cgo) => acc + (cgo?.weight || 0), 0),
        bagCount: paxNodes.reduce((acc, pax) => acc + (pax?.bagCount || 0), 0),
        bagWeight: paxNodes.reduce((acc, pax) => acc + (pax?.bagWeight || 0), 0)
    }
}

/**
 * Find pax/cgo who don't exist anywhere in the new flight leg path.
 * @param oldLegs
 * @param newLegs
 */
export function findDroppedPaxCgoInLegs(oldLegs: FlightLeg[], newLegs: FlightLeg[]){
    const oldPaxIds = new Set<string>();
    const oldCgoIds = new Set<string>();
    const newPaxIds = new Set<string>();
    const newCgoIds = new Set<string>();

    function scan(set: Set<string>, listType: 'pax' | 'cgo'){
        return (leg: FlightLeg) => {
            const list = listType === 'pax' ? 'paxIDs' : 'cgoIDs';

            leg[list]?.forEach((entity) => {
                let id = entity?._id || typeof entity === 'string' ? entity : undefined;
                if (id){
                    set.add(entity);
                }
            })
        }
    }

    oldLegs?.forEach(scan(oldPaxIds, 'pax'));
    oldLegs?.forEach(scan(oldCgoIds, 'cgo'));
    newLegs?.forEach(scan(newPaxIds, 'pax'));
    newLegs?.forEach(scan(newCgoIds, 'cgo'));

    function diff(set1: Set<string>, set2: Set<string>){
        return difference(Array.from(set1), Array.from(set2));
    }

    const droppedPax = diff(oldPaxIds, newPaxIds);
    const droppedCgo = diff(oldCgoIds, newCgoIds);

    return {
        droppedPaxIds: droppedPax,
        droppedCgoIds: droppedCgo,
        dropCount: droppedPax.length + droppedCgo.length
    }
}