import React, { useEffect, useRef } from 'react'
import { debounce } from 'lodash';

export interface DebouncedFieldProps<T=any, C=any> {
    value?: T,
    onChange: (value: T) => void,
    getValueFromChangeEvent: (e: any) => T,
    children: React.ReactElement<C>,
    valuePropName?: string,
    debounceTime?: number,
    initializeValueOnce?: boolean
}

const DebouncedField: React.FC<DebouncedFieldProps> = (props) => {
    const [ value, setValue ] = React.useState();
    
    const refState = useRef({ initialized: false })

    useEffect(() => {
        if (props.initializeValueOnce && refState.current.initialized){
            return;
        }
        // prop value changed. Update internal state.
        setValue(props.value);
        refState.current.initialized = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.value ])

    const debouncedOnChange = debounce(props.onChange, props.debounceTime || 300);

    let cloned = React.cloneElement(props.children, {
        [props.valuePropName || 'value']: value,
        onChange: (e: any) => {
            let value = props.getValueFromChangeEvent(e);
            setValue(value);
            debouncedOnChange(value);
        }
    })

    return cloned;
}

export default DebouncedField