import { Input, message, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import gql from 'graphql-tag';
import React, {useContext} from 'react';
import { useQuery} from 'react-apollo';
import {OrgDataContext} from "../../context/orgData";

const { Option } = Select;

const GET_PILOTS = gql`
query PilotSelectGetPilots($tpID: ID!){
    getPilots(tpID: $tpID) {
        _id
        name {
            lastName
            firstName
        }
    }
}
`

const PilotSelect = React.forwardRef((props: SelectProps, ref?: React.LegacyRef<Select>) => {

    const { transporterID } = useContext(OrgDataContext);

    const { data, loading } = useQuery(GET_PILOTS, {
        variables: {
            tpID: transporterID
        },
        fetchPolicy: 'cache-and-network',
        onError: () => message.error('Error: Could not load aircrafts')
    })

    const noPilots = !loading && data && data.getPilots && data.getPilots.length <= 0;
    if (noPilots){
        return (
            <Input disabled={true} value="No Pilots Available" />
        )
    }
    return (
        <Select
            ref={ref}
            defaultValue=""
            showSearch
            disabled={noPilots}
            loading={loading}
            {...props}
            optionFilterProp="children"
            showAction={["focus", "click"]}
            filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            value={(!loading && data && data.getPilots && data.getPilots.length && props.value) || ''}>
            <Option value="">Choose a pilot</Option>
            {!loading && data && data.getPilots && data.getPilots.map(pilot => (
                pilot && <Option key={pilot._id} value={pilot._id}>{`${pilot.name.lastName}, ${pilot.name.firstName}`}</Option>
            ))}
        </Select>
    )
})

export default PilotSelect