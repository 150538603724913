import {produce} from "immer";
import {ApolloQueryAdapter, ApolloQueryAdapterArgs} from "./apollo-query-adapter";
import {LoadArgs, SideEffect} from "../infinite-scroller";
import {QueryResult} from "react-apollo";
import {MangoQueryResult} from "../../../common/types/couchdb/mango-query-result";
import React, {Dispatch} from "react";
import {NetworkStatus} from "apollo-boost";
import {cloneDeep} from "lodash";

const CouchFindAdapterInitState = {
    lastBookmark: null as string,
    currentBookmark: null as string,
    prevNetStat: null as NetworkStatus,
    currNetStat: null as NetworkStatus
}

type State = typeof CouchFindAdapterInitState;

type Action = {
    type: "INIT_BOOKMARK"
} |
{
    type: "UPDATE_NETWORK_STATUS",
    status: NetworkStatus
} |
{
    type: 'ON_PAGE_LOADED',
    bookmark: string
}

interface CouchdbFindAdapterArgs extends ApolloQueryAdapterArgs {
    getMangoQueryResult: (data: unknown) => MangoQueryResult,
}

/**
 * Implements pagination for CouchDB's mango queries using CouchDB's server-side pagination via bookmarks.
 */
export class CouchdbMangoAdapter extends ApolloQueryAdapter<State, Action> {
    initState = CouchFindAdapterInitState;
    sideEffect: SideEffect;
    getLoading = (queryResult: QueryResult<unknown>) => {
        return queryResult.networkStatus === NetworkStatus.fetchMore ||
            queryResult.networkStatus === NetworkStatus.loading;
    }
    getMangoQueryResult: (data: unknown) => MangoQueryResult;

    constructor(args: CouchdbFindAdapterArgs) {
        super(args);
        this.getMangoQueryResult = args.getMangoQueryResult;
    }

    /**
     * State machine used for keeping track of the current bookmark and the last bookmark, and tracks query NetworkStatus.
     * Eventually the currentBookmark and lastBookmark will be compared to check if the last page has been reached.
     */
    reducer(state: State, action: Action){
        switch (action.type){
            case "ON_PAGE_LOADED":
                return produce(state, (draft) => {
                    draft.lastBookmark = draft.currentBookmark;
                    draft.currentBookmark = action.bookmark;
                })
            case "INIT_BOOKMARK":
                return produce(state, (draft) => {
                    draft.lastBookmark = null;
                    draft.currentBookmark = null;
                });
            case "UPDATE_NETWORK_STATUS":
                return produce(state, (draft) => {
                    draft.prevNetStat = draft.currNetStat;
                    draft.currNetStat = action.status;
                })
            default:
                return state;
        }
    }

    onLoadMore(state: State, dispatch: React.Dispatch<Action>) {
        this.queryResult.fetchMore({
            variables: {
                ...this.queryResult.variables,
                bookmark: state.currentBookmark
            },
            query: this.query,

            // When the next page is fetched, update the cached query. This will
            // subsequently update the UI subscribed to this cache as well.
            updateQuery: (prevData: any, args) => {
                const prevClone = cloneDeep(prevData);

                const fetchMoreData = args.fetchMoreResult;
                const prevResult = this.getMangoQueryResult(prevClone);
                const currResult = this.getMangoQueryResult(fetchMoreData);
                prevResult.docs.push(...currResult.docs);
                prevResult.bookmark = currResult.bookmark;

                dispatch({ type: 'ON_PAGE_LOADED', bookmark: currResult.bookmark });

                return prevClone;
            }
        })
    }

    getLoadArgs(state: State, dispatch: Dispatch<Action>): LoadArgs {

        /**
         * Dispatches state actions when certain network status changes happen.
         */
        this.sideEffect = {
            deps: [this.queryResult.networkStatus, state.currNetStat, state.prevNetStat],
            callback: () => {
                if (state.currNetStat !== this.queryResult.networkStatus){
                    // Update query network status
                    dispatch({ type: 'UPDATE_NETWORK_STATUS', status: this.queryResult.networkStatus });
                }
                if (state.currNetStat === NetworkStatus.ready && state.prevNetStat === NetworkStatus.loading){
                    // Query just finished loading
                    const result = this.getMangoQueryResult(this.queryResult.data);
                    if (result.bookmark === undefined){
                        console.warn(
                            "Bookmark is undefined in MangoQueryResult. " +
                            "Make sure you have selected the 'bookmark' field in your query!"
                        );
                    }
                    dispatch({ type: 'ON_PAGE_LOADED', bookmark: result.bookmark });
                }
                if (
                    (state.currNetStat === NetworkStatus.ready && state.prevNetStat === NetworkStatus.refetch) ||
                    (state.currNetStat === NetworkStatus.ready && state.prevNetStat === NetworkStatus.setVariables)
                ){
                    // Query just finished reloading due to refetch or variable change
                    const result = this.getMangoQueryResult(this.queryResult.data);
                    dispatch({ type: 'INIT_BOOKMARK' });
                    dispatch({ type: 'ON_PAGE_LOADED', bookmark: result.bookmark });
                }
            }
        }

        // If the current and last bookmarks are the same, then we have reached the last page.
        const hasNextPage = state.lastBookmark !== state.currentBookmark;

        return {
            ...super.getLoadArgs(state, dispatch),
            hasNextPage: hasNextPage
        }
    }
}