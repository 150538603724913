import gql from "graphql-tag";

export default gql`
mutation SetFilter(
    $visible: Boolean
    $customer: [SelectItemInput]
    $transporter: selectItem!
    $departure: [SelectItemInput]
    $destination: [SelectItemInput]
    $employer: [SelectItemInput]
    $pax: [PaxGlobalFilterInput]
    $cgo: [CgoGlobalFilterInput]
){
    setGlobalFilter(
        visible: $visible
        customer: $customer
        transporter: $transporter
        departure: $departure
        destination: $destination
        employer: $employer
        pax: $pax
        cgo: $cgo
    ) @client {
        visible
        customer
        transporter
        departure {
            key
            label
        }
        destination {
            key
            label
        }
        employer {
            key
            label
        }
        pax {
            lastName
            firstName
        }
        cgo {
            name
        }
    }
}
`