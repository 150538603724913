import React, { useContext, useState, useEffect } from 'react';
import { Layout } from 'antd';
import EmployerNav from './nav';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { LivePassengersWithData } from '../../../components/live/LivePassengers';
import { MDPersonnel } from 'components/masterdata-2-0/pages';
import { GlobalAppStateContext } from 'context/global-app-state';
import cn from 'classnames';
import windowSizes from 'common/windowSizes';
import useUserGroups from 'hooks/useUserGroups';
import EmailTriggersPage from 'pages/app/management/email-triggers/email-triggers';

const { Sider } = Layout;

function Content(props){
    const { noPadding, ...rest } = props;
    let style = {
        padding: 24
    };
    if (props.noPadding){
        style.padding = 0;
    }
    if (props.style){
        style = {
            ...style,
            ...props.style
        }
    }
    return <Layout.Content {...rest} style={style} />
}

const EmployerRoutes = React.memo(({ userGroups }) => {

    return <Switch>
        <Route exact path="/app/passengers" component={LivePassengersWithData} />
        <Route exact path="/app/masterdata/personnel" component={MDPersonnel} />

        {!!(userGroups.includes('flytsuite.management.all') ||
            userGroups.includes('flytsuite.management.emailtriggers.all') ||
            userGroups.includes('flytsuite.master.all')) &&
            <Route
                exact
                path={'/app/management/email-triggers'}
                component={EmailTriggersPage}
            />
        }

        <Route render={() => <Redirect to="/app/passengers" />} />
    </Switch>
})

/**
 * Handles navigation and routes of employer branch of the application.
 */
function EmployerMain(){
    const [ userGroups ] = useUserGroups();
    const { state: { sidebarCollapsed }, setState } = useContext(GlobalAppStateContext);
    const [ hasExpanded, setHasExpanded ] = useState(false);

    useEffect(() => {
      if (!sidebarCollapsed && !hasExpanded){
        setHasExpanded(true);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sidebarCollapsed ])

    const history = useHistory();
    const { pathname } = useLocation();

    const navKey = pathname.replace('/app/', '');
    return <Layout className={cn({
        "mc-main-app-layout": true,
        "mc-main-app-layout-sider-open": (!sidebarCollapsed)
    })}>
        <div className="mc-main-app-layout-click-receiver" onClick={() => setState({ sidebarCollapsed: true })} />
        <Sider
            collapsed={sidebarCollapsed}
            width={200}
        >
            {hasExpanded ? 
            <EmployerNav
                selectedKeys={[navKey]}
                onClick={({ key }) => {
                    history.push('/app/' + key);
                    if (window.innerWidth <= windowSizes.small){
                        setState({ sidebarCollapsed: true })
                    }
                }}
            />
            : null}
        </Sider>
        <Content className="mc-main-app-layout-content">
            <EmployerRoutes userGroups={userGroups} />
        </Content>
    </Layout>
}

export default EmployerMain