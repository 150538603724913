import React, {Dispatch, ReducerAction, useEffect, useRef, useState} from "react";
import {Reducer, State} from './state';
import {Button, Col, Icon, message, Row, Spin} from "antd";
import {DraggableBodyRow} from "../../../TableDragSorting";
import {ColumnProps} from "antd/lib/table";
import {Location} from "../../../../common/types/location";
import CenteredLoadingScreen from "../../../CenteredLoadingScreen";
import useRouteEditorApi from "./hook";
import "./route-editor.less";
import {LocationSelect} from "../../../form/select/LocationSelect";
import useApolloClientHelper from "../../../../hooks/useApolloClientHelper";
import ETable from "../../../enchanced-antd-table";

export interface RouteEditorProps {
    initFlightPath?: string[],
    overrideState?: {
        state: State,
        dispatch: Dispatch<ReducerAction<typeof Reducer>>
    }
}

/**
 * Asynchronously turns a list of location IDs into location objects of the Location type
 * @param locIds list of location IDs
 */
function useDereferencedLocations(locIds: string[]){
    const apolloHelper = useApolloClientHelper();
    const [ locationList, setLocationList ] = useState<Location[]>(null);
    const [ loading, setLoading ] = useState(false);

    // Holds the last locIds value (used for race condition)
    const currLocIds = useRef(locIds);

    async function updateLocationList(){
        setLoading(true);
        try{
            const locations = await apolloHelper.resolveEntityIdsByType(locIds, 'Location');
            if (currLocIds.current === locIds){
                // Prevents race condition by ensuring final change to locIds gets rendered
                setLocationList(locations);
            }
        }
        catch (err){
            const msg = 'Failed to load location data';
            console.error(msg, err);
            message.error(msg);
        }
        finally {
            if (currLocIds.current === locIds) {
                // Prevents race condition by ensuring only the final change to locIds turns off the loading state
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        currLocIds.current = locIds;
        updateLocationList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ locIds ]);

    return {
        locationList: locationList,
        loading: loading
    };
}

const RouteEditor: React.FC<RouteEditorProps> = (props) => {
    const api = useRouteEditorApi({
        initFlightPath: props.initFlightPath,
        overrideState: props.overrideState?.state,
        overrideDispatch: props.overrideState?.dispatch
    });

    const { locationList, loading } = useDereferencedLocations(api.route.get());

    const { state, route } = api;

    let table: React.ReactNode = <CenteredLoadingScreen />

    function renderLocName(loc: Location){
        return loc.name + (loc.field && loc.block ? " (" + loc.block + "-" + loc.field + ")" : "");
    }

    if (locationList !== null) {
        const columns: ColumnProps<Location>[] = [
            {
                title: '',
                key: 'delete',
                render: (_, __, index) => {
                    if (index === 0) return null;
                    return (
                        <Button
                            size="small"
                            shape="circle"
                            type="danger"
                            icon="delete"
                            onClick={() => route.remove(index)}
                        />
                    )
                },
                width: 50
            },
            {
                title: '#',
                key: '#',
                render: (_, __, index) => index + 1,
                width: 25
            },
            {
                title: "Name",
                width: 400,
                render: (_, loc) => (
                    renderLocName(loc)
                )
            },
            {
                title: 'Type',
                key: 'type',
                dataIndex: 'type'
            },
            {
                title: '',
                render: (_, loc, index) => (
                    <span className="mc-route-editor-actions">
                        <Button
                            title="Duplicate"
                            size="small"
                            icon="copy"
                            onClick={() => {
                                route.add(loc._id, index);
                            }}
                        />
                    </span>
                )
            }
        ]

        table = (
            <ETable<Location>
                className="draggable-table mc-table mc-table-bordered"
                dataSource={locationList}
                columns={columns}
                rowKey={(_, index) => String(index)}
                size="small"
                pagination={false}
                components={{
                    body: {
                        row: DraggableBodyRow
                    }
                }}
                onRow={(row, index) => {
                    const rowProps = {
                        moveRow: route.move,
                        index
                    }
                    return { ...rowProps}
                }}
            />
        )
    }

    return (
        <div className="mc-route-editor">
            <Row type="flex" gutter={6} style={{ marginBottom: 12, alignItems: 'center' }}>
                <Col
                    style={{ flex: 1 }}
                >
                    <h4 style={{ margin: 0 }}>Drag to reorder flight locations</h4>
                </Col>
                <Col>
                    <span style={{marginRight: '12px'}}>Add location:</span>
                    <LocationSelect
                        onChange={api.route.add}
                        style={{minWidth: '12rem'}}
                    />
                </Col>
                <Col>
                    <Button
                        icon="undo"
                        disabled={!state.path.past.length}
                        onClick={route.undo}
                        title="Undo"
                    />
                </Col>
                <Col>
                    <Button
                        disabled={!state.path.future.length}
                        icon="redo"
                        onClick={route.redo}
                        title="Redo"
                    />
                </Col>
            </Row>
            <Spin spinning={loading} indicator={<Icon type="loading" />}>
                {table}
            </Spin>
        </div>
    )
}

export default RouteEditor;