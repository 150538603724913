import { Button, Icon, Layout } from 'antd';
import { Auth } from '@aws-amplify/auth';
import { FeedbackFormModal } from 'components/feedback-form';
import { AuthContext, AuthProvider } from 'context/auth';
import { GlobalAppStateProvider } from 'context/global-app-state';
import GlobalFilterProvider from 'context/global-filter';
import { ThemeContext, ThemeProvider } from 'context/theme';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { withRouter } from 'react-router-dom';
import App from '../components/App';
import AppLoading from '../components/AppLoading';
import { GlobalFilterInitializer } from '../components/GlobalFilterInitializer';
import NotAuthorized from '../components/NotAuthorized';
import withApolloProvider from '../components/WithApolloProvider';
import { OrgDataProvider } from '../context/orgData';
import queryString from 'qs';
import { PersistenceProvider } from 'hooks/persistence';
import UserSettingsQuery from 'Queries/UserSettingsQuery';
import {OrganizationFragment} from "../Queries/Organization";
import { enableMapSet } from "immer";

// Allow immer library to work with Maps and Sets.
enableMapSet();

const ORG_QUERY = gql`
query OrgQuery($id: ID!){
  getOrganization(_id: $id){
      ...OrganizationFragment
  }
}
${OrganizationFragment}
`

const FINISH_APP_LOADING = gql`
mutation {
    appLoaded(payload: true) @client
}
`

const NoContracts = (org) => (
    <div>
        <h1>No contracts have been assigned to your organization {org.name}</h1>
    </div>
)

/**
 * Wraps the entire application and retrieves data that is required for the application to function.
 */
class AppInitializer extends Component {

    initialState = {
        initializing: true,
        error: null,
        notAuthorized: false,
        message: null,
        currentOrganization: null,
        contracts: [],
        attempts: 0,
        loadingMessage: null,
        feedbackOpen: false
    }

    constructor(props){
        super(props);
        this.state = this.initialState
    }

    isWindow(){ String(this.props.location.pathname).startsWith('/app/window') }

    componentDidMount(){
        this.setState({ initializing: true });

        // Start initialization process on component mount.
        this.initialize()
            .then(() => this.setState({ initializing: false }))
            .catch((error) => {
                if (!this.attemptAutomaticRetry()){
                    this.setState({ error })
                }
            })
    }

    /** 
     * Restarts the initialization process.
     * @param resetAttempts boolean -- if true, the attempt counter is reset to 1.
     **/
    retry = (resetAttempts) => {
        this.initialize(resetAttempts)
            .then(() => this.setState({ initializing: false }))
            .catch((error) => {
                if (!this.attemptAutomaticRetry()){
                    this.setState({
                        error: {
                            title: 'Could not establish connection to server',
                            message: <span>If this issue persists please <Button
                                    className="mc-link-btn"
                                    onClick={() => this.setState({ feedbackOpen: true })}
                                >report an outage</Button>
                                </span>
                        },
                        initializing: false
                    })
                }
            })
    }

    /** Attempt to retry initialization process. If max retries has been reached, then a retry will not be performed. */
    attemptAutomaticRetry = () => {
        if (this.canRetry()){
            this.retry();
            return true
        }
        return false
    }

    /** Finishes the app loading process */
    finishAppLoading = async () => {
        return await this.props.client.mutate({
            mutation: FINISH_APP_LOADING
        });
    }

    /** Gets max retry count. If prop maxEntries is not included on components it defaults to 3 retries. */
    getMaxRetryCount = () => {
        return this.props.maxRetries || 3;
    }

    /** Whether or not there are enough retry attempts */
    canRetry = () => {
        return this.state.attempts <= this.getMaxRetryCount()
    }

    /** Starts initialization process */
    initialize = async (resetAttempts) => {
        await this.setState({ ...this.initialState, attempts: resetAttempts ? this.initialState.attempts + 1 : this.state.attempts + 1});
        const { client } = this.props;

        let fetchPolicy = this.isWindow() ? 'cache-first' : 'network-only';

        var user, org;

        // Retrieve organization details from user account.
        try{
            user = await Auth.currentAuthenticatedUser()
        }
        catch(err){
            console.error(err);
            err.title = 'Failed to load user';
            throw err
        }
        const orgId = user.attributes['custom:organization'];

        // Users without an organization are kicked to this screen.
        if (!orgId){
            const msg = <span>
                Your Dataflyt account does not have an assigned organization. Please contact dataflyt at <a href="mailto:support@dataflyt.com">support@dataflyt.com</a>
            </span>
            this.setState({ message: msg })
            return;
        }

        // Retrieve the user's organization from database
        try{
            this.setState({ loadingMessage: 'Loading organization' })
            const { data: { getOrganization } } = await client.query({
                query: ORG_QUERY,
                variables: {
                    id: orgId
                },
                fetchPolicy
            });
            org = getOrganization
            this.setState({ currentOrganization: org });
        }
        catch(error){
            console.error(error);
            const unauthorizedError = error.graphQLErrors.find(err => {
                if (err.errorType === 'Unauthorized' && err.path[0] === 'getOrganization'){
                    return true
                }
                return false
            });
            if (unauthorizedError){
                this.setState({
                    loading: false,
                    notAuthorized: true
                })
                return;
            }
            error.title = 'Failed to load organization';
            throw error
            // this.setState({ error });
            // return;            
        }

        // Set global current organization state
        try{
            await client.mutate({
                mutation: gql`
                mutation SetOrg($org: Organization!){
                    organization(payload: $org) @client {
                        ...OrganizationFragment
                    }
                }
                ${OrganizationFragment}
                `,
                variables: {
                    org: org
                }
            });
        }
        catch(err){
            console.error(err);
            throw err
        }

        try{
            if (!org){
                this.setState({ message: 'Not organization returned' })
                return
            }

            // Organization is a customer. Get transporters contracted with this customer and set a default selected transporter.
            if (org.classType === 'flytsuite.customer'){
                let allContracts;
                this.setState({ loadingMessage: 'Loading contracts' })
                const { data: { contracts } } = await client.query({
                    query: gql`
                    query AppInitializerContractQuery(
                        $filter: ContractsFilterInput
                        $limit: Int
                    ){
                        contracts(
                            filter: $filter
                            limit: $limit
                        ){
                            docs {
                                _id
                                ... on Contract {
                                    name
                                    startDate
                                    endDate
                                    customerID {
                                        ...OrganizationFragment
                                    }
                                    tpID {
                                        ...OrganizationFragment
                                    }
                                }
                            }
                        }
                    }
                    ${OrganizationFragment}
                    `,
                    variables: {
                        filter: {
                            customerID: org._id,
                            active: true
                        },
                        limit: 9999
                    },
                    fetchPolicy
                });
                allContracts = contracts.docs;
                this.setState({ contracts: allContracts });

                // No contracts are available for this customer. Application cannot continue forward.
                if (!allContracts || !allContracts.length){
                    this.setState({ message: <NoContracts /> });
                    return;
                }

                await client.mutate({
                    mutation: gql`
                    mutation SetContracts($contracts: [Contract!]!){
                        contracts(payload: $contracts) @client{
                            _id
                            name
                            startDate
                            endDate
                            tpID {
                                ...OrganizationFragment
                            }
                            customerID {
                                ...OrganizationFragment
                            }
                        }
                    }
                    ${OrganizationFragment}
                    `,
                    variables: {
                        contracts: allContracts
                    }
                });
                const { data: { tpList } } = await client.query({
                    query: gql`
                        query GetTpList{
                            tpList @client{
                                ...OrganizationFragment
                            }
                        }
                        ${OrganizationFragment}
                    `
                });
                const { data: { transporter: currentTp } } = await client.query({
                    query: gql`
                    {
                        transporter @client {
                            ...OrganizationFragment
                        }
                    }
                    ${OrganizationFragment}
                    `
                });
                const userSettingsQueryRes = await client.query({ query: UserSettingsQuery });
                let settingsJson, savedTpId;
                try {
                    settingsJson = JSON.parse(userSettingsQueryRes?.data?.GetRemoteUserAppSettings?.settings);
                    savedTpId = settingsJson?.generalSettings?.savedTpId;
                }
                catch(error){
                    console.error("Failed to parse user settings!");
                }
                
                let selectedTp = tpList[0];
                let savedTpIndex = tpList.findIndex((tp) => tp._id === savedTpId);
                if (savedTpId && savedTpIndex > -1){
                    selectedTp = tpList[savedTpIndex];
                }

                if (!currentTp || (currentTp._id !== savedTpId && savedTpId)){
                    client.writeData({ data: {
                        customer: org,
                        transporter: selectedTp
                    } });
                }
            }

            // Organization is a transporter. Set global transporter state to organization.
            else if (org.classType === 'flytsuite.transporter'){
                await client.writeFragment({
                    id: `Transporter:${org._id}`,
                    fragment: OrganizationFragment,
                    data: {
                        _id: org._id,
                        name: org.name,
                        type: org.type,
                        classType: org.classType,
                        requireOverrideFields1: org.requireOverrideFields1,
                        useV1Scheduler: org.useV1Scheduler,
                        __typename: 'Transporter'
                    }
                })
                await client.mutate({
                    mutation: gql`
                    mutation SetTransporter(
                        $id: ID!
                    ){
                        transporter(
                            id: $id
                        ) @client {
                            ...OrganizationFragment
                        }
                    }
                    ${OrganizationFragment}
                    `,
                    variables: {
                        id: org._id
                    }
                });
            }

            // Organization is an employer. Set global employer state to organization.
            else if (org.classType === 'flytsuite.employer') {
                await client.writeFragment({
                    id: 'Employer:' + org._id,
                    fragment: OrganizationFragment,
                    data: {
                        ...org,
                        __typename: 'Employer'
                    }
                })
                const employer = await client.readFragment({
                    id: 'Employer:' + org._id,
                    fragment: OrganizationFragment
                })
                await client.writeData({ data: {
                    employer: employer
                } });
            }
            else
            {
                const err = new Error(`Manifest Central is not compatible with your organization '${org.name}' at this time.`);
                err.title = 'Incompatible organization';
                throw err
                // this.setState({ error: err })
            }
        }
        catch(err){
            console.error(err);
            this.setState({ initializing: false })
            throw err
            // this.setState({ error: err });
            // return;
        }
        this.setState({ loadingMessage: null })
        this.finishAppLoading();
    }

    /**
     * Signs user out and redirects to login page
     */
    logout = async () => {
        await Auth.signOut();
        this.props.history.push('/login');
    }

    render(){
        const { location } = this.props;
        const themeProviderProps = {
            enableThemingOnMount: true
        }
        
        let urlQuery = queryString.parse(location.search, { ignoreQueryPrefix: true });

        if (urlQuery.popoutActive){
            return <ThemeProvider {...themeProviderProps} />
        }

        const { initializing, error, message, notAuthorized } = this.state;
        
        if (initializing){
            return <ThemeProvider {...themeProviderProps}>
                <div className="app-loading-wrapper">
                    <AppLoading
                        subMessage={
                            <>
                                {this.state.loadingMessage ? this.state.loadingMessage : null }
                                {this.state.loadingMessage && this.state.attempts > 1 ? ' | ' : null}
                                {this.state.attempts > 1 ? `Attempt ${this.state.attempts}` : null}
                            </>
                        }
                    />
                </div>
            </ThemeProvider>
        }
        if (error){
            return (
                <ThemeProvider {...themeProviderProps}>
                    <AuthProvider>
                        <AuthContext.Consumer>
                        {(auth) => (
                            <>
                            <Layout style={{ padding: '20rem', textAlign: 'center', height: '100vh' }}>
                                <Icon style={{ color: 'red', marginBottom: '1rem', fontSize: '4rem' }} type="exclamation-circle" />
                                <h1 style={{ color: 'red' }} >{error.title || 'An error occurred'}</h1>
                                <h2>{error.message}</h2>
                                <div>
                                    <Button className="mc-link-btn" onClick={() => this.retry(true)}>Retry</Button> or <Button className="mc-link-btn" onClick={auth.signOut}>Logout</Button>
                                </div>
                            </Layout>
                            <FeedbackFormModal
                                visible={this.state.feedbackOpen}
                                title="Report an outage"
                                feedbackFormProps={{
                                    lockToType: 'outage',
                                    defaultDescription: `Manifest Central could not establish a connection to the server. I have verified that my internet connection is working as expected.`
                                }}
                                onCancel={() => this.setState({ feedbackOpen: false })}
                            />
                            </>
                        )}
                        </AuthContext.Consumer>
                    </AuthProvider>
                </ThemeProvider>
            )
        }
        if (message){
            return (
                <ThemeProvider {...themeProviderProps}>
                    <AuthProvider>
                        <AuthContext.Consumer>
                            {(auth) => (
                                <Layout style={{ padding: '20rem', textAlign: 'center', height: '100vh' }}>
                                    <h1>Your account has not been set up to use Manifest Central</h1>
                                    <h2>{message}</h2>
                                    <div>
                                        <Button className="mc-link-btn" onClick={() => this.retry(true)}>Retry</Button> or <Button className="mc-link-btn" onClick={auth.signOut}>Logout</Button>
                                    </div>
                                </Layout>
                            )}
                        </AuthContext.Consumer>
                    </AuthProvider>
                </ThemeProvider>
            )
        }
        if (notAuthorized){
            return (
                <ThemeProvider {...themeProviderProps}>
                    <Layout style={{ height: '100vh', paddingTop: '3rem' }} >
                        <ThemeContext.Consumer>
                        {(theme) => (
                            <NotAuthorized
                                style={{ maxWidth: '50rem' }}
                                title="Account Awaiting Approval"
                                icon="team"
                                desciption={<>
                                    <p>
                                        In order to use Manifest Central your account must first be approved by a DataFlyt representative.
                                        We have been notified of your request and will be reviewing your account soon.
                                    </p>
                                    <p>You will receive an email when your account is approved.</p>
                                    <p>If you have any questions or concerns please contact us by phone: <a href="tel:3379120948">337-912-0948</a> or email: <a href="mailto:support@dataflyt.com">support@dataflyt.com</a></p>
                                </>}
                                extra={<Button type="primary" onClick={() => {
                                    theme.setThemingEnabled(false);
                                    this.logout();
                                }}>Logout</Button>}
                            />
                        )}
                        </ThemeContext.Consumer>
                    </Layout>
                </ThemeProvider>
            )
        }
        if (!this.state.currentOrganization){
            return null
        }
        console.debug('AppInitializer props', this.props);

        return <GlobalAppStateProvider>
            <OrgDataProvider>
                <ThemeProvider {...themeProviderProps}>
                    <PersistenceProvider>
                        <GlobalFilterInitializer>
                            <GlobalFilterProvider>
                                <AuthProvider>
                                    <ThemeContext.Consumer>
                                    {(theme) => (
                                        <App themeContext={theme} />
                                    )}
                                    </ThemeContext.Consumer>
                                </AuthProvider>
                            </GlobalFilterProvider>
                        </GlobalFilterInitializer>
                    </PersistenceProvider>
                </ThemeProvider>
            </OrgDataProvider>
        </GlobalAppStateProvider>
    }
}
export default withApolloProvider( withRouter( withApollo(DragDropContext(HTML5Backend)( AppInitializer )) ) )