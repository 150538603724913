
const ReportsResolvers = {
    Mutation: {
        setReportDateRange: (_, { dateRange }, { client }) => {
            client.writeData({
                data: {
                    reportDateRange: dateRange
                }
            })

            return dateRange;
        }
    }
}

export default ReportsResolvers