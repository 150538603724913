import { Typography } from 'antd';
import Select, { SelectProps } from 'antd/lib/select';
import { NetworkStatus } from 'apollo-boost';
import { castArray } from 'common/util';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';

export interface UserGroupSelectProps extends SelectProps {
    data?: Array<string>,
    filterItem?: (item: any) => boolean
}

export interface UserGroupSelectPropsWrapper extends UserGroupSelectProps {
    
}

const QUERY = gql`
    query UserGroupSelectGetUserGroups($limit: Int, $nextToken: String){
        AdminCognitoListGroups(limit: $limit, nextToken: $nextToken){
            groups {
                GroupName
                Description
            }
            nextToken
        }
    }
`
// TODO: Implement pagination

const UserGroupSelectNoQuery: React.FC<UserGroupSelectProps> = (props) => {
    const { data, filterItem, ...restProps } = props;
    let groups = castArray(data);

    if (filterItem){
        groups = groups.filter(filterItem);
    }

    return <Select
        optionLabelProp="value"
        showSearch
        showAction={["focus", "click"]}
        {...restProps}
    >
        {groups.map(group => (
            <Select.Option key={group.GroupName} value={group.GroupName}>
                <strong style={{ display: 'block' }}>{group.GroupName}</strong>
                <Typography.Text type="secondary">{group.Description}</Typography.Text>
            </Select.Option>
        ))}
    </Select>
}

const UserGroupSelect: React.FC<UserGroupSelectPropsWrapper> & {NoQuery?: React.FunctionComponent<UserGroupSelectProps>} = (props) => {
    const { data, networkStatus } = useQuery(QUERY, {
        fetchPolicy: 'cache-and-network'
    });
    let groups = castArray(data?.AdminCognitoListGroups?.groups);
    return <UserGroupSelectNoQuery {...props} data={groups} loading={networkStatus === NetworkStatus.loading} />
}

UserGroupSelect.NoQuery = UserGroupSelectNoQuery;

export default UserGroupSelect