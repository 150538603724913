import React, { PropsWithChildren } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

const QUERY = gql`
query GetGlobalFilter {
    globalFilter @client {
        visible
        customer {
            key
            label
        }
        transporter {
            key
            label
        }
        departure {
            key
            label
        }
        destination {
            key
            label
        }
        employer {
            key
            label
        }
        pax {
            lastName
            firstName
        }
        cgo {
            name
        }
    }
}
`

export interface KeyLabel {
    key: string,
    label?: string
}

export interface GlobalFilterContextValue {
    visible: boolean,
    customer: KeyLabel[],
    transporter: KeyLabel[],
    departure: KeyLabel[],
    destination: KeyLabel[],
    employer: KeyLabel[],
    pax: {
        lastName?: string,
        firstName?: string
    }[],
    cgo: {
        name?: string
    }[]
}

export const GlobalFilterContext = React.createContext<GlobalFilterContextValue>(null);

const GlobalFilterProvider: React.FC<PropsWithChildren<{}>> = (props) => {
    const { data } = useQuery(QUERY);
    return <GlobalFilterContext.Provider value={data?.globalFilter}>
        {props.children}
    </GlobalFilterContext.Provider>
}

export default GlobalFilterProvider