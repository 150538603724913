import { useState, useEffect } from "react";
import { createFormFields, getFormFieldValue, formFieldsIsTouched } from "common/form";
import { Modal } from "antd";
import { QueryResult, useQuery, useMutation, MutationResult } from "react-apollo";
import { DEFUALT_POLL_INTERVAL } from '.';
import { isNSLoading, isNSReloading } from './util';
import { ApolloError, WatchQueryFetchPolicy } from 'apollo-client';
import { pickBy, identity } from 'lodash';

export interface UseMasterDataStateOptions {
    queryGQL: any,
    getQueryVariables: (searchValues: SearchValues) => any,
    saveMutationGQL: any,
    deleteMutationGQL: any,
    getQueryData: (queryResult: QueryResult | any) => Array<any>,
    pollInterval?: number,
    fetchPolicy?: WatchQueryFetchPolicy,
    paginationLimit?: number,
    updateQueryAfterFetchMore(prevResult: any, fetchMoreResult: any)
    tpID?: string,
    transformEditFromExisting?: (values: any) => Promise<any> | any,
    lockFieldsToValue?: { [key: string]: any },
    onEditFromNew?: (defaultValues?: {[key: string]: any}) => void,
}

export interface UseMasterDataStateOptionsNoQueryHook {
    data: Array<any>,
    queryResult: QueryResult,
    // queryGQL: any,
    // getQueryVariables: (searchValues: SearchValues) => any,
    onSave?: (newValues: any) => Promise<any>,
    saveMutationResult?: Pick<MutationResult, 'loading' | 'error' | 'data'>,
    deleteMutationResult?: Pick<MutationResult, 'loading' | 'error' | 'data'>,
    onDelete?: (newValues: any) => Promise<any>,
    onReload?: () => void,
    onCancel?: () => void,
    closeAfterSave?: boolean,
    // saveMutationGQL: any,
    // deleteMutationGQL: any,
    // getQueryData: (queryResult: QueryResult | any) => Array<any>,
    // pollInterval?: number,
    // fetchPolicy?: WatchQueryFetchPolicy,
    // paginationLimit?: number,
    // updateQueryAfterFetchMore(prevResult: any, fetchMoreResult: any)
    // tpID?: string,
    transformEditFromExisting?: (values: any) => any,
    lockFieldsToValue?: { [key: string]: any }
}

export type SearchValues = {
    [key: string]: any
}

export interface UseMasterDataStateReturnProps {
    entryFields: any,
    setEntryFields(values: any): void,
    getEntryFieldValue(fieldName: string): any,
    resetEntryFields(): void,
    isEditing: boolean,
    // setIsEditing: (editing: boolean, isNew?: boolean) => void,
    editFromExisting(values: any, confirmUnsaved?: boolean): void,
    editFromNew(defaultValues?: {[key: string]: any}): Promise<any>,
    cancelEditing(comfirm?: boolean): Promise<any>,
    initEntryValues: any,
    // queryResult: QueryResult,
    queryError: ApolloError,
    loading: boolean,
    reloading: boolean,
    data: Array<any>,
    save(values: any): Promise<any>,
    saving: boolean,
    saveError: ApolloError,
    queryNetworkStatus: number,
    savedData: any,
    delete?(values: any): void,
    deleting?: boolean,
    deleteError?: ApolloError,
    deletedData?: any,
    deletePressed?:any,
    deletingId?: string,
    reload: () => void,
    getSearchValue(key: string): any,
    searchValues: {[key: string]: any},
    isNewEntry: boolean,
    onSearchValueChange: (key: string, value: any, preserveCase?: boolean) => void,
    clearSearchValues(): void,
    pagination: {
        hasMore: boolean,
        loadMore: () => Promise<any>,
        offset: number
    }
}

export interface UseMasterDataStateReturnNoQueryHookProps {
    entryFields: any,
    setEntryFields(values: any): void,
    getEntryFieldValue(fieldName: string): any,
    resetEntryFields(): void,
    isEditing: boolean,
    // setIsEditing: (editing: boolean, isNew?: boolean) => void,
    editFromExisting(values: any, confirmUnsaved?: boolean, cancelPrevious?: boolean): void,
    editFromNew(defaultValues?: {[key: string]: any}): Promise<any>,
    cancelEditing(comfirm?: boolean): Promise<any>,
    initEntryValues: any,
    // queryResult: QueryResult,
    queryError: ApolloError,
    loading: boolean,
    reloading: boolean,
    data: Array<any>,
    save(values: any, getValues?: (obj) => any): Promise<any>,
    saving: boolean,
    saveError: ApolloError,
    queryNetworkStatus: number,
    savedData: any,
    delete?(values: any): void,
    deleting?: boolean,
    deleteError?: ApolloError,
    deletedData?: any,
    deletePressed?:any,
    reload: () => void,
    getSearchValue(key: string): any,
    searchValues: {[key: string]: any},
    isNewEntry: boolean,
    onSearchValueChange: (key: string, value: any, preserveCase?: boolean) => void,
    clearSearchValues(): void
}

function useMasterDataState(options: UseMasterDataStateOptions): UseMasterDataStateReturnProps {
    // const [ searchValue, setSearchValue ] = useState<string>(null);

    function getQueryData(resultData: any){
        try {
            let data = options.getQueryData(resultData);
            if (Array.isArray(data)) return data;
        }
        catch(err){
            console.error('Error while calling getQueryData:', err);
        }
        return [];
    }

    const [ searchValues, setSearchValues ] = useState<SearchValues>({});
    const [ paginationOffset, setPaginationOffset ] = useState(0);
    const [ paginationHasMore, setPaginationHasMore ] = useState(false);
    const [ isInitalQuery, setIsInitialQuery ] = useState(true);

    // // Had to do this because paginationState didn't re-render component upon being changed
    // useEffect(() => {
    //     setForcedUpdateCnt(prev => prev + 1);
    // }, [ paginationState.offset, paginationState.hasMore ])

    const paginationLimit = options.paginationLimit || 100

    const queryResult = useQuery(options.queryGQL, {
        variables: {
            ...options.getQueryVariables(searchValues),
            limit: paginationLimit
        },
        fetchPolicy: options.fetchPolicy || 'network-only',
        pollInterval: (['cache-only', 'cache-first'].includes(options.fetchPolicy)) ? undefined : options.pollInterval || DEFUALT_POLL_INTERVAL,
        notifyOnNetworkStatusChange: true,
        context: {
            debounceKey: 'md-aircraft',
            debounceTimeout: 1000
        },
        onCompleted: (data) => {
            if (getQueryData(data).length >= paginationLimit && isInitalQuery){
                console.debug('onCompleted called');
                setPaginationHasMore(true);
                setIsInitialQuery(false);
            }
        }
    });

    const [ initEntryValues, setInitEntryValues ] = useState<any>(null);
    const [ entryFields, setEntryFields ] = useState<any>(null);
    const [ isEditing, setIsEditing ] = useState<boolean>(false);
    const [deletePressed, setDeletePressed] = useState<boolean>(false);
    const [ deleteId, setDeleteId ] = useState<string>(null);

    const [ invokeSave, { loading: saving, error: saveError, data: savedData } ] = useMutation(options.saveMutationGQL, {
        onCompleted: () => {
            if (!initEntryValues){
                queryResult.refetch();
            }
            handleCancel();
        }
    });

    const [ invokeDelete, { loading: deleting, error: deleteError, data: deletedData } ] = useMutation(options.deleteMutationGQL, {
        onCompleted: () => {
            queryResult.refetch();
            setDeleteId(null);
            handleCancel();
        },
        onError: () => {
            setDeleteId(null);
        }
    });


    const loading = isNSLoading(queryResult.networkStatus);
    const reloading = isNSReloading(queryResult.networkStatus);

    let data: Array<any> = !loading && queryResult.data ? getQueryData(queryResult.data) : [];
    if (!Array.isArray(data)){
        data = [];
    }

    function handleCancel(){
        setInitEntryValues(null);
        setEntryFields(null);
        setIsEditing(false);
    }

    function handleSave(values: any){
        setDeletePressed(false);
        return invokeSave({
            variables: {
                tpID: options.tpID,
                ...values
            }
        })
        .then(() => queryResult.refetch())
    }

    function handleDelete(values: any){
        setDeletePressed(true);
        let id;
        if ('id' in values){
            id = values.id;
        }
        else if ('_id' in values){
            id = values._id;
        }
        setDeleteId(id);
        
        invokeDelete({
            variables: {
                tpID: options.tpID,
                ...values
            }
        })
    }

    function cancelEditing(confirm: boolean=true) {
        return new Promise<void>((resolve, reject) => {
            if (confirm && formFieldsIsTouched(entryFields, true)){
                Modal.confirm({
                    title: 'Cancel current changes',
                    content: 'Are you sure you want to cancel your current changes?',
                    onOk: () => {
                        handleCancel();
                        resolve();
                    },
                    onCancel: () => reject()
                })
            }
            else{
                handleCancel();
                resolve();
            }
        })
    }

    console.debug('hasMore', paginationHasMore);

    function loadMore(){
        let nextOffset = paginationOffset + paginationLimit;
        return queryResult.fetchMore({
            variables: {
                skip: nextOffset
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                if (options.getQueryData(fetchMoreResult).length < 1){
                    setPaginationOffset(nextOffset);
                    setPaginationHasMore(false);
                }
                else
                {
                    setPaginationOffset(nextOffset);
                    setPaginationHasMore(true);
                }
                return options.updateQueryAfterFetchMore(prev, fetchMoreResult)
            }
        })
    }

    return {
        queryNetworkStatus: queryResult.networkStatus,
        entryFields,
        setEntryFields: (values: any) => {
            let newValues = values;
            if (options.lockFieldsToValue){
                newValues = {
                    ...values,
                    ...pickBy(options.lockFieldsToValue, identity)
                }
            }
            setEntryFields(createFormFields(newValues));
        },
        resetEntryFields: () => {
            setEntryFields(createFormFields(initEntryValues))
        },
        getEntryFieldValue: (fieldName: string) => getFormFieldValue(entryFields && entryFields[fieldName]),
        isEditing,
        // setIsEditing: (editing, isNew=false) => {
        //     setIsEditing(editing);
        //     setIsNewEntry(isNew);
        // },
        async editFromExisting(values, confirmUnsaved: boolean=true) {
            let finalValues = values;
            if (options.transformEditFromExisting){
                finalValues = await Promise.resolve(options.transformEditFromExisting(values));
            }
            if (options.lockFieldsToValue){
                finalValues = {
                    ...finalValues,
                    ...pickBy(options.lockFieldsToValue, identity)
                }
            }
            cancelEditing(confirmUnsaved).then(() => {
                setInitEntryValues(finalValues);
                setEntryFields(createFormFields(finalValues))
                setIsEditing(true);
            })
            .catch((err) => console.error(err))
        },
        editFromNew(defaultValues=undefined){
            return new Promise<void>((resolve, reject) => {
                cancelEditing().then(() => {
                    setInitEntryValues(null);
                    setEntryFields({
                        ...defaultValues,
                        ...pickBy(options.lockFieldsToValue, identity)
                    });
                    options.onEditFromNew?.(defaultValues);
                    setIsEditing(true);
                    resolve();
                })
                .catch(() => {
                    console.log('Changes preserved');
                    reject();
                });
            })
        },
        cancelEditing,
        initEntryValues,
        loading,
        reloading,
        data,
        saving,
        saveError,
        save: handleSave,
        delete: handleDelete,
        deletePressed,
        deleting,
        deleteError,
        deletedData,
        savedData,
        queryError: queryResult.error,
        reload: () => {
            setPaginationOffset(0);
            setIsInitialQuery(true);
            queryResult.refetch()
        },
        getSearchValue: (key) => searchValues[key],
        searchValues,
        onSearchValueChange: (key, value, preserveCase=false) => {
            let finalValue = value;
            if (typeof value === 'string' && !preserveCase){
                finalValue = value.toUpperCase();
            }
            setSearchValues({ ...searchValues, [key]: finalValue });
        },
        clearSearchValues: () => setSearchValues({}),
        isNewEntry: isEditing && !initEntryValues ? true : false,
        deletingId: deleteId,
        pagination: {
            hasMore: paginationHasMore,
            offset: paginationOffset,
            loadMore: loadMore
        }
    }
}

export default useMasterDataState

export function useMasterDataStateNoQueryHook(options: UseMasterDataStateOptionsNoQueryHook): UseMasterDataStateReturnNoQueryHookProps {
    // const [ searchValue, setSearchValue ] = useState<string>(null);

    const queryResult = options.queryResult;

    const [ searchValues, setSearchValues ] = useState<SearchValues>({});

    const [ initEntryValues, setInitEntryValues ] = useState<any>(null);
    const [ entryFields, setEntryFields ] = useState<any>(null);
    const [ isEditing, setIsEditing ] = useState<boolean>(false);
    const [deletePressed, setDeletePressed] = useState<boolean>(false);
    const [ handleSaveParams, setHandleSaveParams ] = useState({ values: null, getValues: null });

    const loading = isNSLoading(queryResult.networkStatus);
    const reloading = isNSReloading(queryResult.networkStatus);

    let data: Array<any> = !loading && options.data ? options.data : [];
    if (!Array.isArray(data)){
        data = [];
    }

    useEffect(() => {
        if (options.saveMutationResult && !options.saveMutationResult.error && !options.saveMutationResult.loading && options.saveMutationResult.data){
            // Must be a successful save.
            afterSuccessfulSave();
        }
    // eslint-disable-next-line
    }, [options.saveMutationResult?.data, options.saveMutationResult?.loading, options.saveMutationResult?.error])

    function handleCancel(){
        setInitEntryValues(null);
        setEntryFields(null);
        setIsEditing(false);
        options.onCancel?.();
    }

    function editFromExisting(values, confirmUnsaved: boolean=true, cancelPrevious: boolean=true) {
        let finalValues = values;
        if (options.transformEditFromExisting){
            finalValues = options.transformEditFromExisting(values);
        }
        if (options.lockFieldsToValue){
            finalValues = {
                ...finalValues,
                ...pickBy(options.lockFieldsToValue, identity)
            }
        }
        if (initEntryValues && cancelPrevious){
            cancelEditing(confirmUnsaved).then(() => {
                setInitEntryValues(finalValues);
                setEntryFields(createFormFields(finalValues))
                setIsEditing(true);
            })
            .catch((err) => console.error(err))
        }
        else
        {
            setInitEntryValues(finalValues);
            setEntryFields(createFormFields(finalValues))
            setIsEditing(true);
        }
    }

    function handleSave(values: any, getValues?: (obj) => any){
        setDeletePressed(false);
        setHandleSaveParams({ values, getValues })
        return options.onSave && options.onSave(values);
    }

    function afterSuccessfulSave(){
        let values = handleSaveParams.values;
        let getValues = handleSaveParams.getValues;
        if (!initEntryValues){
            queryResult.refetch();
        }
        if (options.closeAfterSave !== undefined && options.closeAfterSave !== null ? options.closeAfterSave : true){
            handleCancel();
        }
        else
        {
            setInitEntryValues(null);
            setEntryFields(null);
            editFromExisting(getValues ? getValues(values) : values, false, false);
        }
    }

    function handleDelete(values: any){
        setDeletePressed(true);
        options.onDelete && options.onDelete(values)
        .then(() => {
            queryResult.refetch();
            handleCancel();
        })
    }

    function cancelEditing(confirm: boolean=true) {
        return new Promise<void>((resolve, reject) => {
            if (confirm && formFieldsIsTouched(entryFields, true)){
                Modal.confirm({
                    title: 'Cancel current changes',
                    content: 'Are you sure you want to cancel your current changes?',
                    onOk: () => {
                        handleCancel();
                        resolve();
                    },
                    onCancel: () => reject()
                })
            }
            else{
                handleCancel();
                resolve();
            }
        })
    }

    return {
        queryNetworkStatus: queryResult.networkStatus,
        entryFields,
        setEntryFields: (values: any) => {
            let newValues = values;
            if (options.lockFieldsToValue){
                newValues = {
                    ...values,
                    ...pickBy(options.lockFieldsToValue, identity)
                }
            }
            setEntryFields(createFormFields(newValues));
        },
        resetEntryFields: () => {
            setEntryFields(createFormFields(initEntryValues))
        },
        getEntryFieldValue: (fieldName: string) => getFormFieldValue(entryFields && entryFields[fieldName]),
        isEditing,
        // setIsEditing: (editing, isNew=false) => {
        //     setIsEditing(editing);
        //     setIsNewEntry(isNew);
        // },
        editFromExisting,
        editFromNew(defaultValues=undefined){
            return new Promise<void>((resolve, reject) => {
                cancelEditing().then(() => {
                    setInitEntryValues(null);
                    let entryFields = {
                        ...defaultValues,
                        ...pickBy(options.lockFieldsToValue, identity)
                    }
                    setEntryFields(entryFields);
                    setIsEditing(true);
                    resolve();
                })
                .catch(() => {
                    console.log('Changes preserved');
                    reject();
                });
            })
        },
        cancelEditing,
        initEntryValues,
        loading,
        reloading,
        data,
        saving: options.saveMutationResult && options.saveMutationResult.loading,
        saveError: options.saveMutationResult && options.saveMutationResult.error,
        save: handleSave,
        delete: handleDelete,
        deletePressed,
        deleting: options.deleteMutationResult && options.deleteMutationResult.loading,
        deleteError: options.deleteMutationResult && options.deleteMutationResult.error,
        deletedData: options.deleteMutationResult && options.deleteMutationResult.data,
        savedData: options.saveMutationResult && options.saveMutationResult.data,
        queryError: queryResult.error,
        reload: () => {
            options.onReload && options.onReload();
        },
        getSearchValue: (key) => searchValues[key],
        searchValues,
        onSearchValueChange: (key, value, preserveCase=false) => {
            let finalValue = value;
            if (typeof value === 'string' && !preserveCase){
                finalValue = value.toUpperCase();
            }
            setSearchValues({ ...searchValues, [key]: finalValue });
        },
        clearSearchValues: () => setSearchValues({}),
        isNewEntry: isEditing && !initEntryValues ? true : false
    }
}