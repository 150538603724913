import { Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { castArray, getReportCriteriaValue } from 'common/util';
import { ContextProviderWrapperProps } from 'component-wrappers/wrap-ctx-provider';
import ETable from 'components/enchanced-antd-table';
import ReportUI from 'components/reporting-2/report-ui';
import { REColumnSet } from 'components/reporting/export';
import moment from 'moment-timezone';
import React, { CSSProperties } from 'react';
import CriteriaForm, { fieldLabelMap } from './criteria-form';

export interface FWChangesByConsoleReportPageProps extends ContextProviderWrapperProps {
    style?: CSSProperties
}

const FWChangesByConsoleReportPage: React.FC<FWChangesByConsoleReportPageProps> = (props) => {
    return <ReportUI
        useExternalContextProvider={props.useExternalContextProvider}
        overrideContext={props.overrideContext}
        overrideProvider={props.overrideProvider}
        reportTitle='Changes By Console Report'
        contentFlexMode
        autoRefreshOptions={{
            enabled: false // TODO: Had to disable auto refresh b/c the checkbox doesn't work for this report. Why does it now work?
        }}
        contextProviderProps={{
            persistenceName: 'flytwatch.changes-by-console-report.criteria-form',
            reportName: 'Changes By Console',
            reportType: 'DISPATCH_CHANGES_BY_CONSOLE',
            submitReportHandler: (formValues, submitFn) => {
                let options;
                try {
                    options = {
                        name: 'Changes By Console',
                        criteria: Object.entries<any>(formValues).map(([ key, value ]) => {
                            let display_value;
                            if (key === 'startDate'){
                                value = moment(value).startOf('day').toISOString(false);
                            }
                            if (key === 'endDate'){
                                value = moment(value).endOf('day').toISOString(false);
                            }
                            if (key === 'startDate' || key === 'endDate'){
                                display_value = moment(value).format('MMM Do, YYYY');
                            }
                            return {
                                key,
                                value,
                                label: fieldLabelMap[key],
                                display_value: display_value
                            }
                        })
                    }
                }
                catch(err){
                    console.error(err);
                    return undefined
                }
                return submitFn(options);
            },
            convertPersistentFormData: (unconverted) => {
                let convertedData = unconverted;
                convertedData.startDate = moment(convertedData.startDate?.value);
                convertedData.endDate = moment(convertedData.endDate?.value);
                return convertedData;
            }
        }}
        renderCriteriaForm={(ref, formFields, onFieldsChange) => {
            return <CriteriaForm
                ref={ref}
                formFields={formFields}
                onFieldsChange={onFieldsChange}
            />
        }}
        renderTable={({ reportDetails }) => {

            let data = castArray(reportDetails?.reportData?.result?.docs);

            const columns: ColumnProps<any>[] = [
                {
                    title: 'Console',
                    key: 'console',
                    dataIndex: 'console',
                    render: (console) => console ? console : <Typography.Text type='secondary'>Unknown</Typography.Text>
                },
                {
                    title: 'Platform',
                    key: 'platform',
                    dataIndex: 'isWeb',
                    render: (isWeb) => isWeb ? 'WEB' : 'LOCAL CONSOLE'
                },
                {
                    title: 'Flights created',
                    key: 'flightsCreatedCt',
                    dataIndex: 'numCreated'
                },
                {
                    title: 'Last Modified Flights',
                    key: 'flightsModifiedCt',
                    dataIndex: 'numModified'
                }
            ]
        
            return <ETable
                rowKey="key"
                dataSource={data}
                columns={columns}
                pagination={false}
                size="small"
                className="mc-table"
                bordered
                flexMode
                stickyHeader
            />
        }}
        setReportExporterProps={(reportData) => {

            let data = castArray(reportData?.result?.docs);

            const columnSets: REColumnSet[] = [{
                setName: 'Billing Report',
                key: 'billing',
                columns: [
                    {
                        label: 'Location',
                        key: 'name'
                    },
                    {
                        label: 'Amount',
                        key: 'amount'
                    },
                    {
                        label: 'Date/Time Taken (Local Time)',
                        key: 'dateTimeTaken'
                    },
                    {
                        label: 'AC Tail',
                        key: 'aircraftName'
                    },
                    {
                        label: 'AC Model',
                        key: 'aircraftModel'
                    }
                ]
            }];

            let startDate = getReportCriteriaValue(reportData, 'startDateTime');
            let endDate = getReportCriteriaValue(reportData, 'endDateTime');

            let startMoment = startDate ? moment(startDate).format('MMMM Do YYYY H:mm') : 'Unknown';
            let endMoment = endDate ? moment(endDate).format('MMMM Do YYYY H:mm') : 'Unknown';

            let title = "Changes by console report from " + startMoment + " to " + endMoment;

            return {
                report_id: reportData?._id,
                report_type: "DISPATCH_CHANGES_BY_CONSOLE",
                column_sets: columnSets,
                data: data,
                titleRowText: title,
                export_name: title
            }
        }}
        style={props.style}
    />
}

export default FWChangesByConsoleReportPage