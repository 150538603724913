import {useApolloClient} from "react-apollo";
import {
    ScheduledCgoNodeFragment,
    ScheduledPaxNodeFragment
} from "../Queries/Scheduling";
import {CgoNode, PaxNode} from "../common/types/carriable";
import gql from "graphql-tag";
import {DocumentNode} from "apollo-link";
import {FetchPolicy} from "apollo-client";
import {LocationFragment} from "../Queries/Location";
import { Location } from "common/types/location";

/*
-------------------------------------------------------------------------------------------------
This section is a bit weird and exists for the resolveEntityIdsByType helper function.
The goal of RESOLVE_TYPE_MAPPING is to provide a mapping of an arbitrary string to arguments for
the resolveEntityIds function. ResolveKeyMapping maps the keys of RESOLVE_TYPE_MAPPING to a Manifest Central
entity type (such as PaxNode, CargoNode, etc) so that resolveEntityIdsByType can return the correct type to the callee.

The keys of RESOLVE_TYPE_MAPPING can be arbitrary, however it is recommended to name it after the Fragment it uses.
For example: 'ScheduledPaxNode' is named from 'ScheduledPaxNodeFragment' without the "Fragment" part.
*/
const RESOLVE_TYPE_MAPPING = {
    'ScheduledPaxNode': {
        typename: 'PaxNode',
        fragment: ScheduledPaxNodeFragment,
        fragmentName: 'ScheduledPaxNodeFragment'
    },
    'ScheduledCgoNode': {
        typename: 'CargoNode',
        fragment: ScheduledCgoNodeFragment,
        fragmentName: 'ScheduledCgoNodeFragment'
    },
    'Location': {
        typename: 'Location',
        fragment: LocationFragment,
        fragmentName: 'LocationFragment'
    }
}

type ResolveTypeMappingKeys = keyof typeof RESOLVE_TYPE_MAPPING;

type DefineResolveKeyType  = {
    'ScheduledPaxNode': PaxNode,
    'ScheduledCgoNode': CgoNode,
    'Location': Location
}

// If an error shows up here, that means DefineResolveKeyType is missing a mapping of a RESOLVE_TYPE_MAPPING key!
type ResolveKeyMapping = {
    [K in ResolveTypeMappingKeys]: DefineResolveKeyType[K]
}

// ----------------------------------------------------------------------------------------------

function useApolloClientHelper(){
    const client = useApolloClient();

    return new class {
        /**
         * Asynchronously converts a list of IDs to a list of documents.
         * If one or more IDs are not found in the cache, a Query will be executed using the resolve_entity_ids query.
         * If all IDs are found in the cache, it will be immediately returned.
         */
        resolveEntityIds = async <T>(args: {
            /**
             * List of IDs to convert into documents.
             */
            ids: string[],

            /**
             * GraphQL type name (e.g. PaxNode)
             */
            typename: string,

            /**
             * Fragment of fields to return
             */
            fragment: DocumentNode,

            /**
             * Name of the fragment (must be the same as fragment)
             */
            fragmentName: string,

            /**
             * Customize the fetch policy used.
             * @default cache-first
             */
            fetchPolicy?: FetchPolicy
        }): Promise<T[]> => {
            const query = gql`
                query ResolveEntityIds($ids: [ID!]!, $typename: String!){
                    resolve_entity_ids(ids: $ids, typename: $typename){
                        ...${args.fragmentName}
                    }
                }
                ${args.fragment}
            `;

            const result = await client.query({
                query: query,
                variables: {
                    ids: args.ids,
                    typename: args.typename
                },
                fetchPolicy: args.fetchPolicy || 'cache-first'
            });

            return result?.data?.resolve_entity_ids || [];
        }

        /**
         * This is a wrapper around resolveEntityIds in which a key is converted to arguments for resolveEntityIds.
         * @see resolveEntityIds
         */
        resolveEntityIdsByType = <T extends keyof typeof RESOLVE_TYPE_MAPPING>(
            ids: string[],
            type: T,
            args?: {
                fetchPolicy?: FetchPolicy
            }
        ) => (
            this.resolveEntityIds<ResolveKeyMapping[T]>({
                ids: ids,
                fetchPolicy: args?.fetchPolicy,
                ...RESOLVE_TYPE_MAPPING[type]
            })
        );

        getScheduledPaxByIds = async (ids: string[]) =>
            (await this.resolveEntityIdsByType(ids, 'ScheduledPaxNode'))
                .filter((pax) => pax.classType === 'flytsuite.paxnode');

        getScheduledCgoByIds = async (ids: string[]) =>
            (await this.resolveEntityIdsByType(ids, 'ScheduledCgoNode'))
                .filter((cgo) => cgo.classType === 'flytsuite.cgonode');
    }()
}

export default useApolloClientHelper;