import { Alert, Button, Card, Col, Row, Tabs, Form, Input, message, Select, Table, Popconfirm, Icon, Modal } from 'antd';
import React, { Component } from 'react';
import moment from 'moment';
import commonColumns from 'common/table/columns';
import { getFlightDeparture } from 'common/flight';
import { FilterableTable } from 'components/tables/FilterableTable';
import LegTimeEntry from 'components/flights/times/LegTimeEntry';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { compose } from 'redux';
import WithOrgData from 'components/WithOrgData';
import { LocationSelect } from 'components/form/select/LocationSelect';
import {  locationListToFlightLegs } from 'common/util';
import { DraggableBodyRow } from 'components/TableDragSorting';
import FlightManualLegsAssign from 'components/flights/times/FlightManualLegsAssign';
import { UserGroup } from 'components/userGroup';
import PilotSelect from 'components/form/PilotSelect';
import ContractSelect from 'components/form/ContractSelect';
import AircraftSelect from 'components/form/AircraftSelect';
import UnassignedPaxCgoWarning from 'components/flights/times/UnassignedPaxCgoWarning';
const { Option } = Select;

const SaveFlightTimes = gql`
mutation SaveFlightTimes(
    $tpID: ID!
    $flightID: ID!
    $payload: AWSJSON!
){
    save_flight_times(
        tpID: $tpID
        flightID: $flightID
        payload: $payload
    ){
        _id
        _rev
        desig
        timeOfDeparture
        scheduledFlightDate
        scheduleType
        originalFlightID
        customerID {
            _id
            name
        }
        state
        legs
        legsArray {
            flightTime
            departure
            departureID
            destination
            destinationID
            seats
            paxWeight
            bagWeight
            bagCount
            cgoWeight
            paxIDs {
                _id,
                firstName,
                lastName,
                paxWeight,
                bagWeight,
                chargeCode,
                individualLegTime,
                customerDoc {
                    _id
                    name
                },
                employerDoc {
                    _id
                    name
                }
                tpID {
                    _id
                    name
                }
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                checkedInTime
                transitType
            }
            cgoIDs {
                _id
                name
                approvedBy
                customerDoc {
                    _id
                    name
                }
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                deliveredBy
                attentionTo
                weight
                hazmat
                hazmatUnCode
                checkedInTime
                transitType
                currentCarrierID {
                    _id
                    desig
                }
            }
        },
        pilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        copilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        contract {
            _id
            name
        },
        sequence,
        paxIDList {
            _id,
            firstName,
            lastName,
            paxWeight,
            bagWeight,
            chargeCode,
            individualLegTime,
            customerDoc {
                _id
                name
            },
            employerDoc {
                _id
                name
            }
            departureDoc {
                _id
                name
            }
            destinationDoc {
                _id
                name
            }
            checkedInTime
            modifiedTs
            transitType
        },
        cgoIDList {
            _id
            name
            scheduledOrder
            scheduledGroup
            approvedBy
            chargeCode,
            individualLegTime,
            customerDoc {
                _id
                name
            }
            departureDoc {
                _id
                name
            }
            destinationDoc {
                _id
                name
            }
            deliveredBy
            attentionTo
            weight
            hazmat
            initials
            notes
            hazmatUnCode
            checkedInTime
            transitType
            currentCarrierID {
                _id
                desig
            }
        },
        aircraftID {
            _id,
            model,
            tailNum,
            maxSeats,
            maxLoad
        }
    }
}
`

const EditFlightHistory = gql`
mutation EditFlightHistory(
    $tpID: ID!
    $flightID: ID!
    $times: AWSJSON!
    $flight: FlightNodeInput!
    $orgID: ID!
){
    editFlightHistory(
        tpID: $tpID
        flightID: $flightID
        times: $times
        flight: $flight
        orgID: $orgID
    ){
        _id
        desig
        timeOfDeparture
        scheduledFlightDate
        scheduleType
        originalFlightID
        customerID {
            _id
            name
        }
        state
        legsArray {
            flightTime
            departure
            departureID
            destination
            destinationID
            seats
            paxWeight
            bagWeight
            bagCount
            cgoWeight
            paxIDs {
                _id,
                firstName,
                lastName,
                paxWeight,
                bagWeight,
                chargeCode,
                individualLegTime,
                customerDoc {
                    _id
                    name
                },
                employerDoc {
                    _id
                    name
                }
                tpID {
                    _id
                    name
                }
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                checkedInTime
                transitType
            }
            cgoIDs {
                _id
                name
                approvedBy
                customerDoc {
                    _id
                    name
                }
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                deliveredBy
                attentionTo
                weight
                hazmat
                hazmatUnCode
                checkedInTime
                transitType
                currentCarrierID {
                    _id
                    desig
                }
            }
        },
        pilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        copilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        contract {
            _id
            name
        },
        sequence,
        paxIDList {
            _id,
            firstName,
            lastName,
            paxWeight,
            bagWeight,
            chargeCode,
            individualLegTime,
            customerDoc {
                _id
                name
            },
            employerDoc {
                _id
                name
            }
            departureDoc {
                _id
                name
            }
            destinationDoc {
                _id
                name
            }
            checkedInTime
            modifiedTs
            transitType
        },
        cgoIDList {
            _id
            name
            scheduledOrder
            scheduledGroup
            chargeCode,
            approvedBy
            individualLegTime,
            customerDoc {
                _id
                name
            }
            departureDoc {
                _id
                name
            }
            destinationDoc {
                _id
                name
            }
            deliveredBy
            attentionTo
            weight
            hazmat
            initials
            notes
            hazmatUnCode
            checkedInTime
            transitType
            currentCarrierID {
                _id
                desig
            }
        },
        aircraftID {
            _id,
            model,
            tailNum,
            maxSeats,
            maxLoad
        }
    }
}
`

const RevertFlightHistory = gql`
mutation RevertFlightHistory(
    $flightID: ID!
){
    revertFlightHistory(
        flightID: $flightID
    ){
        _id
        desig
        timeOfDeparture
        scheduledFlightDate
        scheduleType
        originalFlightID
        customerID {
            _id
            name
        }
        state
        legsArray {
            flightTime
            departure
            departureID
            destination
            destinationID
            seats
            paxWeight
            bagWeight
            bagCount
            cgoWeight
            paxIDs {
                _id,
                firstName,
                lastName,
                paxWeight,
                bagWeight,
                chargeCode,
                individualLegTime,
                customerDoc {
                    _id
                    name
                },
                employerDoc {
                    _id
                    name
                }
                tpID {
                    _id
                    name
                }
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                checkedInTime
                transitType
            }
            cgoIDs {
                _id
                name
                approvedBy
                customerDoc {
                    _id
                    name
                }
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                deliveredBy
                attentionTo
                weight
                hazmat
                hazmatUnCode
                checkedInTime
                transitType
                currentCarrierID {
                    _id
                    desig
                }
            }
        },
        pilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        copilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        contract {
            _id
            name
        },
        sequence,
        paxIDList {
            _id,
            firstName,
            lastName,
            paxWeight,
            bagWeight,
            chargeCode,
            individualLegTime,
            customerDoc {
                _id
                name
            },
            employerDoc {
                _id
                name
            }
            departureDoc {
                _id
                name
            }
            destinationDoc {
                _id
                name
            }
            checkedInTime
            modifiedTs
            transitType
        },
        cgoIDList {
            _id
            name
            scheduledOrder
            scheduledGroup
            chargeCode,
            approvedBy
            individualLegTime,
            customerDoc {
                _id
                name
            }
            departureDoc {
                _id
                name
            }
            destinationDoc {
                _id
                name
            }
            deliveredBy
            attentionTo
            weight
            hazmat
            initials
            notes
            hazmatUnCode
            checkedInTime
            transitType
            currentCarrierID {
                _id
                desig
            }
        },
        aircraftID {
            _id,
            model,
            tailNum,
            maxSeats,
            maxLoad
        }
    }
}
`

const FINALIZED_FLIGHT = gql`
    query Flight($_id: ID!){
        getFlightHistory(_id: $_id){
            _id
            modifiedTs
            modifiedBy
            originalFlightID
            desig
            legs
            timeOfDeparture
            scheduledFlightDate
            scheduleType
            firstLegUsableWt
            lastKnownController {
                _id
                name
            }
            legs
            legsArray {
                flightTime
                order
                departure
                departureID
                destination
                destinationID
                paxCount
                paxWeight
                cgoCount
                paxIDs {
                    _id,
                    firstName,
                    lastName,
                    paxWeight,
                    chargeCode,
                    individualLegTime,
                    departureDoc {
                        _id
                        name
                    }
                    destinationDoc {
                        _id
                        name
                    }
                    transitType
                }
                cgoIDs {
                    _id
                    name
                    approvedBy
                    departureDoc {
                        _id
                        name
                    }
                    destinationDoc {
                        _id
                        name
                    }
                    weight
                    transitType
                    deliveredBy
                }
            },
            pilot {
                _id
                name {
                    firstName,
                    lastName
                },
            },
            copilot {
                _id
                name {
                    firstName,
                    lastName
                },
            },
            contract {
                _id
                name
            },
            paxIDList {
                _id,
                firstName,
                lastName,
                chargeCode,
                individualLegTime,
                departureDoc {
                    _id
                    name
                }
                employerDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                transitType
            },
            cgoIDList {
                _id
                name
                chargeCode,
                approvedBy
                individualLegTime,
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                weight
                transitType
                deliveredBy
            },
            aircraftID {
                _id,
                model,
                tailNum,
                maxSeats,
                maxLoad
            }
        }
    }
    `

const Property = ({ field, value, altVal = 'None' }) => {
    return (
        <div style={{
            lineHeight: '22px',
            marginBottom: 7
        }}>
            <div style={{
                width: '5rem',
                marginRight: '1rem',
                display: 'inline-block'
            }}>
                {field}:
            </div>
            {value || altVal}
        </div>
    )
}

const Section = ({ title, children }) => (
    <div style={{ marginBottom: '1.5rem' }}>
        <h3>{title}</h3>
        <div>{children}</div>
    </div>
)

const getFlightTime = ({flight, form}) => {
    var flightTimeTotal = 0;
    flight.legsArray.forEach((leg, idx) => {
        if(Number.isInteger(Number.parseInt(form.getFieldValue('flightTime_' + idx)))){
            flightTimeTotal += Number.parseInt(form.getFieldValue('flightTime_' + idx));
        }else if(Number.isInteger(leg.flightTime)){
            flightTimeTotal += leg.flightTime
        }
    })

    return flightTimeTotal;
}
class FlightTimesEntryDrawer extends Component {

    componentDidUpdate(prevProps){
        if (prevProps.flightID !== this.props.flightID){
            this.props.reset();
        }
    }

    addLocationToFlight = (flight, locations) => {
        var pax = [];
        var cgo = [];
    
        //We want ALL the pax.
        pax.push(...flight.paxIDList)
        cgo.push(...flight.cgoIDList)
    
        var newLegs = locationListToFlightLegs([...locations], pax, cgo)
        
        flight.legsArray = newLegs;

        this.props.editFlight(flight)
    }

    getLocationsFromLegs = (legs) => {
        var arr = [];

        legs.forEach(leg => {
            arr.push({
                key: leg.departureID,
                label: leg.departure
            })
        })

        if(legs.length > 0){
            arr.push({
                key: legs[legs.length-1].destinationID,
                label: legs[legs.length-1].destination
            })
        }
        
        return arr
    }

    clearSelection = (e) => {
        switch(e){
            case 'pax':
            case 'cgo':
                this.props.changeWidth('105rem')
                break;
            default:
                this.props.changeWidth('60rem')
        }
        this.props.selectLegs([])
        this.props.selectPaxCgo([])
    }

    render() {
        return <Query
            query={FINALIZED_FLIGHT}
            skip={this.props.flightID == null}
            variables={{ _id: this.props.flightID }}
        >
        {({ data }) => {
            if(data){
                return <Mutation
                key={!data.loading && data.getFlightHistory._id}
                mutation={SaveFlightTimes}
                onError= {error => {
                        console.error(error);
                        message.error('Failed to update flight times and charge codes')
                    }
                }
                onCompleted= {() => {
                    message.success('Flight times and charge codes updated successfully')
                    this.props.onClose(true);
                }}
            >
                {(save_flight_times, { loading: save_flight_times_loading }) => {
                const flightData = this.props.editingFlight ? this.props.editedFlight : data.getFlightHistory
                const pilot = flightData && flightData.pilot;
                const copilot = flightData && flightData.copilot;
                const departure = flightData && getFlightDeparture(flightData);
                const legs = (flightData && flightData.legsArray) || [];
                const destinations = flightData && legs.map(leg => leg.destination);
                const orgData = this.props.orgData;
                const {selectedPaxCgo, selectedLegs} = this.props;
                const submit = (props) => {
                    props.form.validateFieldsAndScroll((err, values) => {
                        if (!err) {
                            save_flight_times({
                                variables: {
                                    tpID: orgData.activeOrg._id,
                                    flightID: flightData._id,
                                    payload: JSON.stringify(values)
                                }
                            })
                        }
                    })
                }

                const chargeCodeCol = {
                    title: 'Charge Code',
                    key: 'chargeCode',
                    dataIndex: 'chargeCode',
                    editable: true,
                    width:200,
                    render: (code, row) => {
                        if (!this.props.editMode) return code;
                        return (
                            <Form.Item>{this.props.form.getFieldDecorator('chargeCode_' + row._id, {
                                initialValue: code && code.toUpperCase()
                            })(
                                <Input
                                placeholder={this.props.form.getFieldValue('defaultChargeCode') && this.props.form.getFieldValue('defaultChargeCode').toUpperCase()}
                                size="small"
                            />
                            )}</Form.Item>
                        )
                    }
                }
                const paxColumns = commonColumns.historyPax.some(['name', 'employer', 'departure', 'destination']);
                paxColumns.push(chargeCodeCol)
                const cgoColumns = commonColumns.historyCgo.some(['name', 'weight', 'departure', 'destination', 'deliveredBy'])
                cgoColumns.push(chargeCodeCol)

                var locations = this.getLocationsFromLegs((flightData && flightData.legsArray) || [])

                const paxRowSelection = {
                    selectedRowKeys: selectedPaxCgo,
                    onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    this.props.selectPaxCgo(selectedRowKeys)
                    }
                };

                const legRowSelection = {
                    selectedRowKeys: selectedLegs,
                    onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    this.props.selectLegs(selectedRowKeys)
                    }
                };
                return (
                    <Form layout="inline">
                        <div>
                            {!flightData ? <Alert type="error" showIcon message="Failed to load flight" /> : null}
                            {flightData ? (
                                <div>
                                    <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }} >
                                        <h3 style={{ fontWeight: 'normal', marginBottom: 0 }}>{flightData.desig}</h3>
                                        {this.props.editMode ? 
                                            <div style={{display: 'flex', justifyContent: 'space-between' }}>
                                                {this.props.editingFlight ? 
                                                    <Row>
                                                        <Button onClick={e => this.props.reset()}>Reset Changes</Button>
                                                        {flightData && flightData.originalFlightID ?
                                                            <Mutation
                                                                key={flightData && flightData._id}
                                                                mutation={RevertFlightHistory}
                                                                onError= {error => {
                                                                        console.error(error);
                                                                        message.error('Failed to revert flight times and charge codes')
                                                                    }
                                                                }
                                                                onCompleted= {() => {
                                                                    message.success('Flight times and charge codes reverted successfully')
                                                                    this.props.onClose(true);
                                                                }}
                                                            >
                                                                {(revertFlightHistory, { data, loading, error }) => {
                                                                    return <Popconfirm title="Are you sure you want to revert this flight back to its original finalized state?" okText="Revert" onConfirm={() => {
                                                                        revertFlightHistory({
                                                                            variables: {
                                                                                flightID: flightData._id
                                                                            }
                                                                        })
                                                                    }}>
                                                                        <Button>Revert to Original</Button>
                                                                    </Popconfirm>
                                                                }}
                                                            </Mutation>: null} 
                                                    </Row>
                                                    :
                                                    <UserGroup.Flytsuite.Flight.Times.HistoryEdit>
                                                        <Button onClick={e => {
                                                            this.props.editFlight(flightData)
                                                        }}>Edit Flight</Button>
                                                    </UserGroup.Flytsuite.Flight.Times.HistoryEdit>
                                                }
                                            </div>
                                        : null}
                                        {this.props.editingFlight? <Mutation
                                            key={flightData && flightData._id}
                                            mutation={EditFlightHistory}
                                            onError= {error => {
                                                    console.error(error);
                                                    message.error('Failed to update flight times and charge codes')
                                                }
                                            }
                                            onCompleted= {() => {
                                                message.success('Flight times and charge codes updated successfully')
                                                this.props.onClose(true);
                                            }}
                                        >
                                            {(editFlightHistory, { data, loading, error }) => {
                                                return <Button
                                                type='primary'
                                                loading={loading}
                                                onClick={e => {
                                                    this.props.form.validateFieldsAndScroll((err, values) => {
                                                        if (!err) {
                                                            var assignedPaxCgo = []
                                                            for(var leg of flightData.legsArray){
                                                                assignedPaxCgo.push(...leg.paxIDs.map(p => p._id), ...leg.cgoIDs.map(c => c._id))
                                                            }
                                                            var uniquePaxCgo = [...new Set(assignedPaxCgo)]
                                                            var diffOfPaxCgo = [...flightData.paxIDList.map(p => p._id), ...flightData.cgoIDList.map(c => c._id)].filter(paxCgo =>  !uniquePaxCgo.includes(paxCgo))

                                                            if(diffOfPaxCgo.length !== 0){
                                                                Modal.error({
                                                                    title: 'Unassigned Pax or Cargo',
                                                                    content: <div>The following items are not assigned to a leg.<div>Please assign them before continuing.</div>
                                                                        {diffOfPaxCgo.map(d => [...flightData.paxIDList, ...flightData.cgoIDList].filter(f => f._id === d).map(m => m.__typename === "HistoryPaxNode" ? <div>{m.lastName + ", " + m.firstName}</div>: <div>{m.name}</div>))}
                                                                        </div>
                                                                })
                                                            }else {
                                                                var flight = {
                                                                    _id: flightData._id,
                                                                    aircraftID: values.aircraftID,
                                                                    contractID: values.contractID,
                                                                    pilotID: values.pilotID || null,
                                                                    copilotID: values.copilotID || null,
                                                                    originalFlightID: flightData.originalFlightID || null,
                                                                    legs: JSON.stringify(Object.assign({}, flightData.legs)),
                                                                    customerID: orgData.customer._id,
                                                                    tpID: orgData.activeOrg._id
                                                                }
                                                                editFlightHistory({
                                                                    variables: {
                                                                        tpID: orgData.activeOrg._id,
                                                                        flightID: flightData._id,
                                                                        times: JSON.stringify(values),
                                                                        flight: flight,
                                                                        orgID: orgData.activeOrg._id
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    })
                                                }}>Save</Button>
                                            }}
                                        </Mutation>
                                        :
                                        <Button type='primary' loading={save_flight_times_loading} onClick={e => {
                                            submit({form: this.props.form})
                                        }}>Save</Button>}
                                    </div>
                                    {this.props.editingFlight ? <div><Card style={{ marginBottom: '1rem' }}>
                                            <Row gutter={36} type="flex" style={{ marginBottom: '-1.5rem' }}>
                                                <Col>
                                                    <Section title="Flight Info">
                                                        <Form.Item label="Pilot: ">
                                                            {this.props.form.getFieldDecorator('pilotID', {
                                                                initialValue: flightData.pilot && flightData.pilot._id
                                                            })(
                                                            <PilotSelect style={{minWidth: 150}}/>,
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item label="CoPilot: ">
                                                        {this.props.form.getFieldDecorator('copilotID', {
                                                            initialValue: flightData.copilot && flightData.copilot._id
                                                        })(
                                                        <PilotSelect style={{minWidth: 150}}/>,
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item label="Contract: ">
                                                        {this.props.form.getFieldDecorator('contractID', {
                                                            initialValue: flightData.contract && flightData.contract._id,
                                                            rules: [
                                                                {required: true, message: 'Contract is required'}
                                                            ]
                                                        
                                                        })(
                                                            <ContractSelect style={{minWidth: 200}} orgType={'customer'} />
                                                        )}
                                                    </Form.Item>
                                                    {flightData.aircraftID ? (
                                                    <Form.Item label="Aircraft: ">
                                                        {this.props.form.getFieldDecorator('aircraftID', {
                                                            initialValue: flightData.aircraftID && flightData.aircraftID._id,
                                                            rules: [
                                                                {required: true, message: 'Aircraft is required'}
                                                            ]
                                                        })(
                                                        <AircraftSelect style={{minWidth: 150}}/>,
                                                        )}
                                                    </Form.Item>
                                                ) : null}
                                                    </Section>
                                                </Col>
                                                
                                            </Row>
                                        </Card>
                                        <UnassignedPaxCgoWarning
                                            flightData={flightData}
                                        />
                                        <Tabs defaultActiveKey="locations" type="card"  onChange={e => this.clearSelection(e)}>
                                        <Tabs.TabPane key="locations" tab={`Locations`}>
                                            <div>
                                            
                                            <Row type="flex" align="middle">
                                                <Col style={{ flex: 1 }}>
                                                    <Row type="flex" justify="end">
                                                        <LocationSelect
                                                            placeholder="Add flight leg"
                                                            onChange={value => {
                                                                value["new"] = true
                                                                locations.push(value)
                                                                return this.addLocationToFlight(flightData, locations)}
                                                            }
                                                            labelInValue
                                                            style={{minWidth: '10rem'}}
                                                        />
                                                </Row>
                                                </Col>
                                            </Row>
                                            </div>
                            <Col span={12} style={{ marginTop: '12px', maringBottom: '6px' }}>Drag to reorder flight locations</Col>
                            <Col>
                                <Table
                                    className="draggable-table"
                                    dataSource={locations} 
                                    columns={[{
                                        title: 'Delete',
                                        key: 'delete',
                                        render: (location, __, index) => (
                                            <Row type="flex" gutter={6}>
                                                <Col>
                                                {locations.length > 2 ?
                                                    <Popconfirm title="Are you sure you want to remove this item?" okText="Remove" onConfirm={() => {
                                                        locations.splice(index, 1)
                                                        this.addLocationToFlight(flightData, locations)
                                                    }}>
                                                        <Button size="small" shape="circle" type="danger"><Icon type="delete" /></Button>
                                                    </Popconfirm>
                                                    : null}
                                                </Col>
                                            </Row>
                                        ),
                                        width: 60
                                    },{
                                        title: '#',
                                        key: '#',
                                        render: (_, __, index) => index + 1,
                                        width: 25
                                    },{
                                        title: "Name",
                                        key: "name",
                                        dataIndex: "label"
                                    }]} 
                                    rowKey={(_, index) => index} 
                                    size="small" 
                                    pagination={false}
                                    components={{
                                        body: {
                                            row: DraggableBodyRow
                                        }
                                    }}
                                    onRow={(row, index) => {
                                        const rowProps = {
                                            moveRow: (dragIndex, hoverIndex) => {
                                                var arr = [...locations]
                                                arr.splice(hoverIndex, 0, arr.splice(dragIndex, 1)[0]);
                                                locations = arr
                                                this.addLocationToFlight(flightData, locations)
                                            },
                                            onClick: () => {console.log(locations)},
                                            index
                                        }
                                        return { ...rowProps}
                                    }}/>
                                    </Col>
                                            <Row type="flex" align="middle">
                                                <Col style={{ flex: 1 }}>
                                                    <Form.Item label="Scheduled: ">
                                                        {this.props.form.getFieldDecorator('scheduleType', {
                                                            initialValue: flightData.scheduleType
                                                        })(
                                                        <Select style={{ width: 240 }} placeholder="Please choose your option" showAction={["focus", "click"]}>
                                                            <Option value="SCHEDULED">Scheduled</Option>
                                                            <Option value="UNSCHEDULED">Unscheduled</Option>
                                                        </Select>,
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <LegTimeEntry pax={true} data={legs} form={this.props.form}/>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key="pax" tab={`Passengers (${flightData.paxIDList ? flightData.paxIDList.length : 0})`}>
                                        {
                                            flightData.paxIDList && flightData.paxIDList.length ? (
                                                <div>
                                                    <Form.Item label="Default Charge Code: ">
                                                        {this.props.form.getFieldDecorator('defaultChargeCode')(
                                                            <Input size="small" />
                                                        )}
                                                    </Form.Item>
                                                    
                                                    <FlightManualLegsAssign
                                                        flight={flightData}
                                                        editFlight={this.props.editFlight}
                                                        selectedLegs={selectedLegs}
                                                        selectedPaxCgo={selectedPaxCgo}
                                                        paxRowSelection={paxRowSelection}
                                                        legRowSelection={legRowSelection}
                                                        form={this.props.form}
                                                        clearSelection={this.clearSelection}
                                                        type={'PAX'}
                                                        applyChargeCode={(chargeCode, data) => {
                                                            data.forEach(row => {
                                                                this.props.form.setFieldsValue({['chargeCode_' + row._id]: chargeCode})
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            ) : 'No Passengers'
                                        }
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key="cgo" tab={`Cargo (${flightData.cgoIDList ? flightData.cgoIDList.length : 0})`}>
                                        {
                                                flightData.cgoIDList && flightData.cgoIDList.length ? (
                                                    <div>
                                                        <Form.Item label="Default Charge Code: ">
                                                            {this.props.form.getFieldDecorator('defaultChargeCode')(
                                                                <Input size="small" />
                                                            )}
                                                        </Form.Item>
                                                        <FlightManualLegsAssign
                                                            flight={flightData}
                                                            editFlight={this.props.editFlight}
                                                            selectedLegs={selectedLegs}
                                                            selectedPaxCgo={selectedPaxCgo}
                                                            paxRowSelection={paxRowSelection}
                                                            legRowSelection={legRowSelection}
                                                            form={this.props.form}
                                                            clearSelection={this.clearSelection}
                                                            type={'CGO'}
                                                            applyChargeCode={(chargeCode, data) => {
                                                                data.forEach(row => {
                                                                    this.props.form.setFieldsValue({['chargeCode_' + row._id]: chargeCode})
                                                                })
                                                            }}
                                                        />
                                                </div>
                                            ) : 'No Cargo'
                                        }
                                        </Tabs.TabPane>
                                    </Tabs>
                                    </div>
                                    :
                                    <div>
                                        <Card style={{ marginBottom: '1rem' }}>
                                            <Row gutter={36} type="flex" style={{ marginBottom: '-1.5rem' }}>
                                                <Col>
                                                    <Section title="Pilots">
                                                        <Property field="Pilot" value={(pilot && pilot.name && (pilot.name.lastName && pilot.name.firstName) && `${pilot.name.lastName}, ${pilot.name.firstName}`)} />
                                                        <Property field="Co-Pilot" value={(copilot && copilot.name && `${copilot.name.lastName || ''}, ${copilot.name.firstName || ''}`)} />
                                                    </Section>
                                                </Col>
                                                <Col>
                                                    <Section title="Flight Route">
                                                        <Property field="Departure" value={(departure && departure.name)} />
                                                        <Property field="Destinations" value={destinations.join(', ')} />
                                                    </Section>
                                                </Col>
                                                <Col>
                                                    <Section title="Other">
                                                        <Property field="Contract" value={(flightData.contract && flightData.contract.name)} />
                                                        <Property field="Time of Departure" value={(flightData.timeOfDeparture && moment(flightData.timeOfDeparture).format('YYYY-MM-DD HH:mm'))} />
                                                        <Property field="Flight Time" value={getFlightTime({flight: flightData, form: this.props.form})}/>
                                                    </Section>
                                                </Col>
                                                {flightData.aircraftID ? (
                                                    <Col>
                                                        <Section title="Aircraft">
                                                            <Property field="Model" value={flightData.aircraftID.model} />
                                                            <Property field="Tail Num" value={flightData.aircraftID.tailNum} />
                                                            <Property field="Max Seats" value={flightData.aircraftID.maxSeats} altVal={0} />
                                                            <Property field="Useful Load" value={flightData.aircraftID.maxLoad} altVal={0} />
                                                        </Section>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </Card>
                                        <Tabs defaultActiveKey="legs" type="card">
                                            <Tabs.TabPane key="legs" tab={`Legs (${legs.length})`}>
                                                <Row type="flex" align="middle">
                                                    <Col style={{ flex: 1 }}>
                                                        <Form.Item label="Scheduled: ">
                                                            {this.props.form.getFieldDecorator('scheduleType', {
                                                                initialValue: flightData.scheduleType
                                                            })(
                                                            <Select style={{ width: 240 }} placeholder="Please choose your option" showAction={["focus", "click"]}>
                                                                <Option value="SCHEDULED">Scheduled</Option>
                                                                <Option value="UNSCHEDULED">Unscheduled</Option>
                                                            </Select>,
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <LegTimeEntry data={legs} form={this.props.form}/>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane key="pax" tab={`Passengers (${flightData.paxIDList ? flightData.paxIDList.length : 0})`}>
                                            {
                                                    flightData.paxIDList && flightData.paxIDList.length ? (
                                                        <div>
                                                            <Form.Item label="Default Charge Code: ">
                                                                {this.props.form.getFieldDecorator('defaultChargeCode')(
                                                                    <Input size="small" />
                                                                )}
                                                            </Form.Item>
                                                            <FilterableTable
                                                                dataSource={flightData.paxIDList}
                                                                rowKey={record => record._id}
                                                                size="small"
                                                                pagination={false}
                                                                columns={paxColumns}
                                                                applyChargeCode={(chargeCode, data) => {
                                                                    data.forEach(row => {
                                                                        this.props.form.setFieldsValue({['chargeCode_' + row._id]: chargeCode})
                                                                    })
                                                                }}
                                                            />
                                                    </div>
                                                ) : 'No Passengers'
                                            }
                                            </Tabs.TabPane>
                                            <Tabs.TabPane key="cgo" tab={`Cargo (${flightData.cgoIDList ? flightData.cgoIDList.length : 0})`}>
                                            {
                                                    flightData.cgoIDList && flightData.cgoIDList.length ? (
                                                        <div>
                                                            <Form.Item label="Default Charge Code: ">
                                                                {this.props.form.getFieldDecorator('defaultChargeCode')(
                                                                    <Input size="small" />
                                                                )}
                                                            </Form.Item>
                                                            <FilterableTable
                                                                dataSource={flightData.cgoIDList}
                                                                rowKey={record => record._id}
                                                                size="small"
                                                                pagination={false}
                                                                columns={cgoColumns}
                                                                applyChargeCode={(chargeCode, data) => {
                                                                    data.forEach(row => {
                                                                        this.props.form.setFieldsValue({['chargeCode_' + row._id]: chargeCode})
                                                                    })
                                                                }}
                                                            />
                                                    </div>
                                                ) : 'No Cargo'
                                            }
                                            </Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                    }</div>
                            ) : null}
                        </div>
                    </Form>
                )
            }}
            </Mutation>
            }
            return null
        }}
        </Query>
    }
}

export default compose(
    WithOrgData
)(FlightTimesEntryDrawer)