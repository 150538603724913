import gql from 'graphql-tag';
import React, { PropsWithChildren, useEffect, useReducer, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { NetworkStatus } from 'apollo-client';

const APP_NAME = 'manifestcentral';

export interface IGlobalAppState {
    sidebarCollapsed: boolean,
    flytwatchCollapsedColumns: string[]
}

export interface McMapLayerConfig {
    enabled: boolean,

    // Just in case we need more configurability other than enabling/disabling
    config?: any
}

export interface McMapSettings {
    layers: {
        [id: string]: McMapLayerConfig
    }
}

export interface IGlobalAppStateContext {
    state: IGlobalAppState,
    setState: (state: Partial<IGlobalAppState>) => void,
    getUserSettings: <T = any>(featureName: string, defaultValue?: T) => T,
    userContracts?: any[],
    setUserSettings: (featureName, value: any) => void,
    toggleSidebarCollapse: () => void,
    setTableColumnVisibility: (tableID: string, columnName: string, show: boolean) => void,
    getTableSettings: (tableName: string) => TableSettingsItem,
    collapseFlytwatchColumn: (colName: string) => void
    unCollapseFlytwatchColumn: (colName: string) => void
}

export const GlobalAppStateContext = React.createContext<IGlobalAppStateContext>(null);

export const INIT_USER_SETTINGS = {
    appName: 'manifestcentral',
    settings: {
        generalSettings: {
            savedTpId: null
        },
        tableSettings: [
            {
                name: 'livePassengers',
                settings: {
                    hiddenColumns: []
                }
            },
            {
                name: 'liveFlights',
                settings: {
                    hiddenColumns: []
                }
            },
            {
                name: 'liveCargo',
                settings: {
                    hiddenColumns: []
                }
            }
        ],
        mcMap: {
            layers: {
                'nexrad': {
                    enabled: true
                }
            }
        }
    }
}

const INIT_STATE: IGlobalAppState = {
    sidebarCollapsed: false,
    flytwatchCollapsedColumns: []
}

const TYPES = {
    UPDATE_STATE: 'UPDATE_STATE',
    TOGGLE_SIDEBAR_COLLAPSE: 'TOGGLE_SIDEBAR_COLLAPSE'
}

function reducer (state: IGlobalAppState, action: any): IGlobalAppState {
    switch (action.type) {

    case TYPES.UPDATE_STATE:
        return { ...state, ...action.payload }
    case TYPES.TOGGLE_SIDEBAR_COLLAPSE:
        return { ...state, sidebarCollapsed: typeof state.sidebarCollapsed === 'boolean' ? !state.sidebarCollapsed : true }
    default:
        return state
    }
}

let UserSettingsQuery = gql`
query GetRemoteUserSettings {
    GetRemoteUserSettings {
        contractDocs {
            _id
            name
            customerID {
                _id
                name
            }
            tpID {
                _id
                name
            }
        }
    }
    GetRemoteUserAppSettings(appName: "manifestcentral"){
        appName
        settings
    }
}
`

let SetUserSettingsMutation = gql`
mutation SetRemoteUserSettings($settings: AWSJSON!) {
    SetRemoteUserAppSettings(appName: "manifestcentral", settings: $settings){
        appName
        settings
    }
}
`

export interface TableSettings {
    hiddenColumns: string[]
}

export interface TableSettingsItem {
    name: string,
    settings: TableSettings
}


export const GlobalAppStateProvider: React.FC<PropsWithChildren<any>> = (props) => {
    const [ state, dispatch ] = useReducer(reducer, INIT_STATE);
    const [ userSettingsState, setUserSettingsState ] = useState<any>(INIT_USER_SETTINGS);
    const client = useApolloClient();

    useEffect(() => {
        if (window.innerWidth <= 1048 && !state.sidebarCollapsed){
            console.log('Small window detected. Collapsing sidebar.');
            dispatch({ type: TYPES.UPDATE_STATE, payload: { sidebarCollapsed: true } });
        }
    // eslint-disable-next-line
    }, [])

    let { data, networkStatus } = useQuery(UserSettingsQuery, {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
        // pollInterval: 300000 // TODO: This causes child components to rerender and forget state for some reason.
        // Specifically causes the FlightTimesHeader component to forget its date range and stuff.
    }); 

    const isLoading = networkStatus === NetworkStatus.loading

    useEffect(() => {
        if (networkStatus !== NetworkStatus.loading){
            setUserSettingsState({
                ...INIT_USER_SETTINGS,
                contractDocs: data.GetRemoteUserSettings.contractDocs,
                settings: {
                    ...INIT_USER_SETTINGS.settings,
                    ...JSON.parse(data?.GetRemoteUserAppSettings.settings)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isLoading ])

    let userSettingsData = {
        ...INIT_USER_SETTINGS,
        ...userSettingsState
    }

    if (typeof userSettingsData?.settings === 'string'){
        try {
            userSettingsData.settings = {
                ...INIT_USER_SETTINGS.settings,
                ...JSON.parse(userSettingsData.settings)
            };
        }
        catch (e){
            console.error('Could not parse user settings json:', e)
            console.log('userSettingsData', userSettingsData);
            userSettingsData.settings = undefined;
        }
    }
    else
    {
        userSettingsData.settings = {
            ...INIT_USER_SETTINGS.settings,
            ...userSettingsData.settings
        };
    }
    console.log({data, userSettingsData})

    const [ setUserSettings, { loading: setUserSettingsLoading } ] = useMutation(SetUserSettingsMutation, {
        update: (cache, { data }) => {
            cache.writeQuery({
                query: UserSettingsQuery,
                data: {
                    ...data,
                    GetRemoteUserAppSettings: {
                        __typename: 'WebUserAppSettings',
                        appName: data.SetRemoteUserAppSettings.appName,
                        settings: data.SetRemoteUserAppSettings.settings
                    }
                }
            })
        }
    })

    useEffect(() => {
        setUserSettingsState(userSettingsData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ])

    function getOrCreateTableSettings(tableSettings, name, defaultSettings){
        let settingsItem = tableSettings?.find((item) => item.name === name);
        if (!settingsItem){
            settingsItem = {
                name,
                settings: defaultSettings
            }
        }
        Object.entries(settingsItem.settings).forEach(([ settingName, settingValue ]) => {
            if (defaultSettings && defaultSettings[settingName] && !settingValue){
                settingsItem.settings[settingName] = defaultSettings[settingName];
            }
        })
        return settingsItem;
    }

    function setOrCreateTableSettings(tableSettings, name, settings){
        let settingsItem = tableSettings?.find((item) => item.name === name);
        if (!settingsItem){
            settingsItem = {
                name,
                settings,
                __typename: 'TableSettings'
            }
        }
        else
        {
            settingsItem.settings = { ...settingsItem.settings, ...settings };
        }

        return tableSettings
    }

    function toggleSidebarCollapse(){
        let collapsed = !state.sidebarCollapsed;
        dispatch({ type: TYPES.UPDATE_STATE, payload: { sidebarCollapsed: collapsed } })
        client.writeData({
            data: {
                navSiderCollapsed: collapsed
            }
        })
    }

    function handleSetUserSettingsMut(newUserSettings: any){
        return setUserSettings({
            variables: {
                settings: JSON.stringify(newUserSettings.settings)
            },
            optimisticResponse: {
                __typename: 'Mutation',
                SetRemoteUserAppSettings: {
                    __typename: 'WebUserAppSettings',
                    appName: APP_NAME,
                    settings: newUserSettings.settings
                }
            }
        })
    }

    const debouncedHandleSetUserSettingsMut = useDebouncedCallback(handleSetUserSettingsMut, 1000)

    function handleSetUserSettings(featureName: string, value: any) {
        let newUserSettings = {
            ...userSettingsState,
            settings: {
                ...userSettingsState.settings,
                [featureName]: value
            }
        }
        // @ts-ignore
        setUserSettingsState(newUserSettings);
        return debouncedHandleSetUserSettingsMut(newUserSettings);
    }

    return <GlobalAppStateContext.Provider value={{
        state,
        setState: (state: Partial<IGlobalAppState>) => dispatch({ type: TYPES.UPDATE_STATE, payload: state }),
        toggleSidebarCollapse,
        userContracts: userSettingsState.contractDocs,
        getUserSettings<T=any>(featureName: string, defaultValue?: T): T {
            if (userSettingsState.settings && !userSettingsState.settings[featureName] && defaultValue && !setUserSettingsLoading){
                handleSetUserSettings(featureName, defaultValue);
                return defaultValue
            }
            return (userSettingsState.settings && userSettingsState.settings[featureName])
        },
        setUserSettings: handleSetUserSettings,
        setTableColumnVisibility: (tableID: string, columnName: string, show: boolean) => {
            let settingsItem, newTableSettings;
            if (show){
                settingsItem = getOrCreateTableSettings(userSettingsData.settings?.tableSettings, tableID, { hiddenColumns: [] });
                newTableSettings = setOrCreateTableSettings(userSettingsData.settings?.tableSettings, tableID, {
                    hiddenColumns: settingsItem.settings.hiddenColumns.filter(colName => colName !== columnName)
                });
            }
            else
            {
                settingsItem = getOrCreateTableSettings(userSettingsData.settings?.tableSettings, tableID, { hiddenColumns: [] });
                newTableSettings = setOrCreateTableSettings(userSettingsData.settings?.tableSettings, tableID, {
                    hiddenColumns: [ ...settingsItem.settings.hiddenColumns, columnName ]
                });
            }

            let newSettings: any = {
                ...userSettingsData.settings,
                tableSettings: newTableSettings
            }
            newSettings = JSON.stringify(newSettings);

            setUserSettings({
                variables: {
                    settings: newSettings
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    SetRemoteUserAppSettings: {
                        __typename: 'WebUserAppSettings',
                        appName: 'manifestcentral',
                        settings: newSettings
                    }
                }
            })
            .catch((reason) => {
                // Even if the server fails to update the user settings document write it to the query cache.
                console.log('setUserSettings failed: ', reason)
                client.writeQuery({
                    query: UserSettingsQuery,
                    data: {
                        GetRemoteUserAppSettings: {
                            __typename: 'WebUserAppSettings',
                            appName: APP_NAME,
                            settings: newSettings
                        }
                    }
                })
            })
        },
        getTableSettings: (tableName) => {
            let findFunc = item => item.name === tableName;
            let tb = userSettingsData.settings?.tableSettings?.find(findFunc);
            return tb
        },
        collapseFlytwatchColumn: (colName) => {
            if (!state.flytwatchCollapsedColumns.includes(colName)){
                dispatch({ type: TYPES.UPDATE_STATE, payload: { flytwatchCollapsedColumns: [...state.flytwatchCollapsedColumns, colName] } })
            }
        },
        unCollapseFlytwatchColumn: (colName) => {
            if (state.flytwatchCollapsedColumns.includes(colName)){
                dispatch({ type: TYPES.UPDATE_STATE, payload: { flytwatchCollapsedColumns: state.flytwatchCollapsedColumns.filter(col => col !== colName) } })
            }
        }
    }}>
        {props.children}
    </GlobalAppStateContext.Provider>
}