import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import ISNCheck from '../Mutations/ISNCheck';
import WithOrgData from './WithOrgData';

export const useBulkISNChecker = (client, orgID, onCompleted=null) => {
    const [ status, setStatus ] = useState(null);
    const [ paxArray, setPaxArray ] = useState(null);
    const [ checking, setChecking ] = useState(false);
    const [ returnedIsnStatus, setReturnedIsnStatus ] = useState(null);
    let running = false;
    if (status){
        const itemLoading = status.find(({ loading }) => loading === true);
        running = itemLoading ? true : false;
    }

    const runISNCheck = (paxId) => new Promise((resolve, reject) => {
        client.mutate({
            mutation: ISNCheck,
            variables: {
                _id: paxId,
                tpID: orgID
            }
        })
        .then(({ data: { setPassengerIsnCheck } }) => {
            console.log(`ISN Check for ${setPassengerIsnCheck.lastName}, ${setPassengerIsnCheck.firstName} (${paxId}) is complete!`);
            resolve({ id: paxId, data: setPassengerIsnCheck.lastIsnResultWithHeader});
        })
        .catch((err) => {
            console.warn(`ISN Check for pax ID ${paxId} has failed! Check error log below.`);
            console.error(err);
            reject({ id: paxId, error: err});
        })
    })

    const setStatusComplete = (paxId, data) => {
        return setStatus(status.map((item) => {
            if (item.id === paxId){
                return {
                    id: item.id,
                    loading: false,
                    data: data
                }
            }
            return item
        }))
    }

    const setStatusError = (personID, error) => {
        return setStatus(status.map((item) => {
            if (item.id === personID){
                return {
                    id: item.id,
                    loading: false,
                    error: error
                }
            }
            return item;
        }))
    }

    const initStatus = (paxIds) => {
        setStatus(paxIds.map((id) => {
            return {
                id: id,
                loading: true
            }
        }))
    }

    useEffect(() => {
        if (checking){
            const promises = paxArray.map((paxId) => {
                return runISNCheck(paxId)
                .then(setReturnedIsnStatus)
                .catch(setReturnedIsnStatus);
            });

            Promise.all(promises)
                .then(() => onCompleted?.());
        }
    // eslint-disable-next-line
    }, [ checking ])

    useEffect(() => {
        if (!running){
            setChecking(false);
        }
    }, [ running ])

    useEffect(() => {
        if (returnedIsnStatus){
            const { id, data, error } = returnedIsnStatus;
            if (error){
                setStatusError(id, error);
            }
            if (data){
                setStatusComplete(id, data);
            }
        }
    // eslint-disable-next-line
    }, [ returnedIsnStatus ])

    const runChecks = async (paxIds) => {
        initStatus(paxIds);
        setPaxArray(paxIds);
        setChecking(true);
    }

    const getCheckStatus = (paxId) => {
        return status && status.find(({ id }) => id === paxId)
    }

    return {
        runChecks,
        status,
        running: running,
        checkStatus: getCheckStatus,
        checkLoading: (paxId) => {
            const s = getCheckStatus(paxId);
            return s && s.loading
        },
        checkError: (paxId) => {
            const s = getCheckStatus(paxId);
            return s && s.error
        },
        checkData: (paxId) => {
            const s = getCheckStatus(paxId);
            return s && s.data
        }
    }
}

export const withBulkISNChecker = Component => withApollo( WithOrgData( ({ client, orgData, ...rest }) => {
    const isnCheckerProps = useBulkISNChecker(client, orgData.getActiveOrgID());
    return <Component {...rest} bulkISNChecker={isnCheckerProps} />
}))

const BulkISNChecker = ({ children, client, orgData }) => {
    const { runChecks, ...restProps } = useBulkISNChecker(client, orgData.getActiveOrgID());

    return children(runChecks, restProps );
}

export default withApollo( WithOrgData( BulkISNChecker) )