import React from 'react';
import '../css/Main.css';
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from './Home';
import SignIn from './SignIn';
import SignUp from './SignUp';
import PrivateRoute from './PrivateRoute';
import AppInitializer from '../containers/AppInitializer';
import Maintenance from 'components/Maintenance';
import {useS3Config} from "../hooks/s3config";

/**
 * Entry point of the application.
 */
function Main(){
  const S3Config = useS3Config();

  // Display maintenance page if maintenance is enabled in config file.
  if (S3Config && S3Config.maintenance){
    if (S3Config.maintenance.enabled === true){
      return <Maintenance message={S3Config.maintenance.message} />
    }
  }
  return (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/login" component={SignIn}/>
            <Route path="/register" component={SignUp} />
            <PrivateRoute path="/app" component={AppInitializer}/>
            <PrivateRoute path="/window" component={AppInitializer} />
        </Switch>
    </BrowserRouter>
  );
}

export default Main;
