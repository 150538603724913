import { Button, Col, Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import React, { useState} from 'react';
import WithOrgData, { WithOrgDataProps } from '../../WithOrgData';
import { getFieldLabel } from '../fieldTypes';
import useCachedDateRange from '../ReportForms/hooks/useCachedDateRange';
import {createFormField} from "../../../common/form";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export interface ReportFormProps extends WithOrgDataProps {
    renderFormItems?: (form: any, formItemLayout: any, customer: any, transporter: any, employer: any) => React.ReactNode[],
    onSubmit: (data: { name: string, criteria: any }) => void,
    submitting?: boolean,
    fieldFormatter?: (values: any) => any,
    generatedReportDateTimeFormat?: string
}

const ReportForm_Internal: React.FC<ReportFormProps & FormComponentProps> = (props) => {

    function getFormattedFields() {
        const values = props.form.getFieldsValue();
        return (props.fieldFormatter && props.fieldFormatter(values)) || values
    }
    function generateReportName() {
        let format = props.generatedReportDateTimeFormat || 'MMM D, YYYY HH:mm:ss A'
        let orgName = props.orgData.getActiveOrgName();
        const reportName = `${orgName} ${moment().format(format)}`
        return reportName
    }
    const handleSubmit = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            const formattedFields = getFormattedFields();
            const { reportName, ...restValues } = formattedFields;
            const criteria = Object.entries(restValues).map(([key, value]) => ({
                key,
                value,
                label: getFieldLabel(key)
            }));
            if (!err) {
                props.onSubmit({
                    name: reportName || generateReportName(),
                    criteria: criteria
                });
            }
        })
    }

    const { getFieldDecorator } = props.form;
    const { renderFormItems, orgData: { customer, transporter, employer }, submitting } = props;
    const formItems = renderFormItems && renderFormItems(props.form, formItemLayout, customer, transporter, employer);
    return (
        <Form>
            <Row>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="Report Name" {...formItemLayout}>
                        {getFieldDecorator('reportName')(<Input placeholder="Enter a report name" />)}
                    </Form.Item>
                    {formItems}
                    <Form.Item {...tailFormItemLayout}>
                        <Button loading={submitting} onClick={() => handleSubmit()} type="primary">Run Report</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

interface WithCachedDateRangeProps {
    cachedDateRange: ReturnType<typeof useCachedDateRange>,
    formState: any,
    setFormState: (state: any) => void
}

const ReportForm_Form = WithOrgData(Form.create<ReportFormProps & FormComponentProps & WithCachedDateRangeProps>({
    mapPropsToFields(props){
        return {
            ...props.formState,
            dateRange: createFormField(props.cachedDateRange.dateRange)
        }
    },
    onFieldsChange(props, _, fields){
        props.setFormState(fields);
        props.cachedDateRange.setDateRange(fields.dateRange.value);
    }
})(ReportForm_Internal));

const ReportForm: React.FC<ReportFormProps> = (props) => {
    const cachedDateRange = useCachedDateRange();
    const [ formState, setFormState ] = useState();

    return <ReportForm_Form
        {...props}
        cachedDateRange={cachedDateRange}
        formState={formState}
        setFormState={setFormState}
    />
}

ReportForm.displayName = 'ReportForm'

export default ReportForm;