import gql from "graphql-tag";
import {LocationFragment} from "Queries/Location";
import {
    ScheduledPaxCgoGroupV2Fragment
} from "Queries/Scheduling";

export const Queries = {
    GetScheduledPaxCgoGroups: gql`
        query GetScheduledPaxCgoGroups(
            $tpID: ID!
            $customerID: ID!
            $startDate: AWSDate!
            $endDate: AWSDate!
        ){
            getScheduledPaxInbCgoGroups(
                tpID: $tpID
                customerID: $customerID
                startDate: $startDate
                endDate: $endDate
            ){
                scheduledFlightDate
                scheduledGroup
                departureID {
                    ...LocationFragment
                }
                destinationID {
                    ...LocationFragment
                }
                lastKnownController {
                    ...LocationFragment
                }
                paxOutbound
                paxTransfer
                paxInbound
                cgoOutbound
                cgoTransfer
                cgoInbound
                paxOutboundOnFlight
                paxTransferOnFlight
                paxInboundOnFlight
                cgoOutboundOnFlight
                cgoTransferOnFlight
                cgoInboundOnFlight
            }
            GetScheduledPaxCgoGroupsV2(
                tpID: $tpID
                customerID: $customerID
                startDate: $startDate
                endDate: $endDate
            ){
                ...ScheduledPaxCgoGroupV2Fragment
            }
        }
        ${LocationFragment}
        ${ScheduledPaxCgoGroupV2Fragment}
    `
}