import {Alert, Button, Collapse, Drawer, Icon, Input, InputNumber, Modal, Spin, Tooltip} from 'antd';
import Checkbox, {CheckboxProps} from 'antd/lib/checkbox';
import {PaxISNWithData} from 'components/isn/PaxISN';
import IsnCheckStatusButton from 'components/IsnCheckStatusButton';
import {PassengerEditor} from 'components/PassengerEditor';
import 'css/Collapse.less';
import gql from 'graphql-tag';
import {IsSelected, ToggleSelection} from 'hooks/useMultiSelect';
import React, { useState} from 'react';
import {Query} from 'react-apollo';
import {MultiSelectClassType} from '.';
import './PaxCgoScheduler.less';
import {QueryResult} from '@apollo/react-common';
import FlightPreview from "../../flight/FlightPreview";
import commonColumns from "common/table/columns";
import ETable from "../../enchanced-antd-table";
import MCIcon from "../../icon";
import {useHistory} from "react-router-dom";

const BASE_CLS = 'paxcgo-scheduler-table';

const { Panel } = Collapse;

export type TypeOnCellEdit = (id: string, fieldKey: string, value: any) => void

export interface IPaxCgoSchedulerTableProps {
    paxData: any[],
    cgoData: any[],
    editMode?: boolean,
    paxEdit?: boolean,
    enabledFocusedCell?: boolean,
    multiSelect?: {
        isSelected?: IsSelected,
        toggleSelection?: ToggleSelection<MultiSelectClassType>
    },
    renderPaxPanelExtra?: JSX.Element,
    paxPanelHeader?: JSX.Element,
    onCargoEdit?(data: any): void,
    cargoEditID?: string,
    onCellEdit?: TypeOnCellEdit,
    defaultChargeCode?: string
}

interface DrawerState {
    id: string,
    open: boolean
}

const PaxModalState = (visible=false, paxId=null) => {
    return { visible, paxId }
}

const IsnDrawerState = (visible=false, id=null) => {
    return {visible, id}
}

const PaxCgoSchedulerTable: React.FC<IPaxCgoSchedulerTableProps> = (props) => {
    const [ isnCheckDrawer, setIsnCheckDrawer ] = useState<DrawerState>({ id: '', open: false });
    const [ isnDrawer, setIsnDrawer ] = useState(IsnDrawerState());
    const [ paxModal, setPaxModal ] = useState(PaxModalState());
    const [ flightPreviewState, setFlightPreviewState ] = useState({ flightID: null });
    const history = useHistory();

    const paxData = props.paxData || [];
    const cgoData = props.cgoData || [];

    let hasOutboundPax = paxData.findIndex((row: any) => row.transitType === 'OUTBOUND') !== -1 ? true : false;

    const renderIsnDrawer = () => {
        return (
            <Query 
                query={gql`
                    query PaxName($id: ID!) {
                        getPassenger(_id: $id){
                            _id
                            lastName
                            firstName
                        }
                    }
                `}
                variables={{ id: isnDrawer.id }}
                skip={!isnDrawer.visible}>
                {({ data, loading, error }: QueryResult) => {
                    const passenger = data && data.getPassenger;
                    var title;
                    title = passenger ? `${passenger.lastName}, ${passenger.firstName}` : '';
                    if (loading){
                        title = 'Loading...'
                    }
                    return (
                        <Drawer
                        title={title}
                        visible={isnDrawer.visible}
                        width="50rem"
                        onClose={() => setIsnDrawer(IsnDrawerState())}
                        closable
                        >
                        {error ? <Alert type="error" showIcon message="Failed to load passenger" /> : null}
                        {passenger ? <PaxISNWithData paxId={passenger._id} /> : null}
                        </Drawer>
                    )
                }}
                </Query>
        )
    }

    function toggleSelection(row: any){
        if (props.multiSelect.toggleSelection){
            props.multiSelect.toggleSelection({
                _id: row._id,
                classType: row.classType
            })
        }
    }

    function isSelected(key: string){
        if (props.multiSelect.isSelected){
            return props.multiSelect.isSelected(key);
        }
        return false
    }

    const renderFlight = (_, record: any) => {
        const carrier = record.currentCarrierID;
        if (!carrier?.desig) return "—";

        const [ bgColor, textColor ] = commonColumns.pax.getColor(record.currentCarrierID._id);

        return (
            <Button
                style={{ backgroundColor: bgColor, color: textColor }}
                onClick={() => setFlightPreviewState({ ...flightPreviewState, flightID: record.currentCarrierID?._id })}
                size="small"
                block
            >{carrier.desig}</Button>
        );
    }

    const PaxColSet = [
        {
            title:'',
            key: 'action',
            render: (_, row) => {
                const record = paxData.find((pax) => pax._id === row._id);
                let checked = record ? isSelected(record._id) : false;
                const checkProps: CheckboxProps = {
                    checked,
                    onClick: () => {
                        toggleSelection(record);
                    }
                }
                return <Checkbox {...checkProps} disabled={!!row?.currentCarrierID} />
            }
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, record) => {
                let xbr;

                let icon = null;

                let spanClassName = record.currentCarrierID ? BASE_CLS + '-on-flight' : '';

                if (record.currentCarrierID){
                    icon = <Tooltip title="This pasenger is currently on a flight.">
                        <a
                            href={"/app/scheduling/flight/" + record.currentCarrierID?._id}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push("/app/scheduling/flight/" + record.currentCarrierID?._id)
                            }}
                            style={{ marginRight: '6px', position: 'relative' }}
                        >
                            <MCIcon type="helicopter-flight" />
                        </a>
                    </Tooltip>
                }

                if(!props.editMode){
                    var data;
                    if (record.__typename === 'Person')
                    {
                        data = {
                            ...record,
                            personID: { ...record }
                        }
                    }
                    else
                    {
                        data = record
                    }
                    if(data.personID){
                        xbr = data.personID.extraBroadState;
                    }else {
                        xbr = record.extraBroadState;
                    }
                    let name = `${record.lastName}, ${record.firstName}`
                    if(xbr === 'FAIL'){
                        name = `\u2194 ${record.lastName}, ${record.firstName}`
                    }else if(xbr === 'PASS'){
                        name = `\uc6c3 ${record.lastName}, ${record.firstName}`
                    }
                    return <span className={spanClassName}>
                        {icon}
                        <Button className={'mc-link-btn ' + spanClassName} onClick={() => {
                            setPaxModal(PaxModalState(true, record._id))
                        }}>{name}</Button>
                    </span>
                }
                xbr = record.extraBroadState || (record.personID && record.personID.extraBroadState)

                let text;
                if(xbr === 'FAIL'){
                    text = `\u2194 ${record.lastName}, ${record.firstName}`
                }else if(xbr === 'PASS'){
                    text = `\uc6c3 ${record.lastName}, ${record.firstName}`
                }else {
                    let name = record.lastName + ", " + record.firstName
                    text = <span>{name}</span>
                }

                return <span className={spanClassName}>
                    {icon}
                    {text}
                </span>;
            }
        },
        {
            title: 'Nation',
            key: 'nation',
            render: (_, row) => row.personID && row.personID.nation
        },
        {
            title: 'Employer',
            key: 'employer',
            render: (_, record) => record.employerID && record.employerID.name
        },
        {
            title: 'Pax Weight',
            key: 'paxWeight',
            dataIndex: 'paxWeight',
            editable: true,
            render: (weight, row) => {
                if (!props.editMode) return weight || 0;
                return <InputNumber
                    min={0}
                    onChange={(value) => props.onCellEdit(row._id, 'paxWeight', value || 0)}
                    size="small"
                    value={weight || 0}
                    disabled={!!row?.currentCarrierID}
                />
            }
        },
        {
            title: 'Bag Weight',
            key: 'bagWeight',
            dataIndex: 'bagWeight',
            editable: true,
            render: (weight, row) => {
                if (!props.editMode) return weight || 0;
                return <InputNumber
                    min={0}
                    onChange={(value) => props.onCellEdit(row._id, 'bagWeight', value || 0)}
                    size="small"
                    value={weight || 0}
                    disabled={!!row?.currentCarrierID}
                />
            }
        },
        {
            title: 'Bag Count',
            key: 'bagCount',
            dataIndex: 'bagCount',
            editable: true,
            render: (count, row) => {
                if (!props.editMode) return count || 0;
                return <InputNumber
                    min={0}
                    onChange={(value) => props.onCellEdit(row._id, 'bagCount', value || 0)}
                    size="small"
                    value={count || 0}
                    disabled={!!row?.currentCarrierID}
                />
            }
        },
        {
            title: 'Charge Code',
            key: 'chargeCode',
            dataIndex: 'chargeCode',
            editable: true,
            render: (code, row) => {
                if (!props.editMode) return code && code.toUpperCase();
                return (
                    <Input
                        min={0}
                        value={code}
                        placeholder={props.defaultChargeCode && props.defaultChargeCode.toUpperCase()}
                        onChange={(event) => props.onCellEdit(row._id, 'chargeCode', event.target.value && event.target.value.split(" ").join("").toUpperCase())}
                        size="small"
                    />
                )
            }
        },
        {
            title: 'ISN Status',
            key: 'isnStatus',
            render: (_, row) => {
                return <IsnCheckStatusButton
                    style={{ pointerEvents: props.editMode ? 'none' : undefined }}
                    pax={row}
                    size="small"
                    block
                    checking={row.isnCheckRunning}
                    onClick={() => {
                        if (!props.editMode) {
                            setIsnDrawer(IsnDrawerState(true, row._id))
                        }
                    }}
                />
            }
        },
        {
            title: 'Assigned Flight',
            key: 'assignedFlight',
            width: 200,
            render: renderFlight
        }
    ]

    const CgoColSet = [
        {
            title:'',
            key: 'action',
            render: (_, row) => {
                const record = cgoData.find((cgo) => cgo._id === row._id);
                let checked = record ? isSelected(record._id) : false;
                const checkProps: CheckboxProps = {
                    checked,
                    onClick: () => {
                        toggleSelection(record);
                    }
                }
                return <Checkbox {...checkProps} disabled={!!row?.currentCarrierID} />
            }
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, record) => {

                let spanClassName = record.currentCarrierID ? BASE_CLS + '-on-flight' : '';

                if (record.currentCarrierID){
                    return <span className={spanClassName}>
                        <Tooltip title="This cargo is currently on a flight.">
                            <MCIcon type="helicopter-flight" style={{ marginRight: '6px', position: 'relative' }} />
                        </Tooltip>
                        {record.name}
                    </span>
                }

                return <span>{record.name}</span>
            }
        },
        {
            title: 'Weight',
            key: 'weight',
            dataIndex: 'weight',
            editable: true,
            render: (weight, row) => {
                if (!props.editMode) return weight || 0;
                return <InputNumber
                    min={0}
                    onChange={(value) => props.onCellEdit(row._id, 'weight', value || 0)}
                    size="small"
                    value={weight || 0}
                    disabled={!!row?.currentCarrierID}
                />
            }
        },
        {
            title: 'HazMat',
            key: 'hazmat',
            render: (_, record) => record.hazmat ? record.hazmatUnCode || "YES" : null
        },
        {
            title: 'Attention',
            key: 'attentionTo',
            render: (_, record) => record.attentionTo
        },
        {
            title: 'Initials',
            key: 'initials',
            render: (_, record) => record.initials
        },
        {
            title: 'Notes',
            key: 'notes',
            render: (_, record) => record.notes
        },
        {
            title: 'Assigned Flight',
            key: 'assignedFlight',
            width: 200,
            render: renderFlight
        }
    ]

    return <>
        <Collapse
            defaultActiveKey={['1', '2']}
            className="ant-collapse-flex paxcgo-scheduler-collapse"
            style={{ height: '100%' }}
            bordered={true}
        >
            <Panel
                disabled={true}
                key="1"
                header={props.paxPanelHeader || 'Passengers'}
                extra={props.renderPaxPanelExtra}
            >
                <div
                    style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
                >
                    <ETable
                        className={BASE_CLS}
                        dataSource={paxData}
                        columns={PaxColSet.filter((col) => {
                            if(!props.editMode && col.key === 'action'){
                                return false;
                            }
                            if(!hasOutboundPax && col.key === 'isnStatus'){
                                return false;
                            }
                            return true;
                        })}
                        pagination={false}
                        rowKey={record => record._id}
                        size="small"
                    />
                </div>
            </Panel>
            <Panel
                key="2"
                header={`Inbound Cargo (${cgoData.length}). Weight: ${cgoData.reduce((weight: number, v: any) => {
                    if (v){
                        return weight + v.weight
                    }
                    else
                    {
                        return weight
                    }
                }, 0)}`}
            >
                <ETable
                    className={BASE_CLS}
                    dataSource={cgoData}
                    columns={CgoColSet.filter((col) => {
                        if(!props.editMode && col.key === 'action'){
                            return false;
                        }
                        return true;
                    })}
                    pagination={false}
                    rowKey={record => record._id}
                    size="small"
                />
            </Panel>
        </Collapse>
        <Modal
            title="Edit Passenger"
            visible={paxModal.visible}
            onCancel={() => setPaxModal(PaxModalState(false, paxModal.paxId))}
            footer={null}
            width="50rem"
        >
            <PassengerEditor
                title="Edit Passenger"
                edit={true}
                data={paxData.find(p => p._id === paxModal.paxId)}
                onCancel={() => setPaxModal(PaxModalState(false, paxModal.paxId))}
            />
        </Modal>
        {!props.editMode ? renderIsnDrawer() : null}
        <Query
            query={gql`
            query GetPaxName($id: ID!){
                getPassenger(_id: $id){
                    _id
                    lastName
                    firstName
                }
            }
            `}
            fetchPolicy="cache-and-network"
            variables={{
                id: isnCheckDrawer.id
            }}
            skip={!isnCheckDrawer.id || !isnCheckDrawer.open}
        >
        {({ data, loading }) => {
            let name: string;
            if (data && data.getPassenger){
                let pax = data.getPassenger;
                name = `${pax.lastName}, ${pax.firstName}`
            }
            return <Drawer
                closable
                onClose={() => setIsnCheckDrawer({ ...isnCheckDrawer, open: false })}
                title={!loading && name ? name : <Spin indicator={<Icon type="loading" />} /> }
                visible={isnCheckDrawer.open}
                width="40rem"
            >
                <PaxISNWithData paxId={isnCheckDrawer.id} />
            </Drawer>
        }}
        </Query>
        <Drawer
            title="View Flight"
            visible={Boolean(flightPreviewState.flightID)}
            destroyOnClose
            width="35%"
            onClose={() => setFlightPreviewState({ ...flightPreviewState, flightID: null })}
        >
            <FlightPreview id={flightPreviewState?.flightID} />
        </Drawer>
    </>
}

export default PaxCgoSchedulerTable