import React from "react";
import {produce} from "immer";
import {message} from "antd";
import {CreateUndoRedoReducer, UndoRedoState} from "common/reducer/undo-redo";

export const DefaultState = {
    initPath: [] as string[],
    path: {
        present: [],
        past: [],
        future: []
    } as UndoRedoState<string[]>,
    redoSteps: [],
    changed: false
}

export type State = typeof DefaultState;

export type Action = {
    type: 'INIT',
    payload: {
        flightPath: string[],
    }
} | {
    type: 'RESET'
} | {
    type: 'UPDATE_ROUTE',
    payload: {
        removeIndex?: number,
        add?: {
            locId: string,
            insertIndex?: number
        }
    }
} | {
    type: 'MOVE',
    payload: {
        startIndex: number,
        endIndex: number
    }
} | {
    type: 'UNDO' | 'REDO'
}

export const Reducer: React.Reducer<State, Action> = (prevState, action) => {
    return produce(prevState, (state) => {

        const PathReducer = CreateUndoRedoReducer<string[]>();

        switch (action.type){
            case "INIT": {
                const fp = [...action.payload.flightPath];
                state.initPath = fp;
                state.path = {
                    past: [],
                    present: [...fp],
                    future: []
                }
                state.changed = false;
                return state;
            }
            case "RESET": {
                state.path = {
                    past: [],
                    present: [...state.initPath],
                    future: []
                };
                state.changed = false;
                return state;
            }
            case "UPDATE_ROUTE": {
                const fp = [...state.path.present];
                if (action.payload.removeIndex) {
                    fp.splice(action.payload.removeIndex, 1);
                }
                if (action.payload.add) {
                    fp.splice(
                        action.payload.add.insertIndex || fp.length,
                        0,
                        action.payload.add.locId
                    )
                }
                state.path = PathReducer(prevState.path, { type: 'UPDATE', payload: fp })
                break;
            }
            case "MOVE": {
                const {startIndex, endIndex} = action.payload;
                if (
                    startIndex === endIndex ||
                    startIndex < 0 ||
                    endIndex >= state.path.present.length
                ) return state;

                const fp = [...state.path.present];

                let error: string;

                if (endIndex === 0 || startIndex === 0){
                    error = 'Cannot displace flight departure.';
                }

                const items = fp.splice(startIndex, 1);
                fp.splice(endIndex, 0, ...items);

                if (error) {
                    message.error(error);
                    return state;
                }

                state.path = PathReducer(prevState.path, { type: 'UPDATE', payload: fp });
                break;
            }
            case "UNDO":
                state.path = PathReducer(prevState.path, { type: 'UNDO' });
                break;
            case "REDO":
                state.path = PathReducer(prevState.path, { type: 'REDO' });
                break;
            default:
                return prevState;
        }
        state.changed = true;
        return state;
    })
}