import React, { Component, CSSProperties } from 'react';
import config from 'config';
import debounce from 'lodash/debounce';
import { Icon, message, Select, Spin, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import './style.less';
import { SelectProps } from 'antd/lib/select';
import { getLabelInValueKey } from 'common/form';

const classTypeMap = {
    'flytsuite.customer': 'Customer',
    'flytsuite.transporter': 'Transporter',
    'flytsuite.employer': 'Contractor'
}

export interface OrgSearchProps extends SelectProps<any> {
    orgData?: any,
}

class OrgSearch extends Component<OrgSearchProps> {
    state = {
        search: null,
        loading: null,
        result: [],
        error: null,
        currOrgData: null
    }

    setError = (msg) => {
        this.setState({ error: String(msg), loading: false });
        message.error("An error occurred while loading organization list. Please contact DataFlyt if this persists.");
    }

    getCurrOrgType = () => {
        fetch(`${config.pythonBackendApi}/organization/${getLabelInValueKey(this.props.value)}/classType,name`)
        .then(async (resp) => {
            if (resp.ok){
                let res = await resp.json();
                this.setState({ currOrgData: { classType: res[0], name: res[1] }});
            }
            else
            {
                console.error('Failed to load currOrgType')
            }
        })
    }

    componentDidMount(): void {
        this.getCurrOrgType();
    }

    componentDidUpdate(prevProps: Readonly<OrgSearchProps>): void {
        if (getLabelInValueKey(prevProps.value) !== getLabelInValueKey(this.props.value)){
            this.getCurrOrgType();
        }
    }

    getOrganizations = () => {
        if (!this.state.search){
            return [];
        }
        this.setState({ loading: true });
        fetch(`${config.pythonBackendApi}/organizations?limit=30${this.state.search ? `&filter_name=${encodeURIComponent(this.state.search)}` : ''}`, {
            mode: 'cors'
        })
        .then(async resp => {
            if (resp.ok){
                let data = await resp.json();
                this.setState({ result: data, loading: false });
            }
            else {
                this.setError(resp.text)
            }
        })
        .catch(err => this.setError(String(err)));
    }

    debouncedGetOrganizations = debounce(this.getOrganizations, 500)

    render(){

        let notFoundContent = null;

        if (this.state.loading){
            notFoundContent = <Spin indicator={<Icon type="loading" />} />
        }
        else if (this.state.search && !this.state.error && this.state.result?.length  === 0){
            notFoundContent = "No results found";
        }
        else if (this.state.error){
            notFoundContent =  <Typography.Text type='danger'>Failed to load list</Typography.Text>
        }

        let value = this.props.value;

        if (!this.props.labelInValue && this.props.value){
            value = { key: this.props.value, label: '' }
        }

        if (this.state.currOrgData){
            console.debug('reached here')
            let style: CSSProperties = { fontSize: '0.75rem', color: 'grey', float: 'right', marginLeft: '1rem' }
            let orgType = classTypeMap[this.state.currOrgData.classType] || '';
            let label = <span>
                {this.state.currOrgData.name} <Text style={style} type="secondary">{orgType}</Text>
            </span>
            value = { ...value, label: label }
        }

        return <Select
            className="mc-signup-org-search"
            {...this.props}
            value={value}
            onSearch={(val) => {
                this.setState({ search: val }, this.debouncedGetOrganizations);
            }}
            showSearch
            filterOption={null}
            showAction={["focus", "click"]}
            loading={this.state.loading}
            placeholder="Type to search"
            notFoundContent={notFoundContent}
            onChange={(value) => {
                let v = value;
                if (this.props.labelInValue && Array.isArray(value.label)){
                    v = {
                        key: value.key,
                        label: value.label[0]
                    }
                }
                else if (!this.props.labelInValue){
                    v = getLabelInValueKey(value)
                }
                if (this.props.onChange){
                    // @ts-ignore
                    this.props.onChange(v);
                }
            }}
            allowClear
            labelInValue
        >
        {!this.state.error && this.state.result?.map((item) => <Select.Option key={item.id} value={item.id} label={item.name}>
            {item.name} <Text style={{ fontSize: '0.75rem', color: 'grey', float: 'right', marginLeft: '1rem' }} type="secondary">{classTypeMap[item.classType]}</Text>
        </Select.Option>)}
        {!this.state.error && this.state.result?.length === 30 ? (
            <Select.OptGroup label="Max results reached" />
        ) : null}
        </Select>
    }
}

export default OrgSearch