import {Alert, Col, Icon, Row, Tabs, Typography} from 'antd';
import gql from 'graphql-tag';
import React from 'react';
import {Query} from 'react-apollo';
import LoadingContent from '../../LoadingContent';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ETable from 'components/enchanced-antd-table';
import {FlightLeg} from "schema";
import {MultiSelectReturn} from "../../../hooks/useMultiSelect";
import {ColumnProps} from "antd/lib/table";
import {createLegListSelectColumn} from "./FlightScheduleUtils";

library.add(faBoxOpen);

const GET_PAXNODE_LIST = gql`
query GetPaxNodeSet($paxIds: [ID], $cgoIds: [ID]){
    getPaxNodeList(ids: $paxIds){
        _id
        lastName
        firstName
        employerID {
            _id
            name
        }
        paxWeight
        bagWeight
        bagCount
        scheduledGroup
        scheduledOrder
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        transitType
        classType
    }
    getCargoNodeList(ids: $cgoIds){
        _id
        name
        weight
        scheduledGroup
        scheduledOrder
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        transitType
        classType
    }
}
`

type TEMP_TYPE_Pax = any;
type TEMP_TYPE_Cgo = any;
type TEMP_TYPE_Entity = TEMP_TYPE_Pax | TEMP_TYPE_Cgo

type TEMP_TYPE_Pax_Cgo_Query = {
    getPaxNodeList: TEMP_TYPE_Cgo[],
    getCargoNodeList: TEMP_TYPE_Cgo[]
}

export interface FlightManualScheduleLegPaxProps {
    selectedLegs: FlightLeg[],
    multiSelect?: MultiSelectReturn<TEMP_TYPE_Entity>
}

const FlightManualScheduleLegPax: React.FC<FlightManualScheduleLegPaxProps> = (props) => {

    const { multiSelect, selectedLegs } = props;

    function renderLegData(selectedLeg: FlightLeg) {

        if (!selectedLeg) {
            return <h2 style={{marginTop: '10rem', textAlign: 'center', color: 'grey'}}>No leg data available</h2>
        }

        const { departure, destination, paxIDs, cgoIDs } = selectedLeg;

        const columns: ColumnProps<TEMP_TYPE_Entity>[] = [
            {
                title: 'Scheduled Group',
                key: 'scheduledGroup',
                dataIndex: 'scheduledGroup'
            },
            {
                title: 'Name',
                key: 'name',
                render: (_, record) => {
                    let iconStyle = {
                        marginRight: 6
                    }
                    if (record.__typename === 'CargoNode') {
                        return <span><FontAwesomeIcon icon={faBoxOpen} style={iconStyle}/> {record.name}</span>
                    }
                    return <span><Icon type="user" style={iconStyle}/>{record.lastName}, {record.firstName}</span>
                }
            },
            {
                title: 'Employer',
                key: 'employer',
                render: (_, record) => {
                    if (record.classType === 'flytsuite.paxnode') {
                        return record.employerID ? record.employerID.name :
                            <span style={{color: 'red'}}>Not Found</span>
                    } else if (record.classType === 'flytsuite.cgonode') {
                        return <Typography.Text type="secondary">N/A</Typography.Text>
                    }
                    return null
                }
            },
            {
                title: 'Weight',
                key: 'weight',
                render: (_, record) => {
                    if (record.__typename === 'CargoNode') {
                        return `${record.weight}`
                    }
                    return `${record.paxWeight}`
                }
            },
            {
                title: 'Bag Weight',
                key: 'bagWeight',
                dataIndex: 'bagWeight'
            },
            {
                title: 'Bag Count',
                key: 'bagCount',
                dataIndex: 'bagCount'
            },
            {
                title: 'Destination',
                key: 'destination',
                render: (_, record) => record.destinationID ? record.destinationID.name :
                    <span style={{color: 'red'}}>Not Found</span>
            },
            {
                title: 'Transit Type',
                key: 'transitType',
                dataIndex: 'transitType'
            }
        ]

        return (
            <Query<TEMP_TYPE_Pax_Cgo_Query> query={GET_PAXNODE_LIST} variables={{paxIds: paxIDs, cgoIds: cgoIDs}}>
                {
                    ({ data, loading, error }) => {
                        if (loading) return <LoadingContent />
                        if (error) return <Alert type="error" message="Failed to load passengers or cargo" description={error.message} showIcon />

                        let combinedList: TEMP_TYPE_Entity[] = []
                        if(data.getPaxNodeList.length > 0){
                            combinedList = combinedList.concat(data.getPaxNodeList)
                        }
                        if(data.getCargoNodeList.length > 0){
                            combinedList = combinedList.concat(data.getCargoNodeList)
                        }

                        if (multiSelect){
                            columns.unshift(createLegListSelectColumn(multiSelect, combinedList));
                        }

                        return (
                            <React.Fragment>
                                <Row type="flex" style={{marginBottom: '1rem'}} gutter={12}>
                                    <Col style={{flex: 1}}>
                                        <h3 style={{margin: 0}}>
                                            {departure && destination ?
                                                `${departure} to ${destination}` :
                                                ''
                                            }
                                        </h3>
                                    </Col>
                                </Row>
                                <ETable
                                    className='mc-table'
                                    dataSource={combinedList}
                                    columns={columns}
                                    rowKey={(record: TEMP_TYPE_Entity) => record._id}
                                    onRowClick={(record: TEMP_TYPE_Entity) => {
                                        multiSelect?.toggleSelection(record);
                                    }}
                                    rowClassName={() => "mc-cursor-pointer"}
                                    size="small"
                                    pagination={false}
                                />
                            </React.Fragment>
                        )
                    }
                }
            </Query>
        )
    }

    if (!selectedLegs || selectedLegs.length === 0){
        return <h2 style={{marginTop: '10rem', textAlign: 'center', color: 'grey'}}>No legs selected</h2>
    }
    return (
        <Tabs type='card'>
            {selectedLegs.map(leg => (
                <Tabs.TabPane key={String(leg.order)} tab={"Leg #" + (leg.order+1)}>
                    {renderLegData(leg)}
                </Tabs.TabPane>)
            )}
        </Tabs>
    )
}

export default FlightManualScheduleLegPax;