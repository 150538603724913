import {Alert, Button, Card, Col, Icon, message, Row, Spin, Table} from "antd";
import Text from 'antd/lib/typography/Text';
import { cleanGraphQLErrorMsg, safeGet } from 'common/util';
import withFlightTimesEntryDrawer from 'components/flights/times/WithFlightTimesEntryDrawer';
import useManifestPrintout from 'components/scheduling/Printout/useManifestPrintout';
import withCognitoUser from 'components/WithCognitoUser';
import WithOrgData from "components/WithOrgData";
import gql from 'graphql-tag';
import moment from "moment";
import React, { useState } from 'react';
import { Query, useApolloClient, useQuery } from 'react-apollo';
import { compose } from 'redux';
import { useInfiniteScroll } from "hooks/infinite-scroller/infinite-scroller";
import {CouchdbMangoAdapter} from "../../../hooks/infinite-scroller/adapter/couchdb-mango-adapter";
import {NetworkStatus} from "apollo-boost";
import CenteredLoadingScreen from "../../CenteredLoadingScreen";

const FINALIZED_FLIGHTS = gql`
query FinalizedFlights(
    $tpID: ID!, 
    $customerID: ID, 
    $startDate: AWSDate!, 
    $endDate: AWSDate!, 
    $departureID: ID,
    $bookmark: String
){
    getFinalizedFlights(
        tpID: $tpID
        customerID: $customerID
        startDate: $startDate
        endDate: $endDate
        departureID: $departureID
        limit: 100
        bookmark: $bookmark
    ){
        execution_stats {
            execution_time_ms
            results_returned
            total_keys_examined
            total_docs_examined
            total_quorum_docs_examined
        }
        bookmark
        docs {
            _id
            modifiedTs
            modifiedBy
            ... on HistoryFlightNode {
                _id
                desig
                legs
                firstLegUsableWt
                scheduledFlightDate
                lastKnownController {
                    _id
                    name
                }
                legsArray {
                    paxCount
                    paxWeight
                    flightTime
                },
                paxIDList {
                    _id
                    chargeCode
                    transitType
                },
                cgoIDList {
                    _id
                    chargeCode
                    transitType
                },
                aircraftID {
                    _id
                    tailNum
                    maxSeats
                    maxLoad
                }
            }
        }
    }
}
`

const FlightTable = ({data, flightDrawer, refetch}, ...rest) => {
    const columns = [
        {
            title: 'Name',
            key: 'desig',
            render: (_, record) => {
                return `${record.desig}`
            },
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                if(a.desig.toLowerCase() > b.desig.toLowerCase()){
                    return -1;
                }
                if(a.desig.toLowerCase() < b.desig.toLowerCase()){
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Tail No.',
            key: 'tailNum',
            render: (_, record) => record?.aircraftID?.tailNum
        },
        {
            title: 'Departure',
            key:'departure',
            render: (_, record) => {
                var legs = JSON.parse(record.legs);
                if('0' in legs){
                    return legs['0'].departure;
                }

                return 'None';
            },
            sorter: (a, b) => {
                var legsA = JSON.parse(a.legs);
                var legsB = JSON.parse(b.legs);

                var depA = "None";
                var depB = "None";
                if('0' in legsA){
                    depA = legsA['0'].departure;
                }
                if('0' in legsB){
                    depB = legsB['0'].departure;
                }

                if(depA.toLowerCase() > depB.toLowerCase()){
                    return -1;
                }
                if(depA.toLowerCase() < depB.toLowerCase()){
                    return 1;
                }
                return 0;
            },
        },
        {
            title: 'Pax Out',
            key:'paxOut',
            render: (_, record) => {
                var count = 0;
                record.paxIDList.forEach(pax => {
                    if(pax.transitType === "OUTBOUND"){
                        count+=1;
                    }
                })
                return count;
            }
        },
        {
            title: 'Pax In',
            key:'paxIn',
            render: (_, record) => {
                var count = 0;
                record.paxIDList.forEach(pax => {
                    if(pax.transitType === "INBOUND"){
                        count+=1;
                    }
                })
                return count;
            }
        },
        {
            title: 'Cgo Out',
            key:'cgoOut',
            render: (_, record) => {
                var count = 0;
                record.cgoIDList.forEach(cgo => {
                    if(cgo.transitType === "OUTBOUND"){
                        count+=1;
                    }
                })
                return count;
            }
        },
        {
            title: 'Cgo In',
            key:'cgoIn',
            render: (_, record) => {
                var count = 0;
                record.cgoIDList.forEach(cgo => {
                    if(cgo.transitType === "INBOUND"){
                        count+=1;
                    }
                })
                return count;
            }
        },
        {
            title: 'Seat %',
            key: 'seatPercent',
            render: (_, record) => {
                if (!record || !record.aircraftID){
                    return null
                }
                if(record.legsArray && record.legsArray[0] && record.aircraftID.maxSeats){
                    const seats = record.aircraftID.maxSeats || 0
                    const paxCount = record.legsArray[0].paxCount

                    const num = ((paxCount/seats)*100).toFixed(2)

                    if(num === 'NaN' || num === 'Infinity' || num < 0){
                        return null
                    }

                    return num
                }

                return null;
            }
        },
        {
            title: 'Weight %',
            key: 'weightPercent',
            render: (_, record) => {
                if (!record){
                    return null
                }
                if(record.legsArray && record.legsArray[0]){
                    const paxWeight = record.legsArray[0].paxWeight
                    const firstLegUsableWeight = record.firstLegUsableWt || 0
                    const aircraftMaxLoad = record?.aircraftID?.maxLoad || 0;

                    // If the user didn't enter a firstLegUsableWt, then use the aircraft maxLoad instead.
                    // From https://dataflyt.atlassian.net/browse/MCR-1116
                    let usefulLoad = firstLegUsableWeight;
                    if (firstLegUsableWeight <= 0){
                        usefulLoad = aircraftMaxLoad;
                    }

                    const num = ((paxWeight/usefulLoad)*100).toFixed(2)
                    
                    if(num === 'NaN' || num === 'Infinity' || num < 0){
                        return null
                    }
                    
                    return num
                }

                return null;
            }
        },
        {
            title: 'Flight Time',
            key:'flightTime',
            render: (_, record) => {
                var legs = record.legsArray;
                var noTimeEntered = legs.every(leg => leg.flightTime === null || leg.flightTime === 0);
                var paxNCgo = record.paxIDList.concat(record.cgoIDList)
                var noChargeCode = paxNCgo.length > 0 && paxNCgo.some(obj => !obj.chargeCode || obj.chargeCode === null)
                if(noTimeEntered){
                    return {
                        props: {
                            style: {background:'red', color: 'black'}
                        },
                        children: noChargeCode ? "Missing Charge Codes. No Time Entered." : "No Time Entered"
                    }
                }

                var legNoTime = legs.some(leg => leg.flightTime === null || leg.flightTime === 0);
                var time = 0;
                legs.forEach(leg => {
                    if(Number.isInteger(leg.flightTime)){
                        time += leg.flightTime;
                    }
                })
                if(legNoTime){
                    return {
                        props: {
                            style: {background: noChargeCode? 'red' : 'yellow', color: 'black'}
                        },
                        children: noChargeCode ? time + " | Leg With No Time. Missing Charge Codes" : time + " | Leg With No Time"
                    }
                }

                if(noChargeCode){
                    return {
                        props: {
                            style: {background:'red', color: 'black'}
                        },
                        children: "Missing Charge Codes"
                    }
                }

                return {
                    props: {
                        style: {background:'green', color:'white'}
                    },
                    children: time
                }
            }
        }
    ]
    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey={record => record._id}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        flightDrawer.open(record._id, refetch)
                    },
                    style: { cursor: 'pointer' }
                }
            }}
            pagination={false}
            size="middle"
            style={{marginBottom: '1rem'}}
            bordered
            footer={flightData => {
                var totalFlightTime = 0;
                flightData.forEach((flight) => {
                   flight.legsArray.forEach(leg => {
                        if(Number.isInteger(leg.flightTime)){
                            totalFlightTime += Number.parseInt(leg.flightTime)
                        }
                    })
                })
                return (<div>
                    <Text strong={true}>Total Daily Flight Time: </Text>
                    <Text style={{color:'yellow', fontWeight:"bold"}}>{totalFlightTime}</Text>
                  </div>);

            }}
            {...rest}
        />
    )
}

const DayGroupCard = ({data, flightDrawer, refetch}, ...rest ) => {
    return (
        <Card
            style={{marginBottom: '1rem'}}
            type="inner"
            title={<h3>{data[0]}</h3>}
            {...rest}
        >
            <FlightTable refetch={refetch} data={data[1]} flightDrawer={flightDrawer}/>
        </Card>
    )
}

const FlightTimes = (props) => {
    const queryResult = useQuery(FINALIZED_FLIGHTS,
    {
        variables: {
            tpID: props.orgData.transporter._id,
            customerID: props.orgData.customer._id,
            startDate: props.dateRange[0].format('YYYY-MM-DD'),
            endDate: props.dateRange[1].format('YYYY-MM-DD'),
            departureID: props.departureID && props.departureID.key
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    const mangoAdapter = new CouchdbMangoAdapter({
        query: FINALIZED_FLIGHTS,
        getMangoQueryResult: (data) => data?.getFinalizedFlights,
        queryResult: queryResult
    });

    const { sentryRef, hasNextPage } = useInfiniteScroll({
        adapterImpl: mangoAdapter,
        rootMargin: '0px 0px 600px 0px'
    });

    const { data, networkStatus, error, refetch } = queryResult;

    const loading = networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.setVariables ||
        networkStatus === NetworkStatus.refetch;

    if (loading) return <CenteredLoadingScreen style={{ minHeight: '10rem' }} />

    const getFinalizedFlights = data?.getFinalizedFlights;

    var groups = {};

    if(getFinalizedFlights && getFinalizedFlights.docs && getFinalizedFlights.docs.length > 0){
        getFinalizedFlights.docs.forEach(currentFlight => {
            if(!(currentFlight.scheduledFlightDate in groups)){
                groups[currentFlight.scheduledFlightDate] = [currentFlight];
            }else{
                groups[currentFlight.scheduledFlightDate].push(currentFlight);
            }
        });

    }

    return (
        <div>
            {error ? <Alert
                    type="error"
                    style={{ marginTop: '12px' }}
                    message="Failed to display finalized flights."
                    description={String(error.message).includes('too large') ? '' + cleanGraphQLErrorMsg(error.message) + '. Please narrow your date range selection.' : null}
                    showIcon/>
                : null}
            {!error && Object.keys(groups).length === 0 ? "No Finalized Flights Found." : null}
            {!error && Object.keys(groups).length > 0 ? (
                <>
                    <Row gutter={12} style={{ padding: '12px 0', width: 'fit-content' }}>
                        <Col span={12}>
                            <PrintoutTimesButton {...props}/>
                        </Col>
                        <Col span={12}>
                            <PrintoutPercentageButton {...props}/>
                        </Col>
                    </Row>
                    {Object.entries(groups).map((group, i) => {
                        return <DayGroupCard refetch={refetch} data={group} key={i} flightDrawer={props.flightDrawer} />
                    })}
                    <div
                        className="sentryRef"
                        ref={sentryRef}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '7rem',
                            width: '100%'
                        }}
                    >
                        {queryResult.networkStatus === NetworkStatus.fetchMore && hasNextPage ? (
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Spin size="large" indicator={<Icon type="loading" />} />
                                </div>
                                <div>Above data may be incomplete.</div>
                                <div>Please wait for next page.</div>
                            </div>
                        ) : null}
                        {queryResult.networkStatus === NetworkStatus.ready && !hasNextPage ? (
                            <div>End of results.</div>
                        ) : null}
                    </div>
                </>
            ) : null}

        </div>
    )
}

const FINALIZED_FLIGHTS_FULL = gql`
query FinalizedFlights($tpID: ID!, $customerID: ID, $startDate: AWSDate!, $endDate: AWSDate!, $departureID: ID){
    getFinalizedFlights(
        tpID: $tpID
        customerID: $customerID
        startDate: $startDate
        endDate: $endDate
        departureID: $departureID
    ){
        execution_stats {
            execution_time_ms
            results_returned
            total_keys_examined
            total_docs_examined
            total_quorum_docs_examined
        }
        docs {
            _id
            modifiedTs
            modifiedBy
            ... on HistoryFlightNode {
            _id
            originalFlightID
            desig
            legs
            timeOfDeparture
            scheduledFlightDate
            scheduleType
            firstLegUsableWt
            lastKnownController {
                _id
                name
            }
            legsArray {
                flightTime
                order
                departure
                departureID
                destination
                destinationID
                paxCount
                paxWeight
                cgoCount
                paxIDs {
                    _id,
                    firstName,
                    lastName,
                    paxWeight,
                    chargeCode,
                    individualLegTime,
                    departureDoc {
                        _id
                        name
                    }
                    destinationDoc {
                        _id
                        name
                    }
                    transitType
                }
                cgoIDs {
                    _id
                    name
                    approvedBy
                    departureDoc {
                        _id
                        name
                    }
                    destinationDoc {
                        _id
                        name
                    }
                    weight
                    transitType
                    deliveredBy
                }
            },
            pilot {
                _id
                name {
                    firstName,
                    lastName
                },
            },
            copilot {
                _id
                name {
                    firstName,
                    lastName
                },
            },
            contract {
                _id
                name
            },
            paxIDList {
                _id,
                firstName,
                lastName,
                chargeCode,
                individualLegTime,
                departureDoc {
                    _id
                    name
                }
                employerDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                transitType
            },
            cgoIDList {
                _id
                name
                chargeCode,
                approvedBy
                individualLegTime,
                departureDoc {
                    _id
                    name
                }
                destinationDoc {
                    _id
                    name
                }
                weight
                transitType
                deliveredBy
            },
            aircraftID {
                _id,
                model,
                tailNum,
                maxSeats,
                maxLoad
            }
        }
    }
}}`

var PrintoutTimesButton = ({...props}) => {
    const [ fetchPrintout, fetching ] = useManifestPrintout('FlightTimes', 'FlightTimes');
    const [ printoutLoading, setPrintOutLoading ] = useState(false);
    const ApolloClient = useApolloClient();

    const userAttr = props.cognitoUser.attributes;
    const orgData = props.orgData;

    async function getFullFinalizedFlightsData(pageSizeWeeks=2){

        /** @type Moment */
        let startDate = props.dateRange[0];
        /** @type Moment */
        let endDate = props.dateRange[1];

        let duration = moment.duration(endDate.diff(startDate));
        let noWeeks = Math.ceil(duration.as('weeks'));

        setPrintOutLoading(true);
        function getDataPage(subStartDate, subEndDate){
            return {
                execute: () => {
                    return ApolloClient.query({
                        variables: {
                            tpID: props.orgData.transporter._id,
                            customerID: props.orgData.customer._id,
                            startDate: subStartDate.format('YYYY-MM-DD'),
                            endDate: subEndDate.format('YYYY-MM-DD'),
                            departureID: props.departureID && props.departureID.key
                        },
                        query: FINALIZED_FLIGHTS_FULL,
                        fetchPolicy: 'no-cache'
                    })
                }
            };
        }

        // Fetch each page of data two pages at a time using two queues.
        const pageQueue = [];
        const results = [];
        let executeQueue = [];

        for (let pageNum = 0; pageNum <= noWeeks; pageNum+=pageSizeWeeks) {
            let subStartDate = moment(startDate).add(pageNum, 'weeks');
            if (pageNum !== 0){
                subStartDate.add(1, 'day'); // Prevent overlapping between pages
            }
            let subEndDateCandidate = moment(startDate)
                .add(pageNum + pageSizeWeeks, 'weeks');
            
            let subEndDate = subEndDateCandidate.isAfter(endDate, 'date') ? endDate : subEndDateCandidate;
            if (subStartDate.isAfter(subEndDate, 'date')){
                continue;
            }
            const page = getDataPage(subStartDate, subEndDate);
            pageQueue.push(page);
        }

        while (pageQueue.length){
            executeQueue.push(pageQueue.pop());
            if (executeQueue.length >= 3 || (!pageQueue.length && executeQueue.length)){
                results.push(...await Promise.all(executeQueue.map(p => p.execute())));
                executeQueue = [];
            }
        }

        let compiledResults = [];
        results.forEach((queryResult, idx) => {
            if (queryResult.error){
                console.error('Failed to fetch page ' + (idx + 1) + ' of results!', queryResult.error)
                return;
            }
            let data = queryResult.data.getFinalizedFlights.docs;
            compiledResults = compiledResults.concat(data);
        })
        return compiledResults;
    }

    return (
        <Query
            query={gql`
                query GetCustomerManifestPrintout($id: ID!){
                    getCustomer(_id: $id){
                        _id
                        name
                        manifestPrintout {
                            type
                            layouts {
                                name
                                logo
                            }
                        }
                    }
                }
            `}
            variables={{ id: orgData.customer._id }}
        >
        {({ data }) => {
            const manifestPrintouts = safeGet(['getCustomer', 'manifestPrintout'], data) || [];
            const flightManifest = manifestPrintouts.find((m) => m.type === 'Flight'); 
            const hessLayout = flightManifest && flightManifest.layouts.find((l) => l.name === 'Hess');
            const logoURL = hessLayout && hessLayout.logo;

            const handleClick = async () => {
                try {
                    var flights = await getFullFinalizedFlightsData(1);
                }
                catch(err){
                    setPrintOutLoading(false);
                    console.error(err);
                    message.error('One or more pages of data failed to be fetched. You may need to narrow your date range.', 6)
                    return;
                }
                const body = {
                    issuedBy: `${userAttr.given_name} ${userAttr.family_name}`,
                    orgName: orgData.customer.name,
                    logoURL: logoURL,
                    flights: flights,
                    layoutName: props.dateRange[0].format("MMMM DD, YYYY") === props.dateRange[1].format("MMMM DD, YYYY") ?
                            props.dateRange[0].format("MMMM DD, YYYY") + ` ${orgData.customer.name}`:
                            props.dateRange[0].format("MMMM DD, YYYY") +" - " + props.dateRange[1].format("MMMM DD, YYYY") + ` ${orgData.customer.name}`
                }
                fetchPrintout(body, true, `${orgData.customer.name} Flight Manifest ${props.dateRange[0].format('MMM DD, YYYY')} - ${props.dateRange[1].format('MMM DD, YYYY')}.xlsx`)
                .then(() => setPrintOutLoading(false))
                .catch((err) => {
                    setPrintOutLoading(false);
                    message.error('Failed to download printout', 3).then(() => {
                        message.error(err.message, 5);
                    }, null)
                })
            }
            return (
                <Button {...props} onClick={handleClick} loading={fetching || printoutLoading}>Download Times Printout</Button>
            )
        }}
        </Query>
    )
}

var PrintoutPercentageButton = ({...props}) => {
    const [ fetchPrintout, fetching ] = useManifestPrintout('FlightPercentages', 'FlightPercentages');
    const ApolloClient = useApolloClient();
    const [ printoutLoading, setPrintOutLoading ] = useState(false);
    const userAttr = props.cognitoUser.attributes
    const orgData = props.orgData;

    async function getFullFinalizedFlightsData(pageSizeWeeks=2){

        /** @type Moment */
        let startDate = props.dateRange[0];
        /** @type Moment */
        let endDate = props.dateRange[1];

        let duration = moment.duration(endDate.diff(startDate));
        let noWeeks = Math.ceil(duration.as('weeks'));

        setPrintOutLoading(true);
        function getDataPage(subStartDate, subEndDate){
            return {
                execute: () => {
                    return ApolloClient.query({
                        variables: {
                            tpID: props.orgData.transporter._id,
                            customerID: props.orgData.customer._id,
                            startDate: subStartDate.format('YYYY-MM-DD'),
                            endDate: subEndDate.format('YYYY-MM-DD'),
                            departureID: props.departureID && props.departureID.key
                        },
                        query: FINALIZED_FLIGHTS_FULL,
                        fetchPolicy: 'no-cache'
                    })
                }
            }
        }

        // Fetch each page of data two pages at a time using two queues.
        const pageQueue = [];
        const results = [];
        let executeQueue = [];

        for (let pageNum = 0; pageNum <= noWeeks; pageNum+=pageSizeWeeks) {
            let subStartDate = moment(startDate).add(pageNum, 'weeks');
            if (pageNum !== 0){
                subStartDate.add(1, 'day'); // Prevent overlapping between pages
            }
            let subEndDateCandidate = moment(startDate)
                .add(pageNum + pageSizeWeeks, 'weeks');

            let subEndDate = subEndDateCandidate.isAfter(endDate, 'date') ? endDate : subEndDateCandidate;
            if (subStartDate.isAfter(subEndDate, 'date')){
                continue;
            }
            pageQueue.push(getDataPage(subStartDate, subEndDate));
        }

        while (pageQueue.length){
            executeQueue.push(pageQueue.pop());
            if (executeQueue.length >= 3 || (!pageQueue.length && executeQueue.length)){
                results.push(...await Promise.all(executeQueue.map(p => p.execute())));
                executeQueue = [];
            }
        }

        let compiledResults = [];
        results.forEach((queryResult, idx) => {
            if (queryResult.error){
                console.error('Failed to fetch page ' + (idx + 1) + ' of results!', queryResult.error)
                return;
            }
            let data = queryResult.data.getFinalizedFlights.docs;
            compiledResults = compiledResults.concat(data);
        })
        return compiledResults;
    }
    
    return (
        <Query
            query={gql`
                query GetCustomerManifestPrintout($id: ID!){
                    getCustomer(_id: $id){
                        _id
                        name
                        manifestPrintout {
                            type
                            layouts {
                                name
                                logo
                            }
                        }
                    }
                }
            `}
            variables={{ id: orgData.customer._id }}
        >
        {({ data }) => {
            const manifestPrintouts = safeGet(['getCustomer', 'manifestPrintout'], data) || [];
            const flightManifest = manifestPrintouts.find((m) => m.type === 'Flight'); 
            const hessLayout = flightManifest && flightManifest.layouts.find((l) => l.name === 'Hess');
            const logoURL = hessLayout && hessLayout.logo;
            const handleClick = async () => {
                try {
                    var flights = await getFullFinalizedFlightsData(1);
                }
                catch(err){
                    setPrintOutLoading(false);
                    console.error(err);
                    message.error('One or more pages of data failed to be fetched. You may need to narrow your date range.', 6)
                    return;
                }
                const body = {
                    issuedBy: `${userAttr.given_name} ${userAttr.family_name}`,
                    orgName: orgData.customer.name,
                    logoURL: logoURL,
                    flights: flights,
                    layoutName: props.dateRange[0].format("MMMM DD, YYYY") === props.dateRange[1].format("MMMM DD, YYYY") ?
                    `${orgData.customer.name} Flight Usage for ` + props.dateRange[0].format("MMMM DD, YYYY"):
                    `${orgData.customer.name} Flight Usage for ` + props.dateRange[0].format("MMMM DD, YYYY") + " - " + props.dateRange[1].format("MMMM DD, YYYY")
                }
                fetchPrintout(body, true, `${orgData.customer.name} Flight Usages ${props.dateRange[0].format('MMM DD, YYYY')} - ${props.dateRange[1].format('MMM DD, YYYY')}.xlsx`)
                .then(() => setPrintOutLoading(false))
                .catch((err) => {
                    setPrintOutLoading(false);
                    message.error('Failed to download printout', 3).then(() => {
                        message.error(err.message, 5);
                    }, null)
                })
            }
            return (
                <Button {...props} onClick={handleClick} loading={fetching || printoutLoading}>Download Usages Printout</Button>
            )
        }}
        </Query>
    )
}

export default compose(
    WithOrgData,
    withCognitoUser,
    withFlightTimesEntryDrawer({editMode: true})
)(FlightTimes)