import { library } from '@fortawesome/fontawesome-svg-core';
import { faBed, faHelicopter, faLevelDownAlt, faLevelUpAlt, faMapMarkerAlt, faShip, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Card, Col, Layout, List, Row } from 'antd';
import config from 'config';
import { OrgDataContext } from 'context/orgData';
import escapeStringRegexp from 'escape-string-regexp';
import gql from 'graphql-tag';


import defaultAvatarImg from 'img/default-avatar.png';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import InTransitList from '../inTransitList';
import OffshoreLocList from '../offshoreLocList';
import './quartermaster.less';


library.add(faMapMarkerAlt, faUsers, faBed, faLevelUpAlt, faLevelDownAlt, faHelicopter, faShip);

const { Sider, Header } = Layout;


//--------------------------------------------
//>  RETURN A LIST OF (POB-NODE)   
//>  (POBNODE is IMPLIED)
//--------------------------------------------
const LOCATIONS_QUERY = gql`
    query getLocations($customerID:String!){
        GetCustomerLocationDocs(customerID:$customerID){
        
            _id
            checkInType
            firstName
            lastName
            checkedIn
            createdTs
            personID{
                _id
            }
            currentCarrierID{
                ... on FlightNode{
                    _id
                    desig
                    legsArray{
                        destinationID
                    }
                    classType
                }
                ... on BoatNode{
                    _id
                    desig
                    classType
                }
            }
            lastKnownController {
                _id
                name
                getRig{
                    _id
                    bedQuartersIds
                }
            }
        }
    }
`


//--------------------------------------------
//> [ SAVE FOR MULTI CLASS QUERY EXAMPLE ]  QUERY getLocations Query
/*const LOCATIONS_QUERY = gql`
    query getLocations($customerID:String!){
        GetCustomerLocationDocs(customerID:$customerID){
            pob {
                _id
                destinationID {
                    _id
                    name
                }
            }
            flight {
                _id
                desig
            }
            boat {
                _id
                vesselID {
                    _id
                    name
                }
            }
        }
    }
`*/


//--------------------------------------------
export interface QuartermasterMainPageProps {
}


//--------------------------------------------
//> [GRAPH QL]  Appolo Run Query
const QuartermasterMainPage: React.FC<QuartermasterMainPageProps> = (props) => {

    //------------------------------------------------------------
    let locationInfoArray = [];
    let listOfInTransits = []


    //----------------------------------------------------
    //> (useState) saves 'persists' lsit selection info
    const [selectedColumOne, setSelectedColumOne] = useState({
        id: null,
        name: null,
        classType: null
    })


    let [searchValue, setSearchValue] = useState("")


    const orgData = useContext(OrgDataContext)


    // console.log("[DEBUG] ----------------------  selectedColumOne state = ", selectedColumOne)

    //--------------------------------------------------------------------------
    //> This will AUTO-UPDATE the SCREEN LOGIC (every 30000 ms = (30 seconds))
    const result = useQuery(LOCATIONS_QUERY, {
        variables: {
            customerID: orgData.customer._id
        },
        fetchPolicy: "cache-and-network",
        pollInterval: 30000
    })



    //-------------------------------------------------------
    // FUNCTION:  Handle Location/Transit LIST(s) Selections
    //>  If Already Selected - then CLEAR ALL SELECTIONS
    function handleListSelect(id: string, name: string, classType: string) {
        if (id === selectedColumOne.id) {
            setSelectedColumOne({ id: null, name: null, classType: null })
        } else {
            setSelectedColumOne({ id, name, classType })
        }
    }

    //-------------------------------------------------------
    function sortPobs(pobA: any, pobB: any) {
        let pobAName = '' + pobA.lastName + pobA.firstName;
        let pobBName = '' + pobB.lastName + pobB.firstName;
        return pobAName.localeCompare(pobBName)
    }



    //--------------------------------------------------------------------
    //> PREDICATE (FILTERS  'IN TRANSIT' LIST WHEN LOCATION IS SELECTED)
    function filterTransitByLocations(carrierInfo: any) {
        //---------------------------------------------
        if (!selectedColumOne.classType) {
            return true;
        }
        //---------------------------------------------
        let hasLegWithLoc = false;
        //---------------------------------------------
        if (selectedColumOne.classType === "flytsuite.location") {
            carrierInfo.legsArray.forEach((leg: any) => {
                if (leg.destinationID === selectedColumOne.id) {
                    hasLegWithLoc = true;
                }
            })
        } else {
            return true;
        }
        return hasLegWithLoc;
    }




    //--------------------------------------------------------------------
    //> PREDICATE (FILTERS  'IN TRANSIT' LIST WHEN LOCATION IS SELECTED)
    function filterLocationsByCarrier(locInfo: any) {
        //---------------------------------------------
        if (!selectedColumOne.classType) {
            return true;
        }

        //---------------------------------------------
        let hasFlightWithLoc = false;

        //---------------------------------------------
        if (selectedColumOne.classType !== "flytsuite.location") {
            let carrierObj = carrierIdToCarrierObj.get(selectedColumOne.id)
            //---------------------------------------------
            // console.log("[DEBUG]  carrier.desig = ", carrierObj.desig)
            if (carrierObj.classType === "flytsuite.flightnode") {
                carrierObj.legsArray.forEach((leg: any) => {

                    console.log("[DEBUG]  leg.destinationID = ", leg.destinationID)
                    console.log("[DEBUG]  locInfo.locationID = ", locInfo.locationID)

                    if (leg.destinationID === locInfo.locationID) {
                        hasFlightWithLoc = true;
                    }
                })
            }

        } else {
            return true;
        }
        return hasFlightWithLoc;
    }


    //-------------------------------------------------------
    //> [SARCH BOX]  List Filter
    function filterInfoBySearchValue(info: any) {
        if (!searchValue) {
            return true;
        }
        //-------------------------------------
        let regex = new RegExp(escapeStringRegexp('' + searchValue), 'gi');

        //-------------------------------------
        if (info.classType !== "flytsuite.location") {
            // console.log("[DEBUG]   ---search-- NOT LOCATION " + info.carrierName)
            return regex.test(info.carrierName);

        } else {
            // console.log("[DEBUG]   ---search-- LOCATION " + info.locationName)
            return regex.test(info.locationName);
        }
    }

    //-------------------------------------------------------
    //> PREDICATE for Filtering POB lists upon Click
    function filterPobListsBySelection(pob: any) {
        //------------------------------------
        if (!selectedColumOne.classType) {
            return true;

        } else if (selectedColumOne.classType !== "flytsuite.location") {
            return (selectedColumOne.id === pob.currentCarrierID?._id);

        } else {
            if (!selectedColumOne.id || pob.lastKnownController?._id === selectedColumOne.id) {
                return true;
            }
        }

        return false;
    }


    function buildPobProfImgUrl(pob: any) {
        return config.dfS3WebImages
            + "pax/"
            + pob.personID._id
            + "/"
            + pob.personID._id
            + "_thum.jpeg"
    }


    //---------------------------------------
    //> (OLD) BUILD LIST (FILTER OUT NULL ITEMS (using .filter)
    // let locations = result.data?.GetCustomerLocationDocs?.map(item => {
    //     return { 
    //         locationName: item?.lastKnownController?.name
    //     }
    // })
    // .filter(item => item.locationName)
    // .sort(( a, b )=> a.locationName.localeCompare(b.locationName)) //>  SORTS THE LOCATION NAMES LIST (in alphab. order)



    //-------------------------------------------------------------------------------------
    //>  GENERATE (POPULATE) LOCATION-TO_POBNODE MAP MAP <locId,  MAP < pobid, PobNode>>
    let locIdToPobNodeMap = new Map()
    let locIdToLocNameMap = new Map()
    let locIdToRigDocMap = new Map()

    let carrierIdCountMap = new Map<string, number>();
    let carrierIdToCarrierObj = new Map<string, any>();

    let allListedPobsExpected = []
    let allListedPobsOnboard = []
    let allListedPobsDeparting = []

    result.data?.GetCustomerLocationDocs?.forEach(pobNodeItem => {

        if (!pobNodeItem?.lastKnownController?._id) {
            //>  IF LOCATION IS (UNDEFINED)     
            return;

        } else if (!(locIdToPobNodeMap.has(pobNodeItem.lastKnownController._id))) {
            //>  IF LOCATION NOT IN MAP, THEN ADD NEW LOCATION ID  ( '=' will SET out LOCid KEY )
            locIdToPobNodeMap.set(pobNodeItem?.lastKnownController?._id, new Map([[pobNodeItem?._id, pobNodeItem]]))

        } else {
            //>  IF LOCATION IS ALREADY IN MAP, THEN ADD NEW LOCATION ID ( '=' will SET out POB-MAP )
            locIdToPobNodeMap.get(pobNodeItem?.lastKnownController?._id).set(pobNodeItem?._id, pobNodeItem)
        }

        //-----------------------------------------------
        //> ADD Pob-LastKnwCont NAME to LocIdToLocNameMap
        locIdToLocNameMap.set(pobNodeItem.lastKnownController._id, pobNodeItem.lastKnownController.name)
        locIdToRigDocMap.set(pobNodeItem.lastKnownController._id, pobNodeItem.lastKnownController.getRig)
    })


    //------------------------------------------------------------
    //> TRANSFORM locIdToPobNodeMap into a KeyValueArray of 
    locIdToPobNodeMap.forEach((pobNodeMapValue, locIdKey) => {

        //------------------------------------------------
        //> GET THE List-Of-POBS for 'this' LOCATION 
        let listOfPobs = Array.from(pobNodeMapValue, ([name, value]) => value)

        // Sort pob list by lastName, firstName
        listOfPobs = listOfPobs.sort(sortPobs)

        //------------------------------------------------
        //> ((FILTERED)) List-Of-POBS for 'this' LOCATION 
        let listOfPobsExpected = listOfPobs.filter(pobNode => pobNode.checkInType === "EXPECTED")
        let listOfPobsOnboard = listOfPobs.filter(pobNode => pobNode.checkInType === "ONBOARD")
        let listOfPobsDeparting = listOfPobs.filter(pobNode => pobNode.checkInType === "DEPARTED")

        //---------------------------------------------------
        //> APPEND to ALL-EXPECTED-POBS-LISTINGs (BY TYPE)
        allListedPobsExpected = allListedPobsExpected.concat(listOfPobsExpected)
        allListedPobsOnboard = allListedPobsOnboard.concat(listOfPobsOnboard)
        allListedPobsDeparting = allListedPobsDeparting.concat(listOfPobsDeparting)

        //----------------------------------------------------
        //> GET THE (RIG's) TOTAL-BEDCOUNT - POBS on BOARD
        let bedcnt = 0;

        if (locIdToRigDocMap.has(locIdKey)) {
            bedcnt = (locIdToRigDocMap.get(locIdKey)?.bedQuartersIds.length || 0) - listOfPobsOnboard.length
            if (bedcnt < 0) {
                bedcnt = 0;
            }
        }

        //-----------------------------
        locationInfoArray.push({
            locationID: locIdKey,
            locationName: locIdToLocNameMap.get(locIdKey),
            pobNodes: listOfPobs,
            bedCount: bedcnt,
            expectedCount: listOfPobsExpected.length,
            departingCount: listOfPobsDeparting.length,
            onboardCount: listOfPobsOnboard.length + listOfPobsDeparting.length,
            classType: "flytsuite.location"
        })

        //-----------------------------
        listOfPobsExpected.forEach(pob => {

            if (carrierIdCountMap.has(pob.currentCarrierID._id)) {
                carrierIdCountMap.set(pob.currentCarrierID._id, carrierIdCountMap.get(pob.currentCarrierID._id) + 1)

            } else {
                // console.log("[DEBUG]  ------  CRATE NEW KEY FOR COUNT-MAP --------  ")
                carrierIdCountMap.set(pob.currentCarrierID._id, 1)
            }

            carrierIdToCarrierObj.set(pob.currentCarrierID._id, pob.currentCarrierID)
        })
    });


    //-----------------------------------------------------------
    //> DO filtering based on Predicate
    allListedPobsOnboard = allListedPobsOnboard.filter(filterPobListsBySelection);
    allListedPobsExpected = allListedPobsExpected.filter(filterPobListsBySelection);
    allListedPobsDeparting = allListedPobsDeparting.filter(filterPobListsBySelection);


    //-----------------------------------------------------------
    Array.from(carrierIdCountMap.entries()).forEach(item => {
        listOfInTransits.push({
            carrierID: item[0],
            expectedCount: item[1],
            carrierName: carrierIdToCarrierObj.get(item[0]).desig,
            legsArray: carrierIdToCarrierObj.get(item[0]).legsArray,
            classType: carrierIdToCarrierObj.get(item[0]).classType,
        })
    })

    //------------------------------------------------------------
    //> FILTER TRANSITS by SLECTED LOCATIONS
    listOfInTransits = listOfInTransits
        .filter(filterTransitByLocations)
        .filter(filterInfoBySearchValue);

    //------------------------------------------------------------
    //> SORT LOCATION NAMES ARRAY,  THEN FILTER LOCATION by SLECTED TRANSIT
    locationInfoArray =
        locationInfoArray
            .sort((a, b) => a.locationName.localeCompare(b.locationName)) //>  SORTS THE LOCATION NAMES LIST (in alphab. order)
            .filter(filterLocationsByCarrier)
            .filter(filterInfoBySearchValue)


    //---------------------------------------
    //> (OLD)  BUILD LIST (FILTER OUT NULL ITEMS (using .filter)
    // let locations = result.data?.GetCustomerLocationDocs?.map(item => item?.lastKnownController?.name)
    //     .filter(locName => locName) // check for locNames that are NULL
    //     .sort(( a, b )=> a.localeCompare(b)) //>  SORTS THE LOCATION NAMES LIST (in alphab. order)

    //---------------------------------------
    // // and filter NON-UNIQUE ITEMS (using 'uniq' from lodash)
    // if (!locations) {
    //     locations = []
    // } else {
    //     locations = uniqBy(locations, 'locationName')
    //     locations.map(item=> {
    //         return { 
    //             ...item, 
    //             pobCount: result.data?.GetCustomerLocationDocs
    //         }
    //     })
    // }

    //---------------------------------------
    console.log(result) // debug - print to screen results

    //---------------------------------------
    return <Layout
        style={{
            height: "100%"
        }}
        className="mc-quartermaster"
    >

        {/*==========================================  LEFT-MOST PANEL ==========================================  */}
        <Sider className="mc-qm-sider" width="25rem">
            <div className="header transparent">
                <input className="search-input" placeholder="Search" value={searchValue} onChange={(event) => setSearchValue(event.target.value)} />
            </div>
            < OffshoreLocList
                data={locationInfoArray}
                style={{ flex: 1 }}
                onOffshoreLocClick={handleListSelect}
                selectedID={selectedColumOne.id}

            />
            < InTransitList
                data={listOfInTransits}
                style={{ flex: 1 }}
                onInTransitLocClick={handleListSelect}
                selectedID={selectedColumOne.id}
            />
        </Sider>

        <Layout>
            <Header className="mc-qm-content-header" style={{ padding: "0 0.96rem" }}>
                <span className="title">{selectedColumOne.name || "Displaying All Data"}</span>
            </Header>

            <Layout >
                <Row type="flex" style={{ width: "100%", height: "100%" }}>

                    {/*==========================================  CENTER PANEL ==========================================  */}
                    <Col style={{ flex: 1, display: "flex", flexDirection: 'column' }}>


                        {/* ----------------------------- CENTER TOP----------------------------------- */}
                        <Row type="flex" style={{ flexDirection: 'column', flex: 1, flexBasis: 0, minHeight: 0 }}>

                            <Col style={{ flex: 1, padding: "0.5rem", paddingRight: 0 }}>

                                <Card style={{ height: "100%" }} className="mc-qm-card mc-qm-card-onboard" title={<>
                                    <FontAwesomeIcon icon={["fas", "users"]} />Onboard Passengers</>} extra={allListedPobsOnboard.length} size="small" >

                                    <List size="small">
                                        {allListedPobsOnboard.map(pobItem => {
                                            return <List.Item key={pobItem?._id}>
                                                <List.Item.Meta
                                                    //avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                                    avatar={<Avatar icon={<img src={defaultAvatarImg} alt="" />} src={buildPobProfImgUrl(pobItem)} />}
                                                    title={<span>{pobItem.lastName + ", " + pobItem.firstName}</span>}
                                                    description="Rig Engineer"
                                                />
                                            </List.Item>
                                        })}
                                    </List>
                                </Card>

                            </Col>
                        </Row>
                    </Col>

                    {/*==========================================  RIGHT-MOST PANEL ==========================================  */}
                    <Col style={{ flex: 1, display: "flex", flexDirection: 'column' }}>

                        {/* ----------------------------- RIGHT TOP----------------------------------- */}
                        <Row type="flex" style={{ flexDirection: 'column', flex: 1, flexBasis: 0, minHeight: 0 }}>

                            <Col style={{ flex: 1, padding: "0.5rem  0.5rem 0 0.5rem" }}>

                                <Card style={{ height: "100%" }} className="mc-qm-card mc-qm-card-expected" title={<>
                                    <FontAwesomeIcon icon={["fas", "level-down-alt"]} />Expected Passengers</>} extra={allListedPobsExpected.length} size="small" >
                                    <List size="small">
                                        {allListedPobsExpected.map(pobItem => {
                                            return <List.Item key={pobItem?._id}>
                                                <List.Item.Meta
                                                    //avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                                    avatar={<Avatar icon={<img src={defaultAvatarImg} alt="" />} src={buildPobProfImgUrl(pobItem)} />}
                                                    title={<span>{pobItem.lastName + ", " + pobItem.firstName}</span>}
                                                    description="Rig Engineer"
                                                />
                                            </List.Item>
                                        })}
                                    </List>
                                </Card>
                            </Col>

                            {/* ----------------------------- CENTER BOTTOM----------------------------------- */}
                            <Col style={{ flex: 1, padding: "0.5rem" }}>
                                <Card style={{ height: "100%" }} className="mc-qm-card mc-qm-card-departing" title={<>
                                    <FontAwesomeIcon icon={["fas", "level-up-alt"]} />Departing Passengers</>} extra={allListedPobsDeparting.length} size="small" >
                                    <List size="small">
                                        {allListedPobsDeparting.map(pobItem => {
                                            return <List.Item key={pobItem?._id}>
                                                <List.Item.Meta
                                                    //avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                                    avatar={<Avatar icon={<img src={defaultAvatarImg} alt="" />} src={buildPobProfImgUrl(pobItem)} />}
                                                    title={<span>{pobItem.lastName + ", " + pobItem.firstName}</span>}
                                                    description="Rig Engineer"
                                                />
                                            </List.Item>
                                        })}
                                    </List>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout>

        </Layout>
    </Layout>
}
export default QuartermasterMainPage




//--------------------------------------------
//> [GRAPH QL]  Appolo Run Query
/*const QuartermasterMainPage: React.FC<QuartermasterMainPageProps> = (props) => {
    const result = useQuery(LOCATIONS_QUERY, {
        variables: {
            customerID: "CUS-15b4b71dbd2c263e2b4082928429132e3f6dedea18bd08e33ec4303611fa19f1"
        }
    })

    //---------------------------------------
    //> BUILD LIST (FILTER OUT NULL ITEMS (using .filter)
    let locations = result.data?.pob?.map(item => item?.destinationID?.name)
        .filter(locName => locName)

    //---------------------------------------
    // and filter NON-UNIQUE ITEMS (using 'uniq' from lodash)
    if(!locations){
        locations = []
    } else{
        locations = uniq(locations)
    }

    //---------------------------------------
    console.log(result) // debug - print to screen results

        //---------------------------------------
    return <div>
        <h1> Welcome to Quartermaster </h1>
        <p>{JSON.stringify(locations)}</p>
    </div>
}
export default QuartermasterMainPage*/


//--------------------------------------------