import { Alert, Button, Card, Icon, Spin, message } from 'antd'
import { OrgEmailListItem } from 'common/types/org-email-list'
import { removeTypename } from 'common/util'
import CenteredLoadingScreen from 'components/CenteredLoadingScreen'
import OrgNotifEmailListEditor from 'components/org-notif-email-list-editor'
import 'css/Border.less'
import gql from 'graphql-tag'
import React, { useEffect, useRef, useState } from 'react'
import { MutationResult, QueryResult, useMutation, useQuery } from 'react-apollo'

const USER_ORG_QUERY = gql`
query GetUserOrgEmailList{
    GetUserOrganization {
        ... on Customer {
            _id
            name
            notificationEmailList {
                email
                options {
                    optIn
                    optInAll
                }
            }
        }
        ... on Employer {
            _id
            name
            notificationEmailList {
                email
                options {
                    optIn
                    optInAll
                }
            }
        }
    }
}
`

const SET_USER_ORG_EMAIL_LIST = gql`
mutation SetUserOrgEmailList($emailList: [OrgEmailListItemInput!]!){
    SetUserOrgNotificationEmailList(emailList: $emailList){    
        ... on Customer {
            _id
            name
            notificationEmailList {
                email
                options {
                    optIn
                    optInAll
                }
            }
        }
        ... on Employer {
            _id
            name
            notificationEmailList {
                email
                options {
                    optIn
                    optInAll
                }
            }
        }
    }
}
`

function useGetUserOrgEmailList(): [ OrgEmailListItem[], QueryResult ]{
    const result = useQuery(USER_ORG_QUERY, {
        fetchPolicy: 'cache-and-network'
    })

    let emailList = []

    if (result.data?.GetUserOrganization?.notificationEmailList){
        emailList = result.data.GetUserOrganization.notificationEmailList
    }
    return [ emailList, result ]
}

function useSetUserOrgEmailList(): [(emailList: OrgEmailListItem[]) => Promise<any>, MutationResult] {
    const [ mutate, result ] = useMutation(SET_USER_ORG_EMAIL_LIST)

    function updateEmailList(emailList: OrgEmailListItem[]){
        return mutate({
            variables: { emailList },
            update: (cache, { data: { SetUserOrgNotificationEmailList } }) => {
                const data = cache.readQuery<any>({ query: USER_ORG_QUERY });
                const org = data?.GetUserOrganization;

                if (!org) return;

                cache.writeQuery({
                    query: USER_ORG_QUERY,
                    data: {
                        GetUserOrganization: {
                            ...org,
                            notificationEmailList: SetUserOrgNotificationEmailList?.notificationEmailList
                        }
                    }
                })
            }
        })
    }

    return [ updateEmailList, result ]
}

const UserOrgEmailTriggers: React.FC<{}> = (props) => {
    const [ emailListState, setEmailListState ] =
        useState<{ dirty: boolean, data: OrgEmailListItem[] }>({ dirty: false, data: [] });
    
    const editorRef = useRef<any>();

    const [ emailList, { data: queryData, loading, error, refetch } ] = useGetUserOrgEmailList();
    const [ setEmailList, { loading: emailListUpdating } ] = useSetUserOrgEmailList();

    function resetEmailListState(){
        setEmailListState({ dirty: false, data: emailList });
    }

    useEffect(() => {
        // Whenever the emailList query changes, update what the user sees
        if (emailList){
            resetEmailListState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ emailList ])

    const orgName = queryData?.GetUserOrganization?.name;

    function handleSave(){
        // When user saves, update the email list in the db
        if (!editorRef.current) return 
        
        editorRef.current.validateFieldsAndScroll((err: any) => {
            if (!err){
                let processed = emailListState.data.filter(i => i.email);
                processed = removeTypename(processed);
                setEmailList(processed)
                .then(() => message.success("Email triggers for " + orgName + " updated successfully"))
                .catch(() => message.error("Failed to update email triggers for " + orgName))
            }
            else
            {
                console.log(err)
                message.warning(<div>
                    <p>No can do. You have some issues with the form you need to fix first.</p>
                    {Object.keys(err).map((key) => {
                        let idx = Number(key.split('-')[0]);
                        let item = emailListState.data[idx];
                        return <p>A field for '{item.email}' is invalid'</p>
                    })}
                </div>, 5)
            }
        })
    }

    function handleEmailListChange(_emailList: OrgEmailListItem[]){
        setEmailListState({ dirty: true, data: _emailList })
    }

    let editor = null;

    if (!error) editor = (
        <OrgNotifEmailListEditor
            listProps={{ bordered: false }}
            onChange={handleEmailListChange}
            value={emailListState.data}
            ref={editorRef}
            collapseProps={{
                style: {
                    overflow: 'auto'
                }
            }}
            addEmailButtonProps={{
                type: 'primary',
                ghost: true
            }}
            style={{
                height: '40rem'
            }}
        />
    )
    else editor = (
        <Alert type='error' showIcon message="Failed to load email list due to error" />
    )

    let extra = []

    if (emailListState.dirty){
        extra = [
            <Button key="cancel" type='link' onClick={resetEmailListState}>Cancel</Button>,
            <Button key="save" type='primary' onClick={handleSave} loading={emailListUpdating}>Save Changes</Button>
        ]
    }
    else
    {
        extra = [<Button key="reload" icon='reload' style={{ marginLeft: '12px' }} onClick={() => refetch()} />]
    }
    

    return (

        <Card
            size='small'
            title={<span id="email-triggers-customer">Email triggers for {orgName || <Spin indicator={<Icon type="loading" />} />}</span>}
            style={{ marginBottom: 12 }}
            extra={[
                <div style={{ display: 'flex', alignItems: 'center' }}>{extra}</div>
            ]}
        >
            {loading && !emailList.length ?
                <CenteredLoadingScreen /> : editor}
        </Card>
    )
}

export default UserOrgEmailTriggers