import {useEffect, useRef, useState} from "react";

// Must be the same as src/theme/layout.less

/*
@screen-size-large: 1340px;
@screen-size-medium: 1048px;
@screen-size-small: 850px;
@screen-size-mobile: 560px;
 */

type ScreenType = 'large' | 'medium' | 'small' | 'mobile';

enum ScreenTypeEnum {
    mobile=560,
    small=850,
    medium=1048,
    large=1340
}

const large = ScreenTypeEnum.large;
const medium = ScreenTypeEnum.medium;
const small = ScreenTypeEnum.small;
const mobile = ScreenTypeEnum.mobile;

type WidthSource = HTMLElement | Window;

export interface UseBreakpointsOptions {
    widthSource?: WidthSource,

    // Number to add to width
    widthOffset?: number
}

/**
 * Watches either the Window or an HTML element for resize changes, then reports the screen size breakpoint.
 *
 * Breakpoints:
 *  - large
 *  - medium
 *  - small
 *  - mobile
 *
 * @param options
 */
function useBreakpoints(options?: UseBreakpointsOptions) {

    const [ source, setSource ] = useState<WidthSource>(null);
    const [ offset, setOffset ] = useState<number>(0);
    const [ screen, setScreen ] = useState<ScreenTypeEnum>();
    const lastWidth = useRef(0);

    setWidthSource(options?.widthSource);
    setWidthOffset(options?.widthOffset);

    function handleResize(){
        let screenType: ScreenType = 'large';

        let width: number;

        if (source instanceof Window){
            width = source.innerWidth + offset;
        }
        else
        {
            width =  (Number(source.clientWidth) ?? 0) + offset;
        }

        if (width === lastWidth.current){
            return;
        }

        lastWidth.current = width;

        if (width <= mobile) screenType = 'mobile';
        else if (width <= small) screenType = 'small';
        else if (width <= medium) screenType = 'medium';

        console.debug('width changed!', width, screenType);

        setScreen(ScreenTypeEnum[screenType]);
    }

    useEffect(() => {

        if (!source) return;

        // Call handleResize once
        // Reason: Need to initialize screen state before the resize event listener is called.
        handleResize();

        if (source instanceof Window){
            // Use window resize event if window
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }

        // Otherwise use ResizeObserver
        const observer = new ResizeObserver((entries) => {
            if (entries.length <= 0) return;

            handleResize();
        })

        observer.observe(source);
        return () => observer.unobserve(source as HTMLElement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source])

    /**
     * Sets the source element that wil be measured.
     * Defaults to window if not provided.
     * @param widthSource HTML element or window
     * @param widthOffset Gets added to the width measurement
     */
    function setWidthSource(widthSource: WidthSource, widthOffset?: number) {
        if (!widthSource) widthSource = window;

        if (widthSource !== source){
            setSource(widthSource);
        }
        if (widthOffset){
            setWidthOffset(widthOffset);
        }
    }

    /**
     * Adds an offset to the width measurement of the source element/window.
     * @param widthOffset Gets added to the width measurement
     */
    function setWidthOffset(widthOffset: number) {
        if (
            typeof widthOffset !== 'number' ||
            widthOffset === offset
        ) return;
        setOffset(widthOffset);
    }

    return {
        // Current breakpoint of the screen/element
        breakpoint: screen,

        /**
         * Returns true for breakpoints bigger than the input.
         * @param breakpoint Breakpoint input
         */
        max: (breakpoint: ScreenType) => {
            return ScreenTypeEnum[breakpoint] > screen
        },

        /**
         * Returns true for breakpoints smaller than or equal the input.
         * @param breakpoint Breakpoint input
         */
        min: (breakpoint: ScreenType) => {
            return ScreenTypeEnum[breakpoint] <= screen
        },

        /**
         * Sizes in pixels for each breakpoint type
         */
        sizes: {
            large: large,
            medium: medium,
            small: small,
            mobile: mobile
        },
        setWidthSource: setWidthSource,
        setWidthOffset: setWidthOffset
    }
}

export default useBreakpoints;