import { Card, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import ContractSelect from 'components/form/ContractSelect';
import { VesselSelect } from 'components/form/select/VesselSelect';
import { CredentialInput } from 'components/masterdata-2-0/entryForms/person/CredentialInput';
import moment from 'moment';
import React from 'react';
import { validateCredWithExpiration, validateISNNumber } from '../../formValidators';
import { LocationSelect } from '../form/select/LocationSelect';
import { OrganizationSelect } from '../form/select/OrganizationSelect';
import ISNChecker from '../ISNChecker';
import { LocationBlock } from '../LocationBlock';
import WithOrgData from '../WithOrgData';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
    style: {
        marginBottom: 0
    }
};

const travelFormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
    style: {
        marginBottom: 0
    } 
}

const defaultMessage = 'This field is required';

export const PersonToPassenger = Form.create({
    onValuesChange:(props, changedValues, allValues) => {
        if(changedValues["lastKnownController"]){
            props.form.setFieldsValue({"contractID": ""})
        }
    }})(
    WithOrgData(
    ({ form: { getFieldDecorator, getFieldValue, getFieldError }, person, type, credentials={}, orgData={} }) => {
        return (
            <Form>
                <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Col>
                        <Card size="small" title="Update Personal Info">
                            <Row gutter={12} type="flex">
                                <Col span={12}>
                                    <Form.Item label="Last Name" {...formItemLayout}>
                                        {getFieldDecorator('lastName', {
                                            initialValue: person && person.lastName,
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(<Input disabled={person ? true: false} size="small" />)}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="First Name" {...formItemLayout}>
                                        {getFieldDecorator('firstName', {
                                            initialValue: person && person.firstName,
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(<Input disabled={person ? true : false} size="small" />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12} type="flex">
                                <Col span={12}>
                                    <Form.Item label="Customer" {...formItemLayout}>
                                        {getFieldDecorator('customerID', {
                                            initialValue: orgData && orgData.customer ? {
                                                key: orgData.customer._id,
                                                label: orgData.customer.name
                                            } : {
                                                key: person?.customerID?._id,
                                                label: person?.customerID?.name
                                            },
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <OrganizationSelect
                                                disabled={!!orgData.customer}
                                                classTypes={['flytsuite.customer']}
                                                labelInValue={true}
                                                size="small"
                                                allowClear
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Employer" {...formItemLayout}>
                                        {getFieldDecorator('employerID', {
                                            initialValue: (person && person.employerID && {
                                                key: person.employerID._id,
                                                label: person.employerID.name
                                            }) || ({
                                                key: null, 
                                                label: null
                                            }),
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <OrganizationSelect
                                                classTypes={['flytsuite.employer']}
                                                labelInValue={true}
                                                size="small"
                                                allowClear
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12} type="flex">
                                <Col span={12}>
                                    <Form.Item label="DOB" {...formItemLayout}>
                                        {getFieldDecorator('dob', {
                                            initialValue: person && person.dob && moment(person.dob),
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <DatePicker disabled={person ? true : false} style={{ width: '100%' }} size="small" format="YYYY-MM-DD" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    {type === 'docks'? null :
                                        <Form.Item label="Contract" {...formItemLayout}>
                                        {getFieldDecorator('contractID', {
                                            initialValue: null
                                        })(
                                            <ContractSelect
                                                orgType={'customer'}
                                                size="small"
                                                allowClear
                                                locationID={getFieldValue('lastKnownController') && getFieldValue('lastKnownController').key}
                                            />
                                        )}
                                        </Form.Item>
                                    }
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Col md={14}>
                        <Card size="small" title="Update Credentials">
                            <Form.Item label="Driver License" {...formItemLayout}>
                                {getFieldDecorator('credential-US_DRIVERS_LICENSE', {
                                    initialValue: credentials.US_DRIVERS_LICENSE,
                                    rules: [{
                                        validator: validateCredWithExpiration,
                                        required: (getFieldValue('credential-US_DRIVERS_LICENSE') &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key !== "" &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration !== "") || !(
                                                (getFieldValue('credential-PASSPORT') &&
                                                getFieldValue('credential-PASSPORT').key &&
                                                getFieldValue('credential-PASSPORT').key !== "" &&
                                                getFieldValue('credential-PASSPORT').expiration &&
                                                getFieldValue('credential-PASSPORT').expiration !== "") ||
                                                (getFieldValue('credential-PASSPORT_CARD') &&
                                                getFieldValue('credential-PASSPORT_CARD').key &&
                                                getFieldValue('credential-PASSPORT_CARD').key !== "" &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration !== "") ||
                                                (getFieldValue('credential-TWIC') &&
                                                getFieldValue('credential-TWIC').key &&
                                                getFieldValue('credential-TWIC').key !== "" &&
                                                getFieldValue('credential-TWIC').expiration &&
                                                getFieldValue('credential-TWIC').expiration !== "")
                                            )
                                    }]
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Passport" {...formItemLayout}>
                                {getFieldDecorator('credential-PASSPORT', {
                                    initialValue: credentials.PASSPORT,
                                    rules: [{
                                        validator: validateCredWithExpiration,
                                        required: (getFieldValue('credential-PASSPORT') &&
                                                getFieldValue('credential-PASSPORT').key &&
                                                getFieldValue('credential-PASSPORT').key !== "" &&
                                                getFieldValue('credential-PASSPORT').expiration &&
                                                getFieldValue('credential-PASSPORT').expiration !== "") || !(
                                                (getFieldValue('credential-US_DRIVERS_LICENSE') &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key !== "" &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration !== "") ||
                                                (getFieldValue('credential-PASSPORT_CARD') &&
                                                getFieldValue('credential-PASSPORT_CARD').key &&
                                                getFieldValue('credential-PASSPORT_CARD').key !== "" &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration !== "") ||
                                                (getFieldValue('credential-TWIC') &&
                                                getFieldValue('credential-TWIC').key &&
                                                getFieldValue('credential-TWIC').key !== "" &&
                                                getFieldValue('credential-TWIC').expiration &&
                                                getFieldValue('credential-TWIC').expiration !== "")
                                            )
                                    }]
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Passport Card" {...formItemLayout}>
                                {getFieldDecorator('credential-PASSPORT_CARD', {
                                    initialValue: credentials.PASSPORT_CARD,
                                    rules: [{
                                        validator: validateCredWithExpiration,
                                        required: (getFieldValue('credential-PASSPORT_CARD') &&
                                                getFieldValue('credential-PASSPORT_CARD').key &&
                                                getFieldValue('credential-PASSPORT_CARD').key !== "" &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration !== "") || !(
                                                (getFieldValue('credential-PASSPORT') &&
                                                getFieldValue('credential-PASSPORT').key &&
                                                getFieldValue('credential-PASSPORT').key !== "" &&
                                                getFieldValue('credential-PASSPORT').expiration &&
                                                getFieldValue('credential-PASSPORT').expiration !== "") ||
                                                (getFieldValue('credential-US_DRIVERS_LICENSE') &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key !== "" &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration !== "") ||
                                                (getFieldValue('credential-TWIC') &&
                                                getFieldValue('credential-TWIC').key &&
                                                getFieldValue('credential-TWIC').key !== "" &&
                                                getFieldValue('credential-TWIC').expiration &&
                                                getFieldValue('credential-TWIC').expiration !== "")
                                            )
                                    }]
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="TWIC" {...formItemLayout}>
                                {getFieldDecorator('credential-TWIC', {
                                    initialValue: credentials.TWIC,
                                    rules: [{
                                        validator: validateCredWithExpiration,
                                        required: (getFieldValue('credential-TWIC') &&
                                                getFieldValue('credential-TWIC').key &&
                                                getFieldValue('credential-TWIC').key !== "" &&
                                                getFieldValue('credential-TWIC').expiration &&
                                                getFieldValue('credential-TWIC').expiration !== "") || !(
                                                (getFieldValue('credential-PASSPORT') &&
                                                getFieldValue('credential-PASSPORT').key &&
                                                getFieldValue('credential-PASSPORT').key !== "" &&
                                                getFieldValue('credential-PASSPORT').expiration &&
                                                getFieldValue('credential-PASSPORT').expiration !== "") ||
                                                (getFieldValue('credential-PASSPORT_CARD') &&
                                                getFieldValue('credential-PASSPORT_CARD').key &&
                                                getFieldValue('credential-PASSPORT_CARD').key !== "" &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration &&
                                                getFieldValue('credential-PASSPORT_CARD').expiration !== "") ||
                                                (getFieldValue('credential-US_DRIVERS_LICENSE') &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').key !== "" &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration &&
                                                getFieldValue('credential-US_DRIVERS_LICENSE').expiration !== "")
                                            )
                                    }]
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Safety Card" {...formItemLayout}>
                                {getFieldDecorator('credential-SAFETY_CARD', {
                                    initialValue: credentials.SAFETY_CARD
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                        showKey={false}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="Huet Card" {...formItemLayout}>
                                {getFieldDecorator('credential-HUET_CARD', {
                                    initialValue: credentials.HUET_CARD
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                        showKey={false}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="EBS Card" {...formItemLayout}>
                                {getFieldDecorator('credential-EBS_CARD', {
                                    initialValue: credentials.EBS_CARD
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                        showKey={false}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label="ISN Card" {...formItemLayout}>
                                {getFieldDecorator('credential-ISN', {
                                    initialValue: credentials.ISN,
                                    rules: [
                                        {validator: (rule, value, callback) => validateISNNumber(rule, value && value.key, callback)}
                                    ]
                                })(
                                    <CredentialInput
                                        inputProps={{ size: 'small' }}
                                        showExpiration={false}
                                    />
                                )}
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col md={10}>
                        <Card size="small" title="Travel">
                            <Row gutter={12} style={{ display: orgData.customer ? 'block' : 'none'  }}>
                                <Col span={24}>
                                    <Form.Item label="Transporter" {...{
                                        ...formItemLayout,
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 8 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 16 },
                                        },
                                    }}>
                                        {getFieldDecorator('tpID', {
                                            initialValue: {
                                                key: orgData.transporter?._id,
                                                label: orgData.transporter?.name
                                            },
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <OrganizationSelect
                                                classTypes={['flytsuite.transporter']}
                                                labelInValue={true}
                                                size="small"
                                                allowClear
                                                disabled={!orgData.customer}
                                                organizations={orgData.tpList?.map((tp) => tp._id)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item label="Departure" {...travelFormItemLayout}>
                                        {getFieldDecorator('departureID', {
                                            initialValue: null,
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <LocationSelect
                                                labelInValue
                                                size="small"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Destination" {...travelFormItemLayout}>
                                        {getFieldDecorator('destinationID', {
                                            initialValue: null,
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <LocationSelect
                                                labelInValue
                                                size="small"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item label="Area Block" {...travelFormItemLayout}>
                                    {getFieldValue('departureID') ? (
                                        <LocationBlock locationId={getFieldValue('departureID')} />
                                    ) : '-'}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Area Block" {...travelFormItemLayout}>
                                    {getFieldValue('destinationID') ? (
                                        <LocationBlock locationId={getFieldValue('destinationID')} />
                                    ) : '-'}
                                    </Form.Item>
                                </Col>
                            </Row>
                                {type === 'docks'?
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item label="Vessel" {...travelFormItemLayout}>
                                        {getFieldDecorator('currentCarrierID', {
                                            initialValue: null,
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <VesselSelect
                                                size="small"
                                                customerID={orgData.customer && orgData.customer._id}
                                                tpID={orgData.transporter && orgData.transporter._id}
                                                finalized={false}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>: type === 'flight'?<Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item label="Heliport" {...travelFormItemLayout}>
                                        {getFieldDecorator('lastKnownController', {
                                            initialValue: null,
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <LocationSelect
                                                labelInValue
                                                type='ONSHORE'
                                                size="small"
                                                includeOneOff
                                                hasDataflytHardware
                                                limit={50}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Transit Type" {...travelFormItemLayout}>
                                        {getFieldDecorator('transitType', {
                                            initialValue: 'OUTBOUND',
                                            rules: [
                                                {required: true, message: defaultMessage}
                                            ]
                                        })(
                                            <Select size="small" defaultValue="OUTBOUND" showAction={["focus", "click"]}>
                                                <Select.Option value='OUTBOUND'>OUTBOUND</Select.Option>
                                                <Select.Option value='INBOUND'>INBOUND</Select.Option>
                                                <Select.Option value='TRANSFER'>TRANSFER</Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                </Row>:null
                                }
                            <Row gutter={12}>
                                <Col>
                                    <Form.Item
                                        label="Weight"
                                        // hasFeedback={person?.lastPaxWeight}
                                        help={
                                            person?.lastPaxWeight && !getFieldError('paxWeight') ?
                                            "Previous pax weight filled in" : undefined
                                        }
                                        {...travelFormItemLayout}
                                    >
                                        {getFieldDecorator('paxWeight', {
                                            initialValue: person?.lastPaxWeight || 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Weight must be more than 90.",
                                                    type: 'integer',
                                                    min: 90
                                                }
                                            ]
                                        })(
                                            <InputNumber size="small" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label="Bag Weight"
                                        {...travelFormItemLayout}
                                        hasFeedback={person?.lastBagWeight}
                                        help={
                                            person?.lastBagWeight && !getFieldError('bagWeight') ?
                                                "Previous bag weight filled in" : undefined
                                        }
                                    >
                                        {getFieldDecorator('bagWeight', {
                                            initialValue: person?.lastBagWeight || 0,
                                            rules: [
                                                {required: getFieldValue('bagCount') !== 0
                                                        || getFieldValue('bagWeight') !== 0,
                                                message: defaultMessage,
                                                type: 'integer',
                                                min: getFieldValue('bagCount') !== 0
                                                || getFieldValue('bagWeight') !== 0 ? 1 : 0}
                                            ]
                                        })(
                                            <InputNumber size="small" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label="Bag Count"
                                        {...travelFormItemLayout}
                                        hasFeedback={person?.lastBagCount}
                                        help={
                                            person?.lastBagCount && !getFieldError('bagCount') ?
                                                "Previous bag count filled in" : undefined
                                        }
                                    >
                                        {getFieldDecorator('bagCount', {
                                            initialValue: person?.lastBagCount || 0,
                                            rules: [
                                                {
                                                    required: getFieldValue('bagCount') !== 0
                                                        || getFieldValue('bagWeight') !== 0,
                                                    message: defaultMessage,
                                                    type: 'integer',
                                                    min: getFieldValue('bagCount') !== 0
                                                        || getFieldValue('bagWeight') !== 0 ? 1 : 0
                                                }
                                            ]
                                        })(
                                            <InputNumber size="small" />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={14}>
                        <Card title="ISN Result" size="small">
                            <ISNChecker
                                customerID={getFieldValue('customerID')?.key}
                                isnNumber={getFieldValue('credential-ISN') && getFieldValue('credential-ISN').key}
                            />
                        </Card>
                    </Col>
                </Row>
            </Form>
        )
}))

export const formatPersonToPassengerValues = (values) => {
    const {
        lastName,
        firstName,
        customerID,
        dob,
        employerID,
        tpID,
        contractID,
        departureID,
        destinationID,
        currentCarrierID,
        transitType,
        lastKnownController,
        paxWeight,
        bagWeight,
        bagCount,
        ...restValues
    } = values;
    const valuesEntries = Object.entries(restValues); 
    const credEntries = valuesEntries
                        .filter(([field]) => field.startsWith('credential-'))
                        .map(([field, cred]) => [field, cred && { key: cred.key, expiration: cred.expiration }]);
    var credentials = {};
    credEntries.forEach(entry => credentials[entry[0].replace('credential-', '').toUpperCase().trim()] = entry[1]);
    const formattedValues = {
        lastName: lastName.toUpperCase().trim(),
        firstName: firstName.toUpperCase().trim(),
        customerID: customerID && customerID.key,
        dob: moment(dob).format('YYYY-MM-DD'),
        employerID: employerID && employerID.key,
        departureID: departureID && departureID.key,
        destinationID: destinationID && destinationID.key,
        tpID: tpID?.key,
        currentCarrierID,
        transitType,
        contractID,
        credentials,
        lastKnownController: lastKnownController && lastKnownController.key,
        paxWeight,
        bagWeight,
        bagCount,
    }
    return formattedValues;
}