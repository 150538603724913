import React, { PropsWithChildren } from 'react';
import gql from "graphql-tag";
import { Query } from 'react-apollo';
import { Alert, Result, Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const USER_GROUPS = gql`
query GetUserGroups {
    userGroups @client
}
`

// const DefaultError = ({error}) => <Alert type="error" message="Failed to get user groups" description={error.message} />

export interface RequireUserGroupProps {
    group: string | Array<string>,
    unauthorizedTitle?: string,
    unauthorizedSubTitle?: string,
    unauthorizedIcon?: React.ReactElement<IconProps>,
    renderUnauthorized?: () => any
}

export const RequireUserGroup: React.FC<PropsWithChildren<RequireUserGroupProps>> = (props) => {
    return <Query query={USER_GROUPS}>
    {
        (result) => {
            if (result.loading) return null
            if (result.error) {
                console.error(result.error);
                return <Alert
                    type="error"
                    showIcon
                    message="Failed to check user permissions"
                    description={result.error.message}
                />
            }
            var authorized = false;
            const checkGroup = (group) => {
                if (result.data.userGroups.includes(group))
                {
                    authorized = true;
                }
            }
            switch (typeof props.group) {
                case 'string':
                    checkGroup(props.group)
                    break;
                default:
                    props.group.forEach(g => {
                        checkGroup(g)
                    })
                    break;
            }
            if (authorized){
                return props.children
            }

            if (props.renderUnauthorized){
                return props.renderUnauthorized();
            }

            return <Result
                status="warning"
                icon={props.unauthorizedIcon || <Icon type="lock" />}
                title={props.unauthorizedTitle || "Invalid permissions"}
                subTitle={props.unauthorizedSubTitle || "You do not have permission to access this feature"}
            />
        }
    }
    </Query>
}

export interface WithRequireUserGroupOptions {

}

export const requireUserGroup = <P extends object>(group: string | Array<string>, options?: Omit<RequireUserGroupProps, 'children' | 'group'>) => (Component: React.ComponentType<P>) => {
    class WithRequireUserGroup extends React.Component<P> {
        render(){
            return <RequireUserGroup group={group} {...options} >
                <Component {...this.props} />
            </RequireUserGroup>
        }
    }
    return WithRequireUserGroup
}

const GroupWrapper = ({ children, renderError = null, renderNotAuthorized = null, group }) => (
    <Query query={USER_GROUPS}>
    {
        ({ data, loading, error }: any) => {
            if (loading) return null
            if (error) {
                console.error(error);
                return renderError
            }
            var authorized = false;
            const checkGroup = (group) => {
                if (data.userGroups.includes(group))
                {
                    authorized = true;
                }
            }
            switch (typeof group) {
                case 'string':
                    checkGroup(group)
                    break;
                default:
                    group.forEach(g => {
                        checkGroup(g)
                    })
                    break;
            }
            if (authorized){
                return children
            }
            return renderNotAuthorized
        }
    }
    </Query>
)

const Master = {
    All: props => <GroupWrapper {...props} group="flytsuite.master.all" />
}

const Isn = {
    Override: props => <GroupWrapper {...props} group="flytsuite.isn.override" />
}

const Schedule = {
    Flight: props => <GroupWrapper {...props} group="flytsuite.schedule.flight" />,
    Passenger: props => <GroupWrapper {...props} group="flytsuite.schedule.passenger" />,
    PassengerViewOnly: props => <GroupWrapper {...props} group="flytsuite.schedule.passenger.view" />,
    Sets: props => <GroupWrapper {...props} group="flytsuite.schedule.sets" />,
    Any: props => <GroupWrapper {...props} group={["flytsuite.schedule.sets", "flytsuite.schedule.flight", "flytsuite.schedule.passenger", "flytsuite.schedule.passenger.view"]} />
}

const Times = {
    HistoryEdit: props => <GroupWrapper {...props} group="flytsuite.flight.times.history_edit"/>,
    Default: props => <GroupWrapper {...props} group="flytsuite.flight.times"/>,
}

const Flight = {
    Times
}

const Flytwatch = {
    View: props => <GroupWrapper {...props} group="flytsuite.flytwatch.view"/>,
    History: props => <GroupWrapper {...props} group={["flytsuite.flytwatch.view", "flytsuite.flytwatch.history", "flytsuite.flytwatch.history.read"]}/>,
    Report: props => <GroupWrapper {...props} group={["flytsuite.flytwatch.view", "flytsuite.flytwatch.report"]}/>
}


const Flytsuite = {
    Master,
    Isn,
    Schedule,
    Flight,
    Flytwatch,
    Default: props => <GroupWrapper {...props} group="flytsuite.default" />
}

export const UserGroup = {
    Flytsuite
}

export interface WithUserGroupsProps {
    userGroups?: string[]
}

export const withUserGroups = <P extends object>(Component: React.ComponentType<P>) => {
    const withOrgDataWrapper: React.FC<P & WithUserGroupsProps> = (props) => (
        <Query query={USER_GROUPS}>
            {
                ({ data, error }: any) => {
                    if (error) {
                        console.error(error)
                    }
                    const userGroups = (data && data.userGroups) || [];
                    return <Component {...props} userGroups={userGroups} />
                }
            }
        </Query>
    )
    return withOrgDataWrapper
}

export interface RequireUserGroupsProps extends PropsWithChildren<{}> {
    groups: string[],
    render?: any
}

export const RequireUserGroups: React.FC<RequireUserGroupsProps> = ({ groups=[], render=null, children=[] }) => {
    return (
        <Query query={USER_GROUPS}>
            {
                ({ data, loading, error }: any) => {
                    if (loading) return null
                    if (error) return <span style={{ color: 'red' }} >Failed to load user permissions</span>
                    const userGroups = (data && data.userGroups) || [];
                    const inGroup = groups.find(group => userGroups.includes(group)) ? true : false;
                    if (inGroup){
                        return children
                    }
                    return render
                }
            }
        </Query>
    )
}