import { Dropdown, Menu, Checkbox } from 'antd';
import MCIcon from 'components/icon';
import { GlobalAppStateContext, McMapSettings } from 'context/global-app-state';
import React, { useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { ControlPosition, useControl } from 'react-map-gl';
import './style.less';

export interface McMapLayerDropdownProps {
    position?: ControlPosition
}

let DEFAULT_SETTINGS: McMapSettings = {
    layers: {
        'nexrad': {
            enabled: true
        }
    }
}

class LayerDropdownControl {
    _map: any
    _container: HTMLDivElement

    constructor(container: HTMLDivElement){
        this._container = container;
    }

    onAdd(map){
        this._map = map;
        this._container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
        return this._container;
    }

    onRemove(){
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

const McMapLayerDropdown: React.FC<McMapLayerDropdownProps> = React.forwardRef((props, ref: any) => {
    const { getUserSettings, setUserSettings } = useContext(GlobalAppStateContext);
    const [ layerDropdownVisible, setLayerDropDownVisible ] = useState(false);
    const [ divContainer, setDivContainer ] = useState(null);

    let uSettings = getUserSettings('mcMap');

    useControl(
        () => {
            const div = document.createElement('div');
            const control = new LayerDropdownControl(div);
            setDivContainer(div);
            return control;
        },
        {
            position: props.position
        }
    );

    let settings = {
        ...DEFAULT_SETTINGS,
        ...uSettings,
        layers: {
            ...DEFAULT_SETTINGS.layers,
            ...uSettings?.layers
        }
    };

    function toggleLayer(id: string){
        setUserSettings('mcMap', {
            ...settings,
            layers: {
                ...settings.layers,
                [id]: {
                    ...settings.layers[id],
                    enabled: !settings.layers[id]?.enabled
                }
            }
        })
    }

    if (!divContainer) return;

    let dropdown = <Dropdown
        ref={ref}
        visible={layerDropdownVisible}
        onVisibleChange={(visible) => setLayerDropDownVisible(visible)}
        overlay={
        <Menu
            onClick={(p) => { p.domEvent.preventDefault(); toggleLayer(p.key); }}
        >
            <Menu.Item
                key="nexrad"
            >
                <Checkbox
                    checked={settings.layers['nexrad']?.enabled}
                >
                    Weather Radar (NEXRAD)
                </Checkbox>
            </Menu.Item>
        </Menu>
    }>
        <button className='mc-map-layer-btn'>
            <MCIcon type='map-layers' style={{ fontSize: '1.1rem', position: 'relative', top: '0.1rem' }} />
        </button>
    </Dropdown>

    return createPortal(dropdown, divContainer);
})

export default McMapLayerDropdown