import { useQuery } from "@apollo/react-hooks";
import defaultState from "defaultState";
import gql from "graphql-tag";
import moment, {Moment, MomentInput} from "moment";
import {useMutation} from "react-apollo";

const QUERY_CACHE = gql`
query ReportsCachedDateRangeQuery {
    reportDateRange @client
}
`

const MUTATION_CACHE = gql`
mutation ReportsCachedDateRangeMutation($dateRange: [String!]!) {
    setReportDateRange(dateRange: $dateRange) @client
}
`

export default function useCachedDateRange(defaultStartDate?: MomentInput, defaultEndDate?: MomentInput){
    const { data, loading } = useQuery(QUERY_CACHE, {
        onError: console.error
    });
    const [ reportDateRangeMutation ] = useMutation(MUTATION_CACHE, {
        onError: console.error
    });

    let reportDateRange = data?.reportDateRange;

    function cleanDateRange(dateRange: [MomentInput, MomentInput]){
        if (!dateRange || !dateRange[0] || !dateRange[1]){
            if (defaultStartDate && defaultEndDate){
                dateRange = [ moment(defaultStartDate), moment(defaultEndDate) ]
            }
            else
            {
                dateRange = [ moment(defaultState.reportDateRange[0]), moment(defaultState.reportDateRange[1]) ];
            }
        }
        else {
            dateRange = [ moment(dateRange[0]), moment(dateRange[1]) ]
        }
        return dateRange;
    }

    reportDateRange = cleanDateRange(reportDateRange);

    return {
        dateRange: reportDateRange,
        setDateRange: (newDateRange: [startDate: Moment, endDate: Moment]) => {
            const cleaned = cleanDateRange(newDateRange);
            const newDateRangeStr = [
                moment(cleaned[0]).format('YYYY-MM-DD'),
                moment(cleaned[1]).format('YYYY-MM-DD')
            ]

            return reportDateRangeMutation({
                variables: {
                    dateRange: newDateRangeStr
                }
            })
        },
        loaded: !loading
    }
}