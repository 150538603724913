import React, {useEffect, useReducer, useRef} from 'react';
import {Button, Col, Divider, Dropdown, Input, Row, Typography} from "antd";
import {DEFAULT_STATE, Dispatcher, Reducer, State} from "./state";
import './style.less';
import cn from 'classnames';
import {DropDownProps} from "antd/lib/dropdown";
import {ButtonProps} from "antd/lib/button";
import uuidv4 from 'uuid/v4';
import {ScheduleNode} from "components/scheduling/types";
import {InputProps} from "antd/lib/input";

// TODO: Split this out into its own component. A pop-out text area would be useful in more places.

export interface NotesForPilotQuickEditProps {
    scheduleNodeData: ScheduleNode,
    previewProps?: ButtonProps,
    textAreaProps?: Omit<InputProps, 'placeholder'>,
    placeholderText?: string,
    readOnly?: boolean,
    disabled?: boolean,
    popupContainer?: HTMLElement,

    renderExtra?: (props: NotesForPilotQuickEditProps['overrideState']) => React.ReactNode[],

    renderAccept?: (options: { onConfirm: (...args: unknown[]) => void, buttonProps: ButtonProps }) => React.ReactNode,
    renderCancel?: (options: { onCancel: () => void, buttonProps: ButtonProps }) => React.ReactNode,

    onConfirm?: (newText: string, ...args: unknown[]) => void,

    // Allows the internal state to be overwritten with an external state
    overrideState?: {
        state: State,
        dispatch: Dispatcher
    }
}

/**
 * Displays an editable TextArea when the user hovers their mouse over tet displayed from the notesForPilot field.
 */
export const NotesForPilotQuickEdit: React.FC<NotesForPilotQuickEditProps> = (props) => {

    const ClassNames = new ClassNameBuilder(props);

    const notesForPilot = props.scheduleNodeData?.notesForPilot || '';

    const componentId = "notes-for-pilot:" + uuidv4();

    let [ state, dispatch ] = useReducer(Reducer, DEFAULT_STATE);

    if (props.overrideState){
        state = props.overrideState.state;
        dispatch = props.overrideState.dispatch;
    }

    const textAreaRef = useRef(null);

    function handleConfirm(){
        props?.onConfirm?.(state.modifiedText);
        dispatch({ type: 'EDIT_MODE', value: false, close: true });
    }

    function handleCancel(){
        dispatch({ type: 'EDIT_MODE', value: false, close: true });
    }

    let renderAccept: NotesForPilotQuickEditProps['renderAccept'] =
        ({ onConfirm, buttonProps }) => {
        return <Button {...buttonProps} onClick={onConfirm} />
    }

    if (props.renderAccept) renderAccept = props.renderAccept;

    let renderCancel: NotesForPilotQuickEditProps['renderCancel'] =
        ({ onCancel, buttonProps }) => {
        return <Button {...buttonProps} onClick={onCancel} />
    }

    if (props.renderCancel) renderCancel = props.renderCancel;

    // Exit edit mode when disabled
    useEffect(() => {
        if (props.readOnly || props.disabled){
            dispatch({ type: 'EDIT_MODE', value: false, close: true });
        }

        if (props.disabled){
            dispatch({ type: 'MOUSE_TRIGGER', value: false });
        }

    }, [props.readOnly, props.disabled]);

    // if notesForPilot field changes, reset the text.
    // This means that while the user is editing the text, updates to notesForPilot
    // will overwrite what they're typing.
    useEffect(() => {
        if (!state.editMode){
            dispatch({ type: 'INIT_TEXT', text: notesForPilot });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notesForPilot, state.open]);


    useEffect(() => {
        // Focus text area when edit mode is enabled
        if (state.editMode && !props.readOnly){
            const textAreaElement = document.getElementById(componentId);
            textAreaElement?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.editMode]);

    let footer = null;
    if (state.editMode){

        let extra = [];
        if (props.renderExtra){
            extra = props.renderExtra({ state, dispatch });
        }

        if (extra.length > 0){
            extra.push(<Divider type="vertical" style={{ margin: 0, height: '100%' }} />)
        }
        extra = extra.map(node => <Col>{node}</Col>);

        footer = (
            <div onClick={(e) => e.stopPropagation()}>
                <Divider style={{ margin: 0 }} />
                <div
                    className={ClassNames.Body}
                >
                    <Row type="flex" gutter={12} justify="end">
                        {extra}
                        <Col>
                            {renderAccept({
                                onConfirm: handleConfirm,
                                buttonProps: {
                                    className: 'mc-btn-green',
                                    icon: 'check',
                                    children: 'Accept'
                                }
                            })}
                        </Col>
                        <Col>
                            {renderCancel({
                                onCancel: handleCancel,
                                buttonProps: {
                                    type: 'danger',
                                    icon: 'close',
                                    children: 'Cancel'
                                }
                            })}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    const overlay = <div
        className={ClassNames.Wrapper}
    >
        <div
            onClick={(e) => e.stopPropagation()}
            className={ClassNames.Body}
        >
            <Input
                {...props.textAreaProps}
                placeholder={state.editMode ? "Type a short pilot note here" : (props.placeholderText || "Click to add pilot notes")}
                id={componentId}
                value={state.modifiedText}
                ref={textAreaRef}
                disabled={props.readOnly}
                className={ClassNames.TextArea}
                // allowClear={state.editMode}
                onChange={(e) => {
                    if (!state.editMode) {
                        dispatch({ type: 'EDIT_MODE', value: true });
                    }
                    dispatch({ type: 'SET_TEXT', text: e.target.value });
                }}
                onClick={() => {
                    dispatch({ type: 'EDIT_MODE', value: true })
                }}
                onKeyDown={(e) => {
                    if (e.ctrlKey && e.key === 'Enter') {
                        handleConfirm();
                    }
                }}
            />
            {state.editMode ? (
                <span>
                    <Typography.Text type="secondary">Press Ctrl + Enter to accept</Typography.Text>
                    <Button
                        type="link"
                        style={{ padding: 0, float: 'right' }}
                        size="small"
                        onClick={() => dispatch({ type: 'SET_TEXT', text: '' })}
                    >
                Clear
            </Button>
                </span>
            ) : (
                !props.readOnly ? <Typography.Text
                    type="secondary"
                >Click to edit</Typography.Text> : null
            )}
        </div>
        {footer}
    </div>

    let triggers: DropDownProps['trigger'] = ['click'];

    if (!state.editMode){
        triggers.push('hover');
    }

    if (!notesForPilot && props.readOnly){
        triggers = [];
    }

    if (props.disabled){
        triggers = [];
    }

    let placeholderText = "Click to add pilot notes";

    if (props.placeholderText){
        placeholderText = props.placeholderText;
    }
    else if (props.readOnly){
        placeholderText = '—';
    }

    return <>
        <Dropdown
            visible={state.open}
            overlay={overlay}
            trigger={triggers}
            getPopupContainer={() => {
                return props.popupContainer
            }}
            onVisibleChange={(visible) => {
                if (!props.disabled) {
                    dispatch({ type: 'MOUSE_TRIGGER', value: visible });
                }
            }}
            align={{
                offset: [-12, -36]
            }}
            overlayStyle={{
                width: '400px'
            }}
            placement="bottomLeft"
            mouseEnterDelay={0}
        >
            <Button
                size="small"
                {...props.previewProps}
                disabled={props.previewProps?.disabled || props.readOnly || props.disabled}
                className={cn({
                    [ClassNames.Preview]: true,
                    [ClassNames.PreviewDisabled]: props.readOnly,
                    [props.previewProps?.className]: Boolean(props.previewProps?.className)
                })}
                onClick={(e) => e.stopPropagation()}
            >
                {notesForPilot ? notesForPilot : <Typography.Text type="secondary">{placeholderText}</Typography.Text>}
            </Button>
        </Dropdown>
    </>
}

/**
 * Builds the classnames used for the NotesFroPilotRenderer component based on props.
 */
class ClassNameBuilder {

    private disabled = false;

    constructor(props: NotesForPilotQuickEditProps) {
        this.disabled = Boolean(props.readOnly);
    };

    private getDisabledSuffix(className: string){
        if (this.disabled)
            return className + ' ' + className + '-disabled';
        return className
    }

    Base = 'notes-for-pilot-quickedit';
    Wrapper = this.Base + '-wrapper';
    Preview = this.getDisabledSuffix(this.Base + '-preview');
    Body = this.Base + '-body';
    TextArea = this.Base + '-textarea';
    PreviewDisabled = this.Base + '-preview-disabled';
}