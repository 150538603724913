import React, { useState } from "react";
import { momentOrNull, YesNo } from "../../../common/util";
import { Button, Icon } from "antd";
import PaxDetail from "./Pax";
import CgoDetail from "./Cgo";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";
import useSortableData from "hooks/useSortableData";

const CarriableCount = ({ count, onClicked }) => {
  const text = `${count} item(s)`;
  if (!count) {
    return text;
  }
  return (
    <Button className="mc-link-btn" onClick={onClicked}>
      {text}
    </Button>
  );
};

const ManifestDetail = (props) => {
    const [selFlight, setSelFlight] = useState(null);
    const [carriableType, setCarriableType] = useState('pax');

    const mappedData = props.data.map((row) => {
        const getVal = (obj, field) => obj && obj[field];
        const legs = (row.legs && Object.values(row.legs)) || [];
        const haslegs = legs.length ? true : false;
        const firstleg = haslegs ? legs[0] : null;
        const departure = (firstleg && firstleg.departure) || (row.departure && row.departure.name) || null;
        const destinations = (haslegs && legs.map((leg) => leg.destination).join(', ')) || null;
        return {
            ...row,
            departure,
            destinations,
            tailNum: getVal(row.aircraft, 'tailNum'),
            customerName: getVal(row.customer, 'name'),
            transporterName: getVal(row.transporter, 'name'),
            finalized: row.nodeState === 'FINALIZED',
        };
    });

    const [ data, { setSortKey, currentSortKey, currentSortDir } ] = useSortableData(mappedData);

    const setSelectedFlight = (selFlight, ct = carriableType) => {
        setSelFlight(selFlight);
        setCarriableType(ct);
    };

    const getSelectedFlight = () => {
        if (!selFlight || !data) return undefined;
        return data.find((flight) => flight._id === selFlight);
    };

    const columns = [
        {
            name: 'Flight Designation',
            key: 'desig',
        },
        {
            name: 'Departure',
            key: 'departure',
        },
        {
            name: 'Destinations',
            key: 'destinations',
        },
        {
            name: 'Transporter',
            key: 'transporterName',
        },
        {
            name: 'Customer',
            key: 'customerName',
        },
        {
            name: 'Aircraft Tail Num',
            key: 'tailNum',
        },
        {
            name: 'Scheduled Flight Date',
            key: 'scheduledFlightDate',
            formatter: (value) => momentOrNull(value)?.format('YYYY-MM-DD'),
        },
        {
            name: 'Finalized',
            key: 'state',
            formatter: (value) => YesNo(value === 'FINALIZED' || value === 'RETIRED'),
        },
        {
            name: 'PAX Count',
            key: 'paxList',
            formatter: (value, row) => (
                <CarriableCount
                    count={value.length}
                    onClicked={() => setSelectedFlight(row._id, 'pax' )}
                />
            ),
        },
        {
            name: 'CGO Count',
            key: 'cgoList',
            formatter: (value, row) => (
                <CarriableCount
                    count={value.length}
                    onClicked={() => setSelectedFlight(row._id, 'cgo' )}
                />
            ),
        },
        {
            name: 'Export',
            Key: 'export',
            formatter: (_, row) => (
                renderExportButtons(row)
            )
        }
    ]
        .map((col) => {
            const cellRenderer = (i) => {
                const value = col.formatter ?
                    col.formatter(data[i]?.[col.key], data[i]) :
                    data[i]?.[col.key]
                return <Cell tooltip={value} style={{ display: 'flex', alignItems: 'center' }}>{value}</Cell>
            }
            return (
                <Column
                    key={col.key}
                    name={col.name}
                    cellRenderer={cellRenderer}
                    columnHeaderCellRenderer={
                        () => (
                            <SortableColumnHeaderCell
                                name={col.name}
                                sortKey={col.key}
                                onSort={setSortKey}
                                currentSortedKey={currentSortKey}
                                currentSortDir={currentSortDir}
                            />
                        )
                    }
                />
            )
        })

    const [ rowHeights, setRowHeights ] = useState(new Array(data?.length).fill(30));

    const renderFlightsGrid = () => {
        if (!data) return null;
        return (
            <Table
                selectionModes={SelectionModes.NONE}
                className="mc-table"
                numRows={data?.length}
                rowHeights={rowHeights}
                onRowHeightChanged={(i, size) => (
                    setRowHeights(curr => [...curr.slice(0, i), size, ...curr.slice(i+1, rowHeights.length)])
                )}
            >
                {columns}
            </Table>
        );
    };

    const renderExportButtons = (row) => {
        return (
            <Button size="small" style={{ color: 'green' }} onClick={() => {
                props.onManifestExportClick(row._id, row.desig);
            }}>
                <Icon type="file-excel" />Export
            </Button>
        );
    };

    const renderChildGrids = () => {
        switch (carriableType) {
            case 'pax':
                return <PaxDetail data={getSelectedFlight().paxList} minHeight="40rem" />;
            case 'cgo':
                return <CgoDetail data={getSelectedFlight().cgoList} minHeight="40rem" />;
            default:
                break;
        }
    };

    const selectedFlight = getSelectedFlight();

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            {selectedFlight ? (
                <div className="mc-border-bottom" style={{ padding: 12, display: 'flex', alignItems: 'center' }}>
                    <Button icon="left" onClick={() => setSelFlight(null)} />
                    <h2 style={{ marginLeft: '1rem', marginBottom: 0, display: 'inline' }}>
                        {`${carriableType === 'pax' ? 'Passengers' : 'Cargo'} of flight ${selectedFlight.desig}`}
                    </h2>
                </div>
            ) : null}
            <div style={{ flex: 1, minHeight: 0 }}>
                {selectedFlight ? renderChildGrids() : renderFlightsGrid()}
            </div>
        </div>
    );
};

export default ManifestDetail;