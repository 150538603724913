import { Button, Input, InputNumber, message } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { requiredRule } from 'common/form';
import { cleanGraphQLErrorMsg, dateMtimeMtoMoment } from 'common/util';
import DateTimePicker from 'components/date-time-picker';
import AircraftSelect from 'components/form/AircraftSelect';
import ContractSelect from 'components/form/ContractSelect';
import LocationSelect2 from 'components/form/select/LocationSelect/LocationSelect2';
import { OrgDataContext } from 'context/orgData';
import { validateDateTimePicker } from 'formValidators';
import gql from 'graphql-tag';
import useDelayedEffect from 'hooks/useDelayedEffect';
import moment, { Moment } from 'moment';
import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { getValidateAmountValidator, getValidatePSIDValidator } from './form';

export interface AddFuelTakenFormFields {
    amount: number,
    dateTimeTaken: { date: Moment, time: Moment },
    flightIdName: string,
    contractId: string,
    aircraftId: string,
    psid: number
}

export interface AddFuelTakenFormProps extends FormComponentProps {
    date?: moment.Moment,
    onCancel?: () => void,
    onSubmit?: (values: AddFuelTakenFormFields, form: WrappedFormUtils) => void,
    submitting?: boolean,
    error?: any
}

export interface AddFuelTakenFormSubmitterProps {
    children: React.ReactElement<React.FC<AddFuelTakenFormProps>>,
    onSuccess?: (fuelTakenData: any, form: WrappedFormUtils) => void
}

// Wraps AddFuelTakenForm component to add submission functionality
const FuelTakenFormSubmitter: React.FC<AddFuelTakenFormSubmitterProps> = (props) => {

    const { transporterID } = useContext(OrgDataContext);

    const MUTATION = gql`
    mutation AddFuelTaken($tpID: String!, $fuelTaken: FuelTakenInput!){
        UploadFuelTaken(
            tpID: $tpID
            fuelTaken: $fuelTaken
            overwriteExisting: false
        ){
            locationId
            dateTimeTaken
            flightIdName
            amount
            psid
            aircraftId
            contractId
        }
    }
    `

    const [ submit, { loading } ] = useMutation(MUTATION);

    return React.cloneElement(props.children, {
        submitting: loading,
        onSubmit: (values, form) => {
            let dtt = dateMtimeMtoMoment(values.dateTimeTaken).format('YYYY-MM-DD  HH:mm');
            submit({
                variables: {
                    tpID: transporterID,
                    fuelTaken: {
                        ...values,
                        dateTimeTaken: dtt
                    }
                }
            })
            .then(() => {
                props.onSuccess?.(values, form);
                message.success("Successfully added fuel taken record.");
            })
            .catch((e) => {
                message.error("Failed to add fuel taken due to an error: " + cleanGraphQLErrorMsg(e.message), 5);
            })
        }
    });
}

// Displays a form for adding a new fuel taken object. Doesn't submit anything on its own,
// must be wrapped by FuelTakenForm.Submitter component.
const AddFuelTakenForm_Internal: React.FC<AddFuelTakenFormProps> = (props) => {

    const values = props.form.getFieldsValue();

    useDelayedEffect(() => {
        props.form.validateFields();
    }, [ values.amount, values.psid ])

    return <Form onSubmit={(e) => {
        e.preventDefault();

        props.form.validateFieldsAndScroll((err, values) => {
            if (!err){
                props.onSubmit?.(values, props.form);
            }
        })
    }}>
        <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap' }}>
            <Form.Item label="Location">    
                {props.form.getFieldDecorator('locationId', { rules: requiredRule() })(
                    <LocationSelect2 style={{ width: '120px' }} dropdownMatchSelectWidth={false} />
                )}
            </Form.Item>
            <Form.Item label="Amount">    
                {props.form.getFieldDecorator('amount', { rules: [{ validator: getValidateAmountValidator(props.form) }] })(
                    <InputNumber />
                )}
            </Form.Item>
            <Form.Item label="Date/Time Taken">
                {props.form.getFieldDecorator('dateTimeTaken', {
                    initialValue: { date: props.date || moment(), time: undefined },
                    rules: [{ required: true, message: 'This field is required', validator: validateDateTimePicker }] 
                })(
                    <DateTimePicker datePickerProps={{ size: 'default' }} timePickerProps={{ size: 'default' }} />
                )}
            </Form.Item>
            <Form.Item label="Flight ID">    
                {props.form.getFieldDecorator('flightIdName')(
                    <Input />
                )}
            </Form.Item>
            <Form.Item label="Contract">    
                {props.form.getFieldDecorator('contractId')(
                    <ContractSelect style={{ width: '120px' }} dropdownMatchSelectWidth={false} />
                )}
            </Form.Item>
            <Form.Item label="AC Tail">    
                {props.form.getFieldDecorator('aircraftId')(
                    <AircraftSelect style={{ width: '90px' }} dropdownMatchSelectWidth={false} />
                )}
            </Form.Item>
            <Form.Item label="PSID">
                {props.form.getFieldDecorator('psid', { rules: [{ validator: getValidatePSIDValidator(props.form) }] })(
                    <InputNumber style={{ width: '60px' }} />
                )}
            </Form.Item>
            </div>
            <div>
                <Button
                    size='large'
                    type='primary'
                    style={{ marginRight: '12px' }}
                    loading={props.submitting}
                    htmlType="submit"
                >Add</Button>
                <Button size='large' onClick={props.onCancel}>Cancel</Button>
            </div>
    </Form>
}

const AddFuelTakenForm_temp = Form.create<AddFuelTakenFormProps>()(AddFuelTakenForm_Internal);

type AddFuelTakenFormType = { Submitter: React.FC<AddFuelTakenFormSubmitterProps> } & typeof AddFuelTakenForm_temp;

const AddFuelTakenForm: AddFuelTakenFormType = Object.assign(AddFuelTakenForm_temp, {
    Submitter: FuelTakenFormSubmitter
})

AddFuelTakenForm.displayName = 'Form(AddFuelTakenForm)';
AddFuelTakenForm.Submitter.displayName = 'AddFuelTakenForm.Sumbitter';

export default AddFuelTakenForm;