import { message } from "antd";
import { ThemeContext } from "context/theme";
import usePersistence from "hooks/persistence"
import { useContext, useEffect } from "react";

export interface DbShortcutsInterface {
    autoScrollEnabled: boolean,
    scrollSpeed: number,
    setAutoScroll?: (enabled: boolean) => void
}

const DEFAULTS: DbShortcutsInterface = {
    autoScrollEnabled: true,
    scrollSpeed: 5
}

export interface DbShortcutsOptions {

    /** Max speed of automatic scrolling. Defaults to 10. */
    maxScrollSpeed?: number,
    disableShortcuts?: ('theme' | 'auto-scroll' | 'auto-scroll-speed')[]
}

function useFbShortcuts(options?: DbShortcutsOptions): DbShortcutsInterface{

    let maxScrollSpeed = options?.maxScrollSpeed || 10;

    const { persistentData, setPersistentValue } = usePersistence<DbShortcutsInterface>('flightboard.shortcut-settings', DEFAULTS);
    const { themeName, changeTheme } = useContext(ThemeContext);

    function isDisabled(shortcut: 'theme' | 'auto-scroll' | 'auto-scroll-speed'){
        if (!options?.disableShortcuts){
            return false;
        }
        let idx = options.disableShortcuts.findIndex((s) => s === shortcut);
        return idx > -1
    }

    function handleKey(e: KeyboardEvent){
        if (document.activeElement?.tagName === 'INPUT'){
            // User is focused on an input. Don't allow keyboard shortcuts.
            return;
        }
        if (!e.ctrlKey && !e.altKey && !e.shiftKey && e.key === 'd' && !isDisabled('theme')){
            const newTheme = themeName === 'dark' ? 'light' : 'dark';
            changeTheme(newTheme);
        }
        else if (!e.ctrlKey && !e.altKey && !e.shiftKey && e.key === 's' && !isDisabled('auto-scroll')){
            setPersistentValue('autoScrollEnabled', !persistentData.autoScrollEnabled);
            if (persistentData.autoScrollEnabled){
                message.info({ content: 'Auto scroll disabled', key: 'autoScrollMsg' }, 1);
            }
            else
            {
                message.info({ content: 'Auto scroll enabled', key: 'autoScrollMsg' }, 1);
            }
        }
        else if (!e.ctrlKey && !e.shiftKey && !e.altKey && e.key === '=' && !isDisabled('auto-scroll-speed')){
            e.preventDefault();
            let newSpeed = Math.min(persistentData.scrollSpeed + 1, maxScrollSpeed);
            message.info({ content: 'Increased auto scroll speed to ' + newSpeed, key: 'scrollSpeedMsg' }, 1);
            setPersistentValue('scrollSpeed', newSpeed);
        }
        else if (!e.ctrlKey && !e.shiftKey && !e.altKey && e.key === '-' && !isDisabled('auto-scroll-speed')){
            e.preventDefault();
            let newSpeed = Math.max(persistentData.scrollSpeed - 1, 1);
            message.info({ content: 'Decreased auto scroll speed to ' + newSpeed, key: 'scrollSpeedMsg' }, 1);
            setPersistentValue('scrollSpeed', newSpeed);
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleKey);
        return () => {
            document.removeEventListener('keyup', handleKey);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ themeName, persistentData ])

    return {
        ...persistentData,
        setAutoScroll: (enabled) => setPersistentValue('autoScrollEnabled', enabled)
    }
}

export default useFbShortcuts