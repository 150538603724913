import gql from "graphql-tag";

// eslint-disable-next-line import/no-anonymous-default-export
export default gql`
    extend type Mutation {
        transporter(id: ID!): Transporter
    }
    extend type Query {
        selectedTransporter: Transporter
        selectedOrganization: Organization
        customerList: [Customer]
        tpList: [Transporter]
    }
`