import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Drawer, Icon, Layout, PageHeader } from 'antd';
import wrapCtxProvider, { WithContextOverrideProps } from 'component-wrappers/wrap-ctx-provider';
import CenteredLoadingScreen from 'components/CenteredLoadingScreen';
import NonIdealState from 'components/NonIdealState';
import { getReportRuntimeFromNow } from 'components/reporting/hooks/report-details';
import { Criteria } from 'components/reporting/ReportDetails/Criteria';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { FWBRContext, FWBRContextInterface, FWBRContextProvider } from './context';
import FWBRCriteriaForm from './criteria-form';
import './index.less';
import FWBillingReportTable, { FWBRTContextConsumer } from './table';
import cn from 'classnames';
import { ReportExporter } from 'components/reporting/export';


library.add(faFileAlt);

const BASE_CLS = 'mc-fw-billing-report'

const { Sider, Header, Content } = Layout;


export interface FWBillingReportPageProps extends WithContextOverrideProps<FWBRContextInterface> {
    style?: CSSProperties,
    reportId?: string
}

const FWBillingReportPage_Internal: React.FC<FWBillingReportPageProps> = (props) => {
    const {
        formRef,
        criteriaFormFields,
        onCriteriaFieldsChange,
        resetCriteriaForm,
        submitReport,
        reportSubmitting,
        reportDetails: { reportData, reportState }
    } = useContext(props.overrideContext || FWBRContext);

    const [ showReportCriteria, setShowReportCriteria ] = useState(false);
    const [ showCriteriaSider, setShowCriteriaSider ] = useState(true);
    const [ showExportDrawer, setShowExportDrawer ] = useState(false);
    const [ forceUpdateCount, setForceUpdateCount ] = useState<number>(0);
    const forceUpdateInterval = useRef({ prevVal: 0, interval: null });

    useEffect(() => {
        console.debug('REPORT STATE CHANGED TO "' + reportState + '"')
        console.debug('has interval: ', forceUpdateInterval.current);

        function intervalFunc(){
            setForceUpdateCount(forceUpdateInterval.current.prevVal + 1);
            forceUpdateInterval.current.prevVal++;
        }

        if (reportState === 'IN_PROGRESS' && !forceUpdateInterval.current.interval){
            forceUpdateInterval.current = {
                prevVal: forceUpdateCount,
                interval: setInterval(intervalFunc, 1000)
            }
        }
        if (reportState !== 'IN_PROGRESS'){
            console.debug('CLEARING INTERVAL');
            if (forceUpdateInterval.current){
                clearInterval(forceUpdateInterval.current.interval);
                forceUpdateInterval.current.interval = null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ reportState ])

    useEffect(() => {
        if (reportState === 'NOT_STARTED' && showReportCriteria){
            setShowReportCriteria(false);
        }
        if ((reportState === 'IN_PROGRESS') && !showReportCriteria){
            setShowReportCriteria(true);
        }
    // eslint-disable-next-line
    }, [ reportState ])

    function renderContent(){
        return <>
            {reportState === 'QUERY_FAILED' || reportState === 'DOWNLOAD_FAILED' ? <Alert type="error" showIcon message="Failed to load report"
                style={{ marginBottom: '12px' }}
            /> : null}
            {reportState === 'COMPLETE' && reportData?.status === 'ERROR' ? (
                <Alert type="error" showIcon 
                    message="An error occurred while running the report"
                    description={reportData && reportData.error && reportData.error.type + ": " + reportData.error.message}
                    style={{ marginBottom: '12px' }}
                />
            ) : null}
            {reportState === 'IN_PROGRESS' ? (
                <CenteredLoadingScreen
                    message={
                        <>
                            <div style={{ marginTop: 24, fontSize: '1.2rem' }}>
                                Report is being generated. Please wait.
                            </div>
                            {getReportRuntimeFromNow(reportData) >= 120/* seconds */ ? (
                                <Alert
                                    style={{ marginTop: 12 }}
                                    type="error"
                                    showIcon
                                    message={'It is taking longer than usual to generate this report.'}
                                    description={`
                                        Something might have gone wrong.
                                        If your report seems to be stuck in this state,
                                        try running a new report with more specific criteria or please contact DataFlyt.
                                    `}
                                />
                            ) : null}
                        </>
                    }
            />
            ) : null}
            {reportState === 'DOWNLOADING' ? (
                <CenteredLoadingScreen
                    message={<h3 style={{ textAlign: 'center' }}>Downloading Report</h3>}
                />
            ) : null}
            {reportState === 'NOT_STARTED' ? (
                <NonIdealState
                    title="Choose your criteria"
                    description={
                        <>
                        <div>Use the left panel to choose your criteria. Then click "Generate Report" to run the report.</div>
                        </>
                    }
                    style={{ height: '100%' }}
                    icon={<FontAwesomeIcon icon={faFileAlt} />}
                    type="primary"
                />
            ) : null}
            {reportState === 'COMPLETE' ? (
                <FWBRTContextConsumer>
                    <FWBillingReportTable />
                </FWBRTContextConsumer>
            ) : null}
        </>
    }

    return <Layout style={{ height: '100%', ...props.style }}>
        <Header className={BASE_CLS + "-header"} style={{ display: 'flex', alignItems: 'center' }}>
            <h2 className={BASE_CLS + "-title"}>FlytWatch Billing Report</h2>
            <div style={{ width: '24px' }} />
            {reportData ? (
                <Button
                    onClick={() => setShowCriteriaSider(!showCriteriaSider)}
                    icon={showCriteriaSider ? 'menu-fold' : 'menu-unfold'}
                >{showCriteriaSider ? 'Hide Criteria' : 'Show Criteria'}</Button>
            ) : null}
            <div style={{ margin: '0 auto' }} />
            {reportState === 'COMPLETE' ? (
                <Button type="primary" icon="export" onClick={() => setShowExportDrawer(true)}>
                    Export
                </Button>
            ) : null}
        </Header>
        <Layout>
            <Sider className={cn({ [BASE_CLS + "-sider"]: true, [BASE_CLS + "-sider-hidden"]: !showCriteriaSider})} width={ showCriteriaSider ? 400 : 0} style={{ overflow: 'auto' }}>
                <div style={{ display: showReportCriteria ? undefined : 'none' }}>
                    <PageHeader onBack={() => setShowReportCriteria(false)} title="CRITERIA" className={BASE_CLS + '-form-title'} />
                    <Criteria
                        data={reportData}
                        isInProgress={false}
                        justCriteria={true}
                        criteriaItemStyle={{
                            marginBottom: 12
                        }}
                    />
                    <div style={{ marginTop: 12 }}>
                        <Button type="primary" block onClick={() => setShowReportCriteria(false)}>New Report</Button>
                    </div>
                </div>
                <div style={{ display: showReportCriteria ? 'none' : undefined }}>
                    <PageHeader
                        title="NEW CRITERIA"
                        className={BASE_CLS + '-form-title'}
                        extra={reportData && reportState === 'COMPLETE' ? <Button type="link" onClick={() => setShowReportCriteria(true)}>Show Criteria</Button> : null}
                    />
                    <div>
                        <FWBRCriteriaForm
                            ref={formRef}
                            formFields={criteriaFormFields}
                            onFieldsChange={onCriteriaFieldsChange}
                        />
                    </div>
                    <div style={{ marginTop: 12 }}>
                        <Button
                            type="primary"
                            block
                            onClick={() => {
                                formRef.current.validateFieldsAndScroll((err, values) => {
                                    if (!err){
                                        submitReport(values);
                                    }
                                })
                            }}
                            loading={reportSubmitting}
                        >Generate Report</Button>
                    </div>
                    {formRef.current?.isFieldsTouched() ? (
                        <div style={{ marginTop: 6 }}>
                            <Button type="link" block onClick={resetCriteriaForm}>Reset</Button>
                        </div>    
                    ) : null}
                </div>
            </Sider>
            <Content style={{ padding: 12, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                {renderContent()}
            </Content>
        </Layout>
        <Drawer
            visible={showExportDrawer}
            title={<span><Icon type="export" style={{ marginRight: '0.5rem' }} /><span>Export Report</span></span>}
            width={600}
            onClose={() => setShowExportDrawer(false)}
            bodyStyle={{ height: 'calc(100% - 56px)' }}
        >
            {reportData ? (
                <ReportExporter
                    report_id={reportData._id}
                    column_sets={[{
                        setName: 'Billing Report',
                        key: 'billing',
                        columns: [
                            {
                                label: 'Flight Date',
                                key: 'flightDate'
                            },
                            {
                                label: 'Aircraft Count',
                                key: 'aircraftCount'
                            },
                            {
                                label: 'Active Aircraft',
                                key: 'activeAircraft',
                                format: 'join_array_spaces'
                            },
                        ]
                    }]}
                    report_type="DISPATCH_BILLING"
                />
            ) : null}
        </Drawer>
    </Layout>
}

FWBillingReportPage_Internal.displayName = 'FWBillingReportPage'

const FWBillingReportPage = wrapCtxProvider({
    providerComponent: FWBRContextProvider
})(FWBillingReportPage_Internal)

export default FWBillingReportPage