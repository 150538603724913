import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';
import './override-card.less';
import { getLocalizedMoment } from 'common/util-ts';
import IsnOverrideFileUploader from 'components/isn-override-file-uploader';

const statusMap = {
    'MANUALLY_VERIFIED': 'Manually Verified',
    'MANAGEMENT_OVERRIDE': 'Management Override',
    'CUSTOMER_NOT_ENFORCING': 'Customer Not Enforcing',
    'DO_NOT_OVERRIDE': 'Do Not Override'
}

const RedWhiteOverride = ({ value }) => {
    let color = 'gray';
    let textColor = 'white';
    let label = '';
    switch (value) {
        case 'RED':
            color = 'red';
            label = ' (training)';
            break;
        case 'WHITE':
            color = 'white';
            textColor = 'black';
            label = ' (white listed)';
            break;
        default:
            break;
    }
    return <span style={{ color: textColor, backgroundColor: color }} className='mc-redwhiteoverride'>{value}{label}</span>
}

class OverrideCard extends Component {
    render() {
        const { data, paxData, ...restProps } = this.props;

        const hasNewFieldsAndOverriderName = ((data.overriderFirstName || data.overriderLastName) &&
            (data.overriderTitle || data.overriderReason ||
            data.approverFirstName || data.approverLastName ||
            data.redWhiteOverride))
        
        const hasOverriderNameOnly = ((data.overriderFirstName || data.overriderLastName) &&
        !(data.overriderTitle || data.overriderReason ||
        data.approverFirstName || data.approverLastName ||
        data.redWhiteOverride))

        return (
            <Card className='mc-override-card' style={{marginBottom: '1rem'}} title="Override Status" {...restProps}>
                <Row>
                    <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                        <strong>Override Type:</strong>
                    </Col>
                    <Col span={17}>
                        {statusMap[data.type] || data.type}
                    </Col>
                </Row>
                {hasNewFieldsAndOverriderName ? (
                    <>
                        <Row>
                            <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                                <strong>Overrider Title:</strong>
                            </Col>
                            <Col span={17}>
                                {data.overriderTitle}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                                <strong>Overrider Reason:</strong>
                            </Col>
                            <Col span={17}>
                                {data.overriderReason}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                                <strong>Training or Whitelist Override:</strong>
                            </Col>
                            <Col span={17}>
                                <RedWhiteOverride value={data.redWhiteOverride} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                                <strong>Overrider Name:</strong>
                            </Col>
                            <Col span={17}>
                                {data.overriderFirstName} {data.overriderLastName}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                                <strong>Approver Name:</strong>
                            </Col>
                            <Col span={17}>
                                {data.approverFirstName} {data.approverLastName}
                            </Col>
                        </Row>
                    </>
                ) : null}
                {hasOverriderNameOnly ? (
                    <Row>
                        <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                            <strong>Overrider Name:</strong>
                        </Col>
                        <Col span={17}>
                            {data.overriderFirstName} {data.overriderLastName}
                        </Col>
                    </Row>
                ) : null}
                <Row>
                    <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                        <strong>Notes:</strong>
                    </Col>
                    <Col span={17}>
                        {data.notes}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                        <strong>Override Time:</strong>
                    </Col>
                    <Col span={17}>
                        {data.createdTs ? getLocalizedMoment(data.createdTs, paxData).format('MMMM Do, YYYY H:mm z') : null}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{textAlign: 'right', marginRight: '1rem'}}>
                        <strong>Attached Files:</strong>
                    </Col>
                    <Col span={17}>
                        <IsnOverrideFileUploader paxID={paxData?._id} addOnly />
                    </Col>
                </Row>
            </Card>
        );
    }
}

OverrideCard.propTypes = {
    data: PropTypes.shape({
        type: PropTypes.string,
        notes: PropTypes.string
    }).isRequired
}
 
export default OverrideCard;