import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { getZoneList } from 'common/util-ts';
import React, { useEffect, useState } from 'react';

export interface TimezonePickerProps extends SelectProps {
    date: string,
    noDST?: boolean
}

const TimezonePicker: React.FC<TimezonePickerProps> = (props) => {

    const { date, ...selectProps } = props;

    const [ data, setData ] = useState([]);

    const style = {
        width: '6rem',
        ...selectProps.style
    }

    useEffect(() => {
        let zoneData = getZoneList(date, props.noDST)
        setData(zoneData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ date ])

    return <Select dropdownMatchSelectWidth={false} {...selectProps} style={style}>
        <Select.OptGroup label="Select timezone">
            {data.map((val) => <Select.Option title={val.name} key={val.name} value={val.name}>{val.shorthand} ({val.offset})</Select.Option>)}
        </Select.OptGroup>
    </Select>
}

TimezonePicker.defaultProps = {
    noDST: false
}

export default TimezonePicker