import McMap from 'common/mc-map';
import { McMapProps } from 'common/mc-map/mc-map';
import getGenericStateReducer from 'common/reducer/generic-state-reducer';
import React, { CSSProperties, Ref, useCallback, useEffect, useReducer } from 'react';
import { MapRef } from 'react-map-gl';

export interface FlytWatchDetailsMapProps extends McMapProps {
    viewport?: {
        height: number,
        width: number
    },
    style?: CSSProperties,
    ref?: Ref<MapRef>
}

interface ReducerState {
    viewport: any
}

const ACTION_TYPES = {
    SET_VIEWPORT: 'SET_VIEWPORT'
}

const DEFAULT_STATE: ReducerState = {
    viewport: {
        width: '100%',
        height: '100%',
        latitude: 28.592096591682588,
        longitude: -90.72440040014745,
        zoom: 6
    }
}

function reducer(state: ReducerState, type: string, payload: any){
    switch (type){
        case ACTION_TYPES.SET_VIEWPORT:
            return {
                ...state,
                viewport: {
                    ...state.viewport,
                    ...payload
                }
            }
        default:
            return state;
    }
}

const FlytWatchDetailsMap: React.FC<FlytWatchDetailsMapProps> = React.forwardRef((props, ref: Ref<MapRef>) => {

    let [ state, dispatch ] = useReducer(getGenericStateReducer<ReducerState>(reducer), DEFAULT_STATE)

    let viewport = {
        ...state.viewport,
        ...props.viewport
    }

    const handleMove = useCallback(({ viewState }) => {
        dispatch({ type: ACTION_TYPES.SET_VIEWPORT, payload: viewState });
    }, [])
    
    useEffect(() => {
        setTimeout(() => {
            dispatch({ type: ACTION_TYPES.SET_VIEWPORT, payload: {
                latitude: props.latitude,
                longitude: props.longitude,
                zoom: props.zoom || state.viewport.zoom,
                // transitionDuration: 5000,
                // transitionInterpolator: new FlyToInterpolator(),
                // transitionEasing: easeCubicOut
            } })
        }, 100)
    // eslint-disable-next-line
    }, [])

    return <McMap
        onMove={handleMove}
        {...props}
        {...viewport}
        latitude={state.viewport.latitude}
        longitude={state.viewport.longitude}
        zoom={state.viewport.zoom}
        ref={ref}
    >
        {props.children}
    </McMap>
})

export default FlytWatchDetailsMap;