import React, { useContext, useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { GlobalAppStateContext } from 'context/global-app-state';
import windowSizes from 'common/windowSizes';

const { Sider } = Layout;

const DocksNav = () => {
    const { state: { sidebarCollapsed }, setState } = useContext(GlobalAppStateContext);
    const [ hasExpanded, setHasExpanded ] = useState(false);

    useEffect(() => {
      if (!sidebarCollapsed && !hasExpanded){
        setHasExpanded(true);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sidebarCollapsed ])

    const history = useHistory();
    const { pathname } = useLocation();

    function handleMenuClick({ key }){
        history.push(`/app/${key}`);
        if (window.innerWidth <= windowSizes.small){
            setState({ sidebarCollapsed: true })
        }
    }
    const pathArray = pathname.split('/').filter(path => path);

    var key;
    if (pathname.startsWith('/app/masterdata')){
        key = `${pathArray[1]}/${pathArray[2]}`
    }
    else{
        key = pathArray[1];
    }

    let menu = <Menu
        selectedKeys={[key]}
        defaultOpenKeys={['masterdata']}
        mode="inline"
        style={{height: '100%'}}
        onClick={handleMenuClick}
        theme="dark"
    >
        <Menu.Item key="boats">
            Boats
        </Menu.Item>
        <Menu.Item key="checkin">
            Check In
        </Menu.Item>
        <Menu.SubMenu key="masterdata" title="Master Data">
            <Menu.Item key="masterdata/location">Locations</Menu.Item>
            <Menu.Item key="masterdata/employer">Employers</Menu.Item>
            <Menu.Item key="masterdata/vessel">Vessels</Menu.Item>
        </Menu.SubMenu>
    </Menu>

    if (!hasExpanded) menu = null;

    return (
        <Sider
        collapsed={sidebarCollapsed}
        breakpoint="lg"
        >
            {menu}
        </Sider>
    )

}

export default DocksNav