import { Layout } from 'antd';
import cn from 'classnames';
import CenteredLoadingScreen from 'components/CenteredLoadingScreen';
import FlightTimesHeader from 'components/flights/times/FlightTimesHeader';
import LiveCargo from 'components/live-2/cargo';
import LiveFlights from 'components/live-2/flights';
import LivePassengers from 'components/live-2/passengers';
import { MDAircraft, MDContract, MDCustomer, MDEmployer, MDLocation, MDPersonnel, MDPilot } from 'components/masterdata-2-0/pages';
import MobileScreenAlert from 'components/MobileScreenWarningAlert';
import QuartermasterMainPage from 'components/quartermaster/pages/quartermaster';
import { GlobalAppStateContext } from 'context/global-app-state';
import FuelTakenPage from 'pages/app/flytwatch/fuel-taken';
import FuelTakenReportPage from 'pages/app/flytwatch/fuel-taken-report';
import { FWHistoryEditorContextProvider } from 'pages/app/flytwatch/history-editor-page/editor';
import qs from 'qs';
import React, { lazy, useContext } from 'react';
import { Mutation } from 'react-apollo';
import { Prompt } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getAdminRoutes } from 'routes/admin';
import LiveFlightEditor from '../../containers/flighteditor/LiveFlightEditor';
import { CheckInPassengerFlight } from '../../Mutations/CheckInPassenger';
import CurrentOrg from '../CurrentOrg';
import MainScheduling from '../flights/scheduling/main';
import { PaxCheckIn } from '../PaxCheckIn';
import PrivateRoute from '../PrivateRoute';
import { ReportingInterface } from '../reporting';
import { renderDetail as renderReportDetail } from '../reporting/detail';
import { renderExport as renderReportExport } from '../reporting/export';
import { ReportDetailsWithData } from '../reporting/ReportDetails';
import { renderSummary as renderReportSummary } from '../reporting/summaries';
import { requireUserGroup, RequireUserGroup, withUserGroups } from '../userGroup';
import WithOrgData from '../WithOrgData';
import Dashboard from './dashboard/Dashboard';
import FlightsNav from './FlightsNav';
import NonPreferred from './masterdata/nonpreferred/NonPreferred.js';
import WhiteList from './masterdata/whitelisting/Whitelist';
import ISNReport from './report/ISN';
import TsaCheck from './TsaCheck';
import EmailTriggersPage from 'pages/app/management/email-triggers/email-triggers';
import useUserGroups from 'hooks/useUserGroups';

// Use react lazy loading
// https://reactgo.com/tutorial-reactlazy-react-suspense/
const FlytWatch = lazy(() => import('pages/app/flytwatch'));
const FlytWatchHistory = lazy(() => import('pages/app/flytwatch/history'));
const FlytWatchHistoryEditor = lazy(() => import('pages/app/flytwatch/history-editor-page'));
const FlytWatchHistoricalReportingPage = lazy(() => import('pages/app/flytwatch/historical-reporting'))

const basePath = "/app/";

/** Removes padding from child component by using negative margins */
const removePadding = (Cp) => (props) => {
    return <div style={{ margin: -24, height: 'calc(100% + 48px)' }} >
        <Cp {...props} />
    </div>
}

const { Content } = Layout;

const getReport = (type) => {
    switch (type) {
        case 'covid':
            return {
                title: 'Covid Pax Report',
                type: "COVID"
            }
        case 'vaccinated-passengers':
            return {
                title: 'Vaccinated Pax',
                description: 'Reports on whether or not passengers are vaccinated against Covid-19',
                type: 'PAX_COVID19_VAX'
            }
        case 'personnel-vax-details':
            return {
                title: 'Personnel Vaccine Details',
                description: 'Reports all personnel Covid-19 vaccination records',
                type: 'PERSON_COVID19_VAX_DETAILS'
            }
        case 'passenger':
            return {
                title: 'Passenger Report',
                type: 'PAX'
            }
        case 'cargo':
            return {
                title: 'Cargo Report',
                type: 'CGO'
            }
        case 'manifest':
            return {
                title: 'Manifest Report',
                type: 'MANIFEST'
            }
        case 'isn':
            return {
                title: 'ISN Report',
                type: 'ISN'
            }
        case 'hotfuel':
            return {
                title: 'Hot Fuel Report',
                type: 'PERSON_HOTFUEL'
            }
        case 'pax-isn-events':
            return {
                title: 'Pax ISN Events',
                type: 'PAX_ISN_EVENTS',
                description: 'Reports ISN events from passengers (overrides and non-green isn statuses)'
            }
        default:
            return
    }
}

/** Connect ReportingInterface state changes with routes */
const renderReportInterface = ({ history, match: { params: { type, tab, id } } }) => {
    const r = getReport(type);
    if (!r){
        return <div>Unsupported report type</div>
    }
    const onTabChange = (tab) => {
        history.push(basePath + `report/${type}/${tab}`);
    }
	const onManifestExportClick = (flightID) => {
        history.push(basePath + `report/${type}/details/${id}/export/${flightID}`);
    }
    const onSelectedReportChange = (id, name) => {
        history.push({
            pathname: basePath + `report/${type}/details/${id}`,
            state: {
                reportName: name
            }
        });
    }
    return (
        <ReportingInterface
            type={r.type}
            title={r.title}
            description={r.description}
            activeTab={tab}
			onManifestExportClick={onManifestExportClick}
            onTabChange={onTabChange}
            onSelectedReportChange={onSelectedReportChange}
        />
    )
}

/** Connect ReportDetails state changes with routes */
const renderReportDetails = ({ history, match: { params: { type, id, tab, flightID } } }) => {
    const onTabChange = (activeTab) => {
        history.push(basePath + `report/${type}/details/${id}/${activeTab}`);
    }
	const onManifestExportClick = (flightID) => {
        history.push(basePath + `report/${type}/details/${id}/export/${flightID}`);
    }
    const onBackClicked = () => {
        history.push(basePath + `report/${type}`)
    }
    const r = getReport(type);
    if (!r) {
        return <div>Unsupported report type</div>
    }

    return (
        <Layout style={{margin: -24, height: 'calc(100% + 48px)'}}>
            <ReportDetailsWithData
                id={id}
                activeTab={tab}
                onTabChange={onTabChange}
                onBackClicked={onBackClicked}
                renderSummary={(data) => renderReportSummary(r.type, data)}
                renderDetail={(data) => renderReportDetail(r.type, data, onManifestExportClick)}
                renderExport={(reportId) => renderReportExport(r.type, reportId, flightID)}
                enableExport={true}
            />
        </Layout>
    )
}

/** Render FlightEditor with flightid from route url */
const renderFlightEditor = ({ match: { params: { flightid } }, history }) => {
    return (
        <LiveFlightEditor
            flightId={flightid}
            style={{margin: -24, height: 'calc(100% + 48px)'}}
            onBack={() => history.push(basePath + 'flights')}
            onIDChange={(newID) => history.push(basePath + 'flights/' + newID)}
        />
    )
}

const renderLayout = (component) => (props) => {
    const cmp = React.cloneElement(component, props);
    return (
        <div style={{ margin: '-24px', height: 'calc(100% + 48px)', display: 'flex' }}>
            {cmp}
        </div>
    )
}

/** Render PassengerCheckIn with personId from route url */
const renderPassengerCheckIn = (props) => {
    var personId = props.match.params.personId;
    let qsParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    return renderLayout(
        <Mutation
            mutation={CheckInPassengerFlight}
        >
        {(checkIn, { data, loading, error }) => {
            return (
                <CurrentOrg>
                {({ transporter }) => {
                    return (
                        <PaxCheckIn
                            type={'flight'}
                            selectedPerson={personId}
                            onBackClicked={qsParams.goBackTo ? () => props.history.push(qsParams.goBackTo) : undefined}
                            onPersonSelect={(id) => props.history.push(basePath + 'passengers/checkin/' + id)}
                            onSubmit={(values) => {
                                checkIn({
                                    variables: {
                                        payload: values
                                    }
                                })
                            }}
                            onClearSelection={() => props.history.push(basePath + 'passengers/checkin')}
                            submitting={loading}
                            submitError={error}
                            submitSuccess={data && !loading && !error}
                            submittedPassengerData={data && data.CheckInPassengerFlight}
                        />
                    )
                }}
                </CurrentOrg>
            )
        }}
        </Mutation>
    )(props)
}

const FlightsRoutes = React.memo(({ orgData: { customer }, userGroups }) => (
    <>
    {/* Displays warning when using on a small screen for unoptimized pages */}
    <Route
        path={[
            basePath + 'scheduling/sets',
            basePath + 'scheduling/groups',
            basePath + 'scheduling/flight',
            basePath + 'dashboard',
            basePath + 'flightTimes',
            basePath + 'report',
            basePath + 'passengers/checkin',
            basePath + 'flights/:flightid'
        ]}
        render={() => (
            <MobileScreenAlert
                style={{ margin: '-24px', marginBottom: '24px' }}
            />
        )}
    />
    <Switch>
        {getAdminRoutes()}
        
        <PrivateRoute exact path={basePath} component={() => <Redirect to={basePath + 'passengers'} />} />
        <PrivateRoute path={basePath + 'boats'} component={() => <Redirect to={basePath + 'passengers'} />} />
        <PrivateRoute exact path={basePath + "dashboard"} component={Dashboard} />
        <PrivateRoute exact path={basePath + "flights"} component={LiveFlights} />
        <PrivateRoute exact path={basePath + "flytwatch"} render={() => <React.Suspense fallback={<CenteredLoadingScreen />}>
            <FlytWatch />
        </React.Suspense>} />
        <PrivateRoute
            exact
            path={basePath + "flytwatch/details/:type/:id"}
            render={({ match: { params }, history }) => <React.Suspense fallback={<CenteredLoadingScreen />}>
                <FlytWatch detailsDrawerProps={{
                    dataId: params.id,
                    dataType: params.type,
                    visible: true,
                    onClose: () => history.push(basePath + 'flytwatch')
                }} />
            </React.Suspense>}
        />
        <PrivateRoute exact path={basePath + "flytwatch/history"} render={({ location, history }) => {
            let qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            return <React.Suspense fallback={<CenteredLoadingScreen />}>
                <FlytWatchHistory
                    flightDate={qsParams.date}
                    contractId={qsParams.contractID}
                    tz={qsParams.tz}
                    onAircraftChosen={(airID, date, tz) => history.push(basePath + "flytwatch/history/editor/" + airID + "/" + date + "?tz=" + tz, { historySearchUrl: location.pathname + location.search })}
                    getFlightEditorHref={(airID, date, tz) => basePath + "flytwatch/history/editor/" + airID + "/" + date + "?tz=" + tz}
                    onFormSubmit={({ flightDate, contractId, tz }) => {
                        let params = []
                        if (flightDate){
                            params.push(`date=${flightDate}`)
                        }
                        if (contractId){
                            params.push(`contractID=${contractId}`)
                        }
                        if (tz){
                            params.push(`tz=${tz}`)
                        }
                        if (params.length){
                            history.push(basePath + 'flytwatch/history?' + params.join('&'))
                        }
                        else
                        {
                            history.push(basePath + 'flytwatch/history')
                        }
                    }}
                />
            </React.Suspense>
        }} />
        <PrivateRoute exact path={basePath + "flytwatch/history/editor/:aircraftID/:date"} render={({ match: { params }, location, history }) => {
            let qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            return <FWHistoryEditorContextProvider
                aircraftID={params.aircraftID}
                flightDate={params.date}
                onApproved={() => history.push(basePath + "flytwatch/history?date=" + params.date + '&tz=' + qsParams.tz)}
                onDiscard={() => history.push(basePath + "flytwatch/history?date=" + params.date + '&tz=' + qsParams.tz)}
                >
                <React.Suspense fallback={<CenteredLoadingScreen />}>
                    <FlytWatchHistoryEditor
                        showBackButton
                        useExternalEditorContext
                        onBack={() => {
                            history.push(basePath + "flytwatch/history?date=" + params.date + '&tz=' + qsParams.tz);
                        }}
                        onDiscard={() => {
                            history.push(basePath + "flytwatch/history?date=" + params.date + '&tz=' + qsParams.tz);
                        }}
                        aircraftID={params.aircraftID}
                        flightDate={params.date}
                        onPreview={() => history.push(basePath + "flytwatch/history/preview/" + params.aircraftID + "/" + params.date + '?tz=' + qsParams.tz)}
                        renderPrompt={(valuesChanged, isApproved) => (
                            <Prompt
                                when={valuesChanged && !isApproved}
                                message={(location) => {
                                    if (isApproved) return null
                                    if (location.pathname !== basePath + "flytwatch/history/preview/" + params.aircraftID + "/" + params.date){
                                        return "You currently have unsaved data. Are you sure you want to leave?"
                                    }
                                    return null;
                                }}
                            />
                        )}
                    />
                </React.Suspense>
            </FWHistoryEditorContextProvider>
        }} />
        <PrivateRoute exact path={basePath + "flytwatch/history/preview/:aircraftID/:date"} render={({ match: { params }, location, history }) => {
            let qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            return <FWHistoryEditorContextProvider
                        aircraftID={params.aircraftID}
                        flightDate={params.date}
                        onApproved={() => history.push(basePath + "flytwatch/history?date=" + params.date + '&tz=' + qsParams.tz)}
                        onDiscard={() => history.push(basePath + "flytwatch/history?date=" + params.date + '&tz=' + qsParams.tz)}
                    >
                    <React.Suspense fallback={<CenteredLoadingScreen />}>
                    <FlytWatchHistoryEditor
                        showBackButton
                        preview
                        aircraftID={params.aircraftID}
                        flightDate={params.date}
                        onBack={() => {
                            history.push(basePath + "flytwatch/history/editor/" + params.aircraftID + "/" + params.date + '?tz=' + qsParams.tz)
                        }}
                        onDiscard={() => {
                            history.push(basePath + "flytwatch/history?date=" + params.date + '&tz=' + qsParams.tz);
                        }}
                        dispatchID={params.id}
                        onReturnToEditor={() => {
                            history.push(basePath + "flytwatch/history/editor/" + params.aircraftID + "/" + params.date + '?tz=' + qsParams.tz)
                        }}
                        useExternalEditorContext
                        renderPrompt={(valuesChanged, isApproved) => (
                            <Prompt
                                when={valuesChanged && !isApproved}
                                message={(location) => {
                                    if (isApproved) return null
                                    if (location.pathname !== basePath + "flytwatch/history/editor/" + params.aircraftID + "/" + params.date){
                                        return "You currently have unsaved data. Are you sure you want to leave?"
                                    }
                                    return null;
                                }}
                            />
                        )}
                    />
                </React.Suspense>
            </FWHistoryEditorContextProvider>
        }} />
        <PrivateRoute exact path={basePath + "flytwatch/historical-reporting"} render={() => {
            return <React.Suspense fallback={<CenteredLoadingScreen />}>
                <FlytWatchHistoricalReportingPage
                    style={{ margin: -24, height: 'calc(100% + 48px)' }}
                />
            </React.Suspense>
        }} />
        <PrivateRoute exact path={basePath + "flytwatch/historical-reporting/:reportid"} render={({ match: { params } }) => {
            return <React.Suspense fallback={<CenteredLoadingScreen />}>
                <FlytWatchHistoricalReportingPage
                    style={{ margin: -24, height: 'calc(100% + 48px)' }}
                />
            </React.Suspense>
        }} />
        <PrivateRoute exact path={basePath + "flytwatch/fuel-taken"} render={({ location, history }) => {
            let qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });

            let newParams = {
                date: qsParams.date,
                reportType: qsParams.reportType
            }

            let pushHistory = () => history.push(basePath + "flytwatch/fuel-taken?" + qs.stringify(newParams));

            return <React.Suspense fallback={<CenteredLoadingScreen />}>
                <FuelTakenPage
                    style={{ margin: -24, height: 'calc(100% + 48px)' }}
                    date={qsParams.date}
                    reportType={qsParams.reportType}
                    onReportTypeChange={(reportType) => {
                        newParams.reportType = reportType;
                        pushHistory();
                    }}
                    onDateChange={(date) => {
                        newParams.date = date.format('YYYY-MM-DD');
                        pushHistory();
                    }}
                />
            </React.Suspense>
        }} />
        <PrivateRoute exact path={basePath + "flytwatch/fuel-taken/report"} render={() => {        
            return <React.Suspense fallback={<CenteredLoadingScreen />}>
                <FuelTakenReportPage
                    style={{ margin: -24, height: 'calc(100% + 48px)' }}
                />
            </React.Suspense>
        }} />
        <PrivateRoute exact path={basePath + "passengers"} render={({ location, history }) => {
            let qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
            let activeTab = qsParams.tab || 'outbound';
            return (
                <LivePassengers
                    activeTab={activeTab}
                    onActiveTabChange={(tab) => history.replace(basePath + 'passengers?tab=' + tab)}
                />
            )
        }} />
        <PrivateRoute
            path={basePath + 'passengers/checkin'}
            render={() => (
                <RequireUserGroup group="flytsuite.live.passenger">
                    <PrivateRoute
                        exact path={basePath + 'passengers/checkin/:personId?'}
                        render={renderPassengerCheckIn}
                    />
                </RequireUserGroup>
            )}
        />
        <PrivateRoute exact path={basePath + "cargo"} component={LiveCargo} />
        <PrivateRoute exact path={basePath + "flightTimes"} component={requireUserGroup(['flytsuite.flight.times'])(FlightTimesHeader)} />
        {!!(userGroups.includes('flytsuite.management.all') ||
            userGroups.includes('flytsuite.management.emailtriggers.all') ||
            userGroups.includes('flytsuite.master.all')) &&
            <PrivateRoute
                exact
                path={basePath + 'management/email-triggers'}
                component={EmailTriggersPage}
            />
        }
        <PrivateRoute exact path={basePath + "report"} component={() => (<Redirect to={basePath +"report/manifest"} />)} />
        <PrivateRoute exact path={basePath + "report/:type"} render={renderReportInterface} />
        <PrivateRoute exact path={basePath + "report/:type/details/:id"} render={renderReportDetails} />
        <PrivateRoute exact path={basePath + "report/:type/details/:id/:tab"} render={renderReportDetails} />
        <PrivateRoute exact path={basePath + "report/:type/details/:id/:tab/:flightID"} render={renderReportDetails} />
        <PrivateRoute exact path={basePath + "report/:type/:tab"} render={renderReportInterface} />
        <PrivateRoute exact path={basePath + "report/isn"} component={ISNReport} />
        {customer && (
            <React.Fragment>
                    <PrivateRoute
                    exact
                    path={basePath + "quartermaster"}
                    component={removePadding(requireUserGroup(['flytsuite.quartermaster'])(QuartermasterMainPage))}
                />
                {/* Scheduling routes are defined by MainScheduling component */}
                <PrivateRoute path={basePath + "scheduling"} component={MainScheduling} />
                <PrivateRoute exact path={basePath + "flights/:flightid"} render={renderFlightEditor} />
                <PrivateRoute exact path={basePath + 'masterdata/whitelist/:tab?'} component={WhiteList} />
                <PrivateRoute exact path={basePath + 'masterdata/whitelist/location/:location'} component={WhiteList} />
                <PrivateRoute exact path={basePath + 'masterdata/nonpreferred/:tab?'} component={NonPreferred} />
                <Route exact path={basePath + 'masterdata/person'} render={() => <Redirect to={basePath + 'masterdata/personnel'} />} />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/employer"}
                    component={removePadding(requireUserGroup(['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete'])(MDEmployer))}
                />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/personnel"}
                    component={removePadding(requireUserGroup(
                        [
                            'flytsuite.master.all',
                            'flytsuite.master.edit',
                            'flytsuite.master.delete',
                            'flytsuite.master.person.all'
                        ]
                    )(MDPersonnel))}
                />
            </React.Fragment>
        )}

        {!customer && (
            <React.Fragment>
                <PrivateRoute exact path={basePath + "tsacheck"} component={TsaCheck} />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/aircraft"}
                    component={removePadding(requireUserGroup(['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete'])(MDAircraft))} />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/contract"}
                    component={removePadding(requireUserGroup(['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete'])(MDContract))}
                />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/customer"}
                    component={removePadding(requireUserGroup(['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete'])(MDCustomer))}
                />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/employer"}
                    component={removePadding(requireUserGroup(['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete'])(MDEmployer))}
                />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/location"}
                    component={removePadding(requireUserGroup(['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete'])(MDLocation))}
                />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/pilot"}
                    component={removePadding(requireUserGroup(['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete'])(MDPilot))}
                />
                <Route exact path={basePath + 'masterdata/person'} render={() => <Redirect to={basePath + 'masterdata/personnel'} />} />
                <PrivateRoute
                    exact
                    path={basePath + "masterdata/personnel"}
                    component={removePadding(requireUserGroup(
                        [
                            'flytsuite.master.all',
                            'flytsuite.master.edit',
                            'flytsuite.master.delete',
                            'flytsuite.master.person.all'
                        ]
                    )(MDPersonnel))}
                />
            </React.Fragment>
        )}

    </Switch>
    </>
))

/**
 * Handles navigation and routes of flights branch of the application.
 */
const FlightsMain = (props) => {
    const { state: { sidebarCollapsed }, setState } = useContext(GlobalAppStateContext);
    const [ userGroups ] = useUserGroups();

    return(
        <Layout className={cn({
            "mc-main-app-layout": true,
            "mc-main-app-layout-sider-open": (!sidebarCollapsed)
        })}>
            <div className="mc-main-app-layout-click-receiver" onClick={() => setState({ sidebarCollapsed: true })} />
            <FlightsNav />
            <Content className="mc-main-app-layout-content" style={{padding: 24, margin: 0}}>
                <FlightsRoutes orgData={props.orgData} userGroups={userGroups} />
            </Content>
        </Layout>
    )
}
export default WithOrgData( withUserGroups( FlightsMain ) )