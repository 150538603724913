/**
 * Contains stateful logic for report UI.
 * Wrap the ReportUIContextProvider component around your child component to use stateful functionality.
 */

import { message } from 'antd';
import { NetworkStatus } from 'apollo-client';
import { formFieldsValuesHaveChanged } from 'common/form';
import getGenericStateReducer from 'common/reducer/generic-state-reducer';
import useReportDetails, { ReportDetailsResult } from 'components/reporting/hooks/report-details';
import useReportSubmitter, { useQuickReportQuery } from 'components/reporting/hooks/report-submitter';
import { OrgDataContext } from 'context/orgData';
import usePersistence from 'hooks/persistence';
import React, { createContext, PropsWithChildren, useContext, useReducer, useRef } from 'react';

export interface ReportUIContextInterface {
    criteriaFormFields?: { [key: string]: any },
    onCriteriaFieldsChange?: (values: { [key: string]: any }) => void,
    resetCriteriaForm?: () => void,
    formRef?: any,
    reportID?: string,
    displayReportCriteria?: boolean,
    reportSubmitting?: boolean,
    submitReport?: (reportData: any) => Promise<boolean>,
    reportDetails?: ReportDetailsResult,
    autoRefreshMode: boolean,
    setAutoRefreshInterval: (ms: number) => void,
    setAutoRefreshMode?: (on: boolean) => void
}

const DEFAULT_CONTEXT_VALUE: ReportUIContextInterface = {
    autoRefreshMode: false,
    setAutoRefreshInterval: undefined
}

export const ReportUIContext = createContext<ReportUIContextInterface>(DEFAULT_CONTEXT_VALUE);

export interface ReportUIContextProviderProps {
    reportID?: string,
    reportName: string,
    submitReportHandler: (formValues: any, submitFunction: (reportData: any) => Promise<boolean>) => Promise<boolean>,

    /** Example: "flytwatch.historical-reporting.criteria-form" */
    persistenceName: string,
    reportType: string,

    /**
     * Converts JSON values stored in local storage back to the format that the form expect.
     * For example: convert startDateTime and endDateTime fields from an ISO string to a moment() object
     */
     convertPersistentFormData?: (unconverted: any, updateTs: any) => any
}

interface ReducerState {
    autoRefreshMode?: boolean,
    reportId: string,
    autoRefreshInterval: number
}

const ACTION_TYPES = {
    SET_AUTO_REFRESH_INTERVAL: 'SET_AUTO_REFRESH_INTERVAL',
    SET_AUTO_REFRESH_MODE: 'SET_AUTO_REFRESH_MODE'
}

function reducer(state: ReducerState, type: string, payload: any){
    console.debug('reducer called', state, type, payload);
    switch (type){
        case ACTION_TYPES.SET_AUTO_REFRESH_INTERVAL:
            return {
                ...state,
                autoRefreshInterval: payload
            }
        case ACTION_TYPES.SET_AUTO_REFRESH_MODE:
            return {
                ...state,
                autoRefreshMode: payload
            }
        default:
            return state;
    }
}

const DEFAULT_STATE: ReducerState = {
    autoRefreshMode: false,
    reportId: null,
    autoRefreshInterval: 30000
}

export const ReportUIContextProvider: React.FC<PropsWithChildren<ReportUIContextProviderProps>> = (props) => {
    const [ state, dispatch ] = useReducer(getGenericStateReducer<ReducerState>(reducer), DEFAULT_STATE);
    const { transporterID } = useContext(OrgDataContext);
    
    const formRef: any = useRef();
    
    const { persistentData, setPersistentValues, updateTs } = usePersistence(props.persistenceName);
    
    const criteriaFormFields = persistentData?.formData ? props.convertPersistentFormData(persistentData.formData, updateTs) : undefined;

    let reportSubmitting = false;

    let reportName: string;
    let criteria: any;

    let autoRefreshMode = persistentData?.autoRefreshMode;

    if (autoRefreshMode){
        if (!formRef.current){
            console.error('formRef REQUIRED to use autoRefreshMode');
        }
        else
        {
            formRef.current.validateFieldsAndScroll((err, values) => {
                console.debug('updating criteria', values);
                if (!err){
                    props.submitReportHandler(
                        values,
                        async (options) => {
                            reportName = options.name;
                            criteria = options.criteria;
                            return true
                        }
                    );
                }
            })
        }
    }

    let res1 = useQuickReportQuery(props.reportType, transporterID, reportName, criteria, {
        pollInterval: state.autoRefreshInterval,
        skip: !autoRefreshMode || !reportName || !criteria
    });

    let reportState = undefined;

    if (res1.loading){
        reportSubmitting = true;
    }

    if (autoRefreshMode && res1.networkStatus === NetworkStatus.loading){
        reportState = 'IN_PROGRESS';
    }
    else if (autoRefreshMode && res1.networkStatus === NetworkStatus.setVariables){
        reportState = 'IN_PROGRESS';
    }
    else if (autoRefreshMode && res1.error){
        reportState = 'QUERY_FAILED';
    }
    else if (autoRefreshMode && res1.networkStatus === NetworkStatus.ready){
        reportState = 'COMPLETE';
    }
    else if (autoRefreshMode && res1.networkStatus === NetworkStatus.poll){
        reportState = 'COMPLETE';
        reportSubmitting = true;
    }
    

    let reportDetails = useReportDetails(state.reportId, reportState);

    if (autoRefreshMode && res1.error){
        reportDetails.error = res1.error;
    }
    else if (autoRefreshMode && res1.networkStatus === NetworkStatus.ready && res1?.data?.run_quick_report){
        reportDetails = {...reportDetails}
        reportDetails.reportData = res1?.data?.run_quick_report;
        if (typeof reportDetails.reportData.result?.docs === 'string'){
            reportDetails.reportData.result.docs = JSON.parse(reportDetails.reportData.result.docs);
        }
        
        reportDetails.reportState = reportState;
    }

    let res2 = useReportSubmitter(props.reportType, transporterID, {
        onCompleted: ({ id }) => {
            console.log('Report submitted. report id: ' + id)
            dispatch({ newState: { reportId: id } })
        },
        onError: (error) => {
            console.error('Failed to submit report: ', error);
            message.error("Failed to submit report due to an error");
        }
    });

    const submitReport = res2[0];
    if (res2[1]?.called && res2[1].loading){
        reportSubmitting = true;
    }

    return <ReportUIContext.Provider value={{
        criteriaFormFields,
        onCriteriaFieldsChange: (fields) => {
            
            // Check if each field value is equal. This prevents an infinite loop from happening.
            if (formFieldsValuesHaveChanged(criteriaFormFields, fields)){
                // setCriteriaFormFields(fields);
                setPersistentValues({ autoRefreshMode: autoRefreshMode, formData: fields });
            }
        },
        resetCriteriaForm: () => {
            // setCriteriaFormFields({});
            setPersistentValues({ autoRefreshMode: autoRefreshMode, formData: {} });
        },
        setAutoRefreshMode: (on) => {
            setPersistentValues({ formData: criteriaFormFields, autoRefreshMode: on });
        },
        formRef,
        reportID: state.reportId,
        submitReport: (formValues: any) => {

            dispatch({ newState: { reportId: null } })

            return props.submitReportHandler(formValues, submitReport);
        },
        reportSubmitting: reportSubmitting,
        reportDetails: reportDetails,
        autoRefreshMode: autoRefreshMode || false,
        setAutoRefreshInterval: (ms: number) => {
            if (typeof ms !== 'number') return;

            dispatch({ type: ACTION_TYPES.SET_AUTO_REFRESH_INTERVAL, payload: ms });
        }
    }}>
        {props.children}
    </ReportUIContext.Provider>
}