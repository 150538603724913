import { Button, Card, Form, Layout, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import LocationSelect from 'components/form/select/LocationSelect/LocationSelect2';
import usePersistence from 'hooks/persistence';
import React from 'react';
import './location-page.less';
import useFbShortcuts from './fb-shortcut-listener';
import { castToLabelInValue } from 'common/form';
import ShortcutDescriptions from './shortcut-descriptions';

const BASE_CLS = 'mc-fb-location';

export interface FlightboardLocationSelectPageProps extends FormComponentProps {
    onSubmit?: (locationID: string) => void
}

const FlightboardLocationSelectPage_Internal: React.FC<FlightboardLocationSelectPageProps> = (props) => {
    const { getFieldDecorator, validateFieldsAndScroll } = props.form;
    const { getPersistentValue, setPersistentValues } = usePersistence('flightboard.location-form-data');
    useFbShortcuts();

    return <Layout className={BASE_CLS}>
        <div className={BASE_CLS + '-content-wrapper'}>
            <Typography.Title className={BASE_CLS + '-title'} level={2}>View Flight Board</Typography.Title>
            <Card>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validateFieldsAndScroll((err, values) => {
                        if (!err){
                            setPersistentValues(values);
                            props.onSubmit?.(values.locationID.key)
                        }
                    })
                }}>
                    <Form.Item label="Find your location">
                        {getFieldDecorator('locationID', {
                            rules: [{ required: true, message: 'Please choose a location' }],
                            initialValue: castToLabelInValue(getPersistentValue('locationID'))
                        })(
                            <LocationSelect size="large" placeholder="Type in your location name here" labelInValue />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <ShortcutDescriptions />
                    </Form.Item>
                    <Button size='large' htmlType="submit" type="primary">View Flight Board</Button>
                </Form>
            </Card>
        </div>
    </Layout>
}

const FlightboardLocationSelectPage = Form.create<FlightboardLocationSelectPageProps>()(FlightboardLocationSelectPage_Internal);

FlightboardLocationSelectPage.displayName = 'Form(FlightboardLocationSelectPage)';

export default FlightboardLocationSelectPage