import { Alert, Card, Col, Row } from 'antd';
import compose from 'lodash.flowright';
import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import LoadingContent from '../../../LoadingContent';
import WithOrgData from '../../../WithOrgData';
import Employer from './Employer';
import Person from './Person';
import GetNonPreferredQuery from "Queries/GetNonPreferred";
import SetNonPreferredMutation from "Mutations/SetNonPreferred";

class NonPreferred extends Component {
    renderContent = () => {
        const { loading, error } = this.props.data;
        const { useDefault } = this.props;
        if (loading) return <LoadingContent />
        if (error) return <Alert type="error" message="Failed to load Non Preferred List" description={error.message} showIcon />
        const currentTab = this.props.match.params.tab;
        const renderTab = () => {
            switch (currentTab) {
                case 'person':
                    return (
                        <div style={{margin: '-24px -32px'}}>
                            <Person fetchPolicy="cache-only" useDefault={useDefault} />
                        </div>
                    )
                case 'employer':
                default:
                return (
                    <div style={{margin: '-24px -32px'}}>
                        <Employer fetchPolicy="cache-only" useDefault={useDefault} />
                    </div>
                )
            }
        }
        return (
            <div>
                { (
                    <Card 
                        tabList={[
                        {
                            tab: 'Employer',
                            key: 'employer'
                        },
                        {
                            tab: 'Personnel',
                            key: 'person'
                        },
                    ]}
                    activeTabKey={currentTab}
                    onTabChange={(key) => this.props.history.push(`/app/masterdata/nonpreferred/${key}`)}
                    >
                        {renderTab()}
                    </Card>
                ) }
            </div>
        )
    }
    render() { 
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <h2>Non Preferred List</h2>
                    </Col>
                </Row>
                {this.renderContent()}
            </React.Fragment>
        )
    }
}
 
export default compose(
    WithOrgData,
    withApollo,
    graphql(
        GetNonPreferredQuery,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'NPF-')
                },
                pollInterval: 15000,
                fetchPolicy: 'network-only'
            }),
            props: props => {
                const defaultWl = {
                    _id: props.ownProps.orgData.customer._id.replace('CUS-', 'NPF-'),
                    organizationIds: [],
                    personIds: [],
                    __typename: 'NonPreferredList'
                }
                var error = props.data.error;
                if (error){
                    const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
                    if (notFoundError){
                        return {
                            ...props,
                            useDefault: true,
                            data: {
                                ...props.data,
                                error: undefined,
                                loading: false,
                                getNonPreferredList: defaultWl
                            }
                        }
                    }
                }
                return {
                    ...props,
                    data: {
                        ...props.data,
                        getNonPreferredList: {
                            ...defaultWl,
                            ...(props.data.getNonPreferredList || {})
                        }
                    }
                }
            }
        }
    ),
    graphql(
        SetNonPreferredMutation,
        {
            name: 'setNonPreferredList'
        }
    ),
    withRouter
)(NonPreferred);