import { ExecutionResult } from '@apollo/react-common';
import { useMutation } from '@apollo/react-hooks';
import { Alert, Button, Checkbox, Divider, Dropdown, Icon, InputNumber, Menu, message, Modal, Spin, Tabs, Tooltip } from 'antd';
import { gql, NetworkStatus } from 'apollo-boost';
import commonColumns, { replaceColumn } from 'common/table/columns';
import {
    cleanGraphQLErrorMsg,
    isPaxNodeLockedOrDeparted,
    reactNodeTextGetter
} from 'common/util';
import EditableText from 'components/editable-text';
import { GlobalFilterWithWrapper } from 'components/GlobalFilter';
import { PassengerEditorWithData } from 'components/PassengerEditor';
import PassengerTable from 'components/tables/PassengerTable';
import { GlobalAppStateContext } from 'context/global-app-state';
import { GlobalFilterContext } from 'context/global-filter';
import { OrgDataContext } from 'context/orgData';
import useAutoPaginatedQuery from 'hooks/apollo-extensions/useAutoPaginatedQuery';
import useUserGroups from 'hooks/useUserGroups';
import pluralize from 'pluralize';
import React, { useContext, useReducer } from 'react';
import { useApolloClient } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import ColorKey from '../ColorKey';
import LiveCard from '../LiveCard';
import LiveColoredButton from '../LiveColoredButton';
import '../style.less';
import { fetchMoreAndGetToken } from '../util';
import { PAX_QUERY } from './query';
import './style.less';
import DataExport from "../../../common/data-export";

export interface LivePassengersProps {
    activeTab?: ActiveTab,
    onActiveTabChange?: (tab: ActiveTab) => void
}

type ActiveTab = 'outbound' | 'inbound/transfer'

interface ReducerState {
    editPaxModal: {
        id?: string,
        visible: boolean
    },
    errorClosed?: boolean,
    tableSettingsDropdownVisible: boolean
}

const INIT_REDUCER_STATE: ReducerState = {
    editPaxModal: {
        id: null,
        visible: false
    },
    errorClosed: false,
    tableSettingsDropdownVisible: false
}

const TYPES = {
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    OPEN_PAX_EDITOR: 'OPEN_PAX_EDITOR',
    CLOSE_PAX_EDITOR: 'CLOSE_PAX_EDITOR',
    CLOSE_ERROR_MSG: 'CLOSE_ERROR_MSG',
    OPEN_ERROR_MSG: 'OPEN_ERROR_MSG',
    SET_SETTING_DD_VIS: 'SET_SETTING_DD_VIS'
}

const SET_PASSENGER = gql`
mutation SetPassenger($payload: PaxNodeInput!){
    setPaxNode(
        payload: $payload
    ){
        _id
        lastName,
        firstName,
        personID {
            _id
            extraBroadState
        }
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        },
        tpID {
            _id
            name
        }
        paxWeight,
        bagWeight,
        bagCount,
        checkedInTime,
        scheduledFlightDate,
        destinationID {
            _id, name
        }
        departureID {
            _id, name
        },
        transitType
        lastIsnResultWithHeader {
            success,
            qc_result {
                isnStatus {
                    shortLabel
                }
                quickCheckStatus
                errorMessage
                reason
            }
            selectedResult {
                isnStatus {
                    shortLabel
                }
                quickCheckStatus,
                errorMessage
                reason
            }
        },
        authorization {
            onWhiteList
            noFlyListed
            brdRestriction_Isn {
                status
            }
            brdRestriction_SafetyCard {
                status
            }
            brdRestriction_HuetCard {
                status
            }
            brdRestriction_SteelToes {
                status
            }
            brdRestriction_Twic {
                status
            }
            brdRestriction_NonPreferred {
                status
            }
            brdRestriction_Hazmat {
                status
            }
            brdRestriction_NoFlyListed {
                status
            }
            brdRestriction_EbsCard {
                status
            }
            brdRestriction_Illness {
                status
            }
            brdRestriction_Quarantined {
                status
            }
        }
        currentCarrierID {
            _id
            desig
            state
        }
    }
}`

function reducer(state: ReducerState, action: { type: string, payload?: any }): ReducerState{
    switch (action.type) {
        case TYPES.OPEN_PAX_EDITOR:
            return { ...state, editPaxModal: { id: action.payload.id, visible: true } }
        case TYPES.CLOSE_PAX_EDITOR:
            return { ...state, editPaxModal: { id: state.editPaxModal.id, visible: false } }
        case TYPES.CLOSE_ERROR_MSG:
            return { ...state, errorClosed: true }
        case TYPES.OPEN_ERROR_MSG:
            return { ...state, errorClosed: false }
        case TYPES.SET_SETTING_DD_VIS:
            return { ...state, tableSettingsDropdownVisible: action.payload }
        default:
            break;
    }
}

const LivePassengers: React.FC<LivePassengersProps> = (props) => {
    const [ state, dispatch ] = useReducer(reducer, INIT_REDUCER_STATE);
    const { customer, transporter } = useContext(OrgDataContext);
    const globalFilter = useContext(GlobalFilterContext);
    const globalAppState = useContext(GlobalAppStateContext);
    const [ userGroups ] = useUserGroups();
    const orgData = useContext(OrgDataContext);
    const client = useApolloClient();
    const history = useHistory();

    let queryVars = {
        filter: {
            customers: globalFilter.customer && globalFilter.customer.length ? globalFilter.customer.map(({ key }) => key) : customer && [customer._id],
            transporters: globalFilter.transporter && globalFilter.transporter.length ? globalFilter.transporter.map(({ key }) => key) : transporter && [transporter._id],
            employers: globalFilter.employer && globalFilter.employer.map(({ key }) => key),
            departures: globalFilter.departure && globalFilter.departure.map(({ key }) => key),
            destinations: globalFilter.destination && globalFilter.destination.map(({ key }) => key),
            transitTypes: ['OUTBOUND', 'INBOUND', 'TRANSFER'],
            onlyShowLive: true
        },
        limit: 100
    }

    const [ setPassengerMutation ] = useMutation(gql`
        mutation SetPaxNode($payload: PaxNodeInput!){
            setPaxNode(payload: $payload){
                _id
                paxWeight
                bagWeight
                bagCount
            }
        }
    `)

    function updatePaxField(paxObj: any, fieldName: string, value: any): Promise<ExecutionResult<any>>{
        return setPassengerMutation({
            variables: {
                payload: {
                    _id: paxObj._id,
                    paxWeight: paxObj.paxWeight,
                    bagWeight: paxObj.bagWeight,
                    bagCount: paxObj.bagCount,
                    [fieldName]: value,
                    tpID: orgData.transporterID
                }
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setPaxNode: {
                    __typename: 'PaxNode',
                    _id: paxObj._id,
                    paxWeight: paxObj.paxWeight,
                    bagWeight: paxObj.bagWeight,
                    bagCount: paxObj.bagCount,
                    [fieldName]: value
                }
            }
        })
    }

    const { data, networkStatus, error, refetch, paginating, pageCount, paginationError, fetchMore } = useAutoPaginatedQuery<any, any>(PAX_QUERY, {
        variables: queryVars,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: 20000,
        supressDataUpdates: true,
        onError: () => dispatch({ type: TYPES.OPEN_ERROR_MSG }),
        onPaginationError: () => dispatch({ type: TYPES.OPEN_ERROR_MSG }),
        getNextToken: async (token) => await fetchMoreAndGetToken(fetchMore, queryVars, token, 'passengers'),
        getTokenFromData: (data) => {
            let token = data?.passengers?.bookmark;
            if (token === 'nil'){
                token = null;
            }
            return token;
        }
    })

    const passengers: any[] = (data?.passengers?.docs) || [];
    const outbound = passengers.filter(pax => pax?.transitType === 'OUTBOUND');
    const inboundTransfer = passengers.filter(pax => pax?.transitType === 'INBOUND' || pax?.transitType === 'TRANSFER');

    let dataSource = [];

    switch (props.activeTab) {
        case 'outbound':
            dataSource = outbound;
            break;
        case 'inbound/transfer':
            dataSource = inboundTransfer;
            break;
        default:
            break;
    }

    const tableSettings = globalAppState.getTableSettings('livePassengers');

    function getColumns() {
        const activeTab = props.activeTab;
        const { customer, employer, transporter } = orgData;

        // Create custom set of columns for display in the table
        const columns = [
            {
                ...commonColumns.pax.columns.assignedFlight,
                defaultSortOrder: 'ascend'
            },
            {
                ...commonColumns.pax.columns.name,
                render: (text, record) => {
                    const name = commonColumns.pax.columns.name.render(text, record);
                    const handleClick = () => dispatch({ type: TYPES.OPEN_PAX_EDITOR, payload: { id: record._id } });
                    let btn = <LiveColoredButton onClick={handleClick} type={record.checkInType}>{name}</LiveColoredButton>
                    if (record.checkedInTime){
                        return <Tooltip
                            title={"Passenger's Check In Type is " + record.checkInType}
                            placement="right"
                            mouseEnterDelay={1}
                        >{btn}</Tooltip>
                    }
                    return btn
                }
            },
            !employer && commonColumns.pax.columns.employer,
            !customer && commonColumns.pax.columns.customer,
            !customer && !transporter ? commonColumns.pax.columns.transporter : undefined,
            ...commonColumns.pax.except(['name', 'assignedFlight', 'transitType', 'isn', 'restrictions', 'customer', 'employer', 'transporter']),
            {
                ...commonColumns.pax.columns.restrictions,
                width: 150
            },

            // ISN Column will only display when the Outbound tab is selected.
            // Inbound/Transfer passengers do not require an ISN status.
            activeTab === 'outbound' && {
                ...commonColumns.pax.columns.isn
            }
        ].filter(col => col)
        return columns
    }

    const onKeyDown = (e) => {
        const specialCharRegex = new RegExp("[a-zA-Z]");
        const pressedKey = e.key
        if ((pressedKey !== "Tab" && pressedKey !== "Backspace" && pressedKey !== "Delete") && specialCharRegex.test(pressedKey)) {
           e.preventDefault();
           return false;
        }
    }

    function renderOptionsContent(){
        function isChecked(key: string){
            return tableSettings
                ?.settings?.hiddenColumns?.includes(key)
                ? false : true
        }

        let columns = getColumns().map(col => ({ key: String(col.key), title: String(col.title) }))

        return <Menu
            onClick={(cp) => {
                globalAppState.setTableColumnVisibility(
                    'livePassengers',
                    cp.key,
                    !isChecked(cp.key)
                );
            }}
            style={{ display: 'flex' }}
        >
            <Menu.ItemGroup title="Pax Name Color Legend">
                <div style={{ margin: '0 4px' }}>
                    <ColorKey type="overnight" text="Overnight" />
                    <ColorKey type="expected" text="Expected" />
                </div>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Show Columns">
                {columns.map(col => (
                    <Menu.Item key={col.key}>
                        {(
                            <Checkbox
                                style={{ marginRight: '12px' }}
                                checked={isChecked(col.key)}
                            />
                        )}
                        {col.title}
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>
        </Menu>
    }

    function renderTabs(){
        return (
            <Tabs
                activeKey={props.activeTab}
                onChange={(tab: ActiveTab) => props.onActiveTabChange?.(tab)}
                className="mc-tabs-pill"
            >
                <Tabs.TabPane key="outbound" tab={`Outbound (${outbound.length})`} />
                <Tabs.TabPane key="inbound/transfer" tab={`Inbound/Transfer (${inboundTransfer.length})`} />
            </Tabs>
        )
    }

    let columns = getColumns().map((col: any) => {
        if (props.activeTab !== "inbound/transfer" || !col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: record => ({
            inputProps: {
                onKeyDown,
                onBlur: e => {
                    if(!e.target.value){
                        e.target.value = record[col.dataIndex]
                        return;
                    }
                    if(e.target.value === record[col.dataIndex]){
                        return;
                    }
                    if(e.target.value !== 0 && e.target.value < 90){
                        e.target.value = record[col.dataIndex]
                        message.warn(record['lastName'] + ", " + record["firstName"] + " weight must be 0 or greater than 90.")
                        return;
                    }
                    const payload = {
                        _id: record['_id'],
                        paxWeight: e.target.value,
                        tpID: orgData.transporter._id
                    }

                    client.mutate({
                        mutation: SET_PASSENGER,
                        variables: {payload},
                        optimisticResponse:{
                            __typename: 'Mutation',
                            setPaxNode: {
                                __typename: 'PaxNode',
                                ...record,
                                paxWeight: e.target.value
                            }
                        }
                    })
                    .then(() => {
                        message.success(record['lastName'] + ", " + record["firstName"] + " has been successfully saved.")
                    })
                    .catch((err) => {
                        message.error("Error saving " + record['lastName'] + ", " + record["firstName"] + ": " + err)
                    })
                }
            },
            record,
            ...col,
            cancelButton: true,
            editable: col.editable,
            editing: true,
            dataIndex: col.dataIndex,
            title: col.title
          })
        };
      });

    columns = columns.filter((col) => {
        return !tableSettings?.settings?.hiddenColumns?.includes(col.key)
    })

    if (props.activeTab === 'inbound/transfer'){
        columns = replaceColumn(columns, 'weight', {
            render: (value, record) => isPaxNodeLockedOrDeparted(record) ? (
                <Tooltip title={`Cannot edit value. Passenger's flight is ${record.currentCarrierID.state}.`}>
                    <span style={{ cursor: 'not-allowed' }}>{value || 0}</span>
                </Tooltip>
            ) : (
                <EditableText
                    value={value}
                    inputComponent={<InputNumber min={0} max={1000} />}
                    onValueChange={(value) => {
                        updatePaxField(record, 'paxWeight', value)
                        .then(() => message.success("Successfully updated passenger weight"))
                        .catch((err) => message.error("Failed to update passenger weight due to an error: " + err))
                    }}
                />
            )
        })

        columns = replaceColumn(columns, 'bagWeight', {
            render: (value, record) => isPaxNodeLockedOrDeparted(record) ? (
                <Tooltip title={`Cannot edit value. Passenger's flight is ${record.currentCarrierID.state}.`}>
                    <span style={{ cursor: 'not-allowed' }}>{value || 0}</span>
                </Tooltip>
            ) : (
                <EditableText
                    value={value}
                    inputComponent={<InputNumber min={0} max={1000} />}
                    onValueChange={(value) => {
                        updatePaxField(record, 'bagWeight', value)
                        .then(() => message.success("Successfully updated passenger bag weight"))
                        .catch((err) => message.error("Failed to update passenger bag weight due to an error: " + err))
                    }}
                />
            )
        })

        columns = replaceColumn(columns, 'bagCount', {
            render: (value, record) => isPaxNodeLockedOrDeparted(record) ? (
                <Tooltip title={`Cannot edit value. Passenger's flight is ${record.currentCarrierID.state}.`}>
                    <span style={{ cursor: 'not-allowed' }}>{value || 0}</span>
                </Tooltip>
            ) : (
                <EditableText
                    value={value}
                    inputComponent={<InputNumber min={0} max={1000} />}
                    onValueChange={(value) => {
                        updatePaxField(record, 'bagCount', value)
                        .then(() => message.success("Successfully updated passenger bag count"))
                        .catch((err) => message.error("Failed to update passenger bag count due to an error: " + err))
                    }}
                />
            )
        })
    }

    const { getNodeText, registerNode }  = reactNodeTextGetter();
    columns = columns.map((col) => DataExport.Utils.registerAntdColumn(col, registerNode));

    let rightContent = [
        <Divider type="vertical" style={{ height: '100%', marginLeft: 0 }} className="mc-show-larger-than-medium-screen" />,
    ];

    if (![NetworkStatus.setVariables, NetworkStatus.loading].includes(networkStatus)){
        rightContent.unshift(
            <span className="mc-show-larger-than-medium-screen">{`${dataSource.length} ${pluralize('Result', dataSource.length)}`}</span>
        );
    }

    if (userGroups.includes('flytsuite.live.passenger')) {
        rightContent = [
            ...rightContent,
            <Button type="primary" onClick={() => history.push('/app/passengers/checkin?goBackTo=/app/passengers')}>Add Passenger</Button>,
            <Divider type="vertical" style={{ height: '100%' }} />
        ]
    }

    rightContent = [
        ...rightContent,
        <Dropdown
            overlay={renderOptionsContent()}
            placement="bottomLeft"
            trigger={['click']}
            visible={state.tableSettingsDropdownVisible}
            onVisibleChange={(visible) => dispatch({ type: TYPES.SET_SETTING_DD_VIS, payload: visible })}
        >
            <Button icon="setting" />
        </Dropdown>,
        <Tooltip title="Export as CSV" placement="bottomLeft">
            <Button
                icon="export"
                onClick={() => {
                    const config = {
                        columns: columns.map((col) => DataExport.Utils.convertAntdColumn(col, getNodeText))
                    }
                    DataExport.exportDataAsCsv(dataSource, "Passengers", config);
                }}
            />
        </Tooltip>,
        <Button
            icon="reload"
            loading={networkStatus === NetworkStatus.refetch}
            disabled={networkStatus === NetworkStatus.loading}
            onClick={() => refetch()}
        />
    ]


    if ([NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus) || paginating){
        rightContent.unshift(
            <span className="mc-show-larger-than-large-screen"><Icon type="loading" spin /> {`Fetching page ${pageCount}`}</span>
        )
    }

    return <>
    <div className="mc-live-passengers">
        <GlobalFilterWithWrapper wrapperProps={{ style: { margin: 0 } }} />
        {(error || paginationError) && !state.errorClosed ? (
                <Alert
                    message="Failed to load passengers"
                    description={cleanGraphQLErrorMsg((error?.message) || paginationError?.message)}
                    style={{ margin: '12px', marginBottom: 0 }}
                    showIcon
                    type="error"
                    closable
                    afterClose={() => dispatch({ type: TYPES.CLOSE_ERROR_MSG })}
                />
            ) : null}
        <LiveCard
            contentNoPadding
            animation={{
                slideIn: true
            }}
            hasTable
            headerProps={{
                title: 'Passengers',
                verticallyCenterTitle: true,
                rightContent,
                tabs: renderTabs()
            }}
        >
            <Spin
                indicator={<Icon type="loading" />}
                spinning={
                    networkStatus === NetworkStatus.loading && passengers.length <= 0
                }
                style={{ overflowX: 'auto' }}
            >
                <PassengerTable
                    refetch={refetch}
                    className="mc-table mc-table-scroll-auto mc-table-header-nowrap"
                    pagination={false}
                    rowKey={record => record._id}
                    dataSource={dataSource}
                    // onChange={this.onTableChange}
                    columns={columns}
                    size="small"
                    filterBarProps={{
                        style: { margin: 0 },
                        banner: true,
                        className: "mc-live-filtered-table-filter-bar"
                    }}
                    showHighlightMatches={false}
                />
            </Spin>
        </LiveCard>
        <div className="mc-live-passengers-mobile-tabs">
            {renderTabs()}
        </div>
    </div>
    <Modal
        visible={state.editPaxModal.visible}
        onCancel={() => dispatch({ type: TYPES.CLOSE_PAX_EDITOR })}
        width="50rem"
        title="Edit Passenger"
        footer={[
            <Button onClick={() => dispatch({ type: TYPES.CLOSE_PAX_EDITOR })}>Close</Button>
        ]}
        destroyOnClose
    >
        {state.editPaxModal.id ? (
            <PassengerEditorWithData
                edit={userGroups.includes('flytsuite.live.passenger.edit')}
                paxId={state.editPaxModal.id}
                onCancel={() => dispatch({ type: TYPES.CLOSE_PAX_EDITOR })}
                style={{
                    height: '30rem',
                    overflow: 'auto'
                }}
                loadingStyle={{
                    height: '30rem',
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            />
        ) : null}
    </Modal>
    </>
}


export default LivePassengers