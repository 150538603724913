import MainHeader, {MainHeaderProps} from "../../../../components/scheduling/MainHeader";
import React from "react";
import {ScheduleEditorApi} from "../../../../components/scheduling/editor/schedule-editor-v2/api";

interface Phase2MainHeaderProps {
    api: ScheduleEditorApi
}

const Phase1HistoryEditorMainHeader: React.FC<Phase2MainHeaderProps> = (props) => {

    const { api } = props;

    let mainHeaderProps: MainHeaderProps = {
        routes: [
            {
                path: '/app/scheduling/history',
                title: 'Historical Scheduled Passengers and Cargo'
            },
            {
                path: '',
                title: api.groupData?.name
            }
        ]
    }

    return <MainHeader {...mainHeaderProps} />
}

export default Phase1HistoryEditorMainHeader;