import React from "react";
import useSortableData from "hooks/useSortableData";
import { useColWidths } from "common/table";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";

const PaxIsnEventsDetail = (props) => {
  const initialData = props.data.map((row) => {
    const getVal = (obj, field) => obj && obj[field];
    //console.log(row);

    //console.log( row._id  + " -- " + row.departure['name'] + "---->" + row.destination['name']);
    return {
      ...row,
      currentCarrierName: getVal(row.currentCarrier, "desig"),
      //currentCarrierName: getVal(row.currentCarrier, 'desig'),
      departureName: getVal(row.departure, "name"),
      destinationName: getVal(row.destination, "name"),
      customerName: getVal(row.customer, "name"),
      transporterName: getVal(row.transporter, "name"),
      employerName: getVal(row.employer, "name"),
      finalizationReasonType: row.finalizationReasonType,
      //finalized: row.nodeState === 'FINALIZED'
    };
  });

  const [data, { setSortKey, currentSortKey, currentSortDir }] =
    useSortableData(initialData);

  const columns = [
    "Event Timestamp",
    "Event Type",
    "Pax Name",
    "Pax ID",
    "Departure",
    "Destination",
    "Customer",
    "Employer",
    "Scheduled Flight Date",
    "ISN Status",
    "ISN Reason",
    "ISN Company",
    "ISN ID",
    "Override Type",
    "Override Time",
    "Override Title",
    "Override Reason",
    "Training or Whitelist",
    "Overrider Name",
    "Approver Name",
    "Override Notes",
    "Deleted Ts",
    "Created Ts",
    "Created By",
    "Modified Ts",
    "Modified By",
  ].map((col) => {
    const cellRenderer = (i) => {
      const value = data[i]?.[col];
      return (
        <Cell tooltip={value} style={{ display: "flex", alignItems: "center" }}>
          {value}
        </Cell>
      );
    };
    return (
      <Column
        key={col}
        name={col}
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={() => (
          <SortableColumnHeaderCell
            name={col}
            sortKey={col}
            onSort={setSortKey}
            currentSortedKey={currentSortKey}
            currentSortDir={currentSortDir}
          />
        )}
      />
    );
  });

  const [colWidths, , updateColWidth] = useColWidths(columns.length, {
    0: 180,
    1: 215,
    2: 167,
    7: 167,
    11: 160,
    13: 175,
    16: 162,
  });

  return (
    <Table
      selectionModes={SelectionModes.NONE}
      className="mc-table"
      numRows={data?.length}
      columnWidths={colWidths}
      onColumnWidthChanged={updateColWidth}
    >
      {columns}
    </Table>
  );
};

export default PaxIsnEventsDetail