// TODO: Deprecate this

export function getISNStatus(pax: any): { text: string, backgroundColor?: string, textColor?: string } {
    if (pax){
        var selectedResult = pax.lastIsnResultWithHeader && pax.lastIsnResultWithHeader.selectedResult;
        var overridden = pax.authorization && pax.authorization.brdRestriction_Isn && pax.authorization.brdRestriction_Isn.status === 'OVERRIDDEN';
    }
    var qcs: string;
    if (overridden){
        qcs = 'overridden'
    }
    else if (selectedResult && typeof selectedResult.quickCheckStatus === 'string'){
        qcs = selectedResult.quickCheckStatus.toLowerCase();
        if(selectedResult.isnStatus){
            if(selectedResult.isnStatus.shortLabel === 'DISA BYPASS'){
                qcs = selectedResult.isnStatus.shortLabel.toLowerCase();
            }
        }
        if (selectedResult.reason === "WHITELISTED"){
            qcs = "WHITELISTED";
        }
    }
    if (pax && pax.isnCheckError){
        var errMessage = pax.isnCheckError.message;
    }
    if (errMessage && !pax.isnCheckRunning){
        errMessage = errMessage.replace('GraphQL error: NotFoundError: ', '')
        const noCredentials = errMessage.includes('No credentials') ? true : false;
        let messageSmall = errMessage.substring(0, 10);
        if (errMessage.length > messageSmall){
            messageSmall = messageSmall + '...';
        }
        if (noCredentials){
            messageSmall = 'No Credentials'
        }
        return {
            text: messageSmall
        }
    }
    switch (qcs) {
        case 'disa bypass':
            return {
                text:'DISA',
                backgroundColor: '#bbffff',
                textColor: 'black'
            }
        case 'green':
            return {
                text: 'GREEN',
                backgroundColor: '#52c41a',
                textColor: 'white'
            }
        case 'red':
            return {
                text: 'RED',
                backgroundColor: '#f5222d',
                textColor: 'white'
            }
        case 'multi':
            return {
                text: 'MULTIPLE RESULTS',
                textColor: 'white',
                backgroundColor: '#faad14'
            }
        case 'overridden':
            return {
                text: 'OVERRIDDEN',
                textColor: 'white',
                backgroundColor: '#1890ff'
            }
        default:
            let text = 'NO ISN RESULT';
            let textColor: string;
            if (pax && pax.lastIsnResultWithHeader && pax.lastIsnResultWithHeader.error_message){
                text = pax.lastIsnResultWithHeader.error_message;
                textColor = 'red';
            }
            return {
                text: text,
                textColor
            };
    }
}

export type ISNPassFail = { passed: boolean, reason: string, pax: any, running: false } | { running: true, pax: any }

export function getISNPassFail(pax: any): null | ISNPassFail {
    if (!pax) return null;

    if (pax.isnCheckRunning){
        return {
            running: true,
            pax: pax
        }
    }

    const selectedResult = pax.lastIsnResultWithHeader && pax.lastIsnResultWithHeader.selectedResult;
    const overridden = pax.authorization && pax.authorization.brdRestriction_Isn && pax.authorization.brdRestriction_Isn.status === 'OVERRIDDEN';

    var qcs: string;
    if (overridden){
        qcs = 'overridden'
    }
    else if (selectedResult && typeof selectedResult.quickCheckStatus === 'string'){
        qcs = selectedResult.quickCheckStatus.toLowerCase();
        if(selectedResult.isnStatus){
            if(selectedResult.isnStatus.shortLabel === 'DISA BYPASS'){
                qcs = selectedResult.isnStatus.shortLabel.toLowerCase();
            }
        }
        if (selectedResult.reason === "WHITELISTED"){
            qcs = "whitelisted";
        }
    }
    if (pax.isnCheckError && !overridden){
        var errMessage = pax.isnCheckError.message;
    }
    if (errMessage && !pax.isnCheckRunning){
        errMessage = errMessage.replace('GraphQL error: NotFoundError: ', '')
        const noCredentials = errMessage.includes('No credentials') ? true : false;
        let messageSmall = errMessage.substring(0, 10);
        if (errMessage.length > messageSmall){
            messageSmall = messageSmall + '...';
        }
        if (noCredentials){
            messageSmall = 'No Credentials'
        }

        return {
            passed: false,
            reason: messageSmall,
            pax: pax,
            running: false
        };
    }

    // Fail
    if (String(qcs).includes('red') || String(qcs).includes('multi')){
        return {
            passed: false,
            reason: qcs,
            pax: pax,
            running: false
        }
    }

    return {
        passed: true,
        reason: qcs,
        pax: pax,
        running: false
    }
}