import { Modal } from "antd";
import { castArray } from "common/util";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";
import { useApolloClient, useQuery } from "react-apollo";

interface GetAllContractIDsOptions {
    customerID?: string
}

const CONTRACT_QUERY = gql`
query ListContractsQuery(
    $filter: ContractsFilterInput
    $limit: Int
    $bookmark: String
){
    contracts(
        filter: $filter
        limit: $limit
        bookmark: $bookmark
    ){
        bookmark
        docs {
            _id
            ... on Contract {
                name
                tpID {
                    _id
                    name
                }
                customerID {
                    _id
                    name
                }
                locationID {
                    _id
                    name
                }
                startDate
                endDate
                active
            }
        }
    }
}
`

const QUERY_GET_USER = gql`
query GetCognitoUser($username: String!){
    AdminCognitoGetUser(Username: $username) {
        Username
        mappedAttributes {
            organization
            organizationName
            email
        }
        userSettings {
            contracts
        }
    }
}
`

const MUTATION_UPDATE_USER_CONTRACTS = gql`
mutation UpdateUserContracts($username: String!, $email: String!, $contracts: [ID!]){
    AdminUpdateUserSettings(cognitoUsername: $username, email: $email, contracts: $contracts, createNew: false){
        _id
        _rev
        contracts
        contractDocs {
            _id
            name
            tpID {
                _id
                name
            }
            customerID {
                _id
                name
            }
            locationID {
                _id
                name
            }
            startDate
            endDate
            active
        }
    }
}
`

function useGetUser(username: string){
    const { data, loading, error } = useQuery(QUERY_GET_USER, {
        variables: {
            username: username
        },
        fetchPolicy: 'cache-and-network',
        skip: !username
    })

    return {
        user: data?.AdminCognitoGetUser,
        loading: loading,
        error: error
    }
}

function useApplyAllContractsToUser(username: string){
    const apolloClient = useApolloClient();
    const [ applying, setApplying ] = useState(false);
    const [ error, setError ] = useState(null);
    
    const cRef = useRef({ cancelled: false });

    function setCancelled(cancelled: boolean){
        cRef.current.cancelled = cancelled;
    }

    function isCancelled(){ return cRef.current.cancelled }

    const { user } = useGetUser(username);

    const canApply = user && String(user.mappedAttributes?.organization).startsWith('CUS');


    // Set cancelled state when unmounted
    useEffect(() => {
        return () => setCancelled(true);
    }, [])

    // Get all contract results using pagination
    async function getAllContractIDs(options: GetAllContractIDsOptions){
        let lastBookmark = undefined;
        let currBookmark = null;

        let allContractIDs = [];


        while(lastBookmark !== currBookmark){
            if (isCancelled()) break;

            let result = await apolloClient.query({
                query: CONTRACT_QUERY,
                fetchPolicy: 'network-only',
                variables: {
                    filter: {
                        customerID: options?.customerID
                    },
                    limit: 100,
                    bookmark: currBookmark
                }
            });
            
            lastBookmark = currBookmark;
            currBookmark = result.data?.contracts?.bookmark || null;

            let contractsPage = castArray(result.data?.contracts?.docs);
            let contractIds = contractsPage
                .map(doc => doc._id)

                // Filter contracts already selected
                .filter(id => !(user?.userSettings?.contracts || []).includes(id));

            allContractIDs.push(...contractIds);
        }

        

        return allContractIDs;
    }

    async function mutateUserContracts(contractIDs: string[]){
        await apolloClient.mutate({
            mutation: MUTATION_UPDATE_USER_CONTRACTS,
            variables: {
                username: username,
                email: user.mappedAttributes.email,
                contracts: contractIDs
            },
            fetchPolicy: 'no-cache'
        })

        return contractIDs;
    }

    async function apply(){
        if (!canApply){
            console.error('Cannot apply all contracts to a non-customer user at this time!');
            return false;
        }

        setApplying(true);
        setCancelled(false);
        setError(null);
        let contractIDs: string[];

        if (!user){
            setError(new Error("User object is empty"))
            setApplying(false);
            return false;
        }

        let mutationResult: any[];

        try{
            contractIDs = await getAllContractIDs({
                customerID: user.mappedAttributes.organization
            });

            if (contractIDs.length === 0){
                setApplying(false);
                throw Error(`[NO_PREFIX]No unassigned contracts for the customer '${user.mappedAttributes.organizationName}' found.`);
            }

            try{
                await new Promise((resolve, reject) => {
                    Modal.confirm({
                        title: `This will add ${contractIDs.length} contracts to this user. Continue?`,
                        onOk: () => resolve(true),
                        onCancel: () => reject()
                    })
                })
            }
            catch(e){
                setApplying(false);
                throw new Error("Apply all contracts cancelled.");
            }

            if (isCancelled()) {
                setApplying(false);
                throw new Error("Apply all contracts cancelled.");
            }
            mutationResult = await mutateUserContracts(contractIDs);
        }
        catch(err){
            setError(err);
            let msg = '';
            if (err.message.includes('[NO_PREFIX]')){
                err.message = err.message.replace('[NO_PREFIX]', '');
                msg = err.message;
            }
            else
            {
                msg = 'Failed to apply all contracts! ' + err;
            }
            console.error(msg);
            throw new Error(msg);
        }

        setApplying(false);

        return mutationResult;
    }
    
    
    return {
        isApplying: applying,
        error,
        canApply: canApply,
        apply: apply
    }
}

export default useApplyAllContractsToUser