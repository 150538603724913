import { concat } from "lodash";
import appSchem from "./app";
import authSchem from "./auth";
import dashboardSchem from "./dashboard";
import SchedulingSchema from "./scheduling";
import organizationSchema from './organization';
import reportsSchema from './reports';
import {mergeTypeDefs} from "@graphql-tools/merge";

const types = concat(appSchem, organizationSchema, authSchem, dashboardSchem, SchedulingSchema, reportsSchema);
const TypeDefs = mergeTypeDefs(types);

export default TypeDefs;