import {CargoNode, CgoNode, PaxNode} from "../../../common/types/carriable";

export function calcTotalWeight(entities: (PaxNode | CgoNode)[]): number {

    function reducer(currWt: number, entity: PaxNode | CgoNode){
        if (entity.classType === 'flytsuite.paxnode'){
            const pax = entity as PaxNode;
            return currWt + (pax.paxWeight || 0) + (pax.bagWeight || 0);
        }
        else if (entity.classType === 'flytsuite.cgonode'){
            const cgo = entity as CargoNode;
            return currWt + (cgo.weight || 0)
        }
        return currWt;
    }

    return entities.reduce(reducer, 0);
}

export function calcBagWeight(pax: PaxNode[]){

    function reducer(prevWeight: number, node: PaxNode){
        if (node.classType === 'flytsuite.paxnode'){
            return prevWeight + node.bagWeight;
        }
        return prevWeight;
    }

    return pax.reduce(reducer, 0);
}

export function calcBagCount(pax: PaxNode[]){

    function reducer(prevCount: number, node: PaxNode){
        if (node.classType === 'flytsuite.paxnode'){
            return prevCount + node.bagCount;
        }
        return prevCount;
    }

    return pax.reduce(reducer, 0);
}