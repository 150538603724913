import {Dispatch, ReducerAction} from "react";
import {produce} from "immer";
import {validateNote} from "../validation";

export const DEFAULT_STATE = {
    open: false,
    editMode: false,
    originalText: '',
    modifiedText: ''
}

export type State = typeof DEFAULT_STATE;

export type Dispatcher = Dispatch<ReducerAction<typeof Reducer>>;

export type Action =
{
   type: 'INIT_TEXT',
   text: string
} |
{
    type: 'SET_TEXT',
    text: string
} |
{
    type: 'RESET_TEXT'
} |
{
    type: 'MOUSE_TRIGGER',
    value: boolean
} |
{
    type: 'EDIT_MODE',
    value: boolean,
    close?: boolean
}

export function Reducer(_state: Readonly<State>, action: Action){
    return produce(_state, (state) => {

        const Text = {
            Reset(){
                state.modifiedText = state.originalText;
            }
        }

        switch (action.type){
            case 'INIT_TEXT':
                state.originalText = action.text ?? '';
                state.modifiedText = action.text ?? '';
                break;
            case 'RESET_TEXT':
                Text.Reset();
                break;
            case 'SET_TEXT':
                state.modifiedText = validateNote(action.text) ?? '';
                break;
            case 'MOUSE_TRIGGER':
                state.open = action.value;
                if (!action.value){
                    // Reset text back to original if closed.
                    Text.Reset();
                    state.editMode = false;
                }
                break;
            case 'EDIT_MODE':
                state.editMode = action.value;
                if (action.close){
                    state.open = false;
                    Text.Reset();
                }
                break;
        }
    })
}