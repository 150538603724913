import { OperationVariables, QueryResult } from '@apollo/react-common';
import { ApolloError, ApolloQueryResult, NetworkStatus } from "apollo-boost";
import { DocumentNode } from 'graphql';
import lodash from 'lodash';
import { useEffect, useReducer, useRef } from "react";
import { QueryHookOptions, useQuery } from "react-apollo";
import { v4 as uuid4 } from 'uuid';

export type GetNextTokenFunction = (token: string) => Promise<string>
export type GetTokenFromData = (data: any) => string

export interface PaginatedQueryResult<TData = any, TVariables = OperationVariables> extends QueryResult<TData, TVariables> {
    paginating: boolean,
    pageCount: number,
    paginationError: any,
    startAutoPagination: (continuePagination?: boolean) => void,
    stopAutoPagination: () => void,
    wasStopped: boolean,
    initialPaginationRunning: boolean,
    refetchAndPaginate(): Promise<ApolloQueryResult<TData>>
}

interface AutoPaginatorState {
    pageCount: number,
    retryCount: number,
    currToken: string,
    lastToken: string,
    paginating: boolean,
    error: any,
    wasStopped: boolean,
    uuid: string
}

interface AutoPaginatorConstructorOptions {

    /** Used to update react component UI as data is rolling in */
    updateListener: PageUpdateListener,
    getNextToken: (token: string) => Promise<string>,
    maxPages?: number,
    maxRetries?: number
}

type PageUpdateListener = (state: AutoPaginatorState) => void

const DEFAULT_MAX_PAGES = 100;
const DEFAULT_MAX_RETRIES = 3;

export class AutoPaginator {
    public state: AutoPaginatorState = {
        pageCount: 0,
        retryCount: 0,
        currToken: null,
        lastToken: null,
        paginating: false,
        error: null,
        wasStopped: false,
        uuid: null
    }
    public getNextToken: (token: string) => Promise<string>

    private maxPages: number = DEFAULT_MAX_PAGES;
    private maxRetries: number = DEFAULT_MAX_RETRIES;
    private updateListener: PageUpdateListener;
    private isStopped = false;

    constructor(options: AutoPaginatorConstructorOptions) {
        this.updateListener = options.updateListener;
        this.getNextToken = options.getNextToken;
        this.setMaxPages(options.maxPages);
        this.maxRetries = options.maxRetries === undefined ? DEFAULT_MAX_RETRIES : options.maxRetries;
    }

    onLastPage() {
        if (this.maxPages !== null && this.state.pageCount > this.maxPages) {
            return true;
        }
        let lastPage = AutoPaginator.isOnLastPage(this.state.currToken, this.state.lastToken);
        // if (lastPage){
        //     console.log('Last page reached!');
        // }
        return lastPage;
    }

    public getMaxPages() {
        return this.maxPages;
    }

    public setMaxPages(value: number) {
        if (value === null) {
            this.maxPages = null;
        }
        else {
            this.maxPages = value === undefined ? DEFAULT_MAX_PAGES : value;
        }
    }

    static isOnLastPage(currToken: string, lastToken: string) {
        if (currToken === 'nil') {
            currToken = null;
        }
        if (lastToken === 'nil') {
            lastToken = null;
        }
        // console.log('Checking if on last page...', { currToken, lastToken });
        if (!currToken && !lastToken) {
            // Must be on a page that returned 0 results or something.
            return true;
        }
        if (!lastToken && !currToken) {
            return false;
        }
        if (lastToken === currToken) {
            return true
        }
        if (!currToken && lastToken) {
            return true;
        }
        return false;
    }


    updateState(state: Partial<AutoPaginatorState>) {
        this.state = {
            ...this.state,
            ...state
        }
        if (this.updateListener) {
            this.updateListener(this.state);
        }
        return this.state;
    }

    stopPaging() {
        this.isStopped = true;
        this.updateState({ paginating: false, wasStopped: true });
        // console.log('Auto-Pagination stopping...');
    }

    async startPaging(token: string, startingPageCount = 1): Promise<boolean> {
        let uuid = uuid4();
        // console.log('Starting auto-pagination...');
        this.updateState({
            paginating: true,
            pageCount: startingPageCount,
            retryCount: 0,
            currToken: token,
            lastToken: null,
            error: null,
            wasStopped: false,
            uuid
        });
        let error: any;
        while (!this.onLastPage() && this.state.retryCount < this.maxRetries) {
            if (uuid !== this.state.uuid) {
                // Stop this while loop and exit the function when startPaging() is called again.
                // This one should exit before getNextToken is called
                return false;
            }
            if (this.isStopped) {
                break;
            }
            // console.log('Getting page ' + (this.state.pageCount + 1));
            let newToken: string;
            try {
                error = undefined;
                newToken = await this.getNextToken(this.state.currToken || token);
            }
            catch (err) {
                error = err;
                this.updateState({ retryCount: this.state.retryCount + 1 });
                console.error(`Failed to get next token. Retrying... (${this.state.retryCount}/${this.maxRetries})`);
                break;
            }
            if (uuid !== this.state.uuid) {
                // Stop this while loop and exit the function when startPaging() is called again.
                // This one should exit after getNextToken is called, ignoring the results from it.
                return false;
            }
            this.updateState({
                pageCount: this.state.pageCount + 1,
                currToken: newToken,
                lastToken: this.state.currToken
            });
        }
        if (this.isStopped) {
            this.isStopped = false;
        }
        if (error) {
            console.error(`Auto-pagination failed after ${this.state.retryCount}/${this.maxRetries} retries:`, error);
            this.updateState({ error: error, paginating: false });
            return false;
        }
        this.updateState({ paginating: false });
        return true;
    }

}

interface AutoPaginatedQueryHookOptions<TData, TVariables> extends QueryHookOptions<TData, TVariables> {

    /** Given the current token, fetch the next page and return a new token. */
    getNextToken: GetNextTokenFunction,

    /** Get token from query result data. */
    getTokenFromData: GetTokenFromData,

    /** Start auto-pagination immediately after a successful query result. Defaults to true. */
    autoPaginate?: boolean,

    /** Max number of pages to fetch. (Default: 100) Use 'null' to allow an infinite number of pages (NOT RECOMMENDED). */
    maxPages?: number,

    /** Triggers when an error happens while fetching subsequent pages of data. */
    onPaginationError?: (error: any) => void,

    /** Keep the result data from changing while pagination is happening if a poll happened or if the query's variables changed. 
     *  Useful for preventing the UI from changing while data is rolling in during pagination.
     *  Instructions:
     *      - Set to an array of NetworkStatus numbers that the data should freeze on. e.g. [NetworkStatus.poll, NetworkStatus.setVariables] or [6, 2]
     *      - Set to true to freeze the data while pagination is in effect for all NetworkStatuses.
     *      - Set to false to disable all freezing of data.
     *  Defaults to false.
     */
    // freezeDataOnNetworkStatus?: number[] | boolean

    /**
     * Supress result data from changing while data is being rolled in. Instead of result data being built as the data is coming in due to pagination,
     * the previous result data will persist until pagination is finished. This helps prevent the UI from changing too much as data is rolling in.
     */
    supressDataUpdates?: boolean
}

/**
 * 
 * @param query Query document
 * @param options Query options
 */
function useAutoPaginatedQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode, options?: AutoPaginatedQueryHookOptions<TData, TVariables>): PaginatedQueryResult<TData, TVariables> {
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        paginating: false,
        pageCount: 1,
        paginationError: null,
        prevNetworkStatus: null,
        dataBeforePagination: null,
        initNetworkStatus: NetworkStatus.loading,
        data: false,
        wasStopped: false,
        initialPaginationCompleted: false,
        shouldStartPagination: false
    });


    // For use by useEffect hooks watching changes in query variables.
    // Should prevent useEffect from firing every re-render with queryVars as a dependency
    let queryVars = useRef(options.variables);
    if (!lodash.isEqual(queryVars.current, options.variables)) {
        queryVars.current = options.variables;
    }

    const autoPaginatorRef = useRef<AutoPaginator>();
    if (!autoPaginatorRef.current) {
        // If AutoPaginator object isn't in current, create a new AutoPaginator object.
        // This AutoPaginator object must exist regardless of the component state.
        autoPaginatorRef.current = new AutoPaginator({
            updateListener: onAutoPaginatorUpdate,
            getNextToken: options.getNextToken,
            maxPages: options.maxPages
        });
    }

    useEffect(() => {
        if (autoPaginatorRef.current) {
            autoPaginatorRef.current.setMaxPages(options.maxPages);
        }
    }, [options.maxPages])

    function onAutoPaginatorUpdate(pState: AutoPaginatorState) {
        setState({
            paginating: pState.paginating,
            pageCount: pState.pageCount,
            paginationError: pState.error,
            wasStopped: pState.wasStopped
        })
    }

    const result = useQuery(query, options);

    useEffect(() => {
        if (!options.supressDataUpdates) {
            setState({ data: result.data });
        }
        // eslint-disable-next-line
    }, [result.networkStatus])

    function startPaging(continuePagination?: boolean) {
        autoPaginatorRef.current.startPaging(
            options.getTokenFromData(result.data),
            continuePagination ? autoPaginatorRef.current.state.pageCount : undefined
        )
            .then(() => {
                // Pagination finishes
                setState({ initialPaginationRunning: false, initialPaginationCompleted: true });
            });
    }

    function stopPaging() {
        autoPaginatorRef.current.stopPaging();
    }

    useEffect(() => {
        if (result.networkStatus === NetworkStatus.setVariables) {
            // Query reiteria changed. Remove all existing results since they are no longer valid
            setState({ data: [] });
        }
        if (!state.paginating && options.supressDataUpdates) {
            setState({ data: result.data });
        }
        // eslint-disable-next-line
    }, [
        state.paginating,
        // eslint-disable-next-line
        state.prevNetworkStatus === NetworkStatus.setVariables,
        // eslint-disable-next-line
        state.prevNetworkStatus === NetworkStatus.poll,
        // eslint-disable-next-line
        state.prevNetworkStatus === NetworkStatus.refetch,
        // eslint-disable-next-line
        state.prevNetworkStatus === NetworkStatus.loading
    ])

    let data = state.data;
    // if (
    //     (freezeDataOnNetworkStatus.includes(state.initNetworkStatus) && state.useCachedData) ||
    //     (freezeData && state.useCachedData)
    // ){
    //     data = state.dataBeforePagination;
    // }

    useEffect(() => {
        if (state.paginationError && options.onPaginationError) {
            options.onPaginationError(state.paginationError);
        }
        // eslint-disable-next-line
    }, [state.paginationError])

    useEffect(() => {
        // if (freezeDataOnNetworkStatus.includes(state.initNetworkStatus) && !state.useCachedData){
        //     setState({ useCachedData: true, dataBeforePagination: result.data });
        // }

        if (result.networkStatus === NetworkStatus.setVariables) {
            stopPaging();
        }

        if (
            result.networkStatus === NetworkStatus.ready &&
            state.prevNetworkStatus &&
            state.prevNetworkStatus !== NetworkStatus.ready &&
            state.prevNetworkStatus !== NetworkStatus.fetchMore &&
            ((options.autoPaginate !== undefined ? options.autoPaginate : true) || state.shouldStartPagination)
        ) {
            // console.log('Successful query result detected. Starting auto-pagination...');
            if (!state.initialPaginationRunning && !state.initialPaginationCompleted) {
                setState({ initialPaginationRunning: true });
            }
            // if (freezeData &&  !state.useCachedData){
            //     setState({ useCachedData: true, dataBeforePagination: result.data });
            // }
            // setState({ initNetworkStatus: state.prevNetworkStatus });
            startPaging();
            setState({ shouldStartPagination: false });
        }
        setState({ prevNetworkStatus: result.networkStatus });
        // eslint-disable-next-line
    }, [result.networkStatus, options.autoPaginate])

    useEffect(() => {
        /* getNextToken function is most likely dependent on the current query variables, so when
           those query variables change, the getNextToken callback in the Autopaginator needs to be updated.
        */
        if (autoPaginatorRef.current) {
            autoPaginatorRef.current.getNextToken = options.getNextToken;
        }
        // eslint-disable-next-line
    }, [queryVars.current])

    let error = result.error;
    if (result.networkStatus === NetworkStatus.error) {
        error = new ApolloError({
            errorMessage: 'An error occurred while loading data. Try refreshing the page.'
        })
    }

    return {
        ...result,
        refetch: (...args) => {
            console.debug('Normal refetch');
            return result.refetch(...args);
        },
        refetchAndPaginate: () => {
            console.debug('Paginated refetch');
            const refetchPromise = result.refetch();
            return new Promise<ApolloQueryResult<TData>>((resolve, reject) => {
                refetchPromise
                    .then((data) => {
                        resolve(data);
                        setState({ shouldStartPagination: true });
                    })
                    .catch(reject)
            })
        },
        data,
        error,
        paginating: state.paginating,
        pageCount: state.pageCount,
        paginationError: state.paginationError,
        startAutoPagination: startPaging,
        stopAutoPagination: stopPaging,
        wasStopped: state.wasStopped,
        initialPaginationRunning: state.initialPaginationRunning || result.networkStatus === NetworkStatus.loading
    }
}

export default useAutoPaginatedQuery