import gql from "graphql-tag";

const WhiteListNonComplyBypassFragment = gql`
    fragment WhiteListNonComplyBypassFragment on NonComplyBypass {
        customerID
        personID
        timestamp
        expiresOn
        reason
        cognitoUser {
            cognitoUsername
            givenName
            familyName
            email
        }
        nonComplyCategory
    }
`

export const WhiteListFragment = gql`
fragment WhiteListFragment on WhiteList {
    _id
    personIds {
        _id
        lastName
        firstName
        employerID {
            _id
            name
        }
        contactInfo {
            address {
                city
                state
            }
        }
    }
    locationToEmployer {
        locationID {
            _id
            name
        }
        employerIDs {
            _id
            name
        }
    }
    organizationIds {
        ... on Customer {
            _id
            name
            classType
        }
        ... on Employer {
            _id
            name
            classType
        }
    }
    noncomplyPassList_Training {
        personID
        nonComplyBypass {
            ...WhiteListNonComplyBypassFragment
        }
    }
    noncomplyPassList_DrugAndAlcohol {
        personID
        nonComplyBypass {
            ...WhiteListNonComplyBypassFragment
        }
    }
    noncomplyPassList_CompanyGrade {
        personID
        nonComplyBypass {
            ...WhiteListNonComplyBypassFragment
        }
    }
}
${WhiteListNonComplyBypassFragment}
`

export const QueryGetWhiteList = gql`
    query GetWhiteList($_id: ID!){
        getWhiteList(_id: $_id){
            ...WhiteListFragment
        }
    }
    ${WhiteListFragment}
`