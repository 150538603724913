/**
 * Aircraft Details flyout panel in the FlytWatch homepage.
 * This component is responsible for showing details of a flytwatch.dispatch object
 * and displaying a map of its route from location A to location B.
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Descriptions, Drawer, Icon } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import cn from 'classnames';
import { cleanGraphQLErrorMsg, getDegAngleFromPoints, getPilotName, momentOrNull } from 'common/util';
import { getAreaBlock, getTzNameFromDoc } from 'common/util-ts';
import CenteredLoadingScreen from 'components/CenteredLoadingScreen';
import MapArrowSvg from 'components/icon/icons/map-arrow.svg';
import LocationMarker from 'components/mapping/location-marker';
import NonIdealState from 'components/NonIdealState';
import { RequireUserGroup } from 'components/userGroup';
import { OrgDataContext } from 'context/orgData';
import gql from 'graphql-tag';
import useControlledState from 'hooks/useControlledState';
import moment from 'moment';
import React, { CSSProperties, HTMLAttributes, Ref, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import { ErrorBoundary } from 'react-error-boundary';
import { Layer, MapRef, Marker, Source } from 'react-map-gl';
import FWAircraftHistory from '../aircraft-history';
import FlytWatchDetailsMap from '../details-map';
import { LocCoords } from '../interfaces';
import './style.less';

library.add(faMapMarkerAlt);

const BASE_CLS = 'mc-flytwatch-aircraft-details';

const DItem = Descriptions.Item;

interface AircraftDetailsState {
    historyDrawer: Omit<DrawerProps, 'onClose' | 'width'>
}

interface AircraftData {
    aircraftID: string,
    tailNum?: string,
    departure?: string,
    departureID?: string,
    departureAreaBlock?: string,
    destination?: string,
    destinationID?: string,
    destinationAreaBlock?: string,
    departureCoordinates?: LocCoords,
    destinationCoordinates?: LocCoords,
    ato?: string,
    eta?: string,
    ata?: string,
    pob?: string,
    contract?: string,
    pilot?: string,
    status: 'BASE' | 'AIR' | 'CHECK',
    name: string,
    createdBy: string
}

export interface AircraftDetailsProps extends HTMLAttributes<HTMLDivElement> {
    aircraftID?: string,
    data?: AircraftData,
    loading?: boolean,
    title?: string,
    overrideState?: AircraftDetailsState,
    onStateChange?: (newState: AircraftDetailsState) => void,
    historyDrawerProps?: DrawerProps,
    buttonBarStyle?: CSSProperties
}

function renderTime(time: string, record: any){
    let m = time ? moment(time) : undefined;
    if (!m) return null;
    let text = moment.utc(m).tz(getTzNameFromDoc(record))
                .format('MMM Do, YYYY [::] H:mm z')
                .split('::');
    return <span style={{ whiteSpace: 'pre-wrap', textAlign: 'center', display: 'inline-block' }}>
        {text[0]}
        <br />
        {text[1]}
    </span>
}

const AircraftDetails: React.FC<AircraftDetailsProps> = React.forwardRef(({
    aircraftID,
    data: propData,
    loading: propLoading,
    title,
    className,
    overrideState,
    onStateChange,
    historyDrawerProps,
    buttonBarStyle,
    ...restProps
}, ref: Ref<MapRef>) => {

    /*
        This component has two ways of receiving aircraft data:
            1. "data" attribute in props. If not null, the useQuery hook below is skipped.
            2. "aircraftID" attribute followed by the useQuery hook. When aircraftID is not null and
                "data" is null, the query will execute and the response is transformed to the same structure
                as the "data" attribute.
    */

    let skipQuery = propData ? true : false || !aircraftID;

    const { data: queryData, loading: queryLoading, error: queryError } = useQuery(gql`
        query GetDispatchAircraft($id: ID!){
            GetDispatchByID(id: $id){
                _id
                status
                ata
                eta
                ato
                pob
                checkTime
                aircraftID
                createdBy
                aircraftDoc {
                    _id
                    tailNum
                }
                contractDoc {
                    _id
                    name
                    customerID {
                        _id
                        name
                    }
                    tpID {
                        _id
                        name
                    }
                }
                departingID
                departingDoc {
                    _id
                    name
                    block
                    field
                    type
                    resolvedCoordinates {
                        latitude
                        longitude
                        source
                    }
                }
                destinationID
                destinationDoc {
                    _id
                    name
                    block
                    field
                    type
                    resolvedCoordinates {
                        latitude
                        longitude
                        source
                    }
                }
                pilotDoc {
                    _id
                    name {
                        firstName
                        lastName
                    }
                }
                name
            }
        }
    `, {
        variables: {
            id: aircraftID
        },
        skip: skipQuery,
        fetchPolicy: 'cache-and-network',
        pollInterval: 30000, // Update aircraft details data every 30 seconds
    })

    let data: AircraftData = null;
    let loading = false;

    // Convert propData or queryData to data variable. Same with loading variable.
    if (!skipQuery){
        let af = queryData?.GetDispatchByID;

        if (af){
            data = {
                aircraftID: af.aircraftID,
                tailNum: af.aircraftDoc.tailNum,
                departure: af.departingDoc?.name,
                departureID: af.departingDoc?._id,
                departureAreaBlock: getAreaBlock(af.departingDoc),
                departureCoordinates: af.departingDoc?.resolvedCoordinates,
                destination: af.destinationDoc?.name,
                destinationID: af.destinationDoc?._id,
                destinationAreaBlock: getAreaBlock(af.destinationDoc),
                destinationCoordinates: af.destinationDoc?.resolvedCoordinates,
                ato: af.ato,
                eta: af.eta,
                ata: af.ata,
                pob: af.pob,
                contract: af.contractDoc?.name,
                pilot: getPilotName(af.pilotDoc),
                status: af.status,
                createdBy: af.createdBy,
                name: af.name
            }
        }

        loading = queryLoading;
        console.debug('queryLoading', queryLoading);
    }
    else
    {
        data = propData;
        loading = propLoading;
        console.debug('propLoading', propLoading);
    }


    const [ dimensions, setDimensions ] = useState([0, 0]); // [x, y]
    const [ state, setState ] = useControlledState<AircraftDetailsState>({
        historyDrawer: {
            visible: false,
            title: 'Aircraft History'
        }
    },
    overrideState, onStateChange)
    const orgData = useContext(OrgDataContext);

    const mapContainerRef = useRef<any>();

    function getMapDimensions(){
        let mc = mapContainerRef.current;
        if (mc){
            let x = mc.clientWidth;
            let y = mc.clientHeight;
            setDimensions([x, y]);
        }
    }

    useEffect(() => {
        getMapDimensions();
        // Get the map container dimensions
        window.addEventListener('resize', getMapDimensions)
        return () => {
            window.removeEventListener('resize', getMapDimensions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mapContainerRef.current ])

    let divProps = {
        className: cn(BASE_CLS, className),
        ...restProps
    }

    function renderMap(){
        
        if (data.status === 'BASE' && data.departureCoordinates){
            return (
                <FlytWatchDetailsMap
                    style={{ position: 'absolute' }}
                    viewport={{ width: dimensions[0], height: dimensions[1] }}
                    {...data.departureCoordinates}
                    zoom={6}
                    ref={ref}
                >
                    <Marker key={data.departure} {...data.departureCoordinates}>
                        <LocationMarker label={data.departure} source={data.departureCoordinates?.source} />
                    </Marker>
                    <Layer id="dummylayer" type='custom' render={() => null} />
                </FlytWatchDetailsMap>
            )
        }
        else if (data.status === 'AIR' && data.departureCoordinates && data.destinationCoordinates){
            let avgCoords = {
                latitude: (data.departureCoordinates.latitude + data.destinationCoordinates.latitude) / 2,
                longitude: (data.departureCoordinates.longitude + data.destinationCoordinates.longitude) / 2
            };
            let lineData: any = {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: [
                        [ data.departureCoordinates.longitude, data.departureCoordinates.latitude ],
                        [ data.destinationCoordinates.longitude, data.destinationCoordinates.latitude ]
                    ]
                }
            }
            return (
                <FlytWatchDetailsMap
                    style={{ position: 'absolute' }}
                    viewport={{ width: dimensions[0], height: dimensions[1] }}
                    {...avgCoords}
                    zoom={6}
                    ref={ref}
                >
                    <Marker key={data.departure} {...data.departureCoordinates}>
                        <LocationMarker
                            label={data.departure}
                            isStart
                            subLabel="(Departing)"
                            source={data.departureCoordinates?.source}
                        />
                    </Marker>
                    <Marker key={data.destination} {...data.destinationCoordinates}>
                        <LocationMarker
                            label={data.destination + (data.destinationAreaBlock ? ` (${data.destinationAreaBlock})` : '')}
                            subLabel="(Destination)"
                            source={data.destinationCoordinates?.source}
                        />
                    </Marker>
                    <Marker {...avgCoords} pitchAlignment="map" rotationAlignment='map'>
                        <div style={{ position: 'relative' }}>
                            <img
                                alt="Arrow end"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    width: '22px',
                                    height: '22px',
                                    transform: `translate(-50%, -50%) rotate(${getDegAngleFromPoints(
                                        data.departureCoordinates.latitude,
                                        data.departureCoordinates.longitude,
                                        data.destinationCoordinates.latitude,
                                        data.destinationCoordinates.longitude
                                    )}deg)` }}
                                    src={MapArrowSvg}
                                />
                        </div>
                    </Marker>
                    <Layer id="dummylayer" type='custom' render={() => null} />
                    <Source id="flightPathLine" type="geojson" data={lineData}>
                        <Layer
                            id="flightPathLayer"
                            type="line"
                            source="flightPathLine"
                            layout={{
                                "line-join": "round",
                                "line-cap": "round"
                            }}
                            paint={{
                                "line-color": "rgb(255, 0, 0)",
                                "line-width": 5
                            }}
                        />
                    </Source>
                </FlytWatchDetailsMap>
            )
        }
        else if (data.status === 'CHECK' && data.departureCoordinates){
            return (
                <FlytWatchDetailsMap
                    style={{ position: 'absolute' }}
                    viewport={{ width: dimensions[0], height: dimensions[1] }}
                    {...data.departureCoordinates}
                    zoom={6}
                    ref={ref}
                >
                    <Marker key={data.departure} {...data.departureCoordinates}>
                        <LocationMarker
                            label={data.departure + (data.departureAreaBlock ? ` (${data.departureAreaBlock})` : '')}
                            source={data.departureCoordinates?.source}
                        />
                    </Marker>
                    <Layer id="dummylayer" type='custom' render={() => null} />
                </FlytWatchDetailsMap>
            )
        }
        else if (!data.departureCoordinates && !data.destinationCoordinates){
            let noDataMsg = <span>
                Coordinate data is missing for {data.departure} and {data.destination}.<br/>
                <RequireUserGroup group={['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']} renderUnauthorized={() => null}>
                    <a
                        className='ant-btn ant-btn-primary'
                        style={{ marginRight: '6px', marginTop: '6px' }}
                        href={"/app/masterdata/location?autoFocusLatitude=true&locationID=" + data.departureID}
                        target="_blank" rel="noreferrer"
                    >Update {data.departure}</a>
                    <a
                        className='ant-btn ant-btn-primary'
                        style={{ marginTop: '6px' }}
                        href={"/app/masterdata/location?autoFocusLatitude=true&locationID=" + data.destination}
                        target="_blank" rel="noreferrer"
                    >Update {data.destination}</a>
                </RequireUserGroup>
            </span>
            return <NonIdealState title="Map Unavailable" description={noDataMsg} icon="question" />
        }
        else if (!data.departureCoordinates) {
            let noDataMsg = <span>
                Coordinate data is missing for {data.departure}.<br/>
                <RequireUserGroup group={['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']} renderUnauthorized={() => null}>
                    <a
                        className='ant-btn ant-btn-primary'
                        style={{ marginTop: '6px' }}
                        href={"/app/masterdata/location?autoFocusLatitude=true&locationID=" + data.departureID}
                        target="_blank" rel="noreferrer"
                    >Update {data.departure}</a>
                </RequireUserGroup>
            </span>
            return <NonIdealState title="Map Unavailable" description={noDataMsg} icon="question" />
        }
        else if (!data.destinationCoordinates) {
            let noDataMsg = <span>
                Coordinate data is missing for {data.destination}.<br/>
                <RequireUserGroup group={['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.delete']} renderUnauthorized={() => null}>
                    <a
                        className='ant-btn ant-btn-primary'
                        style={{ marginTop: '6px' }}
                        href={"/app/masterdata/location?autoFocusLatitude=true&locationID=" + data.destinationID}
                        target="_blank" rel="noreferrer"
                    >Update {data.destination}</a>
                </RequireUserGroup>
            </span>
            return <NonIdealState title="Map Unavailable" description={noDataMsg} icon="question" />
        }
    }

    if (loading && !data){
        return <CenteredLoadingScreen />
    }

    if (queryError) {
        return <Alert
            showIcon type='error' message="Failed to load aircraft details"
            description={cleanGraphQLErrorMsg(queryError.message)}
            style={{ margin: '12px 0' }}
        />
    }

    if (!data){
        return <div {...divProps} style={{ ...divProps?.style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <NonIdealState title="No aircraft data found" icon="question" />
        </div>
    }

    function renderLocIcons(): [React.ReactNode, React.ReactNode]{
        let nodes: [React.ReactNode, React.ReactNode] = [null, null];

        let start = <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="mc-location-icon mc-location-icon-start"
        />

        let end = <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="mc-location-icon mc-location-icon-end"
        />

        if (data.status === 'BASE' && data.departureCoordinates){
            nodes[0] = end;
        }
        else if (data.status === 'AIR' && data.departureCoordinates && data.destinationCoordinates){
            nodes[0] = start;
            nodes[1] = end;
        }
        else if (data.status === 'CHECK' && data.departureCoordinates){
            nodes[0] = end;
        }

        return nodes;
    }

    let locIcons = renderLocIcons();

    function getAcTime(){
        let time: string;
        switch (data.status) {
            case 'BASE':
                time = data.ata;
                break;
            case 'AIR':
                time = data.ato;
                break;
            case 'CHECK':
                time = data.ata;
                break;
            default:
                break;
        }
        return time;
    }

    function getDrawerTitle(){
        let time = getAcTime();
        if (!time || moment().isSame(momentOrNull(time), 'date')){
            return 'Today\'s flight history for ' + data.name + ' (' + orgData.transporter?.name + ')'
        }
        return 'Flight history for ' + data.name + ' at ' + moment(time).format('ddd, MMM DD YYYY') + ' (' + orgData.transporter?.name + ')'
    }

    function renderButtonBar(){
        const className = cn({
            [BASE_CLS + '-buttonbar']: true,
            [BASE_CLS + '-buttonbar-header']: title ? true : false
        })
        return <div className={className} style={{ display: 'flex', gap: '6px', ...buttonBarStyle }}>
            {title ? <h2 style={{ margin: 0, marginRight: '12px' }}>{title}</h2> : null}
            {/* <RequireUserGroups groups={['flytsuite.flytwatch.read']}> */}
                <Button
                    type='primary'
                    onClick={() => setState({ ...state, historyDrawer:
                        {
                            ...state.historyDrawer,
                            visible: true,
                            title: getDrawerTitle()
                        }
                    })}
                >View History</Button>
            {/* </RequireUserGroups> */}
        </div>
    }

    return <div {...divProps}>
        {renderButtonBar()}
        <Descriptions className="mc-flytwatch-aircraft-details-table" layout="vertical" bordered size="small" column={9}>
            <DItem label="Flight ID">
                <span>{data.name}</span>
                <br/>
                {/* <span style={{ whiteSpace: 'nowrap' }}>No Manifest</span> */}
            </DItem>
            <DItem label="Departing">
                {locIcons[0]}
                {data.departure}
            </DItem>
            <DItem label="Destination">
                {locIcons[1]}
                {data.destination}
            </DItem>
            <DItem label="ATO">{renderTime(data.ato, data)}</DItem>
            <DItem label="ETA">{renderTime(data.eta, data)}</DItem>
            <DItem label="ATA">{renderTime(data.ata, data)}</DItem>
            <DItem label="POB">{data.pob}</DItem>
            <DItem label="Contract">{data.contract}</DItem>
            <DItem label="Pilot">{data.pilot}</DItem>
        </Descriptions>
        <ErrorBoundary
            fallbackRender={() => (
                <div className="mc-flytwatch-aircraft-details-map" ref={mapContainerRef}>
                    <NonIdealState
                        title="Something went wrong"
                        description="An error occurred while rendering the map"
                        icon={<Icon type="exclamation-circle" className="nonidealstate-icon" style={{ color: 'red' }} />}
                    />
                </div>
            )}
        >
            <div className="mc-flytwatch-aircraft-details-map" ref={mapContainerRef}>
                {renderMap()}
            </div>
        </ErrorBoundary>
        <Drawer
            bodyStyle={{ padding: '6px', overflow: 'auto' }}
            {...state.historyDrawer}
            width={1700}
            {...historyDrawerProps}
            onClose={() => setState({ ...state, historyDrawer: { ...state.historyDrawer, visible: false } })}
        >
            <FWAircraftHistory flightID={data.name} flightDate={momentOrNull(getAcTime())?.format('YYYY-MM-DD')} />
        </Drawer>
    </div>
})

AircraftDetails.displayName = 'AircraftDetails';

export default AircraftDetails;