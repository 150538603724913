import gql from "graphql-tag";

// eslint-disable-next-line import/no-anonymous-default-export
export default gql`
    extend type Query {
        reportDateRange: [String!]!
    }
    extend type Mutation {
        setReportDateRange(dateRange: [String!]!): [String!]!
    }
`