import React from 'react';
import { Alert } from 'antd';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import WithOrgData from '../../../WithOrgData';
import LoadingContent from '../../../LoadingContent';
import { withRouter } from 'react-router-dom';
import commonColumns from '../../../../common/table/columns';
import ETable from 'components/enchanced-antd-table';

class Boats extends React.Component {
    renderContent = () => {
        const { data: { getBoats, loading, error } } = this.props;
        if (error) return <Alert type="error" message="Failed to load boats" description={error.message} showIcon />
        if (loading) return <LoadingContent />
        if (getBoats){
            return (
                <ETable
                    dataSource={getBoats}
                    className="mc-table mc-table-scroll-auto mc-table-header-nowrap"
                    bordered
                    rowKey={record => record._id}
                    pagination={false}
                    size="small"
                    onRow={record => ({
                        onClick: () => this.props.history.push(`/app/boats/${record._id}`),
                        style: {
                            cursor: 'pointer'
                        }
                    })}
                    columns={commonColumns.boat.all()}
                />
            )
        }
    }
    render(){
        return (
            <div style={{ height: '100%', overflowX: 'auto', padding: '12px' }}>
                <h2>Live Boats</h2>
                <div>
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}

const BOATS = gql`
query Boats($tpID: ID! $customerID: ID){
    getBoats(tpID: $tpID customerID: $customerID){
        _id
        desig
        departureIDs {
            _id
            name
        }
        destinationIDs {
            _id
            name
        }
        vesselID {
            _id
            name
        }
        boatCrewIDs {
            _id
        }
        paxIDList {
            _id
        }
    }
}
`

const BoatsWithData = graphql(
    BOATS,
    {
        options: props => {
            const { transporter, customer } = props.orgData;
            return {
                fetchPolicy: 'network-only',
                pollInterval: 5000,
                variables: {
                    tpID: transporter._id,
                    customerID: customer && customer._id
                }
            }
        }
    }
)(Boats)

export default withRouter( WithOrgData(BoatsWithData) )