import {Dispatcher, State} from "./state";
import * as Util from './util';
import { Col, Divider, Layout, List, Row, Select} from "antd";
import MCIcon from "../../../../icon";
import { BASE_CLS as PARENT_BASE_CLS } from './util';
import {SelectProps} from "antd/lib/select";
import {PropsWithChildren} from "react";
import {ScheduleNode} from "../../../../scheduling/types";
import {getSNodeID} from "../../../../scheduling/util";

const BASE_CLS = PARENT_BASE_CLS + '-note-assigner';

export interface NoteAssignerProps {
    state: State,
    dispatch: Dispatcher
}

interface EntityWithNote {
    entity: ScheduleNode,
    noteId: number | undefined
}

const NoteAssigner: React.FC<NoteAssignerProps> = ({ state, dispatch }) => {

    let entitiesWithNote: EntityWithNote[] = Array.from(state.origEntities.values())
        .map((entity) => {
            const noteId = state.entityIdToNote.get(getSNodeID(entity));

            if (noteId < 0){
                return {
                    entity: entity,
                    noteId: undefined
                }
            }

            return {
                entity: entity,
                noteId: noteId
            }
        });

    entitiesWithNote.sort((a, b) => {
        const aName = Util.getEntityName(a.entity);
        const bName = Util.getEntityName(b.entity);

        return String(aName).localeCompare(bName)
    })

    function getNoteSelectorProps(){
        const options = Array.from(state.notes.entries())
            .map(([ noteId, message ]) => {
            if (!message) return null;

            return (
                <Select.Option key={noteId} value={noteId} title={message}>
                    {message}
                </Select.Option>
            )
        })

        return {
            style: {
                minWidth: 200,
                maxWidth: 200
            },
            placeholder: 'Unassigned',
            allowClear: true,
            dropdownRender: (menu) => (
                <div className={BASE_CLS + 'selector-menu'}>
                    {menu}
                </div>
            ),
            children: options
        } as PropsWithChildren<Partial<SelectProps>>
    }

    function renderNoteSelector(entityWithNote: EntityWithNote){

        return <Select
            value={typeof entityWithNote.noteId === 'number' ? entityWithNote.noteId : undefined}
            onChange={(noteId: number) => {
                dispatch({
                    type: 'ASSIGN_ENTITY_TO_NOTE',
                    noteId: noteId,
                    entityId: getSNodeID(entityWithNote.entity)
                });
            }}
            {...getNoteSelectorProps()}
        />
    }

    function renderEntity(entityWithNote: EntityWithNote){
        const name = Util.getEntityName(entityWithNote.entity);
        const icon = Util.getEntityIcon(entityWithNote.entity);
        const noteSelector = renderNoteSelector(entityWithNote);

        return (
            <List.Item
                key={getSNodeID(entityWithNote.entity)}
                extra={noteSelector}
            >
                <div>
                    <MCIcon
                        type={icon}
                        style={{
                            marginRight: 12
                        }}
                    />
                    {name}
                </div>
            </List.Item>
        )
    }

    const items = entitiesWithNote.map(renderEntity);

    const allNoteIds = Array.from(state.entityIdToNote.values());

    const bulkAssignerValue = allNoteIds
        .reduce((prev, noteId) => {
        const noteMessage = state.notes.get(noteId);

        if (!prev && noteId > -1){
            return {
                key: noteId,
                label: noteMessage
            };
        }

        if (prev.key !== noteId){
            return {
                key: -1,
                label: 'Mixed'
            };
        }

        return {
            key: noteId,
            label: noteMessage
        };

    }, undefined as { key: number, label: string })

    let bulkAssigner = (
        <>
            <Row
                type="flex"
                align="middle"
                style={{
                    margin: '12px 6px'
                }}
            >
                <Col>
                    Assign All:
                </Col>
                <Col style={{ flex: 1 }} />
                <Col>
                    <Select
                        value={bulkAssignerValue}
                        onChange={(noteValue: { key: number, label: string }) => {
                            dispatch({
                                type: 'ASSIGN_ENTITY_TO_NOTE',
                                noteId: noteValue.key,
                                entityId: Array.from(state.origEntities.keys())
                            })
                        }}
                        labelInValue
                        {...getNoteSelectorProps()}
                    />
                </Col>
            </Row>
            <Divider
                style={{
                    margin: 0
                }}
            />
        </>
    )

    return <>
        {bulkAssigner}
        <Layout
            style={{ padding: 6, flex: 1 }}
        >
            <List
                className={BASE_CLS}
            >
                {items}
            </List>
        </Layout>
    </>
}

export default NoteAssigner;