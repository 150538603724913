import React, { useRef} from "react";
import {Helper} from './state';
import * as Hook from './hook';
import {ScheduledGroup, ScheduledPaxCgoGroupV2Leg} from "common/types/schedule";
import {Button, Checkbox, Collapse, Divider, Icon, Spin, Tooltip} from "antd";
import CollapseClassNames from "../../../../common/style/collapse";
import './style.less';
import {BASE_CLS} from "./util";
import {CollapseProps} from "antd/lib/collapse";
import {approximateLegRouteColumnWidth, LegRoute} from "../util";
import {PaxNode} from "../../../../common/types/carriable";
import {getTransitType} from "../../../../common/carriable/util";
import ETable from "../../../enchanced-antd-table";
import MCIcon from "../../../icon";
import IsnCheckStatusButton from "../../../IsnCheckStatusButton";
import {union} from 'lodash';
import {ApiInterface} from "./api";
import {ScheduleNode} from "../../../scheduling/types";
import {getSNodeID} from "../../../scheduling/util";
import {NotesForPilotQuickEdit} from "../notes-for-pilot/quickedit/notes-for-pilot-quick-edit";

// These props are intended to be used with the "componentProps" property of useScheduledPaxCgoAdderState in ./hook.tsx
// Use without useScheduledPaxCgoAdderState is not recommended.
export interface ScheduledPaxCgoAdderProps {
    data: ApiInterface['data'],
    selectionEvent?: ApiInterface['selection'],

    collapseGroupKeys?: ApiInterface['action']['groupCollapseKeys'],
    collapseLegKeys?: ApiInterface['action']['legCollapseKeys'],
    onPaxNameClicked?: (paxData: PaxNode) => void,
    onIsnButtonClicked?: (paxData: PaxNode) => void,
    onAddEntitiesToFlight?: ApiInterface['action']['flight']['addEntities'],

    isAddingToFlight?: boolean
}

export type ScheduledPaxCgoAdderType = React.FC<ScheduledPaxCgoAdderProps> & {
    useState: typeof Hook.useScheduledPaxCgoAdderState
}

/**
 * Displays a list of scheduled groups that can be expanded and collapsed. Each scheduled group displays flight legs,
 * and each leg displays a list of pax/cgo.
 */
const ScheduledPaxCgoAdder: ScheduledPaxCgoAdderType = (props) => {

    const groups = props.data.groups.get();
    const containerRef = useRef<HTMLDivElement>(null);

    const legRouteColumnWidth = approximateLegRouteColumnWidth(
        groups.map((item) => ({
            departureName: item.departureID?.name,
            destinationName: item.destinationID?.name
        }))
    );

    function renderAddEntitiesButton(paxIds: string[], cgoIds: string[]){
        return (
            <Tooltip
                title="Quickly adds the PAX/CGO and closes."
                mouseEnterDelay={0.5}
            >
                <Button
                    className={BASE_CLS + '-add-button'}
                    type="primary"
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onAddEntitiesToFlight?.(
                            paxIds,
                            cgoIds
                        )
                    }}
                >Add {paxIds.length + cgoIds.length} PAX/CGO</Button>
            </Tooltip>
        )
    }

    function renderPaxTable(leg: ScheduledPaxCgoGroupV2Leg){

        const columns = [
            {
                title: <strong><i>PAX ({leg.paxnodeIDs.length})</i></strong>,
                key: 'action',
                width: 73,
                render: () => {
                    return (
                        <div className={BASE_CLS + '-icon-container'}>
                            <MCIcon type="user"/>
                        </div>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                render: (_, record) => {
                    let xbr;
                    let icon = null;

                    var data;
                    if (record.__typename === 'Person')
                    {
                        data = {
                            ...record,
                            personID: { ...record }
                        }
                    }
                    else
                    {
                        data = record
                    }
                    if(data.personID){
                        xbr = data.personID.extraBroadState;
                    }else {
                        xbr = record.extraBroadState;
                    }
                    let name = `${record.lastName}, ${record.firstName}`
                    if(xbr === 'FAIL'){
                        name = `\u2194 ${record.lastName}, ${record.firstName}`
                    }else if(xbr === 'PASS'){
                        name = `\uc6c3 ${record.lastName}, ${record.firstName}`
                    }
                    return (
                        <span>
                            {icon}
                            <Button className={'mc-link-btn'} onClick={() => {
                                props?.onPaxNameClicked?.(record);
                            }}>{name}</Button>
                        </span>
                    )
                }
            },
            {
                title: 'Nation',
                key: 'nation',
                render: (_, row) => row.personID && row.personID.nation
            },
            {
                title: 'Employer',
                key: 'employer',
                render: (_, record) => record.employerID && record.employerID.name
            },
            {
                title: 'Pax Weight',
                key: 'paxWeight',
                dataIndex: 'paxWeight'
            },
            {
                title: 'Bag Weight',
                key: 'bagWeight',
                dataIndex: 'bagWeight'
            },
            {
                title: 'Bag Count',
                key: 'bagCount',
                dataIndex: 'bagCount'
            },
            {
                title: 'Charge Code',
                key: 'chargeCode',
                dataIndex: 'chargeCode',
            },
            {
                title: 'Pilot Notes',
                key: 'pilotNotes',
                render: (_, row) => <NotesForPilotQuickEdit
                    scheduleNodeData={row}
                    readOnly
                    popupContainer={containerRef.current}
                />
            },
            {
                title: 'ISN Status',
                key: 'isnStatus',
                render: (_, row) => {

                    if (row.transitType !== 'OUTBOUND') return '—';

                    return <IsnCheckStatusButton
                        pax={row}
                        size="small"
                        block
                        onClick={(e) => {
                            e.stopPropagation();
                            props?.onIsnButtonClicked?.(row);
                        }}
                    />
                }
            },
        ]

        if (!leg.paxnodeIDs.length) return null;

        return <ETable
            className={BASE_CLS + '-table'}
            dataSource={leg.paxnodeDocs}
            columns={columns}
            rowSelection={{
                selectedRowKeys: props.selectionEvent?.('ENTITY').get(),
                onChange: props.selectionEvent?.('ENTITY').set,
                fixed: true
            }}
            onRow={(record: ScheduleNode) => {
                return {
                    onClick: () => {
                        props.selectionEvent?.('ENTITY').toggle([record._id])
                    },
                    className: BASE_CLS + '-table-row-selectable'
                }
            }}
            pagination={false}
            rowKey={record => getSNodeID(record)}
            size="small"
        />
    }

    function renderCgoTable(leg: ScheduledPaxCgoGroupV2Leg){

        const cgo = leg.cgonodeDocs;

        const columns = [
            {
                title: <strong><i>CGO ({cgo.length})</i></strong>,
                key: 'action',
                width: 73,
                render: () => {

                    return (
                        <div className={BASE_CLS + '-icon-container'}>
                            <MCIcon type="cargo"/>
                        </div>
                    )
                }
            },
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: 'Weight',
                key: 'weight',
                dataIndex: 'weight'
            },
            {
                title: 'HazMat',
                key: 'hazmat',
                render: (_, record) => record.hazmat ? record.hazmatUnCode || "YES" : null
            },
            {
                title: 'Attention',
                key: 'attentionTo',
                dataIndex: 'attentionTo'
            },
            {
                title: 'Initials',
                key: 'initials',
                dataIndex: 'initials'
            },
            {
                title: 'Notes',
                key: 'notes',
                dataIndex: 'notes'
            },
            {
                title: 'Pilot Notes',
                key: 'pilotNotes',
                render: (_, row) => <NotesForPilotQuickEdit
                    scheduleNodeData={row}
                    readOnly
                    popupContainer={containerRef.current}
                />
            },
        ]

        if (!cgo.length) return null;

        return <ETable
            className={BASE_CLS + '-table'}
            dataSource={cgo}
            columns={columns}
            rowSelection={{
                selectedRowKeys: props.selectionEvent?.('ENTITY').get(),
                onChange: props.selectionEvent?.('ENTITY').set,
                fixed: true
            }}
            onRow={(record: ScheduleNode) => {
                return {
                    onClick: () => {
                        props.selectionEvent?.('ENTITY').toggle([record._id]);
                    },
                    className: BASE_CLS + '-table-row-selectable'
                }
            }}
            pagination={false}
            rowKey={record => getSNodeID(record)}
            size="small"
        />
    }

    function renderLegPanel(leg: ScheduledPaxCgoGroupV2Leg){


        const pax = leg.paxnodeDocs;
        const cgo = leg.cgonodeDocs;
        const all = [...pax, ...cgo];
        const allIds = [...leg.paxnodeIDs, ...leg.cgonodeIDs];

        const selectedEntityIds = props.selectionEvent?.('ENTITY').get()
            .filter(entityId => leg.cgonodeIDs.includes(entityId) || leg.paxnodeIDs.includes(entityId));

        const intermediate = selectedEntityIds.length < all.length && selectedEntityIds.length > 0;
        const canSelectAll = intermediate || selectedEntityIds.length === 0;

        function handleCheckbox(){
            if (canSelectAll){
                props.selectionEvent?.('ENTITY').add(allIds);
            }
            else
            {
                props.selectionEvent?.('ENTITY').remove(allIds);
            }
        }

        const checked = selectedEntityIds.length >= all.length

        const header = (
            <>
                <div
                    className={BASE_CLS + '-collapse-panel-checkbox-hitbox'}
                    role="checkbox"
                    aria-checked={checked}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCheckbox();
                    }}
                />
                <div className={BASE_CLS + '-collapse-panel-checkbox'}>
                    <Checkbox
                        indeterminate={intermediate}
                        checked={checked}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCheckbox();
                        }}
                    />
                </div>
                <div className={BASE_CLS + '-sections-wrapper'}>
                    <div
                        className={BASE_CLS + '-section'}
                        style={{
                            width: legRouteColumnWidth,
                            minWidth: legRouteColumnWidth
                        }}
                    >
                        <LegRoute
                            departureName={leg.departureDoc?.name}
                            destinationName={leg.destinationDoc?.name}
                        />
                    </div>
                    <div
                        className={BASE_CLS + '-section'}
                        style={{minWidth: 110}}
                    >
                        <span>{getTransitType(leg.departureDoc, leg.destinationDoc)}</span>
                    </div>
                    <div
                        className={BASE_CLS + '-section'}
                        style={{minWidth: 130}}
                    >
                        <span><strong>{pax.length}</strong> PAX</span>
                        <Divider type="vertical"/>
                        <span><strong>{cgo.length}</strong> CGO</span>
                    </div>
                    <div
                        className={
                            BASE_CLS + '-section ' +
                            BASE_CLS + '-totals'
                        }
                        style={{minWidth: 270}}
                    >
                    <span style={{textAlign: 'center', lineHeight: '14px', marginRight: '10px'}}>
                        <div><strong>{leg.totalWeight}</strong></div> Wt
                    </span>
                        <span style={{textAlign: 'center', lineHeight: '14px', marginRight: '10px'}}>
                        <div><strong>{leg.totalPaxWeight}</strong></div> Pax Wt
                    </span>
                        <span style={{textAlign: 'center', lineHeight: '14px', marginRight: '10px'}}>
                        <div><strong>{leg.totalCgoWeight}</strong></div> Cgo Wt
                    </span>
                        <span style={{textAlign: 'center', lineHeight: '14px', marginRight: '10px'}}>
                        <div><strong>{leg.totalBagWeight}</strong></div> Bag Wt
                    </span>
                        <span style={{textAlign: 'center', lineHeight: '14px', marginRight: '10px'}}>
                        <div><strong>{leg.totalCgoWeight}</strong></div> Bag Ct
                    </span>
                    </div>
                </div>
            </>
        )

        const legKey = Helper.legKey.stringify(leg);

        return (
            <Collapse.Panel
                key={legKey}
                header={header}
                className={BASE_CLS + '-collapse-panel'}
                extra={
                    renderAddEntitiesButton(leg.paxnodeIDs, leg.cgonodeIDs)
                }
            >
                <ETable.Group>
                    {renderPaxTable(leg)}
                    {renderCgoTable(leg)}
                </ETable.Group>
            </Collapse.Panel>
        )
    }

    function renderGroupPanel(group: ScheduledGroup) {

        const key = Helper.panelKey.stringify(group);

        // Creates a list of unique pax and cgo IDs from all legs in this group.
        const paxIds = group.legs.reduce(
            function(entityList: string[], leg: ScheduledPaxCgoGroupV2Leg){
                return union(entityList, leg.paxnodeIDs);
            },
            []
        )
        const cgoIds = group.legs.reduce(
            function(entityList: string[], leg: ScheduledPaxCgoGroupV2Leg){
                return union(entityList, leg.cgonodeIDs);
            },
            []
        )
        const entityIds = [...paxIds, ...cgoIds];

        const selectedEntityIds = props.selectionEvent?.('ENTITY').get()
            .filter(entityId => entityIds.includes(entityId));

        const intermediate = selectedEntityIds.length < entityIds.length && selectedEntityIds.length > 0;
        const canSelectAll = intermediate || selectedEntityIds.length === 0;

        function handleCheckbox(){
            if (canSelectAll){
                props.selectionEvent?.('ENTITY').add(entityIds);
            }
            else
            {
                props.selectionEvent?.('ENTITY').remove(entityIds);
            }
        }

        const checked = selectedEntityIds.length >= entityIds.length

        const header = (
            <>
                <div
                    className={BASE_CLS + '-collapse-panel-checkbox-hitbox'}
                    role="checkbox"
                    aria-checked={checked}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCheckbox();
                    }}
                />
                <div className={BASE_CLS + '-collapse-panel-checkbox'}>
                    <Checkbox
                        indeterminate={intermediate}
                        checked={checked}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCheckbox();
                        }}
                    />
                </div>
                <div className={BASE_CLS + '-sections-wrapper'}>
                    <div className={BASE_CLS + '-section'}>
                        {group.scheduledGroup}
                    </div>
                    <div className={BASE_CLS + '-section'}>
                        <strong>Heliport:</strong>
                        <span
                            style={{marginLeft: 3}}
                        >{group.lastKnownController?.name}</span>
                    </div>
                    <div className={BASE_CLS + '-section'}>
                        <strong style={{marginRight: 2}}>{group.legCount}</strong> Legs
                    </div>
                    <div className={BASE_CLS + '-section'}>
                        <strong style={{marginRight: 2}}>{group.paxOutbound + group.cgoOutbound}</strong>{' '}OB
                        <Divider type="vertical"/>
                        <strong style={{marginRight: 2}}>{group.paxTransfer + group.cgoTransfer}</strong>{' '}TR
                        <Divider type="vertical"/>
                        <strong style={{marginRight: 2}}>{group.paxInbound + group.cgoInbound}</strong>{' '}IB
                    </div>
                </div>
            </>
        )

        let collapseControl: Partial<CollapseProps> = {};
        if (props.collapseLegKeys) {
            collapseControl = {
                activeKey: props.collapseLegKeys?.get?.(group) || [],
                onChange: (keys) => props.collapseLegKeys?.set?.(group, keys)
            }
        }

        const panels = group.legs.map(renderLegPanel);

        const legCollapse = (
            <Collapse
                className={
                    BASE_CLS + '-legs-collapse ' +
                    CollapseClassNames.RoundedWithTable
                }
                {...collapseControl}
            >
                {panels}
            </Collapse>
        )

        return (
            <Collapse.Panel
                key={key}
                header={header}
                className={BASE_CLS + '-collapse-panel'}
                extra={
                    renderAddEntitiesButton(paxIds, cgoIds)
                }
            >
                {legCollapse}
            </Collapse.Panel>
        )
    }

    const panels = groups.map(renderGroupPanel);

    let collapseControl = {};
    if (props.collapseGroupKeys) {
        collapseControl = {
            activeKey: props.collapseGroupKeys?.get?.() || [],
            onChange: props.collapseGroupKeys?.set
        }
    }

    const loading = Boolean(props.data?.loading || props?.isAddingToFlight);

    return <Spin
            spinning={loading}
            indicator={<Icon type="loading" />}
            className="ant-spin-fill-height"
        >
        <div className={BASE_CLS} ref={containerRef}>
            <Collapse
                className={
                    BASE_CLS + '-group-collapse ' +
                    CollapseClassNames.Rounded
                }
                {...collapseControl}
            >
                {panels}
            </Collapse>
        </div>
    </Spin>
}

ScheduledPaxCgoAdder.useState = Hook.useScheduledPaxCgoAdderState;

export default ScheduledPaxCgoAdder;