import { ColumnProps } from 'antd/lib/table';
import React, { useContext } from 'react';
import { FWBRContext } from './context';
import ETable, { ETableTotals } from 'components/enchanced-antd-table';

export interface FWBillingReportTableProps {
    data: any[],
    totalAircraft: number
}

export const FWBRTContextConsumer: React.FC<{ children: React.ReactElement<FWBillingReportTableProps> }> = (props) => {
    const ctx = useContext(FWBRContext);

    let summary = ctx.reportDetails?.reportData?.result?.summary;
    if (summary){
        summary = JSON.parse(summary);
    }

    return React.cloneElement(props.children, {
        data: ctx.reportDetails?.reportData?.result?.docs || [],
        totalAircraft: summary?.total_aircraft_count || '?'
    })
}

const FWBillingReportTable: React.FC<Partial<FWBillingReportTableProps>> =
({ data, totalAircraft }) => {

    let totals: ETableTotals = {
        label: 'Total',
        colNum: 0,
        sticky: true,
        td: [<td>{totalAircraft}</td>]
    };

    let columns: ColumnProps<any>[] = [
        {
            title: 'Flight Date',
            key: 'flightDate',
            dataIndex: 'flightDate'
        },
        {
            title: 'Aircraft Count',
            key: 'aircraftCount',
            dataIndex: 'aircraftCount'
        },
        {
            title: 'Active Aircraft',
            key: 'activeAircraft',
            render: (_, record) => record.activeAircraft ? record.activeAircraft.join(' ') : null
        }
    ]

    return <ETable
        rowKey="key"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        className="mc-table"
        bordered
        totals={totals}
        groupSpacing
        flexMode
        stickyHeader
    />
}

export default FWBillingReportTable