import {useCallback, useEffect, useState} from "react";

export type SortDirection = 'asc' | 'desc' | undefined;

export interface SortableDataOptions {
    defaultSortDir?: SortDirection,
    sortDir?: SortDirection,
    sortBy?: string,
}

export default function useSortableData<T=any>(data: T[], options: SortableDataOptions={}){
    const { sortBy, defaultSortDir } = options;

    const [ sortDir, setSortDir ] = useState<SortDirection>(defaultSortDir);
    const [ sortKey, setSortKey ] = useState<string | null>(typeof sortBy === 'string' ? sortBy : null);

    useEffect(() => {
        setSortKey(key => sortBy || key);
        setSortDir(dir => options.sortDir || dir);
    }, [ sortBy, options.sortDir ]);

    const sort = useCallback((a: T, b: T) => {
        if (!sortDir || !sortKey) return 0;

        const sortDirFactor = sortDir === 'desc' ? 1 : -1;

        const valueA = String(a[sortKey]);
        const valueB = String(b[sortKey]);

        return valueA.localeCompare(valueB) > 0 ? -1*sortDirFactor : 1*sortDirFactor;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ data, sortDir, sortBy, sortKey ])

    if (!Array.isArray(data)) return data;

    const returnOptions = {
        setSortKey(key: string, dir: SortDirection = sortDir){
            setSortKey(key);
            setSortDir(dir);
        },
        setSortDir(dir: SortDirection){
            setSortDir(dir);
        },
        toggleSortDir(){
            setSortDir(dir => dir === 'desc' ? 'asc' : 'desc')
        },
        currentSortKey: sortKey,
        currentSortDir: sortDir
    }

    return [
        data.sort(sort),
        returnOptions
    ] as [ T[], typeof returnOptions ]
}
