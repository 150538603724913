import React, {useRef} from "react";
import {
    Button,
    Col,
    Modal,
    Row,
    Tooltip,
} from "antd";
import "./FlightScheduleLegPax.less";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import ETable from "components/enchanced-antd-table";
import { MultiSelectReturn } from "hooks/useMultiSelect";
import {createScheduledEntityColumns} from "./columns";
import {createLegListSelectColumn} from "./FlightScheduleUtils";
import {FlightLeg} from "../../../schema";

library.add(faBoxOpen);

type TEMP_TYPE_Flight = any;
type TEMP_TYPE_Pax = any;
type TEMP_TYPE_Cgo = any;
type TEMP_TYPE_Entity = TEMP_TYPE_Pax | TEMP_TYPE_Cgo

export interface FlightScheduleLegPaxProps {
    flight: TEMP_TYPE_Flight,
    selectedLegIndex: number,
    departureName: string;
    destinationName: string;
    multiSelect?: MultiSelectReturn<TEMP_TYPE_Entity>,
    events: {
        onRemoveRedundantLeg?: () => void
    }
}

const FlightScheduleLegPax: React.FC<FlightScheduleLegPaxProps> = (props) => {
    const {
        selectedLegIndex,
        flight,
        departureName,
        destinationName,
        multiSelect
    } = props;

    const legs: FlightLeg[] = (flight.legs && Object.values(JSON.parse(flight.legs))) || []
    const selectedLeg = legs?.[selectedLegIndex];
    const containerRef = useRef<HTMLDivElement>(null);

    const paxObjList: TEMP_TYPE_Pax[] = flight?.paxIDList?.filter(p => selectedLeg?.paxIDs?.includes(p._id));
    const cgoObjList: TEMP_TYPE_Cgo[] = flight?.cgoIDList?.filter(c => selectedLeg?.cgoIDs?.includes(c._id));

    if (!(paxObjList || cgoObjList)){
        return <h2 style={{marginTop: '10rem', textAlign: 'center', color: 'grey'}}>No leg data available</h2>
    }

    let paxSortedList = [...paxObjList];
    let cgoSortedList = [...cgoObjList];

    paxSortedList.sort((a, b) => String(a.lastName + ', ' + a.firstName).localeCompare(String(b.lastName + ', ' + b.firstName)))
    cgoSortedList.sort((a, b) => String(a.name).localeCompare(String(b)))

    let combinedList = []
    if(paxSortedList?.length > 0){
        combinedList = combinedList.concat(paxSortedList)
    }
    if(cgoSortedList?.length > 0){
        combinedList = combinedList.concat(cgoSortedList)
    }

    const columns = createScheduledEntityColumns(selectedLeg, { containerRef: containerRef.current });

    if (multiSelect){
        columns.unshift(createLegListSelectColumn(multiSelect, combinedList));
    }

    return (
        <React.Fragment>
            <div ref={containerRef}>
                <Row type="flex" style={{marginBottom: '1rem', alignItems: 'center'}} gutter={12}>
                    <Col style={{flex: 1}}>
                        <h3 style={{margin: 0}}>
                            {departureName && destinationName ?
                                `${departureName} to ${destinationName}` :
                                ''
                            }
                        </h3>
                    </Col>
                    {props.events?.onRemoveRedundantLeg ? (
                        <Col>
                            <Tooltip title={`No PAX/CGO are planned to visit this destination. Removal is recommended.`}>
                                <Button
                                    type='danger'
                                    onClick={() => Modal.confirm({
                                        title: 'Are you sure you want to remove this redundant leg?',
                                        onOk: () => props.events?.onRemoveRedundantLeg(),
                                        okButtonProps: {
                                            children: 'Remove',
                                            type: 'danger'
                                        }
                                    })}
                                >Remove Redundant Leg</Button>
                            </Tooltip>
                        </Col>
                    ) : null}
                </Row>
                <ETable.Group>
                    <ETable
                        className='mc-table mc-scheduling-leg-pax'
                        dataSource={combinedList}
                        columns={columns}
                        onRowClick={(record: TEMP_TYPE_Entity) => {
                            multiSelect?.toggleSelection(record);
                        }}
                        rowClassName={() => "mc-cursor-pointer"}
                        rowKey={(record: TEMP_TYPE_Entity) => record._id}
                        size="small"
                        pagination={false}
                    />
                </ETable.Group>
            </div>
        </React.Fragment>
    )
}

export default FlightScheduleLegPax