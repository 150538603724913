import { Cell, Column } from '@blueprintjs/table';
import { Button, Col, Divider, Input, List, message, Row } from 'antd';
import { getLabelInValue, getLabelInValueKey } from 'common/form';
import { getColWidths } from 'common/table';
import { castArray, getFirstElArrayOrNull, safeGet } from 'common/util';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import { buildBulkMutation } from 'components/covid-vax-date-form/submitter';
import { PersonCovidVaxRecord } from 'components/covid-vax-date-list';
import { OrganizationSelect } from 'components/form/select/OrganizationSelect';
import { getFormCredentials } from 'components/PersonEditor';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import credentialTypes from 'credentialTypes';
import gql from 'graphql-tag';
import useUserGroups from 'hooks/useUserGroups';
import { sha256 } from 'js-sha256';
import { isEmpty, keyBy, mapKeys, omit } from 'lodash';
import moment from 'moment';
import DeleteEntity from 'Mutations/DeleteEntity';
import pluralize from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { DuplicationChecker, MDDetails, MDHeader, MDLayout, MDTable, TableLoader, useMasterDataState } from '../..';
import PersonEntryForm from '../../entryForms/person';
import { getMDTableProps, MasterDataProps, MDStateToDetailsProps, MDStateToHeaderProps, MDStateToLayoutProps, MDStateToTableScrollerProps, renderCellFromKeyIndex, renderNoData, renderOrgCell, searchValuesToQuerySearchPayload, trimObjectStringValues, trimString, updateQueryWithFetchMoreResult } from '../../util';

export type HidableColumnTypes = 'customer' | 'employer'

export interface MDPersonnelProps extends WithOrgDataProps, MasterDataProps {

}

const defaultContactValues = (person, info) => {
    return {
        "name":{
            "firstName": person.firstName,
            "lastName": person.lastName
        },
        "phone":{
            "type": (info && info.phone && info.phone.value && info.phone.value.type) || "HOME",
            "number": (info && info.phone && info.phone.value && info.phone.value.number) || ""
        },
        "email":{
            "type": (info && info.email && info.email.value && info.email.value.type) || "PERSONAL",
            "address": (info && info.email && info.email.value && info.email.value.address) || ""
        },
        "address":{
            "street": (info && info.address && info.address.value && info.address.value.street) || "",
            "city": (info && info.address && info.address.value && info.address.value.city) || "",
            "state": (info && info.address && info.address.value && info.address.value.state) || "",
            "zipCode": (info && info.address && info.address.value && info.address.value.zipCode) || ""
        }
    }
}

const QUERY = gql`
query PersonnelQuery($filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $credFilter: String!, $filteringByID: Boolean!, $limit: Int, $skip: Int) {
    MasterDataPersonnel(filter: $filter, search: $search, limit: $limit, skip: $skip) @skip(if: $filteringByID) {
        docs {
            ... on Person {
                _id
                _rev
                firstName
                lastName
                dob
                extraBroadState
                contactInfo {
                    address {
                        street
                        city
                        state
                        zipCode
                    }
                    email {
                        address
                        type
                    }
                    phone {
                        number
                        type
                    }
                }
                credentials {
                    _id
                    type
                    key
                    expiration
                }
                employerID {
                    _id
                    name
                }
                customerID {
                    _id
                    name
                }
                essentialOn {
                    _id
                    locationID {
                        _id
                        name
                    }
                }
            }
        }
    }
    getPersonsByCred (
        search: $credFilter
    ) @include(if: $filteringByID) {
        _id
        _rev
        firstName
        lastName
        dob
        extraBroadState
        contactInfo {
            address {
                street
                city
                state
                zipCode
            }
            email {
                address
                type
            }
            phone {
                number
                type
            }
        }
        credentials {
            _id
            type
            key
            expiration
        }
        employerID {
            _id
            name
        }
        customerID {
            _id
            name
        }
    }
}
`

const SAVE_COVID_MUTATION = gql`
mutation MDSetPersonCovid(
    $payload: PersonInput!,
    $orgID: ID!,
    $credentialMap: CredentialFormInput
    $modifyVaxRecords: Boolean!
    $modifiedVaxRecords: [BulkUpdatePersonCov19VaxRecordInput!]!
    $deleteVaxRecords: Boolean!
    $deletedVaxRecords: [BulkDeletePersonCov19VaxRecordInput!]!
    $essentialOn: ID
    $updatePersonEssentialOn: Boolean
){
    bulkUpdatePersonCovid19VaxDate(input: $modifiedVaxRecords) @include(if: $modifyVaxRecords){ 
        personID
        personName
        date
        manufacturer
        comment
    }
    bulkDeletePersonCovid19VaxDate(input: $deletedVaxRecords) @include(if: $deleteVaxRecords){ 
        personID
        personName
        date
        manufacturer
        comment
    }
    setPerson(
        payload: $payload
        orgID: $orgID
        credentialMap: $credentialMap
        essentialOn: $essentialOn
        updatePersonEssentialOn: $updatePersonEssentialOn
    ){
        _id
        firstName
        lastName
        nation
        contactInfo {
            address {
                city
                state
                street
                zipCode
            }
            email {
                type
                address
            }
            name {
                firstName
                lastName
            }
            phone {
                number
                type
            }
        }
        emergencyContactInfo {
            address {
                city
                state
                street
                zipCode
            }
            email {
                type
                address
            }
            name {
                firstName
                lastName
            }
            phone {
                number
                type
            }
        }
        extraBroadState
        credentials {
            _id
            type
            key
            expiration
        }
        employerID {
            _id
            name
        }
        customerID {
            _id
            name
        }
        essentialOn {
            _id
            locationID {
                _id
                name
            }
        }
    }
}
`

const SAVE_MUTATION = gql`
mutation MDSetPerson(
    $payload: PersonInput!,
    $orgID: ID!,
    $credentialMap: CredentialFormInput
    $essentialOn: ID
    $updatePersonEssentialOn: Boolean
){
    setPerson(
        payload: $payload
        orgID: $orgID
        credentialMap: $credentialMap
        essentialOn: $essentialOn
        updatePersonEssentialOn: $updatePersonEssentialOn
    ){
        _id
        firstName
        lastName
        nation
        contactInfo {
            address {
                city
                state
                street
                zipCode
            }
            email {
                type
                address
            }
            name {
                firstName
                lastName
            }
            phone {
                number
                type
            }
        }
        emergencyContactInfo {
            address {
                city
                state
                street
                zipCode
            }
            email {
                type
                address
            }
            name {
                firstName
                lastName
            }
            phone {
                number
                type
            }
        }
        extraBroadState
        credentials {
            _id
            type
            key
            expiration
        }
        employerID {
            _id
            name
        }
        customerID {
            _id
            name
        }
        essentialOn {
            _id
            locationID {
                _id
                name
            }
        }
    }
}
`

const MDPersonnel: React.FC<MDPersonnelProps> = (props) => {
    const formRef = useRef(null);

    const apolloClient = useApolloClient();

    const [ userGroups ] = useUserGroups();

    const [ credKey, setCredKey ] = useState<string>();
    const [ personVaxRecords, setPersonVaxRecords ] = useState<PersonCovidVaxRecord[]>(null);

    let canModifyAndDeleteVaxRecords = userGroups.includes('flytsuite.covid19vaxrecords.edit') &&
        userGroups.includes('flytsuite.covid19vaxrecords.delete')

    let canReadVaxRecords = userGroups.includes('flytsuite.covid19vaxrecords.read')

    const state = {
        queryGQL: QUERY,
        saveMutationGQL: canModifyAndDeleteVaxRecords ? SAVE_COVID_MUTATION : SAVE_MUTATION,
        deleteMutationGQL: DeleteEntity,
        getQueryData: data => {
            if (credKey){
                return data.getPersonsByCred;
            }
            return data.MasterDataPersonnel.docs;
        },
        getQueryVariables: (searchValues) => {
            let filter = [];
            
            if (props.orgData.getOrgIDByType('employer')){
                filter.push({
                    key: 'employerID',
                    value: JSON.stringify(props.orgData.getOrgIDByType('employer'))
                })
            }else if (searchValues.employer){
                filter.push({
                    key: 'employerID',
                    value: JSON.stringify(getLabelInValueKey(searchValues.employer))
                })
            }
            if (props.orgData.getOrgIDByType('customer')){
                filter.push({
                    key: 'customerID',
                    value: JSON.stringify(props.orgData.getOrgIDByType('customer'))
                })
            }
            else if (searchValues.customer){
                filter.push({
                    key: 'customerID',
                    value: JSON.stringify(getLabelInValueKey(searchValues.customer))
                })
            }
            return {
                filter: filter.length ? filter : undefined,
                search: searchValuesToQuerySearchPayload(omit(searchValues, ['employer', 'customer'])),
                filteringByID: searchValues.credKey ? true : false,
                credFilter: searchValues.credKey + ""
            }
            
        },
        paginationLimit: props.dataPaginationLimit,
        pollInterval: 0,
        updateQueryAfterFetchMore: updateQueryWithFetchMoreResult('MasterDataPersonnel'),
        onEditFromNew: () => {
            setPersonVaxRecords(null);
        },
        transformEditFromExisting: async (values) => {
            let customerID: any;
            let employerID: any
            if (props.orgData.getOrgByType('customer')){
                customerID = getLabelInValue(props.orgData.getOrgByType('customer'), 'name');
            }
            else
            {
                customerID = getLabelInValue(values.customerID, 'name');
            }

            if (props.orgData.getOrgByType('employer')){
                employerID = getLabelInValue(props.orgData.getOrgByType('employer'), 'name');
            }
            else
            {
                employerID = getLabelInValue(values.employerID, 'name');
            }
            let query = gql`
            query GetPersonCov19VaxRecordsQuery($personID: ID!){
                GetPersonCov19VaxRecords(personID: $personID) {
                    personID
                    personName
                    customerID
                    customerName
                    employerID
                    employerName
                    comment
                    manufacturer
                    date
                    label
                    order
                }
            }
            `;

            let result;

            if (userGroups.includes('flytsuite.covid19vaxrecords.read') && values['_id']){
                try{
                    result = await apolloClient.query({
                        query: query,
                        variables: {
                            personID: values['_id']
                        },
                        fetchPolicy: 'network-only',
                    });
                }
                catch(err){
                    message.error('Failed to load covid vax data')
                    setPersonVaxRecords(null);
                }
            }

            setPersonVaxRecords(null);
            if (result?.data?.GetPersonCov19VaxRecords){
                setPersonVaxRecords(result.data.GetPersonCov19VaxRecords);
            }

                return {
                ...values,
                dob: values.dob && moment(values.dob),
                employerID,
                customerID,
                essentialOn: values.essentialOn && values.essentialOn[0] && (
                    {
                        key: values.essentialOn[0]._id,
                        label: values.essentialOn[0]?.locationID?.name
                    }
                ),
                ...mapKeys(keyBy(values.credentials, 'type'), (_, key) => 'credential-' + key)
            }
        },
        lockFieldsToValue: {
            customerID: getLabelInValue(props.orgData.getOrgByType('customer'), 'name'),
            employerID: getLabelInValue(props.orgData.getOrgByType('employer'), 'name')
        }
    }

    const MDState = useMasterDataState({
        ...state
    })

    useEffect(() => {
        setCredKey(MDState.getSearchValue('credKey'));
    // eslint-disable-next-line
    }, [ MDState.getSearchValue('credKey') ])

    let columnElements = [
        <Column name="Last Name" cellRenderer={renderCellFromKeyIndex(MDState.data, 'lastName')} />,
        <Column name="First Name" cellRenderer={renderCellFromKeyIndex(MDState.data, 'firstName')} />,
        <Column name="DOB" cellRenderer={(idx) => {
            let record = MDState.data[idx];
            return <Cell>{record.dob && moment(record.dob).format('MM-DD-YYYY')}</Cell>
        }} />
    ]

    if(!props.orgData.getOrgIDByType('employer')){
        columnElements.push(
            <Column name="Employer" cellRenderer={renderOrgCell(MDState.data, 'employerID')} />
        )
    }

    if (!props.orgData.getOrgIDByType('customer')){
        columnElements.push(
            <Column name="Customer" cellRenderer={renderOrgCell(MDState.data, 'customerID')} />
        )
    }

    columnElements.push(
        <Column name="City, State" cellRenderer={(idx) => {
            let record = MDState.data[idx];
            let city = safeGet(['contactInfo', 'address', 'city'], record)
            let state = safeGet(['contactInfo', 'address', 'state'], record)
            let text: string;
            if (city && !state) text = city
            else if (state && !city) text = state
            else if (!state && !city) text = null
            else text = `${city}, ${state}`
            return <Cell>{text}</Cell>
        }} />
    )

    columnElements.push(
        <Column name="Credentials" cellRenderer={(idx) => {
            let text: React.ReactText | React.ReactElement = null;
            let record = MDState.data[idx];
            let creds: Array<any> = castArray(record.credentials).filter(cred => cred);
            if (!creds.length){
                text = 'No Credentials'
            }
            else
            {
                let credCount = `${creds.length} ${pluralize('Credentials', creds.length)}`;
                let credList = creds.map((cred) => cred && credentialTypes[cred.type] ? credentialTypes[cred.type].label : cred.type).join(', ');
                text = <span><strong>{credCount}: </strong>{credList}</span>
            }
            return <Cell>{text}</Cell>
        }} />
    )

    function getLabelHeaderStyle(){
        let style: React.CSSProperties = {
            marginBottom: 0,
            marginRight: 12
        }
        if (MDState.isEditing){
            style = {
                ...style,
                textAlign: 'right',
                width: '4rem'
            }
        }
        return style
    }

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                lastName: MDState.getSearchValue('lastName'),
                firstName: MDState.getSearchValue('firstName'),
                employer: MDState.getSearchValue('employer'),
                customer: MDState.getSearchValue('customer')
            })
        })}
        headerElement={
            <MDHeader
                hasFiltersApplied={!isEmpty(MDState.searchValues)}
                onFiltersClear={() => MDState.clearSearchValues()}
                {...MDStateToHeaderProps(MDState)}
                inputElement={
                    <Row type={(MDState.isEditing) ? undefined : "flex"} gutter={12}>
                        <Col>
                            <Row type="flex" align="middle">
                                <Col><h4 style={getLabelHeaderStyle()}>Search:</h4></Col>
                                <Col>
                                    <Input.Group compact>
                                        <Input
                                            onChange={(e) => {
                                                MDState.onSearchValueChange('credKey', null)
                                                MDState.onSearchValueChange('lastName', e.target.value)
                                            }}
                                            placeholder="By last name"
                                            value={MDState.getSearchValue('lastName')}
                                            style={{ width: '10rem' }}
                                            allowClear
                                        />
                                        <Input
                                            onChange={(e) => {
                                                MDState.onSearchValueChange('credKey', null)
                                                MDState.onSearchValueChange('firstName', e.target.value)
                                            }}
                                            placeholder="By first name"
                                            value={MDState.getSearchValue('firstName')}
                                            style={{ width: '10rem' }}
                                            allowClear
                                        />
                                    </Input.Group>
                                </Col>
                            </Row>
                            <Row type="flex" align="middle">
                                <Col><h4 style={getLabelHeaderStyle()}>Search:</h4></Col>
                                <Col>
                                    <Input
                                        onChange={(e) => {
                                            MDState.onSearchValueChange('firstName', null)
                                            MDState.onSearchValueChange('lastName', null)
                                            MDState.onSearchValueChange('credKey', e.target.value)}
                                        }
                                        placeholder="By ID"
                                        value={MDState.getSearchValue('credKey')}
                                        style={{ width: '10rem' }}
                                        allowClear
                                    />
                                </Col>
                            </Row>
                        </Col>
                        {!(MDState.isEditing) ? <Divider type="vertical" style={{ height: 'auto' }} /> : null}
                        <Col style={(MDState.isEditing) ? { marginTop: 12 } : undefined }>
                            <Row type="flex" align="middle">
                                <Col><h4 style={getLabelHeaderStyle()}>Filter:</h4></Col>
                                <Col>
                                    <Row type="flex" gutter={12}>
                                        {!props.orgData.getOrgIDByType('employer') ? <Col>
                                            <OrganizationSelect
                                                classTypes={['flytsuite.employer']}
                                                onChange={(value) => MDState.onSearchValueChange('employer', value)}
                                                value={MDState.getSearchValue('employer')}
                                                placeholder="By employer"
                                                style={{ width: '10rem' }}
                                                labelInValue
                                            />
                                        </Col>: null }
                                        
                                        {!props.orgData.getOrgIDByType('customer') ? (
                                            <Col>
                                                <OrganizationSelect
                                                    classTypes={['flytsuite.customer']}
                                                    onChange={(value) => MDState.onSearchValueChange('customer', value)}
                                                    value={MDState.getSearchValue('customer')}
                                                    placeholder="By customer"
                                                    style={{ width: '10rem' }}
                                                    labelInValue
                                                />
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            />
        }
        tableElement={
            <TableLoader mdState={MDState}>
                <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)}>
                    <MDTable
                        {...getMDTableProps(MDState.data, MDState)}
                        columnWidths={getColWidths(columnElements.length, {
                            0: 134,
                            1: 134,
                            2: 100,
                            [props.orgData.getOrgIDByType('customer') || props.orgData.getOrgIDByType('employer') ? 5 : 6]: 600
                        }, 200)}
                        hideDelete
                    >
                        {columnElements}
                    </MDTable>
                </BlueprintTableInfiniteScroller>
            </TableLoader>
        }
        detailsElement={
            <MDDetails
                {...MDStateToDetailsProps(MDState, (entry) => `${entry.lastName}, ${entry.firstName}`)}
                contentWidth="68rem"
                onSave={() => {
                    formRef.current.validateFieldsAndScroll(async (err, values) => {
                        let originalData = MDState.data.find(d => d._id === MDState.getEntryFieldValue('_id'));
                        let essentialOnChanged = values.essentialOn?.key !== getFirstElArrayOrNull(originalData?.essentialOn)?._id;
                        if (err) return;
                        let contactInfo = defaultContactValues(values, values.contactInfo);
                        let emergencyContactInfo = defaultContactValues(values, values.emergencyContactInfo);
                        let credentialMap = getFormCredentials(formRef.current, (cred) => ({ key: cred.key || undefined, expiration: cred.expiration || undefined }));

                        // Trim whitespace off object string values
                        contactInfo = trimObjectStringValues(contactInfo);
                        emergencyContactInfo = trimObjectStringValues(emergencyContactInfo);
                        credentialMap = trimObjectStringValues(credentialMap);

                        let payload = {
                            lastName: trimString(values.lastName),
                            firstName: trimString(values.firstName),
                            contactInfo: contactInfo || null,
                            emergencyContactInfo: emergencyContactInfo || null,
                            dob: moment(values.dob).format('YYYY-MM-DD'),
                            customerID: getLabelInValueKey(values.customerID),
                            employerID: getLabelInValueKey(values.employerID),
                            extraBroadState : values.extraBroadState || "NOT_CHECKED",
                            _id: MDState.getEntryFieldValue('_id')
                        }

                        let saveData: any = {
                            payload,
                            orgID: props.orgData.getActiveOrgID(),
                            credentialMap,
                            personID: MDState.getEntryFieldValue('_id'),
                            updatePersonEssentialOn: essentialOnChanged,
                            essentialOn: values.essentialOn?.key
                        }

                        if (canModifyAndDeleteVaxRecords){
                            let personID = payload._id;
                            if (MDState.isNewEntry){
                                // We are going to need to generate a new ID since this is a new entry
                                personID = "PER-" + sha256(payload.lastName + payload.firstName + payload.dob);
                            }

                            // ------------------------------------------------------------------
                            // Prepare bulk write of vax records
                            let { variables: vars } = await buildBulkMutation(
                                {
                                    personID: personID,
                                    personName: payload.lastName + ', ' + payload.firstName,
                                    customerID:  MDState.getEntryFieldValue('customerID')?.key,
                                    customerName:  MDState.getEntryFieldValue('customerID')?.label,
                                    employerID:  MDState.getEntryFieldValue('employerID')?.key,
                                    employerName: MDState.getEntryFieldValue('employerID')?.label,
                                    originalVaxRecords: personVaxRecords
                                },
                                formRef.current
                            )
                            // ------------------------------------------------------------------
    
                            saveData = {
                                ...saveData,
                                modifyVaxRecords: vars.modifyVaxRecords,
                                deleteVaxRecords: vars.deleteVaxRecords,
                                modifiedVaxRecords: vars.modifiedVaxRecords,
                                deletedVaxRecords: vars.deletedVaxRecords
                            }
                        }

                        MDState.save(saveData)
                    })
                }}
            >
                <PersonEntryForm
                    isNewEntry={MDState.isNewEntry}
                    showCovidForm={canReadVaxRecords}
                    disableCovidForm={!canModifyAndDeleteVaxRecords}
                    formFields={MDState.entryFields}
                    covid19VaxDateRecords={personVaxRecords}
                    onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                    uppercaseFields={['lastName', 'firstName']}
                    autoFocus
                    ref={formRef}
                    disableCustomer={props.orgData.getOrgIDByType('customer') ? true : false}
                    disableEmployer={props.orgData.getOrgIDByType('employer') ? true : false}
                    dupeChecker={(MDState.isNewEntry) ? (
                        <DuplicationChecker
                            query={QUERY}
                            variables={{
                                filter: [
                                    {
                                        key: 'employerID',
                                        value: JSON.stringify(getLabelInValueKey(MDState.getEntryFieldValue('employerID')))
                                    }
                                ],
                                search: [
                                    {
                                        key: 'lastName',
                                        value: JSON.stringify(MDState.getEntryFieldValue('lastName'))
                                    },
                                    {
                                        key: 'firstName',
                                        value: JSON.stringify(MDState.getEntryFieldValue('firstName'))
                                    }
                                ],
                                filteringByID: false,
                                credFilter: '',
                                // filter: {
                                //     employer: employers,
                                //     lastName: lastName,
                                //     firstName: firstName
                                // },
                                limit: 15
                            }}
                            skip={
                                !(MDState.getEntryFieldValue('lastName')) ||
                                !(MDState.getEntryFieldValue('firstName')) ||
                                !(MDState.getEntryFieldValue('employerID'))
                            }
                            getData={(data) => {
                                if (!data) return null;
                                if (MDState.getSearchValue('credKey')){
                                    return data.getPersonsByCred?.docs;
                                }
                                return data.MasterDataPersonnel?.docs
                            }}
                            style={{ marginBottom: 12 }}
                            renderItems={(items) => <List size="small">
                                {items.map((person) =>
                                    <List.Item>
                                        <Button
                                            className="mc-link-btn"
                                            onClick={() => MDState.editFromExisting(person)}>
                                                {person.lastName} {person.firstName} (Customer: {person.customerID && person.customerID.name}, Employer: {person.employerID && person.employerID.name})
                                        </Button>
                                    </List.Item>
                                )}
                            </List>}
                        />
                    ) : null}
                />
                <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
            </MDDetails>
        }
    />
}

export default WithOrgData(MDPersonnel)