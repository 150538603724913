import config from 'config';
import { GlobalAppStateContext } from 'context/global-app-state';
import React, { Ref, useContext } from 'react';
import ReactMapGL, { FullscreenControl, Layer, MapProps, MapRef, NavigationControl, ScaleControl } from 'react-map-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from 'maplibre-gl';
import McMapLayerDropdown from './layer-dropdown';
import './style.less';
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';

// @ts-ignore
maplibregl.workerClass = maplibreglWorker;

const availableLayers = config.reactmapgl.availableLayers;

// react-map-gl component with built-in UI for navigation and changing layers
export interface McMapProps extends MapProps {
    ref?: Ref<MapRef>
}

const McMap: React.FC<McMapProps> = React.forwardRef((props, ref: Ref<MapRef>) => {

    const { getUserSettings } = useContext(GlobalAppStateContext);

    const settings = getUserSettings('mcMap');

    let attributions = [
        "<a href='https://mesonet.agron.iastate.edu' target='_blank'>Iowa Environmental Mesonet</a>",
        "<a href='https://maplibre.org' target='_blank'>MapLibre</a>",
        "<span>©</span> <a href='https://www.openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a> <span>contributers</span>",
        "<a style='font-weight: bold' href='https://www.openstreetmap.org' target='_blank'>Improve this map</a>"
    ];

    function getDependencies(){
        let deps = [];
        if (!settings) return []
        Object.values(settings?.layers).forEach((layer: any) => {
            deps.push(layer.enabled);
            if (layer.config){
                deps.push(JSON.stringify(config));
            }
        })
        return deps
    }
    
    function getLayers(){
        let layers = [];
        
        availableLayers.forEach(layer => {
            let layerSetting = settings.layers[layer.id];
            if (layerSetting && (layerSetting.enabled || layer.permanent)){
                layers.push(<Layer {...layer} />);
            }
        })
        
        return layers
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let layers = React.useMemo(() => getLayers(), getDependencies())

    return <div className='mc-map' style={{ display: 'flex', flexDirection: 'column' }}>
        <ReactMapGL
            {...props}
            mapStyle={config.reactmapgl.style}
            customAttribution={attributions.join(' | ')}
            mapLib={maplibregl}
            ref={ref}
        >
            <McMapLayerDropdown position='top-left' />
            <NavigationControl
                position='top-left'
                showCompass={true}
            />
            <FullscreenControl
                position='top-left'
            />
            <ScaleControl unit='imperial' position='bottom-left' />
            {layers}
            {props.children}
        </ReactMapGL>
    </div>
})

McMap.defaultProps = {
    
}

export default McMap