import { Button, Form, Radio, Tooltip, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { createFormFields, FormField, tailFormItemLayout } from 'common/form';
import { maxTimeMoment, zeroTimeMoment } from 'common/util';
import DateTimePicker, { DateTimePickerValue } from 'components/date-time-picker';
import moment from 'moment-timezone';
import React, { HTMLAttributes } from 'react';

export interface FWBRCriteriaFormProps extends FormComponentProps {
    formFields?: { [key: string]: any },
    onFieldsChange?: (fields: { [key: string]: FormField }, changedFields: { [key: string]: FormField }) => void
}

export const fieldLabelMap = {
    "startDateTime": "Starting Date",
    "endDateTime": "Ending Date",
    "tz": "Time Zone",
    "reportType": "Report Type"
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export const GroupAndSortMap = {
    contract: "contract",
    aircraft: "aircraft",
    // timeOff: "timeOff"
}

export const ReportTypeMap = {
    summary: "summary",
    detail: "detail"
}

const dateLabelProps: HTMLAttributes<HTMLSpanElement> = {
    style: { display: 'none' }
}

const timeLabelProps: HTMLAttributes<HTMLSpanElement> = {
    style: { width: "2.2rem", display: 'inline-block', flex: 1, textAlign: 'right', marginRight: '6px' }
}

const dtPickerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center'
}

const FWBRCriteriaForm_Internal: React.FC<FWBRCriteriaFormProps> = (props) => {
    let { form: { getFieldDecorator, setFieldsValue } } = props;

    function today(){
        setFieldsValue({
            startDateTime: {
                date: moment(),
                time: zeroTimeMoment(moment())
            },
            endDateTime: {
                date: moment(),
                time: maxTimeMoment(moment())
            }
        })
    }

    return <Form>
        <Form.Item label={fieldLabelMap.startDateTime} className="ant-form-item-no-margin" {...formItemLayout}>
            {getFieldDecorator('startDateTime', {
                rules: [ { required: true, message: 'Start date is required' } ],
                initialValue: new DateTimePickerValue(moment(), zeroTimeMoment(moment()))
            })(
                <DateTimePicker
                    showLabels
                    dateLabelProps={dateLabelProps}
                    timeLabelProps={timeLabelProps}
                    style={dtPickerStyle}
                    size="default"
                />
            )}
        </Form.Item>
        <Form.Item label={fieldLabelMap.endDateTime} className="ant-form-item-no-margin" {...formItemLayout}>
            {getFieldDecorator('endDateTime', {
                rules: [ { required: true, message: 'Ending date is required' } ],
                initialValue: new DateTimePickerValue(moment(), maxTimeMoment(moment()))
            })(
                <DateTimePicker
                    showLabels
                    dateLabelProps={dateLabelProps}
                    timeLabelProps={timeLabelProps}
                    style={dtPickerStyle}
                    size="default"
                />
            )}
        </Form.Item>
        <Form.Item className="ant-form-item-no-margin" {...tailFormItemLayout}>
            <Tooltip title="Set Starting Date and Ending Date to today">
                <Button size="small" shape="round" onClick={today}>Today</Button>
            </Tooltip>
        </Form.Item>
        <div style={{ margin: '1rem 0', textAlign: 'center' }}>
            <Typography.Text type='secondary'><strong>NOTE: </strong> The time range entered will return results in local time as entered by the operator</Typography.Text>
        </div>
        <Form.Item label={fieldLabelMap.reportType} className="ant-form-item-no-margin" {...formItemLayout}>
        {getFieldDecorator('reportType', {
            rules: [ { required: true, message: 'Report type is required' } ],
            initialValue: ReportTypeMap.summary
        })(
            <Radio.Group>
                <Radio value={ReportTypeMap.summary}>Summary</Radio>
                <Radio value={ReportTypeMap.detail}>Detail</Radio>
            </Radio.Group>
        )}
        </Form.Item>
    </Form>
}

FWBRCriteriaForm_Internal.displayName = "FWBRCriteriaForm"

const FWBRCriteriaForm = Form.create<FWBRCriteriaFormProps>({
    mapPropsToFields(props){
        return createFormFields(props.formFields)
    },
    onFieldsChange(props, changedFields, allFields){
        props.onFieldsChange(allFields, changedFields);
    }
})(FWBRCriteriaForm_Internal);

export default FWBRCriteriaForm