import useListAvailableNotifiers from 'Queries/list-available-notifiers';
import { Button, Checkbox, Col, Collapse, Divider, Form, Icon, Input, Row, Select, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormComponentProps, WrappedFormUtils } from 'antd/lib/form/Form';
import { ListProps } from 'antd/lib/list';
import { createFormFields, validateEmail } from 'common/form';
import { OrgEmailListItem, OrgEmailListItemOptions } from 'common/types/org-email-list';
import useControlledState from 'hooks/useControlledState';
import React, { CSSProperties, useState } from 'react';
import { validateOptIn } from './validator';
import { CollapseProps } from 'antd/lib/collapse';

export interface OrgNotifSelectProps {
    onFilterChange?: (filter: string) => void,
    filter?: string,
    onChange?: (value: OrgEmailListItemOptions) => void,
    value?: OrgEmailListItemOptions,
    style?: CSSProperties
}

const OrgNotifSelect: React.FC<OrgNotifSelectProps> = (props) => {
    const [ filter, setFilter ] = useControlledState<string>(null, props.filter, props.onFilterChange);
    const [ value, setValue ] = useControlledState<OrgEmailListItemOptions>(null, props.value, props.onChange);

    const { notifiers, loading, error } = useListAvailableNotifiers();
    
    let errorMsg = error ? (
        <Typography.Text type='danger'>Failed to load options</Typography.Text>
    ) : null

    function handleChange(v: string[]){
        setValue({ optInAll: false, optIn: v });
    }
    
    let cols = [
        <Col style={{ paddingTop: '4px' }}>
            <Checkbox
                checked={value.optInAll}
                onChange={(e) => setValue({ ...value, optInAll: e.target.checked })}
                style={{ marginRight: '6px' }}
            >Opt-In All</Checkbox>
        </Col>
    ];
    
    if (!value.optInAll)
    cols.push(
        <Col style={{ flex: 1 }}>
            <Select<any>
                loading={notifiers?.availableNotifiers ? false : loading}
                notFoundContent={errorMsg}
                onSearch={(value) => setFilter(value)}
                filterOption={val => filter ? true : String(val).includes(String(filter))}
                mode='multiple'
                value={value.optIn}
                onChange={handleChange}
                placeholder="Choose notification types"
                style={props.style}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }}  />
                        {value.optIn.length < notifiers?.availableNotifiers?.length && (    
                            <Button
                                size='small'
                                type='primary'
                                style={{ margin: '2px 6px 6px 6px' }}
                                onMouseDown={e => e.preventDefault()}
                                onClick={() => handleChange(notifiers?.availableNotifiers?.map(n => n.notificationType))}
                            >
                                <Icon type='plus' /> Add All
                            </Button>
                        )}
                        {value.optIn.length > 0 && (
                            <Button
                                size='small'
                                type='primary'
                                style={{ margin: '2px 6px 6px 6px' }}
                                onMouseDown={e => e.preventDefault()}
                                onClick={() => handleChange([])}
                            >
                                <Icon type='minus' /> Remove All
                            </Button>    
                        )}
                    </div>
                )}
            >
                {notifiers?.availableNotifiers?.map(n => (
                    <Select.Option key={n.notificationType} value={n.notificationType}>{n.description}</Select.Option>
                ))}
            </Select>
        </Col>
    )
    
    return <Row 
        type='flex'
        style={{
            ...props.style,
            minHeight: '32px'
        }}
    >{cols}</Row>
}

export interface OrgNotifEmailListEditorProps {
    value?: OrgEmailListItem[],
    onChange?: (value: OrgEmailListItem[]) => void,
    listProps?: ListProps<any>,
    addEmailButtonProps?: ButtonProps,
    form?: WrappedFormUtils,
    onFormChange?: (fields: any, allFields: any) => void,
    formFields?: any,
    style?: React.CSSProperties,
    collapseProps?: CollapseProps
}

const OrgNotifEmailListEditor_Internal: React.FC<OrgNotifEmailListEditorProps> = (props) => {

    const [ emailList, setEmailList ] = useControlledState<OrgEmailListItem[]>([], props.value, props.onChange);
    const [ openPanels, setOpenPanels ] = useState(new Set<string>());

    function updateEmailItem(index: number, item: OrgEmailListItem){

        let emailListCopy = [...emailList];
        emailListCopy[index] = item;
        
        setEmailList(emailListCopy);
    }

    function addBlankEmailItem(){
        let newItem = {
            email: '',
            options: {
                optIn: [],
                optInAll: false
            }
        }
        setEmailList([...emailList, newItem]);
        let newSet = new Set(openPanels);
        newSet.add(emailList.length.toString());
        setOpenPanels(newSet);
    }

    function removeEmailItem(index: number){
        let emailListCopy = [...emailList];
        emailListCopy.splice(index, 1);
        setEmailList(emailListCopy);

        let newSet = new Set(openPanels);
        newSet.delete((emailList.length-1).toString());
        setOpenPanels(newSet);
    }

    let rows = emailList.map((item, i) => {
        
        return <Collapse.Panel key={i} style={{ padding: '6px' }} header={
                    <div style={{ display: 'flex', alignItems: 'center', margin: '-6px -14px -6px 6px' }}>
                        <Form.Item className='ant-form-item-no-margin' style={{ flex: 0.75 }}>
                            {props.form.getFieldDecorator(i.toString() + '-email', {
                                rules: [{ validator: validateEmail }],
                                initialValue: item.email
                            })(
                                <Input
                                    type='email'
                                    onChange={e => updateEmailItem(i, { ...item, email: e.target.value })}
                                    placeholder='Enter email address'
                                    style={{ display: 'block', flex: 1, width: '100%' }}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            )}
                        </Form.Item>
                        <span style={{ marginLeft: 'auto' }}>{item.options.optInAll ? 'All ' : item.options.optIn.length} triggers</span>
                        <Button
                            className='mc-btn mc-btn-transparent'
                            icon='delete'
                            onClick={(e) => {e.stopPropagation(); removeEmailItem(i) }}
                            style={{ marginLeft: 'auto' }}
                        />
                    </div>
            }>
            <div style={{ width: '100%' }}>
                
                <Form.Item className='ant-form-item-no-margin'>
                    {props.form.getFieldDecorator(i.toString() + '-options', {
                        rules: [{ validator: validateOptIn }],
                        initialValue: item.options
                    })(
                        <OrgNotifSelect
                            style={{ width: '100%' }}
                            onChange={value => {
                                let options: OrgEmailListItemOptions = {
                                    optInAll: false,
                                    optIn: [],
                                    ...value
                                }
                                updateEmailItem(i, {
                                    ...item,
                                    options: options
                                })
                            }}
                        />
                    )}
                </Form.Item>
            </div>
        </Collapse.Panel>;
    })

    return <div style={{ display: 'flex', flexDirection: 'column', ...props.style }}>
        <Button
            icon='plus'
            block
            type='dashed'
            size='large'
            {...props.addEmailButtonProps}
            onClick={addBlankEmailItem}
        >Add Email</Button>
        {rows.length ? (
            <Collapse
                activeKey={Array.from(openPanels)}
                onChange={(keys) => {
                    let newSet = new Set(keys);
                    setOpenPanels(newSet);
                }}
                {...props.collapseProps}
                style={{
                    marginTop: '12px',
                    ...props.collapseProps?.style
                }}
            >
                {[...rows].reverse()}
            </Collapse>
        ) : null}
    </div>
}

const OrgNotifEmailListEditor = Form.create<OrgNotifEmailListEditorProps & FormComponentProps>({
    onFieldsChange(props, fields, allFields){
        props.onFormChange?.(fields, allFields);
    },
    mapPropsToFields(props){
        return createFormFields(props.formFields)
    }
})(OrgNotifEmailListEditor_Internal)

OrgNotifEmailListEditor.displayName = 'OrgNotifEmailListEditor'

export default OrgNotifEmailListEditor;