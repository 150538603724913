import { Alert } from 'antd';
import { NetworkStatus } from 'apollo-client';
import { castArray, dateToZulu, getDurationHoursMinutes, getPilotName } from 'common/util';
import { getTzNameFromDoc } from 'common/util-ts';
import CenteredLoadingScreen from 'components/CenteredLoadingScreen';
import ETable from 'components/enchanced-antd-table';
import NonIdealState from 'components/NonIdealState';
import { OrgDataContext } from 'context/orgData';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import './style.less';
import { getTotalDurationHoursMinutes } from './util';

const BASE_CLS = 'mc-fw-aircraft-history';

export interface FWAircraftHistoryProps {
    flightID: string,
    flightDate: string
}

const QUERY = gql`
query AircraftHistoryDispatchHistoryFlights($flightName: String!, $dateStart: AWSDateTime!, $dateEnd: AWSDateTime!, $tpID: ID!) {
    DispatchHistoryFlights(flightName: $flightName, dateStart: $dateStart, dateEnd: $dateEnd, tpID: $tpID, limit: 2000) {
        bookmark
        warning
        warningCode
        docs {
            _id
            ... on DispatchHistory {
                _id
                name
                ato
                eta
                ata
                pob
                scheduledFlightDate
                approvedBy
                dispatchDoc {
                    _id
                    getTzAbbr
                }
                aircraftDoc {
                    _id
                    tailNum
                }
                contractID
                contractDoc {
                    _id
                    name
                }
                departingID
                departingDoc {
                    _id
                    name
                    areaBlockName
                }
                destinationID
                destinationDoc {
                    _id
                    name
                    areaBlockName
                }
                pilotID
                pilotDoc {
                    _id
                    name {
                        firstName
                        lastName
                    }
                }
                coPilotID
                coPilotDoc {
                    _id
                    name {
                        firstName
                        lastName
                    }
                }
                _r_latestRemark {
                    remark
                    remarkTime
                }
                remark {
                    remark
                    remarkTime
                }
                getTzAbbr
            }
        }
    }
}
`

export const FWAircraftHistory: React.FC<FWAircraftHistoryProps> = (props) => {

    let orgData = useContext(OrgDataContext);

    let { data: qData, networkStatus, error } = useQuery(QUERY, {
        variables: {
            flightName: props.flightID,
            dateStart: dateToZulu(moment(props.flightDate)),
            dateEnd: dateToZulu(moment(props.flightDate).add(1, 'day')),
            tpID: orgData.getOrgIDByType('transporter')
        },
        skip: !props.flightDate,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: 15000
    })

    // if (!userGroups.includes('flytsuite.flytwatch.history.read')){
    //     return <NotAuthorized desciption="You do not have permission to view FlytWatch history data." />
    // }

    let data = castArray(qData?.DispatchHistoryFlights?.docs)

    const loading = networkStatus === NetworkStatus.loading;

    if (!data.length && !loading && !error) {
        return <NonIdealState
            title={"No flight history available"}
            icon="question"
            style={{ height: '100%', marginTop: '15rem' }}
        />
    }

    if (loading){
        return <CenteredLoadingScreen />
    }
    
    return <div className={BASE_CLS + '-wrapper'}>
        {error ? <Alert showIcon type="error" message="Failed to load flight history" /> : null}
        <ETable
            dataSource={data}
            pagination={false}
            size="small"
            className="mc-table"
            bordered
            totals={{
                label: 'Total time',
                colNum: 5,
                td: [<td>{getTotalDurationHoursMinutes(data, (time) => moment(time))}</td>]
            }}
            columns={[
                {
                    title: 'ID',
                    dataIndex: 'name'
                },
                {
                    title: 'Aircraft',
                    render: (_, record: any) => record.aircraftDoc?.tailNum,
                    width: 80
                },
                {
                    title: 'Departing',
                    render: (_, record: any) => record.dep?.label || record.departingDoc?.areaBlockName,
                    width: 140
                },
                {
                    title: 'Destination',
                    render: (_, record: any) => record.dest?.label || record.destinationDoc?.areaBlockName,
                    width: 140
                },
                {
                    title: 'ATO',
                    dataIndex: 'ato',
                    render: (ato, record) => moment.utc(ato).tz(getTzNameFromDoc(record)).format('MMMM Do, YYYY H:mm z'),
                    sorter: (a, b) => {
                        let aMom = moment(a.ato);
                        let bMom = moment(b.ato);
                        if (aMom.isAfter(bMom)){
                            return 1;
                        }
                        else if (bMom.isAfter(aMom)){
                            return -1;
                        }
                        return 0;
                    },
                    defaultSortOrder: 'descend',
                    width: 190
                },
                // {
                //     title: 'ETA',
                //     key: 'eta',
                //     dataIndex: 'eta',
                //     render: (eta, record) => (String(record.ata).startsWith('-') || !record.ata)
                //         ? moment.utc(eta).tz(getTzNameFromDoc(record)).format('MMM Do, YYYY H:mm z')
                //         : 'Arrived'
                // },
                {
                    title: 'ATA',
                    dataIndex: 'ata',
                    render: (ata, record) => moment.utc(ata).tz(getTzNameFromDoc(record)).format('MMM Do, YYYY H:mm z'),
                    sorter: (a, b) => {
                        let aMom = moment(a.ata);
                        let bMom = moment(b.ata);
                        if (aMom.isAfter(bMom)){
                            return 1;
                        }
                        else if (bMom.isAfter(aMom)){
                            return -1;
                        }
                        return 0;
                    },
                    width: 190
                },
                {
                    title: 'Time',
                    render: (_, record) => getDurationHoursMinutes(moment(record.ata).diff(moment(record.ato))),
                    width: 110
                },
                {
                    title: 'POB',
                    dataIndex: 'pob',
                    width: 50,
                    render: (pob) => pob
                },
                {
                    title: 'Contract',
                    render: (_, record: any) => record.contract?.label || record.contractDoc?.name,
                    width: 140
                },
                {
                    title: 'Pilot',
                    render: (_, record: any) => record.pilot?.label || getPilotName(record.pilotDoc),
                    width: 140
                },
                {
                    title: 'Co-Pilot',
                    render: (_, record: any) => record.coPilot?.label || getPilotName(record.coPilotDoc),
                    width: 140
                },
                {
                    title: 'Remarks',
                    dataIndex: '_r_latestRemark',
                    render: (_r_latestRemark) => _r_latestRemark?.remark
                }
            ]}
        />
    </div>
}

export default FWAircraftHistory;