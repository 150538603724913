/**
 * Second version of report user interface. Integrates with python backend report system.
 */

import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Checkbox, Divider, Drawer, Icon, Layout, PageHeader, Tooltip, Typography } from 'antd';
import cn from 'classnames';
import wrapCtxProvider, { ContextProviderWrapperProps, WithContextOverrideProps } from 'component-wrappers/wrap-ctx-provider';
import CenteredLoadingScreen from 'components/CenteredLoadingScreen';
import NonIdealState from 'components/NonIdealState';
import { DataExporter, DataExporterProps, ReportExporter, ReportExporterProps } from 'components/reporting/export';
import { getReportRuntimeFromNow } from 'components/reporting/hooks/report-details';
import { Criteria } from 'components/reporting/ReportDetails/Criteria';
import React, { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReportUIContext, ReportUIContextInterface, ReportUIContextProvider, ReportUIContextProviderProps } from './context';
import './report-ui.less';

export interface ReportState {
    showCriteriaSider: boolean,
    showReportCriteria: boolean,
    showExportDrawer: boolean,
    forceUpdateCount: number
}

const BASE_CLS = 'mc-report-ui';

export interface ReportUIFormProps {
    formFields: any,
    onFieldsChange: (values: { [key: string]: any }) => void
}

interface AutoRefreshOptions {
    enabled?: boolean,
    pollInterval?: number
}

export interface ReportUIProps extends WithContextOverrideProps<ReportUIContextInterface> {
    renderTable: (consumerValue: any) => React.ReactNode,
    renderCriteriaForm: (ref: any, formFields: any, onCriteriaFieldsChange: (values: { [key: string]: any }) => void) => React.ReactNode,
    setReportExporterProps?: (reportData: any) => Omit<DataExporterProps & ReportExporterProps, 'criteria'>,
    // renderReportExporter?: (reportData: any) => React.ReactElement<ReportExporterProps>
    reportTitle: string | React.ReactNode,
    overrideRenderHeader?: (state: any) => React.ReactNode,
    style?: CSSProperties,
    contentFlexMode?: boolean,

    /** Required if useExternalContextProvider is not specified or false */
    contextProviderProps?: ReportUIContextProviderProps,

    autoRefreshOptions?: AutoRefreshOptions,
    headerExtra?: {
        left?: React.ReactNode,
        right?: React.ReactNode
    }
}

const { Sider, Header, Content } = Layout;

const ReportUI_Internal: React.FC<ReportUIProps> = (props) => {
    const {
            formRef,
            criteriaFormFields,
            onCriteriaFieldsChange,
            resetCriteriaForm,
            submitReport,
            reportSubmitting,
            reportDetails,
            setAutoRefreshInterval,
            setAutoRefreshMode,
            autoRefreshMode
        } = useContext(props.overrideContext || ReportUIContext);

    let reportData = reportDetails?.reportData;
    let reportState = reportDetails?.reportState;
    
    const [ state, _setState ] = useState<ReportState>({
        showCriteriaSider: true,
        showReportCriteria: false,
        showExportDrawer: false,
        forceUpdateCount: 0
    });

    useEffect(() => {
        setAutoRefreshInterval(props.autoRefreshOptions?.pollInterval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.autoRefreshOptions?.pollInterval ])

    // Had to do it this way for some reason because old values were showing up in the 'state' variable
    const [ pendingState, setPendingState ] = useState({});
    useEffect(() => {
        _setState({ ...state, ...pendingState });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pendingState ])

    function setState(newState: Partial<ReportState>){
        setPendingState(newState);
    }
    // ------------------------------------------------------------------------------------------------

    const forceUpdateInterval = useRef({ prevVal: 0, interval: null });

    useEffect(() => {
        console.debug('REPORT STATE CHANGED TO "' + reportState + '"')
        console.debug('has interval: ', forceUpdateInterval.current);

        function intervalFunc(){
            setState({ forceUpdateCount: forceUpdateInterval.current.prevVal + 1 });
            forceUpdateInterval.current.prevVal++;
        }

        if (reportState === 'IN_PROGRESS' && !forceUpdateInterval.current.interval){
            forceUpdateInterval.current = {
                prevVal: state.forceUpdateCount,
                interval: setInterval(intervalFunc, 1000)
            }
        }
        if (reportState !== 'IN_PROGRESS'){
            console.debug('CLEARING INTERVAL');
            if (forceUpdateInterval.current){
                clearInterval(forceUpdateInterval.current.interval);
                forceUpdateInterval.current.interval = null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ reportState ])

    useEffect(() => {
        if (autoRefreshMode){
            return;
        }
        if (reportState === 'NOT_STARTED' && state.showReportCriteria){
            setState({ showReportCriteria: false });
        }
        if ((reportState === 'IN_PROGRESS') && !state.showReportCriteria){
            setState({ showReportCriteria: true });
        }
        if ((reportState === 'DOWNLOADING') && !state.showReportCriteria){
            setState({ showReportCriteria: true });
        }
    // eslint-disable-next-line
    }, [ reportState, autoRefreshMode ])

    function renderContent(){
        return <>
            {reportState === 'QUERY_FAILED' || reportState === 'DOWNLOAD_FAILED' ? <Alert type="error" showIcon message="Failed to load report"
                style={{ marginBottom: '12px' }}
            /> : null}
            {reportState === 'COMPLETE' && reportData?.status === 'ERROR' ? (
                <Alert type="error" showIcon 
                    message="An error occurred while running the report"
                    description={reportData && reportData.error && reportData.error.type + ": " + reportData.error.message}
                    style={{ marginBottom: '12px' }}
                />
            ) : null}
            {reportState === 'IN_PROGRESS' ? (
                <CenteredLoadingScreen
                    message={
                        <>
                            <div style={{ marginTop: 24, fontSize: '1.2rem' }}>
                                Report is being generated. Please wait.
                            </div>
                            {!autoRefreshMode && getReportRuntimeFromNow(reportData) >= 120/* seconds */ ? (
                                <Alert
                                    style={{ marginTop: 12 }}
                                    type="error"
                                    showIcon
                                    message={'It is taking longer than usual to generate this report.'}
                                    description={`
                                        Something might have gone wrong.
                                        If your report seems to be stuck in this state,
                                        try running a new report with more specific criteria or please contact DataFlyt.
                                    `}
                                />
                            ) : null}
                        </>
                    }
            />
            ) : null}
            {reportState === 'DOWNLOADING' ? (
                <CenteredLoadingScreen
                    message={<h3 style={{ textAlign: 'center' }}>Downloading Report</h3>}
                />
            ) : null}
            {reportState === 'NOT_STARTED' ? (
                <NonIdealState
                    title="Choose your criteria"
                    description={
                        <>
                        <div>Use the left panel to choose your criteria. Then click "Generate Report" to run the report.</div>
                        </>
                    }
                    style={{ height: '100%' }}
                    icon={<FontAwesomeIcon icon={faFileAlt} />}
                    type="primary"
                />
            ) : null}
            {reportState === 'COMPLETE' ? (
                <ReportUIContext.Consumer>
                    {(value) => props.renderTable(value)}
                </ReportUIContext.Consumer>
            ) : null}
        </>
    }

    let contentStyle: CSSProperties = { padding: 12, overflow: 'auto' };

    if (props.contentFlexMode){
        contentStyle = {
            ...contentStyle,
            display: 'flex',
            flexDirection: 'column'
        }
    }

    function renderAutoRefreshMode(){
        if (!props.autoRefreshOptions?.enabled){
            return null
        }
        if (!formRef.current){
            return null;
        }
        return <>
            <Checkbox
                checked={autoRefreshMode}
                onChange={(e) => setAutoRefreshMode(e.target.checked)}
            >Auto-Refresh Mode</Checkbox>
            <Tooltip title={
                <div>
                    <p>Report runs every 30 seconds when enabled</p>
                    <p>
                        <Typography.Text type="warning">WARNING:</Typography.Text> Due to the limitations of real-time reports, some reports may take too long to run and display an error.
                        If this happens, please reduce the scope of the report and try again.
                    </p>
                </div>
            }>
                <Icon type="question-circle" style={{ marginLeft: '6px' }} />
            </Tooltip>
        </>
    }

    const renderedCriteriaForm = useMemo(() => {
        return props.renderCriteriaForm(formRef, criteriaFormFields, onCriteriaFieldsChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ criteriaFormFields ])

    const renderExporter = () => {
        let exporterProps = props.setReportExporterProps?.(reportData);
        if (!exporterProps){
            return null;
        }
        if (autoRefreshMode){
            return <DataExporter
                report_type={exporterProps.report_type}
                column_sets={exporterProps.column_sets}
                data={exporterProps.data}
                export_name={exporterProps.export_name}
                flightID={exporterProps.flightID}
                default_file_type={exporterProps.default_file_type}
                criteria={reportDetails?.reportData?.criteria}
            />
        }
        else
        {
            return <ReportExporter
                report_id={exporterProps.report_id}
                report_type={exporterProps.report_type}
                column_sets={exporterProps.column_sets}
                flightID={exporterProps.flightID}
                default_file_type={exporterProps.default_file_type}
            />
        }
    }

    return <Layout style={{ height: '100%', ...props.style }}>
        <Header className={BASE_CLS + "-header"} style={{ display: 'flex', alignItems: 'center' }}>
            {typeof props.reportTitle === 'string' ? (
                <h2 className={BASE_CLS + "-title"}>{props.reportTitle}</h2>
            ) : (
                props.reportTitle
            )}
            {props.headerExtra?.left ? props.headerExtra?.left : null}
            <div style={{ margin: '0 auto' }} />
            {props.headerExtra?.right ? props.headerExtra?.right : null}
            {reportSubmitting && autoRefreshMode && reportState !== 'IN_PROGRESS' ? (
                <span style={{ marginRight: '2rem' }}><Icon type="loading" style={{ marginRight: '3px' }} /> Refreshing data</span>
            ) : null}
            {renderAutoRefreshMode()}
            <Divider type='vertical' style={{ height: '40px', margin: '0 24px' }} />
            {reportState === 'COMPLETE' ? (
                <Button type="primary" icon="export" onClick={() => setState({ showExportDrawer: true })}>
                    Export
                </Button>
            ) : null}
        </Header>
        <Layout>
            {!state.showCriteriaSider ? (
                <Button
                    className={BASE_CLS + '-criteria-display-btn-vertical'}
                    onClick={() => setState({ showCriteriaSider: !state.showCriteriaSider })}
                >Show Criteria Panel</Button>
            ) : null}
            <Sider className={cn({ [BASE_CLS + "-sider"]: true, [BASE_CLS + "-sider-hidden"]: !state.showCriteriaSider})} width={ state.showCriteriaSider ? 400 : 0} style={{ overflow: 'auto' }}>
                <div className={BASE_CLS + '-sider-body'}>
                    <div style={{ display: state.showReportCriteria ? undefined : 'none' }}>
                        <PageHeader onBack={() => setState({ showReportCriteria: false })} title="CRITERIA" className={BASE_CLS + '-form-title'} />
                        <Criteria
                            data={reportData}
                            isInProgress={false}
                            justCriteria={true}
                            criteriaItemStyle={{
                                marginBottom: 12
                            }}
                        />
                        <div style={{ marginTop: 12 }}>
                            <Button type="primary" block onClick={() => setState({ showReportCriteria: false })}>New Report</Button>
                        </div>
                    </div>
                    <div style={{ display: state.showReportCriteria ? 'none' : undefined }}>
                        <PageHeader
                            title="NEW CRITERIA"
                            className={BASE_CLS + '-form-title'}
                            extra={reportData && reportState === 'COMPLETE' ? <Button type="link" onClick={() => setState({ showReportCriteria: true })}>Show Criteria</Button> : null}
                        />
                        <div>
                            {renderedCriteriaForm}
                        </div>
                        {!autoRefreshMode ? (
                            <div style={{ marginTop: 12 }}>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => {
                                        formRef.current.validateFieldsAndScroll((err, values) => {
                                            if (!err){
                                                submitReport(values);
                                            }
                                        })
                                    }}
                                    loading={reportSubmitting}
                                >Generate Report</Button>
                            </div>
                        ) : null}
                        {formRef?.current?.isFieldsTouched() ? (
                            <div style={{ marginTop: 6 }}>
                                <Button type="link" block onClick={resetCriteriaForm}>Reset</Button>
                            </div>    
                        ) : null}
                    </div>
                </div>
                {reportData ? (
                    <Button
                        className={BASE_CLS + '-criteria-display-btn'}
                        onClick={() => setState({ showCriteriaSider: !state.showCriteriaSider })}
                        icon={state.showCriteriaSider ? 'menu-fold' : 'menu-unfold'}
                        block
                    >Hide Criteria Panel</Button>
                ) : null}
            </Sider>
            <Content style={contentStyle}>
                {renderContent()}
            </Content>
        </Layout>
        <Drawer
            visible={state.showExportDrawer}
            title={<span><Icon type="export" style={{ marginRight: '0.5rem' }} /><span>Export Report</span></span>}
            width={600}
            onClose={() => setState({ showExportDrawer: false })}
            bodyStyle={{ height: 'calc(100% - 56px)' }}
        >
            {renderExporter()}
        </Drawer>
    </Layout>
}

ReportUI_Internal.displayName = 'ReportUI'

const ReportUI = wrapCtxProvider({
    providerComponent: ReportUIContextProvider,
    wrapperPropsToProviderProps: (wrapperProps: ReportUIProps) => {
        console.debug('wrapperProps', wrapperProps);
        if (wrapperProps.contextProviderProps){
            return wrapperProps.contextProviderProps;
        }
        else
        {
            throw Error("contextProviderProps must be specified if useExternalContextProvider isn't specified or false.")
        }
    }
})(ReportUI_Internal) as React.FC<ReportUIProps & ContextProviderWrapperProps>

ReportUI.defaultProps = {
    autoRefreshOptions: {
        enabled: true,
        pollInterval: 30000
    }
}

export default ReportUI