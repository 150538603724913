import {FlightLeg} from "../../schema";
import {getOptimalFlightPathSubset} from "./flight-leg-manager";

export interface FlightPathOptionalArgs {

    /**
     * @default false
     */
    preventDupeNodeInsertion?: boolean
}


export class FlightPath {

    private origin: string;
    private originalOrigin: string;
    private path: Array<string> = [];
    public preventDupeNodeInsertion = false;

    constructor(origin?: string, optionalArgs?: FlightPathOptionalArgs) {
        this.origin = origin;

        if (typeof optionalArgs?.preventDupeNodeInsertion === 'boolean'){
            this.preventDupeNodeInsertion = optionalArgs.preventDupeNodeInsertion;
        }
    }

    static fromFlightLegs(legs: FlightLeg[], overrideOrigin?: string, optionalArgs?: FlightPathOptionalArgs): FlightPath {

        let origin = overrideOrigin || legs[0]?.departureID;

        let originOverridden = overrideOrigin && overrideOrigin !== legs[0]?.departureID;
        let originalOrigin = legs[0]?.departureID;

        let flightPath = new FlightPath(origin, optionalArgs);
        flightPath.originalOrigin = originalOrigin;
        if (legs.length === 0) return flightPath;

        legs.forEach((leg, idx) => {

            // If the leg destination is equal to the original origin, change it to the overridden origin.
            let legDest = originalOrigin === leg.destinationID && originOverridden ? origin : leg.destinationID;

            if (idx === legs.length - 1 && legDest === origin) {
                // Skip if last leg is the same as the origin
                return;
            } else {
                flightPath.addNextNode(legDest);
            }
        })

        return flightPath;
    }

    static fromLocationList(locations: string[] | { _id: string, name?: string }[], optionalArgs?: FlightPathOptionalArgs) {
        let getID = (loc: string | { _id: string, name: string }) =>
            typeof loc === 'object' ? loc._id : loc

        let flightPath = new FlightPath(undefined, optionalArgs);

        locations.forEach((loc) => {
            let id = getID(loc);
            flightPath.addNextNode(id);
        })

        return flightPath;
    }

    removeNode(locID: string) {
        if (locID === this.origin) {
            // If there is only one location in the round trip and you remove the leg that is inbound to the origin,
            // remove the location from the path.
            if (this.path.length === 1) {
                this.path = [];
            }
            // Otherwise we can't remove the origin
            return;
        }
        let nodeIdx = this.path.findIndex((node) => locID === node);
        if (nodeIdx > -1) {
            this.path.splice(nodeIdx, 1);
        }
    }

    /**
     * Adds a new location node in the path if not in path.
     * If origin was not defined, then this node will become the origin.
     * @param locID
     * @returns Index of location node in flight path
     */
    addNextNode(locID: string): number {
        if (!this.origin) {
            this.origin = locID;
            return 0;
        } else if (this.path.includes(locID) && this.preventDupeNodeInsertion) {
            return this.path.findIndex((id) => id === locID) + 1 // +1 taking into account the origin
        } else if (locID === this.origin) {
            return 0; // This is the origin, so don't insert anything.
        } else {
            this.path.push(locID);
            return this.path.length // Why not subtract 1? We are taking into account the origin, so -1 + 1 = 0;
        }
    }

    insertNode(departure: string, destination: string) {
        let subset = this.getNodeSubset(departure, destination);
        if (subset && subset.length > 0) {
            // A route for this departure and destination exists. No need to insert anything new
            return;
        }
        let depIdx = this.getNodeIdx(departure);
        let destIdx = this.getNodeIdx(destination);

        if (depIdx > destIdx && destIdx > -1) {
            // Departure and destination indexes for this entity are in reverse order.
            // Insert a new destination after the entity departure
            this.path.splice(depIdx, 0, destination);
        } else if (depIdx > -1) {
            // Departure exists in route. Insert destination to route.
            this.path.push(destination);
        } else if (destIdx > -1) {
            // Destination exists in route, but not departure. Insert departure before destination.
            this.path.splice(destIdx - 1, 0, departure);
        } else {
            // Neither departure nor destination are in the route. Insert both into route.
            this.path.push(departure);
            this.path.push(destination);
        }
    }

    getNodeIdxInSubset(locID: string, startIdx: number, endIdx: number) {
        let idx = this.path
            .slice(startIdx, endIdx)
            .findIndex((id) => id === locID);
        return idx === -1 ? -1 : startIdx + idx + 1;
    }

    getNodeIdx(locID: string) {
        if (locID === this.origin) return 0;

        let idx = this.path.findIndex((id) => id === locID);
        return idx === -1 ? -1 : idx + 1;
    }

    getNodeIdxs(locID: string) {
        if (locID === this.origin) return [0];
        let idxs: number[] = [];
        this.path.forEach((node, idx) => {
            if (node === locID) {
                idxs.push(idx + 1);
            }
        })
        return idxs;
    }

    getNodeSubset(startLoc: string, endLoc: string) {

        const path = this.getRoundTripPath();

        const [startIdx, endIdx] = getOptimalFlightPathSubset(path, startLoc, endLoc);

        if (endIdx - startIdx >= Infinity) {
            return []
        }

        return path.slice(startIdx, endIdx + 1)
    }

    // getNodeSubset(startLoc: string, endLoc: string){
    //     let startIdx = this.getNodeIdx(startLoc);
    //     let endIdx = this.getNodeIdx(endLoc);
    //
    //     if (startIdx > endIdx){
    //         // Reverse order found. Does the endLoc have duplicates?
    //         let idxs = this.getNodeIdxs(endLoc);
    //         if (idxs.length > 1){
    //             // Duplicate found. Find the next index that is larger than the starting index
    //             for (let i = 0; i < idxs.length; i++) {
    //                 const secondEndIdx = idxs[i];
    //                 if (secondEndIdx > startIdx){
    //                     endIdx = secondEndIdx;
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //
    //     if (startIdx === -1 || endIdx === -1){
    //         return undefined;
    //     }
    //
    //     if (startIdx === 0){
    //         // This is the origin
    //         return [ this.origin, ...this.path.slice(0, endIdx) ]
    //     }
    //     if (endIdx === 0){
    //         return [...this.path.slice(startIdx-1, this.path.length), this.origin ]
    //     }
    //     else if (endIdx === 0){
    //         return undefined
    //     }
    //     return this.path.slice(startIdx-1, endIdx)
    // }

    isRoundTrip() {
        return this.path[-1] === this.origin
    }

    getPath() {
        // If there is no origin, then there shouldn't be any other nodes
        if (!this.origin || this.path.length === 0) return []

        return [this.origin, ...this.path]
    }

    getRoundTripPath() {
        // If there is no origin, then there shouldn't be any other nodes
        if (!this.origin || this.path.length === 0) return []

        if (this.path[-1] === this.origin) {
            // Path is already returning to origin
            return [
                this.origin,
                ...this.path
            ]
        }

        return [
            this.origin,
            ...this.path,
            this.origin
        ]
    }

    getOrigin() {
        return this.origin;
    }

    setOrigin(locID: string) {
        this.origin = locID;
    }

    /**
     * Move node to a particular index in the path
     * @param locID Location ID to move
     * @param displaceLocID Location ID to push out of the way
     * @returns Reason of move failure. If undefined, then move was successful.
     */
    moveNode(locID: string, displaceLocID: string): string {
        let nodeIdx = this.getNodeIdx(locID);
        let insertAt = this.getNodeIdx(displaceLocID);
        if (nodeIdx === 0) {
            return "Cannot move origin.";
        }
        if (insertAt === 0) {
            return "Cannot move location before origin.";
        }
        this.path.splice(nodeIdx - 1, 1);
        this.path.splice(insertAt - 1, 0, locID);
        return;
    }

    getOriginalOrigin() {
        return this.originalOrigin;
    }
}