import {FormComponentProps} from "antd/lib/form";
import { Form} from "antd";
import {createFormFields, FormItemClassNames} from "../../../../common/form";
import DepDestSelect from "../../../../components/form/select/dep-dest-select";
import {NewLegFormData, NewLegFormFields} from "./types";


export interface NewLegFormProps extends FormComponentProps{
    formData: NewLegFormFields,
    onFieldsChange: (fields: NewLegFormFields) => void,
    onValuesChange?: (fields: NewLegFormData) => void
}

const NewLegForm: React.FC<NewLegFormProps> = (props) => {

    const { form } = props;

    const validator = DepDestSelect.validator;

    return <Form>
        <Form.Item className={FormItemClassNames.NoMargin}>
            {form.getFieldDecorator('depDest', {
                rules: [{
                    required: true,
                    validator: validator,
                    message: 'Please choose a departure and destination.'
                }]
            })(
                <DepDestSelect />
            )}
        </Form.Item>
    </Form>
}

export default Form.create<NewLegFormProps>({
    mapPropsToFields(props){
        if (!props.formData) return undefined
        return createFormFields(props.formData);
    },
    onFieldsChange(props, _, fields){
        if (props.onFieldsChange){
            props.onFieldsChange(fields);
        }
    },
    onValuesChange(props, _, values){
        if (props.onValuesChange){
            props.onValuesChange(values);
        }
    }
})(NewLegForm)