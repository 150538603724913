import gql from "graphql-tag";
import {
    ScheduledFlightFragment,
    ScheduledPaxCgoGroupV2Fragment
} from "Queries/Scheduling";
import {ScheduledGroup} from "../../../../common/types/schedule";

// Queries
const GetScheduledGroups = gql`
    query GetGroups(
        $flightid: ID!
        $tpID: ID!
        $customerID: ID!
        $scheduledFlightDate: AWSDate!
    ){
        flight: getFlight(_id: $flightid){
            ...ScheduledFlightFragment
        }
        groups: GetScheduledPaxCgoGroups(
            tpID: $tpID
            customerID: $customerID
            startDate: $scheduledFlightDate
            endDate: $scheduledFlightDate
            noCurrentCarrier: true
        ){
                
            ...ScheduledPaxCgoGroupV2Fragment
        }
    }
${ScheduledFlightFragment}
${ScheduledPaxCgoGroupV2Fragment}
`

// Mutations
const AddPaxToFlight = gql`
    mutation AddPaxAndCgoToFlight($flight: FlightNodeInput!, $paxIDs: [ID]!, $cgoIDs: [ID]!, $tpID: ID!){
        result: addPaxAndCgoToFlight(flight: $flight, paxIDs: $paxIDs, cgoIDs: $cgoIDs, tpID: $tpID){
            ...ScheduledFlightFragment
        }
    }
${ScheduledFlightFragment}
`;

export type QueryTypes = {
    GetScheduledGroups: {
        Vars: {
            flightid: string,
            tpID: string,
            customerID: string,
            scheduledFlightDate: string
        },
        Data: {
            flight?: any,
            groups?: ScheduledGroup[]
        }
    }
}

export type MutationTypes = {
    AddPaxToFlight: {
        Vars: {
            flight: any,
            paxIDs: string[],
            cgoIDs: string[],
            tpID: string
        },
        Data: {
            result: any
        }
    }
}

export const Queries = {
    GetScheduledGroups
} as const;

export const Mutations = {
    AddPaxToFlight
} as const;